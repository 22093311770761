import React, { useState, useCallback, useEffect } from 'react';
import { 
  ReactFlowProvider, 
  addEdge, 
  useNodesState, 
  useEdgesState
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Layout, Button, Input, Spin, message } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import ModuleSidebar from '../components/ModuleSidebar';
import ModuleConfigurator from '../components/ModuleConfigurator';
import FlowCanvas from './FlowCanvas';
import { nodeTypes, moduleConfigs } from '../modules/nodeTypes';
import './FlowBuilder.css';
import axios from '../../api/axiosConfig';
import { useFlow } from '../contexts/FlowContext';

const { Sider, Content } = Layout;

const FlowBuilderContent = ({ flow, onClose, isNew }) => {
  const [loading, setLoading] = useState(false);

  const { 
    nodes, setNodes, onNodesChange,
    edges, setEdges, onEdgesChange,
    selectedNode, setSelectedNode,
    isConfiguring, setIsConfiguring,
    flowData, setFlowData,
    onConnect
  } = useFlow();

  useEffect(() => {
    if (flow?.id) {
      setLoading(true);
      axios.get(`/automation/flows/${flow.id}`)
        .then(response => {
          setFlowData(response.data);
          const nodesWithNumbers = (response.data.nodes || []).map(node => {
            if (['flow-start', 'flow-finish'].includes(node.id)) {
              return node;
            }
            return {
              ...node,
              data: {
                ...node.data,
                nodeNumber: node.data.nodeNumber || getNextAvailableNodeNumber(response.data.nodes)
              }
            };
          });
          setNodes(nodesWithNumbers);
          setEdges(response.data.edges || []);
        })
        .catch(error => {
          console.error('Error fetching flow data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [flow, setNodes, setEdges, setFlowData]);

  const getNextAvailableNodeNumber = (nodes) => {
    const existingNumbers = nodes
      .filter(node => !['flow-start', 'flow-finish'].includes(node.id))
      .map(node => node.data.nodeNumber || 0);
    return Math.max(0, ...existingNumbers) + 1;
  };

  const handleSave = async () => {
    if (!flowData.name?.trim()) {
      message.error('Flow name is required');
      return;
    }

    setLoading(true);
    const saveData = {
      ...flowData,
      nodes,
      edges
    };

    try {
      if (isNew) {
        await axios.post('/automation/flows', saveData);
      } else {
        await axios.put(`/automation/flows/${flow.id}`, saveData);
      }
      onClose();
    } catch (error) {
      console.error('Error saving flow:', error);
    } finally {
      setLoading(false);
    }
  };

  const onNodeClick = useCallback((_, node) => {
    if (!isConfiguring) {
      setSelectedNode(node);
    }
  }, [isConfiguring]);

  const handleNodeUpdate = useCallback((id, data) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, ...data } } : node
      )
    );
    setSelectedNode(null);
    setIsConfiguring(false);
  }, [setNodes]);

  const updateNodesConnectionStatus = useCallback(() => {
    setNodes(currentNodes => 
      currentNodes.map(node => {
        const moduleConfig = moduleConfigs[node.type];
        const isConnected = moduleConfig?.moduleConfig?.isConnectedToSource?.(node.id, nodes, edges) ?? false;
        
        return {
          ...node,
          data: {
            ...node.data,
            isConnected
          }
        };
      })
    );
  }, [nodes, edges, setNodes]);

  useEffect(() => {
    updateNodesConnectionStatus();
  }, [nodes, edges, updateNodesConnectionStatus]);

  const handleConnect = useCallback((params) => {
    setEdges(eds => addEdge(params, eds));
    updateNodesConnectionStatus();
  }, [setEdges, updateNodesConnectionStatus]);

  useEffect(() => {
    if (!flowData.id && nodes.length === 0) {
      const startNode = {
        id: 'flow-start',
        type: 'flowStart',
        position: { x: 100, y: 100 },
        data: { 
          ...moduleConfigs.flowStart.defaultData,
          nodeNumber: getNextAvailableNodeNumber(nodes)
        }
      };

      const finishNode = {
        id: 'flow-finish',
        type: 'flowFinish',
        position: { x: 100, y: 500 },
        data: { ...moduleConfigs.flowFinish.defaultData }
      };

      setNodes([startNode, finishNode]);
    }
  }, [flowData.id, nodes.length, setNodes]);

  const onNodesDelete = useCallback((nodesToDelete) => {
    return nodesToDelete.filter(node => 
      !['flow-start', 'flow-finish'].includes(node.id)
    );
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div 
      className="flow-builder"
    >
      <div className="flow-builder-header">
        <div className="flow-title-inputs">
          <Input
            className="minimal-input flow-name-input"
            value={flowData.name}
            onChange={(e) => setFlowData(prev => ({ ...prev, name: e.target.value }))}
            placeholder="Flow name"
          />
          <Input
            className="minimal-input flow-description-input"
            value={flowData.description}
            onChange={(e) => setFlowData(prev => ({ ...prev, description: e.target.value }))}
            placeholder="Description"
          />
        </div>
        <div className="flow-actions">
          <Button 
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button 
            icon={<CloseOutlined />}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
      
      <div className="flow-builder-content">
        <Layout.Sider width={250} className="flow-builder-sidebar" theme="light">
          <ModuleSidebar />
        </Layout.Sider>
        
        <ReactFlowProvider>
          <FlowCanvas
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={handleConnect}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
          />
        </ReactFlowProvider>
        
        {isConfiguring && selectedNode && (
          <Layout.Sider 
            width={moduleConfigs[selectedNode.type]?.configurator?.width === 'wide' ? 500 : 300} 
            className="flow-builder-configurator" 
            theme="light"
          >
            <ModuleConfigurator
              selectedNode={selectedNode}
              onUpdate={handleNodeUpdate}
              onClose={() => {
                setSelectedNode(null);
                setIsConfiguring(false);
              }}
              onConfiguring={(configuring) => setIsConfiguring(configuring)}
            />
          </Layout.Sider>
        )}
      </div>
    </div>
  );
};

const FlowBuilder = ({ flow, onClose, isNew }) => (
  <FlowBuilderContent
    flow={flow}
    onClose={onClose}
    isNew={isNew}
  />
);

export default FlowBuilder;