export const dealFields = {
    // Basic Info
    'deal.name': { label: 'Name', type: 'string' },
    'deal.cvr': { label: 'CVR', type: 'string' },
    'deal.phone': { label: 'Phone', type: 'string' },
    'deal.email': { label: 'Email', type: 'string' },
    'deal.type': { label: 'Type', type: 'string', options: ['Holding', 'Drift'] },
    
    // Address
    'deal.address': { label: 'Address', type: 'string' },
    'deal.zipcode': { label: 'Zipcode', type: 'string' },
    'deal.city': { label: 'City', type: 'string' },
    
    // Business Info
    'deal.description': { label: 'Description', type: 'string' },
    'deal.economic_customer_number': { label: 'Economic Customer Number', type: 'string' },
    'deal.source': { label: 'Source', type: 'string' },
    'deal.onboard_status': { 
      label: 'Onboard Status', 
      type: 'string', 
      options: ['not_onboarded', 'onboarding', 'onboarded'] 
    },
    
    // Email Boxes
    'deal.main_mailbox': { label: 'Main Mailbox', type: 'string' },
    'deal.corpay_mailbox': { label: 'Corpay Mailbox', type: 'string' },
  
    // Data Object Fields
    'deal.data.title': { label: 'Title', type: 'string' },
    'deal.data.value': { label: 'Value', type: 'number' },
    'deal.data.status': { 
      label: 'Status', 
      type: 'string', 
      options: ['pending', 'won', 'lost'] 
    },
    'deal.data.currency': { label: 'Currency', type: 'string' },
    'deal.data.aktiv_kunde': { label: 'Aktiv Kunde', type: 'boolean' },
    'deal.data.momsfrister': { 
      label: 'Momsfrister', 
      type: 'string', 
      options: ['Kvartalsvis', 'Månedlig', 'Halvårlig'] 
    },
    'deal.data.contact_name': { label: 'Contact Name', type: 'string' },
    'deal.data.lønfrekvens': { 
      label: 'Lønfrekvens', 
      type: 'string', 
      options: ['Ingen løn', 'Månedlig', 'Kvartalsvis'] 
    },
    'deal.data.contact_email': { label: 'Contact Email', type: 'string' },
    'deal.data.contact_phone': { label: 'Contact Phone', type: 'string' },
    'deal.data.lønansvarlig': { label: 'Lønansvarlig', type: 'string' },
    'deal.data.andre_softwares': { label: 'Andre Softwares', type: 'string' },
    'deal.data.onboarding_dato': { label: 'Onboarding Dato', type: 'date' },
    'deal.data.virksomhedsform': { 
      label: 'Virksomhedsform', 
      type: 'string', 
      options: ['Enkeltmandsvirksomhed', 'ApS', 'A/S', 'IVS'] 
    },
    'deal.data.regnskabsprogram': { 
      label: 'Regnskabsprogram', 
      type: 'string', 
      options: ['Dinero', 'Economic', 'Billy', 'Other'] 
    },
    'deal.data.hvidvask_compliant': { label: 'Hvidvask Compliant', type: 'boolean' },
    'deal.data.sidste_fakturadato': { label: 'Sidste Fakturadato', type: 'date' },
    'deal.data.ansvarlig_bogholder': { label: 'Ansvarlig Bogholder', type: 'string' },
    'deal.data.bogføringsfrekvens': { 
      label: 'Bogføringsfrekvens', 
      type: 'string', 
      options: ['Månedlig', 'Kvartalsvis', 'Halvårlig'] 
    },
    'deal.data.kundenummer_i_economic': { label: 'Kundenummer i Economic', type: 'string' },
    'deal.data.seneste_momsopgørelse': { label: 'Seneste Momsopgørelse', type: 'string' },
    'deal.data.ansvarlig_lønbogholder': { label: 'Ansvarlig Lønbogholder', type: 'string' },
    'deal.data.ansvarlig_revisor_for_årsregnskab': { label: 'Ansvarlig Revisor for Årsregnskab', type: 'string' },
    'deal.data.hvem_er_ansvarlig_for_årsregnskab': { label: 'Hvem er Ansvarlig for Årsregnskab', type: 'string' },
    'deal.data.hvornår_slutter_næste_regnskabsår': { label: 'Hvornår Slutter Næste Regnskabsår', type: 'date' },
    'deal.data.antal_medarbejdere_til_lønhandtering': { label: 'Antal Medarbejdere til Lønhandtering', type: 'number' },
    'deal.data.kan_bankkonto_integreres_i_bogføringssystem': { label: 'Kan Bankkonto Integreres i Bogføringssystem', type: 'boolean' }
  };
  
  // Helper function to convert to array format
  export const getDealFieldsArray = () => {
    return Object.entries(dealFields).map(([key, config]) => ({
      key,
      ...config
    }));
  };