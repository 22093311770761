import { useState, useEffect } from 'react';
import axios from '../../api/axiosConfig';

export const useFlow = (flowId) => {
  const [flow, setFlow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFlow = async () => {
      if (!flowId) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`/automation/flows/${flowId}`);
        setFlow(response.data);
      } catch (err) {
        setError(err);
        console.error('Error fetching flow:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchFlow();
  }, [flowId]);

  return { flow, loading, error };
};