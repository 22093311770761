import React, { useState } from 'react';
import { Table, Button, Form, Select, InputNumber, message, Modal, Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import { hasRequiredPermission } from '../utils/permissionUtils';
import './TeamManagement.css';

const { Option } = Select;

function TeamManagement({ allUsers, currentUser, onUpdateUsers }) {
  const [editingUser, setEditingUser] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Filter out test users from display
  const displayUsers = allUsers.filter(user => !user.is_test_user);

  const handleEdit = async (values) => {
    try {
      setLoading(true);
      await axios.put(`/user/${editingUser.user_id}/update`, {
        new_user_id: values.user_id,
        permissions: values.permissions
      });
      
      message.success('User updated successfully');
      if (onUpdateUsers) await onUpdateUsers();
      setEditingUser(null);
    } catch (error) {
      message.error(error.response?.data?.message || 'Failed to update user');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    currentUser?.permissions === 'owner' && {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => `${record.name || ''} ${record.surname || ''}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
    },
    currentUser?.permissions === 'owner' && {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setEditingUser(record);
            form.setFieldsValue({
              user_id: record.user_id,
              permissions: record.permissions
            });
          }}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <div className="team-management">
      <h2 className="team-management-title">Team Management</h2>
      <Card className="team-management-card">
        <Table 
          dataSource={displayUsers}
          columns={columns}
          rowKey="user_id"
        />

        <Modal
          title="Edit User"
          open={!!editingUser}
          onCancel={() => setEditingUser(null)}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleEdit}
            layout="vertical"
          >
            <Form.Item
              name="user_id"
              label="User ID"
              rules={[{ required: true, message: 'Please input a user ID!' }]}
            >
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item
              name="permissions"
              label="Permissions"
              rules={[{ required: true, message: 'Please select permissions!' }]}
            >
              <Select>
                <Option value="user">User</Option>
                <Option value="admin">Admin</Option>
                <Option value="owner">Owner</Option>
                <Option value="accounting">Accounting</Option>
                <Option value="payroll">Payroll</Option>
                <Option value="sales">Sales</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
}

export default TeamManagement;