export const config = {
    type: 'scheduleTrigger',
    category: 'triggers',
    label: 'Schedule Trigger',
    icon: 'ClockCircleOutlined',
  
    // Default data when creating new node
    defaultData: {
      label: 'Schedule Trigger',
      schedule: '',
      timeUtc: null,
      dayOfWeek: null,
      dayOfMonth: null
    },
  
    // Module behavior configuration
    moduleConfig: {
      validateConnection: () => false, // Triggers can't have incoming connections
      isConnectedToSource: () => true, // Triggers are always considered connected
    },
  
    // Form configuration for ModuleConfigurator
    formConfig: {
      fields: [
        {
          name: 'schedule',
          label: 'Schedule Type',
          type: 'select',
          rules: [{ required: true }],
          options: [
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' }
          ]
        },
        {
          name: 'timeUtc',
          label: 'Time (UTC)',
          type: 'timePicker',
          rules: [{ required: true }],
          showWhen: (values) => values.schedule === 'daily',
          timeFormat: 'HH:mm',
          use12Hours: false
        },
        {
          name: 'dayOfWeek',
          label: 'Day of Week',
          type: 'select',
          rules: [{ required: true }],
          showWhen: (values) => values.schedule === 'weekly',
          options: [
            { value: 0, label: 'Sunday' },
            { value: 1, label: 'Monday' },
            { value: 2, label: 'Tuesday' },
            { value: 3, label: 'Wednesday' },
            { value: 4, label: 'Thursday' },
            { value: 5, label: 'Friday' },
            { value: 6, label: 'Saturday' }
          ]
        },
        {
          name: 'dayOfMonth',
          label: 'Day of Month',
          type: 'number',
          rules: [{ required: true }],
          showWhen: (values) => values.schedule === 'monthly',
          min: 1,
          max: 31
        }
      ]
    }
  };