import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Layout } from 'antd';
import { Routes, Route, useLocation, useSearchParams, Navigate, useParams, Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Home from './Home';
import Work from './Work';
import Dashboard from './Dashboard';
import Deals from '../customers/Deals';
import Accounts from '../customers/Accounts';
import Contacts from '../customers/Contacts';
import Products from '../customers/Products';
import OnboardingDashboard from '../onboarding/OnboardingDashboard';
import OnboardingCases from '../onboarding/OnboardingCases';
import OnboardingDetails from '../onboarding/OnboardingDetails';
import OnboardingFlow from '../onboarding/OnboardingFlow';
import CustomerOnboardingForm from '../onboarding/forms/CustomerOnboardingForm';
import OffboardingDashboard from '../offboarding/OffboardingDashboard';
import ChurnDetails from '../offboarding/ChurnDetails';
import Revibot from './Revibot';

import TaskView from '../taskboards/views/TaskView';

// Taskboards
import BogforingTasks from '../taskboards/BogforingTasks';
import LonTasks from '../taskboards/LonTasks';
import MomsTasks from '../taskboards/MomsTasks';
import ArsafslutningTasks from '../taskboards/ArsafslutningTasks';
import AndreOpgaverTasks from '../taskboards/AndreOpgaverTasks';
import ActivitiesTasks from '../taskboards/ActivitiesTasks';

// Migrations
import MigrateDeal from '../onboarding/MigrateDeal';

import CustomScrollbar from '../components/CustomScrollbar';

import './Pages.css';

const { Content } = Layout;

function Pages({ theme, toggleTheme, user }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [collapsed, setCollapsed] = useState(false);
  const [users, setUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const isOnboardingForm = location.pathname.includes('/onboarding/customer-onboarding-form');
  const navigate = useNavigate();

  // Memoize the updateUsers callback
  const updateUsers = useCallback((allUsers, online) => {
    setUsers(allUsers);
    setOnlineUsers(online);
  }, []); // Empty dependency array since it doesn't depend on any props or state

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleDirectTaskRoute = () => {
      const navigation = window.performance.getEntriesByType('navigation')[0];
      const isDirectNavigation = navigation?.type === 'reload' || navigation?.type === 'navigate';
      const isFromWorkPage = location.state?.fromWork;
      const isTaskBoardPath = location.pathname.startsWith('/boards/');
      const hasNavigated = sessionStorage.getItem('hasNavigated');

      // Skip redirect if we're already on a taskboard path or have already navigated
      if (isDirectNavigation && !isFromWorkPage && !isTaskBoardPath && !hasNavigated) {
        const match = location.pathname.match(/^\/work\/([^/]+)\/(\d+)/);
        if (match) {
          const [, taskType, taskId] = match;
          
          // Map the backend task type to the frontend board type
          let boardType;
          switch(taskType) {
            case 'other':
              boardType = 'andre-opgaver';
              break;
            case 'activity':
              boardType = 'activities';
              break;
            default:
              // For recurring tasks (bogforing, lon, moms, arsafslutning)
              boardType = taskType;
          }

          sessionStorage.setItem('hasNavigated', 'true');
          navigate(`/boards/${boardType}/${taskId}`, { 
            replace: true,
            state: { directNavigation: true }
          });
        }
      }
    };

    handleDirectTaskRoute();

    // Cleanup navigation flag on unmount
    return () => {
      sessionStorage.removeItem('hasNavigated');
    };
  }, [location, navigate]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header 
        theme={theme} 
        toggleTheme={toggleTheme} 
        toggleSidebar={toggleCollapsed}
        collapsed={collapsed}
        user={user}
        onUpdateUsers={updateUsers}
      />
      <Layout hasSider>
        {!isOnboardingForm && (
          <Sidebar 
            collapsed={collapsed} 
            toggleCollapsed={toggleCollapsed} 
            theme={theme} 
            user={user}
          />
        )}
        <Layout className="site-layout">
          <Content
            className={`site-layout-background ${isOnboardingForm ? 'full-width' : ''}`}
            style={{
              padding: 20,
              minHeight: 280,
            }}
          >
            <Routes>
              <Route path="/home" element={<Home user={user} />} />

              <Route path="/work" element={<WorkWithTaskView />}>
                <Route path=":boardType/:taskId" element={null} />
              </Route>
              
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/revibot" element={<Revibot />} />
              
              <Route path="/customers/deals" element={<Deals />} />
              <Route path="/customers/accounts" element={<Accounts />} />
              <Route path="/customers/contacts" element={<Contacts />} />
              <Route path="/customers/products" element={<Products />} />

              <Route path="/onboarding" element={<OnboardingDashboard />} />
              <Route path="/onboarding/cases" element={<OnboardingCases initialFilter={searchParams.get('filter')} />} />
              <Route path="/onboarding/flow/form/:formExecutionId" element={<OnboardingFlow />} />

              <Route path="/offboarding" element={<OffboardingDashboard />} />
              <Route path="/offboarding/:id" element={<ChurnDetails />} />

              <Route path="/onboarding/migrations" element={<MigrateDeal />} />

              {/* Use wildcard routes for taskboards */}
              <Route 
                path="/boards/:boardType/*" 
                element={<TaskBoardRouter users={users} />}
              />
              
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

function WorkWithTaskView() {
  const location = useLocation();
  const { boardType, taskId } = useParams();

  return (
    <>
      <Work />
      <Outlet />
      {boardType && taskId && <TaskView boardType={boardType} taskId={taskId} />}
    </>
  );
}

const TaskBoardRouter = React.memo(({ users }) => {
  const { boardType } = useParams();
  const location = useLocation();
  
  // Memoize the taskboard components mapping
  const taskBoardComponents = useMemo(() => ({
    bogforing: (props) => <BogforingTasks {...props} users={users} key={location.key} />,
    lon: (props) => <LonTasks {...props} users={users} key={location.key} />,
    moms: (props) => <MomsTasks {...props} users={users} key={location.key} />,
    arsafslutning: (props) => <ArsafslutningTasks {...props} users={users} key={location.key} />,
    'andre-opgaver': (props) => <AndreOpgaverTasks {...props} users={users} key={location.key} />,
    activities: (props) => <ActivitiesTasks {...props} users={users} key={location.key} />,
  }), [users, location.key]);

  // Modify how we render the component
  const TaskBoardComponent = useMemo(() => {
    const Component = taskBoardComponents[boardType];
    if (!Component) return <Navigate to="/work" replace />;

    // Only use the key if it's a refresh or direct navigation
    const isTaskView = location.pathname.includes(`/boards/${boardType}/`);
    return Component({ key: isTaskView ? boardType : location.key });
  }, [boardType, taskBoardComponents, location]);

  return TaskBoardComponent;
});

export default Pages;
