import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Button, DatePicker, message, Select, Popconfirm, Tag } from 'antd';
import axios from '../api/axiosConfig';
import { TeamOutlined, ShoppingOutlined } from '@ant-design/icons';
import './Products.css';
import DealDrawer from '../drawers/DealDrawer';

const { Option } = Select;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [form] = Form.useForm();
  const [dealModalVisible, setDealModalVisible] = useState(false);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [selectedDealDetails, setSelectedDealDetails] = useState(null);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]); // New state for billing history
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);

  useEffect(() => {
    fetchDeals();
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (selectedDealId) {
      fetchProductsForDeal(selectedDealId);
      fetchBillingHistoryForDeal(selectedDealId);
    } else if (selectedAccountId) {
      fetchProductsForAccount(selectedAccountId);
      fetchBillingHistoryForAccount(selectedAccountId);
    } else {
      setProducts([]);
      setBillingHistory([]); // Clear billing history if no deal or account is selected
    }
  }, [selectedDealId, selectedAccountId]);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals');
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
      message.error('Failed to fetch deals');
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      message.error('Failed to fetch accounts');
    }
  };

  const fetchProductsForDeal = async (dealId) => {
    try {
      const response = await axios.get(`/account/deals/${dealId}/products`);
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products for deal:', error);
      message.error('Failed to fetch products for deal');
      setLoading(false);
    }
  };

  const fetchProductsForAccount = async (accountId) => {
    try {
      const response = await axios.get(`/account/accounts/${accountId}/products`);
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products for account:', error);
      message.error('Failed to fetch products for account');
      setLoading(false);
    }
  };

  const fetchBillingHistoryForDeal = async (dealId) => {
    try {
      const response = await axios.get(`/invoice/deals/${dealId}/billing_history`);
      setBillingHistory(response.data);
    } catch (error) {
      console.error('Error fetching billing history for deal:', error);
      message.error('Failed to fetch billing history for deal');
    }
  };

  const fetchBillingHistoryForAccount = async (accountId) => {
    try {
      const response = await axios.get(`/invoice/accounts/${accountId}/billing_history`);
      setBillingHistory(response.data);
    } catch (error) {
      console.error('Error fetching billing history for account:', error);
      message.error('Failed to fetch billing history for account');
    }
  };

  const showProductDetails = (product) => {
    setSelectedProduct(product);
    form.setFieldsValue(product);
    setModalOpen(true);
  };

  const handleUpdate = async (values) => {
    try {
      await axios.put(`/account/products/${selectedProduct.id}`, values);
      message.success('Product updated successfully');
      setModalOpen(false);
      if (selectedDealId) {
        fetchProductsForDeal(selectedDealId);
      } else if (selectedAccountId) {
        fetchProductsForAccount(selectedAccountId);
      }
    } catch (error) {
      console.error('Error updating product:', error);
      message.error('Failed to update product');
    }
  };

  const showDealDetails = (deal) => {
    setSelectedDealId(deal.id);
    setDealDrawerVisible(true);
  };

  const showAccountDetails = async (account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`);
      setSelectedAccountDetails(response.data);
      setAccountModalVisible(true);
    } catch (error) {
      console.error('Error fetching account details:', error);
      message.error('Failed to fetch account details');
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`/product/products/${productId}`);
      message.success('Product deleted successfully');
      if (selectedDealId) {
        fetchProductsForDeal(selectedDealId);
      } else if (selectedAccountId) {
        fetchProductsForAccount(selectedAccountId);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      message.error('Failed to delete product');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Item Price',
      dataIndex: 'item_price',
      key: 'item_price',
      render: (price) => price !== undefined ? `${price.toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} DKK` : 'N/A',
    },
    {
      title: 'Sum',
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => sum !== undefined ? `${sum.toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} DKK` : 'N/A',
    },
    {
      title: 'Deal',
      dataIndex: ['deal', 'name'],
      key: 'deal',
      render: (name, record) => record.deal ? (
        <Button type="link" onClick={() => showDealDetails(record.deal)} className="tag-button">
          <Tag color="blue" className="deal-tag">
            <ShoppingOutlined /> {name}
          </Tag>
        </Button>
      ) : 'N/A',
    },
    {
      title: 'Account',
      dataIndex: ['deal', 'account', 'company_name'],
      key: 'account',
      render: (name, record) => record.deal && record.deal.account ? (
        <Button type="link" onClick={() => showAccountDetails(record.deal.account)} className="tag-button">
          <Tag color="green" className="account-tag">
            <TeamOutlined /> {name}
          </Tag>
        </Button>
      ) : 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showProductDetails(record)}>Edit</Button>
      ),
    },
  ];

  const billingColumns = [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      key: 'product_code',
    },
    {
      title: 'Sum',
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => sum !== undefined ? `${sum.toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} DKK` : 'N/A',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => date || 'N/A',
    },
  ];

  // Add these two new functions
  const filterDeals = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const filterAccounts = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <div className="products-container">
      <h1>Products</h1>
      <div className="selector-container">
        <Select
          style={{ width: 200 }}
          placeholder="Select a deal"
          value={selectedDealId}
          onChange={(value) => {
            setSelectedDealId(value);
            setSelectedAccountId(null);
            if (value) {
              fetchProductsForDeal(value);
              fetchBillingHistoryForDeal(value);
            } else {
              setProducts([]);
              setBillingHistory([]);
            }
          }}
          allowClear
          showSearch
          filterOption={filterDeals}
          optionFilterProp="children"
        >
          {deals.map((deal) => (
            <Option key={deal.id} value={deal.id}>{deal.name}</Option>
          ))}
        </Select>
        <span className="selector-divider">or</span>
        <Select
          style={{ width: 200 }}
          placeholder="Select an account"
          value={selectedAccountId}
          onChange={(value) => {
            setSelectedAccountId(value);
            setSelectedDealId(null);
            if (value) {
              fetchProductsForAccount(value);
              fetchBillingHistoryForAccount(value);
            } else {
              setProducts([]);
              setBillingHistory([]);
            }
          }}
          allowClear
          showSearch
          filterOption={filterAccounts}
          optionFilterProp="children"
        >
          {accounts.map((account) => (
            <Option key={account.id} value={account.id}>{account.company_name}</Option>
          ))}
        </Select>
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          dataSource={products}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
          className="products-table"
          scroll={{ x: 'max-content' }}
        />
      </div>
      {(selectedDealId || selectedAccountId) && (
        <div className="table-container">
          <Table
            columns={billingColumns}
            dataSource={billingHistory}
            rowKey="date"
            pagination={{ pageSize: 10 }}
            className="billing-history-table"
            title={() => 'Billing History'}
            scroll={{ x: 'max-content' }}
          />
        </div>
      )}
      <Modal
        title="Product Details"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleUpdate} layout="vertical">
          <Form.Item name="name" label="Name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="code" label="Code">
            <Input disabled />
          </Form.Item>
          <Form.Item name="item_price" label="Item Price" rules={[{ required: true }]}>
            <Input type="number" step="0.01" />
          </Form.Item>
          <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="billing_frequency" label="Billing Frequency">
            <Select>
              <Option value="monthly">Monthly</Option>
              <Option value="quarterly">Quarterly</Option>
              <Option value="yearly">Yearly</Option>
            </Select>
          </Form.Item>
          <Form.Item name="last_invoice_date" label="Last Invoice Date">
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
              Update Product
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this product?"
              onConfirm={() => {
                handleDeleteProduct(selectedProduct.id);
                setModalOpen(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>Delete Product</Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Deal Details"
        open={dealModalVisible}
        onCancel={() => setDealModalVisible(false)}
        footer={null}
      >
        {selectedDealDetails && (
          <div>
            <p><strong>Name:</strong> {selectedDealDetails.name}</p>
            <p><strong>CVR:</strong> {selectedDealDetails.cvr}</p>
            <p><strong>Email:</strong> {selectedDealDetails.email}</p>
            <p><strong>Phone:</strong> {selectedDealDetails.phone}</p>
          </div>
        )}
      </Modal>
      <Modal
        title="Account Details"
        open={accountModalVisible}
        onCancel={() => setAccountModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setAccountModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedAccountDetails && (
          <div>
            <p><strong>Company Name:</strong> {selectedAccountDetails.company_name}</p>
            <p><strong>CVR:</strong> {selectedAccountDetails.cvr || 'N/A'}</p>
            <p><strong>Address:</strong> {selectedAccountDetails.address || 'N/A'}</p>
            <p><strong>Zipcode:</strong> {selectedAccountDetails.zipcode || 'N/A'}</p>
            <p><strong>City:</strong> {selectedAccountDetails.city || 'N/A'}</p>
          </div>
        )}
      </Modal>
      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false);
        }}
        dealId={selectedDealId}
      />
    </div>
  );
};

export default Products;
