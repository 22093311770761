import React, { useState, useEffect } from 'react';
import { Card, Table, Button, message } from 'antd';
import { UserOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './OffboardingDashboard.css';
import ChurnModal from './ChurnModal';
import axios from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';

const OffboardingDashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stats, setStats] = useState({ active: 0, pending: 0, completed: 0 });
  const [churns, setChurns] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchStats();
    fetchChurns();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await axios.get('/churn/stats');
      setStats(response.data);
    } catch (error) {
      message.error('Failed to fetch churn statistics');
      console.error('Error:', error);
    }
  };

  const fetchChurns = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/churn/');
      setChurns(response.data);
    } catch (error) {
      message.error('Failed to fetch churns');
      console.error('Error:', error);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'deal_name',
      key: 'deal_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const statusMap = {
          new: 'Pending',
          in_progress: 'In Progress',
          churned: 'Completed',
          reverted: 'Reverted'
        };
        return statusMap[status] || status;
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="link" 
          onClick={() => navigate(`/offboarding/${record.id}`)}
        >
          View Details
        </Button>
      ),
    }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleChurnSubmit = async (churnData) => {
    try {
      await axios.post('/churn/', churnData);
      message.success('Churn process initiated successfully');
      fetchStats();
      fetchChurns();
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to initiate churn process');
      console.error('Error:', error);
    }
  };

  return (
    <div className="offboarding-dashboard">
      <h1>Offboarding Dashboard</h1>
      <div className="offboarding-dashboard-cards">
        <Card className="dashboard-card">
          <UserOutlined className="card-icon" />
          <div className="card-content">
            <h2>{stats.active}</h2>
          </div>
          <p>Active Offboardings</p>
        </Card>
        <Card className="dashboard-card">
          <ClockCircleOutlined className="card-icon" />
          <div className="card-content">
            <h2>{stats.pending}</h2>
          </div>
          <p>Pending Offboardings</p>
        </Card>
        <Card className="dashboard-card">
          <CheckCircleOutlined className="card-icon" />
          <div className="card-content">
            <h2>{stats.completed}</h2>
          </div>
          <p>Completed Offboardings</p>
        </Card>
      </div>

      <div className="churned-customers-input">
        <Button type="primary" onClick={showModal}>
          Add Churned Customer
        </Button>
      </div>

      <ChurnModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={handleChurnSubmit}
      />

      <div className="latest-offboardings">
        <h2>Latest Offboardings</h2>
        <Table 
          columns={columns} 
          dataSource={churns} 
          rowKey="id"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default OffboardingDashboard;