import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, message } from 'antd';
import { RobotOutlined, ToolOutlined, SettingOutlined, ExperimentOutlined } from '@ant-design/icons';
import tuesdayDark from '../assets/logos/tuesday_x500_w.png';
import tuesdayLight from '../assets/logos/tuesday_x500_b.png';
import digidashboardDark from '../assets/logos/dashboard_x500_w.png';
import digidashboardLight from '../assets/logos/dashboard_x500_b.png';
import { hasRequiredPermission } from '../utils/permissionUtils';
import './MainMenu.css';

function MainMenu({ theme, user }) {
  console.log('MainMenu rendered. User:', user);
  console.log('MainMenu rendered. Theme:', theme);

  const navigate = useNavigate();
  const tuesdayLogo = theme === 'dark' ? tuesdayDark : tuesdayLight;
  const digidashboardLogo = theme === 'dark' ? digidashboardDark : digidashboardLight;

  if (!user) {
    console.log('User is null or undefined');
    return <div>Loading...</div>;
  }

  console.log('User permissions:', user.permissions);

  const handleAppSelection = (app) => {
    if (app === 'crm' && hasRequiredPermission(user.permissions, 'user')) {
      navigate('/home');
    } else if (app === 'dashboard' && hasRequiredPermission(user.permissions, 'admin')) {
      navigate('/digi-dashboard');
    } else if (app === 'bot' && hasRequiredPermission(user.permissions, 'user')) {
      navigate('/bot');
    } else if (app === 'officebot' && hasRequiredPermission(user.permissions, 'user')) {
      navigate('/officebot');
    } else if (app === 'custom-gpts' && hasRequiredPermission(user.permissions, 'admin')) {
      navigate('/custom-gpts');
    } else if (app === 'admin' && hasRequiredPermission(user.permissions, 'owner')) {
      navigate('/admin');
    } else {
      message.error('You do not have permission to access this application');
    }
  };

  return (
    <div className="main-menu">
      <h1>Welcome to Digi-Tal ERP</h1>
      <div className="app-categories">
        <div className="category apps-category">
          <h2>Apps</h2>
          <div className="app-selection">
            <Card
              hoverable
              className={`mm-app-card ${!hasRequiredPermission(user.permissions, 'user') ? 'disabled' : ''}`}
              onClick={() => handleAppSelection('crm')}
            >
              <img src={tuesdayLogo} alt="Tuesday Logo" className="tuesday-logo" />
              <p>CRM & Task Management</p>
              {!hasRequiredPermission(user.permissions, 'user') && <p className="permission-message">No Access</p>}
            </Card>
            <Card
              hoverable
              className={`mm-app-card ${!hasRequiredPermission(user.permissions, 'admin') ? 'disabled' : ''}`}
              onClick={() => handleAppSelection('dashboard')}
            >
              <img src={digidashboardLogo} alt="Dashboard Logo" className="dashboard-logo" />
              <p>View and analyze business data</p>
              {!hasRequiredPermission(user.permissions, 'admin') && <p className="permission-message">No Access</p>}
            </Card>
            <Card
              hoverable
              className={`mm-app-card ${!hasRequiredPermission(user.permissions, 'user') ? 'disabled' : ''}`}
              onClick={() => handleAppSelection('bot')}
            >
              <div className="bot-logo-container">
                <RobotOutlined className="bot-icon" />
                <span className="bot-text">Digi-Regnskabsbot</span>
              </div>
              <p>AI Chatbot for all your accounting questions</p>
              {!hasRequiredPermission(user.permissions, 'user') && <p className="permission-message">No Access</p>}
            </Card>
          </div>
        </div>
        <div className="category tools-category">
          <h2>Tools</h2>
          <div className="app-selection">
            <Card
              hoverable
              className={`mm-app-card ${!hasRequiredPermission(user.permissions, 'user') ? 'disabled' : ''}`}
              onClick={() => handleAppSelection('officebot')}
            >
              <div className="officebot-logo-container">
                <ToolOutlined className="officebot-icon" />
                <span className="officebot-text">Officebot Tools</span>
              </div>
              <p>Automations and tools for Officebot</p>
              {!hasRequiredPermission(user.permissions, 'user') && <p className="permission-message">No Access</p>}
            </Card>
            <Card
              hoverable
              className={`mm-app-card ${!hasRequiredPermission(user.permissions, 'admin') ? 'disabled' : ''}`}
              onClick={() => handleAppSelection('custom-gpts')}
            >
              <div className="custom-gpts-logo-container">
                <ExperimentOutlined className="custom-gpts-icon" />
                <span className="custom-gpts-text">Custom GPTs</span>
              </div>
              <p>GPT models with custom data</p>
              {!hasRequiredPermission(user.permissions, 'admin') && <p className="permission-message">No Access</p>}
            </Card>
          </div>
        </div>
        <div className="category admin-category">
          <h2>Admin</h2>
          <div className="app-selection">
            <Card
              hoverable
              className={`mm-app-card ${!hasRequiredPermission(user.permissions, 'owner') ? 'disabled' : ''}`}
              onClick={() => handleAppSelection('admin')}
            >
              <div className="admin-logo-container">
                <SettingOutlined className="admin-icon" />
                <span className="admin-text">Admin</span>
              </div>
              <p>Admin control panel</p>
              {!hasRequiredPermission(user.permissions, 'owner') && <p className="permission-message">No Access</p>}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
