import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const UserAvatar = ({ 
  user, 
  size = 32, 
  style = {}, 
  className = '', 
  onClick,
  tooltipPlacement = 'top',
  disableTooltip = false
}) => {
  if (!user) return null;

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const getFontSize = () => {
    return size > 100 ? '2.5rem' : `${size * 0.4}px`;
  };

  const getAvatarUrl = () => {
    if (!user.avatar_urls || Object.keys(user.avatar_urls).length === 0) return null;
    return size > 40 ? user.avatar_urls.medium : user.avatar_urls.small;
  };

  const backgroundColor = user.settings?.avatar_color || '#f56a00';

  const combinedStyle = {
    backgroundColor,
    fontSize: getFontSize(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...style,
  };

  const avatar = (
    <Avatar
      size={size}
      src={getAvatarUrl()}
      style={combinedStyle}
      className={className}
      onClick={onClick}
      icon={!getAvatarUrl() && !user.name && !user.surname ? <UserOutlined /> : null}
    >
      {!getAvatarUrl() && (user.name || user.surname) && getInitials(user.name, user.surname)}
    </Avatar>
  );

  if (disableTooltip) {
    return avatar;
  }

  return (
    <Tooltip 
      title={`${user.name || ''} ${user.surname || ''}`.trim() || 'Unknown User'}
      placement={tooltipPlacement}
    >
      {avatar}
    </Tooltip>
  );
};

export default UserAvatar;
