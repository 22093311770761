import { 
  getAvailableFieldsFromTrigger, 
  getFieldConfig,
  isNodeConnectedToStart 
} from '../../../utils/flowUtils';
import { getAvailableFields } from '../DataCondition';

export const config = {
    type: 'dataCondition',
    category: 'conditions',
    label: 'Data Condition',
    icon: 'BranchesOutlined',
  
    // Default data when creating new node
    defaultData: {
      label: 'New Condition',
      condition: {
        conditions: [{
          key: '0',
          dataField: '',
          operator: '',
          value: '',
          logic: 'AND'
        }]
      }
    },
  
    // Module behavior configuration
    moduleConfig: {
      validateConnection: (sourceNode) => {
        return sourceNode?.type === 'eventTrigger';
      },
      isConnectedToSource: (nodeId, nodes, edges) => {
        return isNodeConnectedToStart(nodeId, nodes, edges);
      },
      getAvailableFields: getAvailableFields
    },
  
    // Form configuration for ModuleConfigurator
    formConfig: {
      fields: [
        {
          name: ['condition', 'conditions'],
          type: 'conditionArray',
          rules: [{ required: true }],
          subFields: {
            dataField: {
              label: 'Field',
              type: 'dynamic',
              rules: [{ required: true }],
              getDynamicInput: (value, nodeId, nodes, edges) => {
                const availableFields = getAvailableFieldsFromTrigger(nodeId, nodes, edges);
                return {
                  type: 'select',
                  options: availableFields.map(field => ({
                    value: field.key,
                    label: field.label
                  }))
                };
              }
            },
            operator: {
              label: 'Operator',
              type: 'select',
              rules: [{ required: true }],
              options: [
                { value: 'is', label: 'Is' },
                { value: 'is_not', label: 'Is Not' },
                { value: 'has_changed_to', label: 'Has Changed To' },
                { value: 'is_more_than', label: 'Is More Than' },
                { value: 'is_more_than_equal', label: 'Is More Than or Equal' },
                { value: 'is_less_than', label: 'Is Less Than' },
                { value: 'is_less_than_equal', label: 'Is Less Than or Equal' },
                { value: 'was', label: 'Was' },
                { value: 'was_more_than', label: 'Was More Than' },
                { value: 'is_between', label: 'Is Between' },
                { value: 'is_not_between', label: 'Is Not Between' },
                { value: 'has_changed', label: 'Has Changed' },
                { value: 'is_empty', label: 'Is Empty' },
                { value: 'is_not_empty', label: 'Is Not Empty' }
              ]
            },
            value: {
              label: 'Value',
              type: 'dynamic',
              rules: [{ required: true }],
              showWhen: (condition) => !['has_changed', 'is_empty', 'is_not_empty'].includes(condition?.operator),
              getDynamicInput: (selectedField, nodeId, nodes, edges) => {
                const availableFields = getAvailableFieldsFromTrigger(nodeId, nodes, edges);
                const fieldConfig = getFieldConfig(selectedField, availableFields);
                
                if (!fieldConfig) return { type: 'input' };

                if (fieldConfig.options) {
                  return {
                    type: 'select',
                    options: fieldConfig.options.map(opt => ({ 
                      value: opt, 
                      label: opt 
                    }))
                  };
                }

                return {
                  type: 'input',
                  inputType: fieldConfig.type === 'number' ? 'number' : 'text'
                };
              }
            },
            logic: {
              label: 'Logic',
              type: 'select',
              options: [
                { value: 'AND', label: 'AND' },
                { value: 'OR', label: 'OR' }
              ],
              showWhen: (condition, index, conditions) => index < conditions.length - 1
            }
          }
        }
      ]
    }
  };