import React, { useState, useEffect } from 'react';
import { Modal, Select, DatePicker, Input, Form, message } from 'antd';
import dayjs from 'dayjs';
import axios from '../api/axiosConfig';

const { Option } = Select;
const { TextArea } = Input;

const ChurnModal = ({ visible, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showOtherReason, setShowOtherReason] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchDeals();
      form.resetFields();
      setShowOtherReason(false);
    }
  }, [visible, form]);

  const fetchDeals = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/churn/deals');
      setDeals(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching deals:', error);
      if (error.response) {
        console.log('Error response:', error.response.data);
        console.log('Error status:', error.response.status);
      } else if (error.request) {
        console.log('Error request:', error.request);
      } else {
        console.log('Error message:', error.message);
      }
      message.error('Failed to fetch deals');
      setDeals([]);
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onSubmit(values);
      form.resetFields();
    });
  };

  const handleChurnReasonChange = (values) => {
    setShowOtherReason(values.includes('other'));
    if (values.length > 3) {
      form.setFieldsValue({ churnReasons: values.slice(0, 3) });
    }
  };

  return (
    <Modal
      title="Add Churned Customer"
      open={visible}
      onCancel={() => {
        form.resetFields();
        setShowOtherReason(false);
        onClose();
      }}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="dealId"
          label="Select Deal"
          rules={[{ required: true, message: 'Please select a deal' }]}
        >
          <Select
            showSearch
            placeholder="Search for a deal"
            optionFilterProp="children"
            loading={loading}
          >
            {Array.isArray(deals) && deals.map((deal) => (
              <Option key={deal.id} value={deal.id}>
                {deal.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="churnDate"
          label="Churn Date"
          rules={[{ required: true, message: 'Please select a churn date' }]}
          initialValue={dayjs()}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="churnReasons"
          label="Churn Reasons (Select up to 3)"
          rules={[{ required: true, message: 'Please select at least one churn reason' }]}
        >
          <Select 
            mode="multiple"
            placeholder="Select reasons"
            onChange={handleChurnReasonChange}
            maxTagCount={3}
          >
            <Option value="price">Price</Option>
            <Option value="service">Service</Option>
            <Option value="product">Product</Option>
            <Option value="competitor">Competitor</Option>
            <Option value="oversold">Oversold</Option>
            <Option value="internal_accountant">Internal accountant</Option>
            <Option value="physical_accountant">Wants physical accountant/auditor</Option>
            <Option value="business_closed">Business closed</Option>
            <Option value="terminated">Terminated</Option>
            <Option value="lost_trust">Lost trust</Option>
            <Option value="communication_issues">Lack of communication, miscommunication or spam</Option>
            <Option value="errors">Errors (onboarding, bookkeeping, etc.)</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
        {showOtherReason && (
          <Form.Item
            name="otherReason"
            label="Other Reason"
            rules={[{ required: true, message: 'Please specify the other reason' }]}
          >
            <Input placeholder="Specify the other reason" />
          </Form.Item>
        )}
        <Form.Item
          name="details"
          label="Details"
          rules={[{ required: true, message: 'Please provide details' }]}
        >
          <TextArea rows={4} placeholder="Describe the churn reasons in detail" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChurnModal;