import React from 'react';
import { Modal, List, Typography, Divider, Tooltip } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import CustomScrollbar from '../components/CustomScrollbar';
import './ActivityScreen.css';
import UserAvatar from '../components/UserAvatar';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const { Text } = Typography;

function ActivityScreen({ visible, onClose, users }) {
  const getDisplayName = (user) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const getLocalTime = (locationInfo) => {
    if (!locationInfo || !locationInfo.timezone) return 'Unknown';
    try {
      return dayjs().tz(locationInfo.timezone).format('HH:mm');
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Unknown';
    }
  };

  const getLocation = (locationInfo) => {
    if (!locationInfo) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  const getLastOnline = (lastHeartbeat) => {
    if (!lastHeartbeat) return 'Never';
    
    // Create UTC date objects for both times
    const lastHeartbeatUTC = dayjs.utc(lastHeartbeat);
    const nowUTC = dayjs.utc();
    
    return lastHeartbeatUTC.from(nowUTC);
  };

  // Filter out test users
  const realUsers = users.filter(user => !user.is_test_user);
  
  const onlineUsers = realUsers.filter(user => user.status === 'online');
  const inactiveUsers = realUsers.filter(user => user.status === 'inactive');
  const offlineUsers = realUsers.filter(user => user.status === 'offline');

  const renderUserList = (userList, status) => (
    <List
      itemLayout="horizontal"
      dataSource={userList}
      renderItem={user => (
        <List.Item>
          <List.Item.Meta
            avatar={<UserAvatar user={user} size={40} />}
            title={getDisplayName(user)}
            description={
              <>
                <Text>{user.email}</Text>
                <br />
                <Text>{getLocation(user.location_info)}</Text>
                <br />
                <Text>Local time: {getLocalTime(user.location_info)}</Text>
                {status === 'online' && <span className="online-indicator">● Online</span>}
                {status === 'inactive' && <span className="inactive-indicator">● Inactive</span>}
                {status === 'offline' && (
                  <Tooltip title={`Last online: ${getLastOnline(user.last_heartbeat)}`}>
                    <span className="offline-indicator">● Offline</span>
                  </Tooltip>
                )}
              </>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <Modal
      title="Team Members"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <CustomScrollbar className="activity-screen-scrollbar">
        <div style={{ padding: '24px' }}>
          {onlineUsers.length > 0 && (
            <>
              <Divider orientation="left">Online Users</Divider>
              {renderUserList(onlineUsers, 'online')}
            </>
          )}
          {inactiveUsers.length > 0 && (
            <>
              <Divider orientation="left">Inactive Users</Divider>
              {renderUserList(inactiveUsers, 'inactive')}
            </>
          )}
          {offlineUsers.length > 0 && (
            <>
              <Divider orientation="left">Offline Users</Divider>
              {renderUserList(offlineUsers, 'offline')}
            </>
          )}
        </div>
      </CustomScrollbar>
    </Modal>
  );
}

export default ActivityScreen;
