import React from 'react';
import { Button, message, Card, Space } from 'antd';
import axios from '../../api/axiosConfig';

const AdminPipedrive = () => {
    const fetchDealFields = async () => {
        try {
            const response = await axios.get('/admin/pipedrive/dealfields');
            message.success('Deal fields printed to server console!');
        } catch (error) {
            message.error('Error: ' + (error.response?.data?.error || error.message));
        }
    };

    return (
        <Card title="Pipedrive Data Fetching">
            <Space direction="vertical">
                <Button 
                    type="primary" 
                    onClick={fetchDealFields}
                >
                    Fetch Deal Fields
                </Button>
            </Space>
        </Card>
    );
};

export default AdminPipedrive;