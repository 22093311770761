import React from 'react';
import { Handle } from '@xyflow/react';
import { Card } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import NodeNumber from '../../../components/NodeNumber';
import '../../ModuleNode.css';

const ShowForm = ({ data, id, selected }) => {
  return (
    <Card 
      size="small"
      title={<div className="node-title"><FormOutlined /> Show Form</div>}
      className={`module-node action-node ${selected ? 'selected' : ''} ${!data.isConnected ? 'disconnected' : ''}`}
      style={{ width: 200 }}
    >
      <NodeNumber nodeId={id} number={data.nodeNumber} />
      <Handle type="target" position="top" style={{ background: '#555' }} />
      <div className="node-content">
        <div className="node-label">{data.label}</div>
        {data.formConfig?.title && (
          <div>
            <span className="node-label">Form:</span>
            {data.formConfig.title}
          </div>
        )}
      </div>
      <Handle type="source" position="bottom" style={{ background: '#555' }} />
    </Card>
  );
};

export default ShowForm;