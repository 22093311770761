import React from 'react';
import { Form, Input, Select, DatePicker, Button, Card } from 'antd';
import dayjs from 'dayjs';
import './DynamicForm.css';

const { Option } = Select;

const DynamicForm = ({ config, onSubmit, initialValues, previousFormData }) => {
  const [form] = Form.useForm();

  const renderFormItem = (field) => {
    // Handle conditional rendering based on previous form data
    if (field.showWhen && !evaluateCondition(field.showWhen, previousFormData)) {
      return null;
    }

    switch (field.type) {
      case 'input':
        return <Input />;
      case 'select':
        return (
          <Select>
            {field.options.map(opt => (
              <Option key={opt.value} value={opt.value}>{opt.label}</Option>
            ))}
          </Select>
        );
      case 'date':
        return <DatePicker />;
      // Add more field types as needed
      default:
        return <Input />;
    }
  };

  const evaluateCondition = (condition, data) => {
    // Implement condition evaluation logic based on previous form data
    try {
      return new Function('data', `return ${condition}`)(data);
    } catch (e) {
      console.error('Error evaluating condition:', e);
      return true;
    }
  };

  return (
    <Card 
      title={config.title}
      className="dynamic-form-card"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmit}
        className="dynamic-form"
      >
        {config.fields.map(field => (
          <Form.Item
            key={field.name}
            name={field.name}
            label={field.label}
            rules={field.rules}
            tooltip={field.tooltip}
          >
            {renderFormItem(field)}
          </Form.Item>
        ))}
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {config.submitText || 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default DynamicForm;