import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Table, Spin, Empty } from 'antd';
import { Line, Bar } from 'react-chartjs-2';
import { ClockCircleOutlined, CheckCircleOutlined, FieldTimeOutlined, UserOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './TaskTimetracking.css';

const TaskTimetracking = () => {
  const [loading, setLoading] = useState(true);
  const [taskStats, setTaskStats] = useState(null);
  const [activeTracking, setActiveTracking] = useState([]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchActiveTracking, 60000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [activeResponse, statsResponse] = await Promise.all([
        axios.get('/dashboard/active-timetracking'),
        axios.get('/dashboard/task-timetracking-data')
      ]);
      
      setActiveTracking(activeResponse.data.active_tracking);
      setTaskStats(statsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchActiveTracking = async () => {
    try {
      const response = await axios.get('/dashboard/active-timetracking');
      setActiveTracking(response.data.active_tracking);
    } catch (error) {
      console.error('Error fetching active tracking:', error);
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '0h 0m';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const renderActiveTasks = () => {
    if (!activeTracking?.length) {
      return <Empty description="No active time tracking sessions" />;
    }

    return activeTracking.map(task => (
      <div key={task.id} className="active-task-item">
        <Row justify="space-between" align="middle">
          <Col>
            <span className={`task-status ${task.is_paused ? 'paused' : 'running'}`}>
              {task.recurring_type || task.task_type}
            </span>
          </Col>
          <Col>
            <span className="task-timer">
              <ClockCircleOutlined /> {formatDuration(task.current_duration)}
            </span>
          </Col>
        </Row>
      </div>
    ));
  };

  const renderCompletionStats = () => {
    if (!taskStats?.total_stats) return null;

    return (
      <div className="stats-grid">
        <Statistic
          title="Total Time Tracking Sessions"
          value={taskStats.total_stats.total_tasks_all_time}
          prefix={<CheckCircleOutlined />}
        />
        <Statistic
          title="Sessions This Month"
          value={taskStats.total_stats.total_tasks_current_month}
          prefix={<FieldTimeOutlined />}
        />
        <Statistic
          title="Active Sessions"
          value={taskStats.total_stats.total_active_tasks}
          prefix={<ClockCircleOutlined />}
        />
      </div>
    );
  };

  const renderDurationChart = () => {
    if (!taskStats?.stats_by_type) return null;

    const chartData = {
      labels: Object.keys(taskStats.stats_by_type),
      datasets: [{
        label: 'Average Duration (hours)',
        data: Object.values(taskStats.stats_by_type).map(type => type.avg_duration / 3600),
        backgroundColor: 'rgba(24, 144, 255, 0.5)',
        borderColor: 'rgba(24, 144, 255, 1)',
        borderWidth: 1
      }]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Hours'
          }
        }
      }
    };

    return (
      <div className="chart-container">
        <Bar data={chartData} options={options} />
      </div>
    );
  };

  const renderHistoricalData = () => {
    if (!taskStats?.stats_by_type) return null;

    const columns = [
      {
        title: 'Task Type',
        dataIndex: 'taskType',
        key: 'taskType',
      },
      {
        title: 'Total Sessions',
        dataIndex: 'totalCount',
        key: 'totalCount',
        sorter: (a, b) => a.totalCount - b.totalCount,
      },
      {
        title: 'Sessions This Month',
        dataIndex: 'currentMonthCount',
        key: 'currentMonthCount',
        sorter: (a, b) => a.currentMonthCount - b.currentMonthCount,
      },
      {
        title: 'Active Sessions',
        dataIndex: 'activeCount',
        key: 'activeCount',
        sorter: (a, b) => a.activeCount - b.activeCount,
      },
      {
        title: 'Total Duration',
        dataIndex: 'totalDuration',
        key: 'totalDuration',
        render: (duration) => formatDuration(duration),
        sorter: (a, b) => a.totalDuration - b.totalDuration,
      }
    ];

    const data = Object.entries(taskStats.stats_by_type).map(([type, stats]) => ({
      key: type,
      taskType: type,
      totalCount: stats.total_count + stats.historical_count,
      currentMonthCount: stats.current_month_count,
      activeCount: stats.active_count,
      totalDuration: stats.total_duration + stats.historical_duration,
    }));

    return (
      <Table 
        columns={columns} 
        dataSource={data}
        className="historical-table"
        pagination={{ pageSize: 5 }}
      />
    );
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="task-data">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Active Tasks" className="active-tasks-card">
            {renderActiveTasks()}
          </Card>
        </Col>
        
        <Col span={12}>
          <Card title="Task Completion Stats">
            {renderCompletionStats()}
          </Card>
        </Col>
        
        <Col span={12}>
          <Card title="Average Duration by Task Type">
            {renderDurationChart()}
          </Card>
        </Col>
        
        <Col span={24}>
          <Card title="Historical Task Data">
            {renderHistoricalData()}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TaskTimetracking;