import React from 'react';
import { Modal } from 'antd';
import { IoNotificationsOutline } from 'react-icons/io5';
import './Notifications.css';

const NotificationsModal = ({ visible, onClose }) => {
  return (
    <Modal
      title="Notifications"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={400}
      className="notifications-modal"
    >
      <div className="notifications-content">
        <div className="notifications-icon">
          <IoNotificationsOutline />
        </div>
        <div className="notifications-message">
          <h3>You have 0 new notifications</h3>
          <p>Actually, I have yet to develop notification functionality in Tuesday, so don't expect to see anything here for a few days ;)</p>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationsModal;
