export const boardStatusConfigs = {
    bogforing: [
      { value: 'Blank', color: '#8c8c8c' },
      { value: 'Indhent bankudtog', color: '#1890ff' },
      { value: 'Bogfør op', color: '#ffa940' },
      { value: 'Indsaml data', color: '#722ed1' },
      { value: 'Bogfør op igen', color: '#13c2c2' },
      { value: 'Klar til gennemgang', color: '#f5222d' },
      { value: 'Udført', color: '#52c41a' },
    ],
    lon: [
      { value: 'Blank', color: '#8c8c8c' },
      { value: 'Lønkunder', color: '#1890ff' },
      { value: 'Indhent timer', color: '#ffa940' },
      { value: 'Timer indsendt', color: '#722ed1' },
      { value: 'Tag kontakt til kunden', color: '#f5222d' },
      { value: 'Løn lavet', color: '#52c41a' },
    ],
    moms: [
      { value: 'Blank', color: '#8c8c8c' },
      { value: 'Indsaml data', color: '#1890ff' },
      { value: 'Udarbejd momsangivelse', color: '#ffa940' },
      { value: 'Klar til gennemgang', color: '#f5222d' },
      { value: 'Indberettet', color: '#52c41a' },
    ],
    arsafslutning: [
      { value: 'Blank', color: '#8c8c8c' },
      { value: 'Indsaml data', color: '#1890ff' },
      { value: 'Udarbejd årsregnskab', color: '#ffa940' },
      { value: 'Klar til gennemgang', color: '#f5222d' },
      { value: 'Indberettet', color: '#52c41a' },
    ],
    andreOpgaver: [
      { value: 'All tasks', color: '#8c8c8c' },
      { value: 'Not started', color: '#ffa940' },
      { value: 'In progress', color: '#1890ff' },
      { value: 'Pending customer', color: '#722ed1' },
      { value: 'Main task completed', color: '#13c2c2' },
      { value: 'Completed', color: '#52c41a' },
    ],
    activities: [
      { value: 'Not started', color: '#8c8c8c' },
      { value: 'In progress', color: '#1890ff' },
      { value: 'Completed', color: '#52c41a' },
    ],
  };