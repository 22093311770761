import React, { useState, useEffect } from 'react';
import { Table, Card, DatePicker, Space, Button, Tag, Tooltip, message } from 'antd';
import { PhoneOutlined, MailOutlined, CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from '../api/axiosConfig';
import './OverdueAccounts.css';

function OverdueAccounts() {
  const [loading, setLoading] = useState(false);
  const [overdueData, setOverdueData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const fetchOverdueInvoices = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get('/invoice/overdue-invoices', {
        params: {
          year: date.year(),
          month: date.month() + 1
        }
      });
      setOverdueData(response.data.overdue_invoices);
    } catch (error) {
      message.error('Failed to fetch overdue invoices');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOverdueInvoices(selectedDate);
  }, [selectedDate]);

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '20%',
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: '10%',
    },
    {
      title: 'Amount Due',
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (amount) => `${amount.toLocaleString('da-DK')} kr.`,
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      width: '15%',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Days Overdue',
      dataIndex: 'days_overdue',
      key: 'days_overdue',
      width: '15%',
      render: (days) => (
        <Tag color={days > 30 ? 'red' : days > 14 ? 'orange' : 'gold'}>
          {days} days
        </Tag>
      ),
    },
    {
      title: 'Contact',
      key: 'contact',
      width: '15%',
      render: (_, record) => (
        <Space>
          {record.customer_email && (
            <Tooltip title={record.customer_email}>
              <Button 
                icon={<MailOutlined />} 
                onClick={() => window.location.href = `mailto:${record.customer_email}`}
              />
            </Tooltip>
          )}
          {record.customer_phone && (
            <Tooltip title={record.customer_phone}>
              <Button 
                icon={<PhoneOutlined />} 
                onClick={() => window.location.href = `tel:${record.customer_phone}`}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="overdue-accounts">
      <div className="overdue-accounts-header">
        <h2 className="overdue-accounts-title">Overdue Accounts</h2>
        <Space>
          <DatePicker
            picker="month"
            value={selectedDate}
            onChange={setSelectedDate}
            allowClear={false}
            format="MMMM YYYY"
            suffixIcon={<CalendarOutlined />}
          />
        </Space>
      </div>
      
      <Card className="overdue-accounts-card">
        <Table
          dataSource={overdueData}
          columns={columns}
          rowKey="invoice_number"
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} overdue invoices`,
          }}
        />
      </Card>
    </div>
  );
}

export default OverdueAccounts;