import React, { useState, useEffect } from 'react';
import { Modal, Tabs } from 'antd';
import { FaFileInvoiceDollar, FaEnvelope, FaUsers, FaMoneyBillWave, FaCog } from 'react-icons/fa';
import BillingSettings from './BillingSettings';
import TeamManagement from './TeamManagement';
import OverdueAccounts from './OverdueAccounts';
import TaskConfiguration from './TaskConfiguration';
import axios from '../api/axiosConfig';
import './Settings.css';
import { hasRequiredPermission } from '../utils/permissionUtils';

const { TabPane } = Tabs;

function Settings({ visible, onClose }) {
  const [allUsers, setAllUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')) || {});

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('/user');
      setAllUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchAllUsers();
    }
  }, [visible]);

  const canAccessBilling = () => {
    return hasRequiredPermission(currentUser?.permissions, 'admin') || 
           currentUser?.permissions === 'payroll';
  };

  const canAccessOverdueAccounts = () => {
    return hasRequiredPermission(currentUser?.permissions, 'admin') || 
           currentUser?.permissions === 'payroll';
  };

  const canAccessTaskConfiguration = () => {
    return hasRequiredPermission(currentUser?.permissions, 'admin');
  };

  return (
    <Modal
      title="Digi-Tal"
      open={visible}
      onCancel={onClose}
      footer={null}
      className="settings-modal"
      destroyOnClose
      width="80vw"
      style={{ minWidth: '1000px' }}
    >
      <Tabs defaultActiveKey="1" tabPosition="left" className="settings-tabs">
        <TabPane
          tab={
            <span className="settings-tab-label">
              <FaUsers /> Teams & Permissions
            </span>
          }
          key="1"
        >
          <TeamManagement 
            embedded={true}
            allUsers={allUsers}
            currentUser={currentUser}
            onUpdateUsers={fetchAllUsers}
          />
        </TabPane>

        <TabPane
          tab={
            <span className={`settings-tab-label ${!canAccessBilling() ? 'disabled' : ''}`}>
              <FaFileInvoiceDollar /> Billing
            </span>
          }
          key="2"
          disabled={!canAccessBilling()}
        >
          <BillingSettings />
        </TabPane>

        <TabPane
          tab={
            <span className={`settings-tab-label ${!canAccessOverdueAccounts() ? 'disabled' : ''}`}>
              <FaMoneyBillWave /> Overdue Accounts
            </span>
          }
          key="3"
          disabled={!canAccessOverdueAccounts()}
        >
          <OverdueAccounts 
            allUsers={allUsers}
            currentUser={currentUser}
          />
        </TabPane>
        
        <TabPane
          tab={
            <span className="settings-tab-label disabled">
              <FaEnvelope /> Email Templates
            </span>
          }
          key="4"
          disabled
        >
          <div>Coming soon</div>
        </TabPane>

        <TabPane
          tab={
            <span className={`settings-tab-label ${!canAccessTaskConfiguration() ? 'disabled' : ''}`}>
              <FaCog /> Task Configuration
            </span>
          }
          key="5"
          disabled={!canAccessTaskConfiguration()}
        >
          <TaskConfiguration />
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default Settings;