import React from 'react';
import { Handle } from '@xyflow/react';
import { Card } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import '../../ModuleNode.css';

const SendEmail = ({ data, selected }) => {
  return (
    <Card 
      size="small" 
      title={<div className="node-title"><MailOutlined /> Send Email</div>}
      className={`module-node action-node ${selected ? 'selected' : ''}`}
      style={{ width: 200 }}
    >
      <Handle type="target" position="top" style={{ background: '#555' }} />
      <div className="node-content">
        <div className="node-label">{data.label}</div>
        {data.template && (
          <div>
            <span className="node-label">Template:</span>
            {data.template}
          </div>
        )}
        {data.to && (
          <div>
            <span className="node-label">To:</span>
            {data.to}
          </div>
        )}
      </div>
      <Handle type="source" position="bottom" style={{ background: '#555' }} />
    </Card>
  );
};

export default SendEmail;