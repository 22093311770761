import React, { useState, useEffect } from 'react';
import { Modal, Button, Descriptions, List, Alert, Select, Input, Switch, DatePicker, message } from 'antd';
import { dealDataConfig } from '../../configs/DealDataConfig';
import { fetchUsersByPermission, getUserOptions } from '../../utils/permissionUtils';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import './DealDetails.css';

const DealDetails = ({ visible, onClose, deal, onUpdateSuccess }) => {
  const [editedDeal, setEditedDeal] = useState(null);
  const [userOptions, setUserOptions] = useState({ accounting: [], payroll: [] });

  useEffect(() => {
    if (visible && deal) {
      setEditedDeal(JSON.parse(JSON.stringify(deal)));
      fetchUserOptions();
    }
  }, [visible, deal]);

  const fetchUserOptions = async () => {
    const users = await fetchUsersByPermission();
    setUserOptions({
      accounting: getUserOptions(users.accounting),
      payroll: getUserOptions(users.payroll),
    });
  };

  const handleEditDeal = (key, value) => {
    setEditedDeal(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: value,
      },
    }));
  };

  const renderEditableField = (key, value, config) => {
    switch (config.type) {
      case 'number':
        return (
          <Input
            type="number"
            value={value}
            onChange={(e) => handleEditDeal(key, parseFloat(e.target.value))}
          />
        );
      case 'boolean':
        return (
          <Switch
            checked={value}
            onChange={(checked) => handleEditDeal(key, checked)}
          />
        );
      case 'dropdown':
        let options = config.options;
        if (['ansvarlig_bogholder', 'ansvarlig_revisor_for_årsregnskab'].includes(key)) {
          options = userOptions.accounting;
          console.log(`Accounting options for ${key}:`, options);
        } else if (key === 'ansvarlig_lønbogholder') {
          options = userOptions.payroll;
          console.log(`Payroll options for ${key}:`, options);
        }
        return (
          <Select
            value={value}
            onChange={(selectedValue) => handleEditDeal(key, selectedValue)}
            style={{ width: '100%' }}
          >
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        );
      case 'date':
        return (
          <DatePicker
            value={value ? dayjs(value) : null}
            onChange={(date) => handleEditDeal(key, date ? date.format('YYYY-MM-DD') : null)}
          />
        );
      default:
        return (
          <Input
            value={value}
            onChange={(e) => handleEditDeal(key, e.target.value)}
          />
        );
    }
  };

  const saveDealChanges = async () => {
    try {
      await axios.put(`/account/deals/${editedDeal.id}`, editedDeal);
      message.success('Deal updated successfully');
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error updating deal:', error);
      message.error('Failed to update deal');
    }
  };

  const isSoftwareField = (key) => {
    const softwareFields = [
      'lønprogram',
      'lagerstyring',
      'kreditorstyring',
      'betalingsgateway',
      'abonnementsstyring',
      'debitorstyring_kredit',
      'debitorstyring_kontant',
      'projekt_og_tidsstyring',
      'kreditorstyring_kvitteringer',
      'andre_softwares'
    ];
    return softwareFields.includes(key);
  };

  if (!editedDeal) return null;

  return (
    <Modal
      title="Deal Details"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={saveDealChanges}>
          Save Changes
        </Button>,
      ]}
      width={1300}
      className="deal-details-modal"
    >
      <Descriptions bordered column={1} labelStyle={{ fontWeight: 'bold', width: '120px' }}>
        <Descriptions.Item label="Name">{editedDeal.name}</Descriptions.Item>
        <Descriptions.Item label="CVR">{editedDeal.cvr}</Descriptions.Item>
        <Descriptions.Item label="Phone">{editedDeal.phone}
          {editedDeal.recommendations?.recommendations['phone'] && (
            <Alert
              message={`Automatic correction: ${editedDeal.recommendations.recommendations['phone']}`}
              type="info"
              showIcon
              className="alert recommendation-alert"
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{editedDeal.email}</Descriptions.Item>
        <Descriptions.Item label="Contact">{editedDeal.data.contact_name}</Descriptions.Item>
        <Descriptions.Item label="Created At">{new Date(editedDeal.created_at).toLocaleString()}</Descriptions.Item>
        <Descriptions.Item label="Source">{editedDeal.source}</Descriptions.Item>
        <Descriptions.Item label="New Deal">{editedDeal.is_new_deal ? 'Yes' : 'No'}</Descriptions.Item>
        {Object.entries(editedDeal.data)
          .filter(([key]) => !['status', 'title', 'value', 'currency', 'is_new_deal', 'contact_email', 'contact_phone', 'contact_name', 'source', 'products'].includes(key) && !isSoftwareField(key))
          .map(([key, value]) => {
            const config = dealDataConfig.find(item => item.key === `data.${key}`) || {};
            return (
              <Descriptions.Item key={key} label={config.label || key}>
                <div className="deal-detail-item">
                  <div className="deal-detail-value">
                    {config.editable ? renderEditableField(key, value, config) : value}
                  </div>
                  {editedDeal.recommendations?.recommendations[key] && (
                    <Alert
                      message={`Automatic correction: ${editedDeal.recommendations.recommendations[key]}`}
                      type="info"
                      showIcon
                      className="alert recommendation-alert"
                    />
                  )}
                  {editedDeal.recommendations?.alerts[key] && (
                    <Alert
                      message={`Alert: ${editedDeal.recommendations.alerts[key]}`}
                      type="error"
                      showIcon
                      className="alert error-alert"
                    />
                  )}
                </div>
              </Descriptions.Item>
            );
          })}
        <Descriptions.Item label={<h3 style={{ margin: 0 }}>Software</h3>}>
          <Descriptions bordered column={1} style={{ marginBottom: 0 }}>
            {Object.entries(editedDeal.data)
              .filter(([key]) => isSoftwareField(key))
              .map(([key, value]) => {
                const config = dealDataConfig.find(item => item.key === `data.${key}`) || {};
                return (
                  <Descriptions.Item key={key} label={config.label || key}>
                    <div className="deal-detail-item">
                      <div className="deal-detail-value">
                        {config.editable ? renderEditableField(key, value, config) : value}
                      </div>
                      {editedDeal.recommendations?.recommendations[key] && (
                        <Alert
                          message={`Automatic correction: ${editedDeal.recommendations.recommendations[key]}`}
                          type="info"
                          showIcon
                          className="alert recommendation-alert"
                        />
                      )}
                      {editedDeal.recommendations?.alerts[key] && (
                        <Alert
                          message={`Alert: ${editedDeal.recommendations.alerts[key]}`}
                          type="error"
                          showIcon
                          className="alert error-alert"
                        />
                      )}
                    </div>
                  </Descriptions.Item>
                );
              })}
          </Descriptions>
        </Descriptions.Item>
        <Descriptions.Item label="Products">
          <List
            dataSource={editedDeal.data.products || []}
            renderItem={(product) => (
              <List.Item>
                <p>Name: {product.name}</p>
                <p>Code: {product.code}</p>
                <p>Price: {product.item_price}</p>
                <p>Quantity: {product.quantity}</p>
              </List.Item>
            )}
          />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default DealDetails;
