import React, { useState, useEffect } from 'react';
import { Table, Spin, Card, Tag, Button, message, Modal, List, Tooltip, Typography } from 'antd';
import { UserOutlined, TeamOutlined, ShoppingOutlined, DollarOutlined, NumberOutlined, CalendarOutlined, FieldTimeOutlined, ShoppingCartOutlined, ClockCircleOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import io from 'socket.io-client';
import './Deals.css';
import { useNavigate } from 'react-router-dom';
import ImportDeals from './modals/ImportDeals';
import DealDetails from './modals/DealDetails';
import { FileAddOutlined, AppstoreOutlined } from '@ant-design/icons';
import DealDrawer from '../drawers/DealDrawer';
const { Text } = Typography;

const excludedDataKeys = ['status', 'title', 'value', 'currency', 'products'];

  const formatDataKey = (key) => {
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const formatDataValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (value instanceof Date) {
      return value.toLocaleString();
    }
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
      return new Date(value).toLocaleString();
    }
    return String(value);
  };

const ProductItem = ({ product }) => (
  <List.Item className="product-item">
    <Tooltip title={`Product Name: ${product.name}`}>
      <Text strong className="product-name">{product.name}</Text>
    </Tooltip>
    <Tooltip title={`Product Code: ${product.code}`}>
      <Text><ShoppingCartOutlined /> Code: {product.code}</Text>
    </Tooltip>
    <Tooltip title={`Price: ${product.item_price}`}>
      <Text><DollarOutlined /> Price: {product.item_price}</Text>
    </Tooltip>
    <Tooltip title={`Quantity: ${product.quantity}`}>
      <Text><NumberOutlined /> Qty: {product.quantity}</Text>
    </Tooltip>
    <Tooltip title={`Sum: ${product.item_price * product.quantity}`}>
      <Text><DollarOutlined /> Sum: {product.item_price * product.quantity}</Text>
    </Tooltip>
    <Tooltip title={`Billing Start Date: ${product.billing_start_date || 'N/A'}`}>
      <Text><CalendarOutlined /> Start: {product.billing_start_date || 'N/A'}</Text>
    </Tooltip>
    <Tooltip title={`Billing Frequency: ${product.billing_frequency}`}>
      <Text><FieldTimeOutlined /> Freq: {product.billing_frequency}</Text>
    </Tooltip>
  </List.Item>
);

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [pendingDeals, setPendingDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [totalDeals, setTotalDeals] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [productsModalVisible, setProductsModalVisible] = useState(false);
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [onboardingDealsCount, setOnboardingDealsCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDeals();
    fetchPendingDeals();
    const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:5000');
    socket.on('pending_deal_added', fetchPendingDeals);
    socket.on('pending_deal_imported', () => {
      fetchDeals();
      fetchPendingDeals();
    });
    return () => socket.disconnect();
  }, []);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals');
      const allDeals = response.data || [];
      const completedDeals = allDeals.filter(deal => 
        deal.onboard_status === 'onboarded' || deal.onboard_status === 'migrated'
      );
      const onboardingDeals = allDeals.filter(deal => 
        deal.onboard_status === 'onboarding' || deal.onboard_status === 'not_onboarded'
      );
      
      setDeals(completedDeals);
      setTotalDeals(completedDeals.length);
      setOnboardingDealsCount(onboardingDeals.length);
    } catch (error) {
      console.error('Error fetching deals:', error);
      setDeals([]);
      setTotalDeals(0);
      setOnboardingDealsCount(0);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingDeals = async () => {
    try {
      const response = await axios.get('/account/pending-deals');
      setPendingDeals(response.data || []);
    } catch (error) {
      console.error('Error fetching pending deals:', error);
      setPendingDeals([]);
    }
  };

  const showDealDetails = (deal) => {
    setSelectedDeal(deal);
    setDetailModalVisible(true);
  };

  const showAccountDetails = async (account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`);
      setSelectedAccount(response.data);
      setAccountModalVisible(true);
    } catch (error) {
      console.error('Error fetching account details:', error);
      message.error('Failed to fetch account details');
    }
  };

  const showContactDetails = (contact) => {
    setSelectedContact(contact);
    setContactModalVisible(true);
  };

  const showProductsDetails = (products) => {
    setSelectedProducts(products);
    setProductsModalVisible(true);
  };

  const columns = [
    {
      title: 'Deal',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Tag 
          color="blue" 
          className="deal-tag" 
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedDealId(record.id);
            setDealDrawerVisible(true);
          }}
        >
          {text}
        </Tag>
      ),
      fixed: 'left',
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Value',
      dataIndex: ['data', 'value'],
      key: 'value',
      render: (value, record) => `${value} ${record.data.currency}`,
      sorter: (a, b) => a.data.value - b.data.value,
    },
    {
      title: 'Status',
      dataIndex: ['data', 'status'],
      key: 'status',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString(),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'Account',
      dataIndex: ['account', 'company_name'],
      key: 'account',
      render: (text, record) => (
        <Button type="link" className="tag-button" onClick={() => showAccountDetails(record.account)}>
          {text && <Tag color="green" className="account-tag">
            <TeamOutlined /> {text}
          </Tag>}
        </Button>
      ),
    },
    {
      title: 'Contacts',
      dataIndex: ['contact', 'name'],
      key: 'contact',
      render: (text, record) => (
        <Button type="link" className="tag-button" onClick={() => showContactDetails(record.contact)}>
          {text && <Tag color="orange" className="contact-tag">
            <UserOutlined /> {text}
          </Tag>}
        </Button>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',
      render: (products, record) => (
        <Button type="link" className="tag-button" onClick={() => showProductsDetails(products)}>
          <Tag color="magenta" className="product-tag">
            <ShoppingOutlined /> {products.length} product(s)
          </Tag>
        </Button>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
      width: 250,
    },
    ...Object.keys(deals[0]?.data || {})
      .filter(key => !excludedDataKeys.includes(key))
      .map(key => ({
        title: formatDataKey(key),
        dataIndex: ['data', key],
        key: key,
        render: (value) => formatDataValue(value),
      })),
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="deals-container">
      <h1>Deals</h1>
      <div className="dashboard-cards">
        <Card className="dashboard-card" onClick={() => setImportModalVisible(true)}>
          <FileAddOutlined className="card-icon" />
          <div className="card-content">
            <h2>{pendingDeals.length}</h2>
          </div>
          <p>Pending Deals</p>
        </Card>
        
        <Card className="dashboard-card" onClick={() => navigate('/onboarding')}>
          <ClockCircleOutlined className="card-icon" />
          <div className="card-content">
            <h2>{onboardingDealsCount}</h2>
          </div>
          <p>Deals in Onboarding</p>
        </Card>

        <Card className="dashboard-card">
          <AppstoreOutlined className="card-icon" />
          <div className="card-content">
            <h2>{totalDeals}</h2>
          </div>
          <p>Total Deals</p>
        </Card>
      </div>
      <Table
        columns={columns}
        dataSource={deals}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        className="ant-table-wrapper"
        scroll={{ x: 'max-content' }}
      />
      <ImportDeals
        visible={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        pendingDeals={pendingDeals}
        onImportSuccess={() => {
          fetchDeals();
          fetchPendingDeals();
        }}
        onShowDealDetails={showDealDetails}
      />
      <DealDetails
        visible={detailModalVisible}
        onClose={() => setDetailModalVisible(false)}
        deal={selectedDeal}
        onUpdateSuccess={fetchDeals}
      />


      <Modal
        title="Account Details"
        open={accountModalVisible}
        onCancel={() => setAccountModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setAccountModalVisible(false)}>
            Close
          </Button>,
          <Button key="view" type="primary" onClick={() => navigate(`/accounts/${selectedAccount.id}`)}>
            View Full Details
          </Button>,
        ]}
      >
        {selectedAccount && (
          <div>
            <p><strong>Company Name:</strong> {selectedAccount.company_name}</p>
            <p><strong>CVR:</strong> {selectedAccount.cvr || 'N/A'}</p>
            <p><strong>Address:</strong> {selectedAccount.address || 'N/A'}</p>
            <p><strong>Zipcode:</strong> {selectedAccount.zipcode || 'N/A'}</p>
            <p><strong>City:</strong> {selectedAccount.city || 'N/A'}</p>
          </div>
        )}
      </Modal>

      <Modal
        title="Contact Details"
        open={contactModalVisible}
        onCancel={() => setContactModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setContactModalVisible(false)}>
            Close
          </Button>,
          <Button key="view" type="primary" onClick={() => navigate(`/contacts/${selectedContact.id}`)}>
            View Full Details
          </Button>,
        ]}
      >
        {selectedContact && (
          <div>
            <p><strong>Name:</strong> {selectedContact.name}</p>
            <p><strong>Email:</strong> {selectedContact.email}</p>
            <p><strong>Phone:</strong> {selectedContact.phone}</p>
          </div>
        )}
      </Modal>

      <Modal
        title="Products Details"
        open={productsModalVisible}
        onCancel={() => setProductsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setProductsModalVisible(false)}>
            Close
          </Button>,
          <Button key="view" type="primary" onClick={() => navigate('/products')}>
            View All Products
          </Button>,
        ]}
        width={1000}
      >
        <List
          dataSource={selectedProducts}
          renderItem={(product) => <ProductItem product={product} />}
        />
      </Modal>

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />
    </div>
  );
};

export default Deals;
