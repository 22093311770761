export const config = {
    type: 'flowStart',
    category: 'flow-control',
    label: 'Flow Start',
    icon: 'PlayCircleOutlined',
    
    defaultData: {
      label: 'Flow Start'
    },
  
    moduleConfig: {
      validateConnection: () => false, // Can't have incoming connections
      isConnectedToSource: () => true, // Always considered connected
      maxIncomingConnections: 0,  // No outgoing connections allowed
      maxOutgoingConnections: 1, // Only one outgoing connection allowed
      isRequired: true, // Cannot be removed
      isDefault: true // Added by default to new flows
    },
  
    formConfig: {
      fields: [] // No configuration needed
    }
  };