import React, { useState, useEffect } from 'react';
import { Table, Spin, Button, Modal, Tag } from 'antd';
import axios from '../api/axiosConfig';
import './Accounts.css';
import AccountTree from './modals/AccountTree';
import DealDrawer from '../drawers/DealDrawer';
import { UserOutlined, TeamOutlined } from '@ant-design/icons';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [dealModalVisible, setDealModalVisible] = useState(false);
  const [accountTreeVisible, setAccountTreeVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountLoading, setAccountLoading] = useState(false);
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(null);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccountDetails = async (accountId) => {
    setAccountLoading(true);
    try {
      const response = await axios.get(`/account/accounts/${accountId}`);
      setSelectedAccount(response.data);
    } catch (error) {
      console.error('Error fetching account details:', error);
    } finally {
      setAccountTreeVisible(true);
      setAccountLoading(false);
    }
  };

  const showContactDetails = (contact) => {
    setSelectedContact(contact);
    setContactModalVisible(true);
  };

  const showDealDetails = (deal) => {
    setSelectedDealId(deal.id);
    setDealDrawerVisible(true);
  };

  const showAccountTree = (account) => {
    fetchAccountDetails(account.id);
    setSelectedAccount(account);
    setAccountTreeVisible(true);
  };

  const columns = [
    {
      title: 'Account',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      render: (text, record) => (
        <Button type="link" className="tag-button" onClick={() => showAccountTree(record)}>
          <Tag color="green" className="account-tag">
            <TeamOutlined /> {text}
          </Tag>
        </Button>
      ),
      fixed: 'left',
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (contacts) => (
        <span>
          {contacts.map((contact, index) => (
            <Button key={contact.id} type="link" className="tag-button" onClick={() => showContactDetails(contact)}>
              <Tag color="orange" className="contact-tag">
                <UserOutlined /> {contact.name}
              </Tag>
            </Button>
          ))}
        </span>
      ),
    },
    {
      title: 'Deals',
      dataIndex: 'deals',
      key: 'deals',
      render: (deals) => (
        <span>
          {deals.map((deal, index) => (
            <Button key={deal.id} type="link" className="tag-button" onClick={() => showDealDetails(deal)}>
              <Tag color="blue" className="deal-tag">
                {deal.name}
              </Tag>
            </Button>
          ))}
        </span>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="accounts-container">
      <h1>Customer Accounts</h1>
      <Table
        columns={columns}
        dataSource={accounts}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        className="accounts-table"
      />
      <Modal
        title="Contact Details"
        open={contactModalVisible}
        onCancel={() => setContactModalVisible(false)}
        footer={null}
      >
        {selectedContact && (
          <div>
            <p><strong>Name:</strong> {selectedContact.name}</p>
            <p><strong>Email:</strong> {selectedContact.email}</p>
            <p><strong>Phone:</strong> {selectedContact.phone}</p>
          </div>
        )}
      </Modal>
      <AccountTree
        visible={accountTreeVisible}
        onClose={() => setAccountTreeVisible(false)}
        account={selectedAccount}
      />
      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />
    </div>
  );
};

export default Accounts;
