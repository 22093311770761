import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Modal, Tabs, Button, Descriptions, Spin, Tag, Tooltip, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowUpOutlined, ArrowDownOutlined, MailOutlined, SyncOutlined, FileTextOutlined, UserOutlined, ShopOutlined, ClockCircleOutlined, CalendarOutlined, FieldTimeOutlined, PlayCircleOutlined, TeamOutlined, ShoppingOutlined, AccountBookOutlined, DollarOutlined, PauseCircleOutlined, StopOutlined, EditOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './TaskView.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TaskViewCommunication from '../components/TaskViewCommunication';
import TaskViewNotes from '../components/TaskViewNotes';
import timezone from 'dayjs/plugin/timezone';
import CustomScrollbar from '../../components/CustomScrollbar';
import DealNavBar from '../components/DealNavBar';
import ContactInfoDrawer from '../drawers/ContactInfo';
import TaskDateEditor from '../../components/TaskDateEditor';
import TaskViewActivity from '../components/TaskViewActivity';
import DealDrawer from '../../drawers/DealDrawer';
import ProductsDrawer from '../../drawers/ProductsDrawer';
import TaskStatusSelector from '../components/TaskStatusSelector';
import TaskCompleteDrawer from '../drawers/TaskCompleteDrawer';
import { TimeTrackerContext } from '../../contexts/TimeTrackerContext';
import PayrollDrawer from '../../drawers/PayrollDrawer';
import { dealSoftwareConfig } from '../../configs/DealSoftwareConfig';

dayjs.extend(utc);
dayjs.extend(timezone);

const { TabPane } = Tabs;

const PRODUCT_ICONS = {
  'Bogføring': <AccountBookOutlined />,
  'Onboarding': <TeamOutlined />,
  'Lønadministration': <DollarOutlined />,
  'Årsregnskab': <FileTextOutlined />
};

const TaskView = ({ onClose, tasks, taskType: propTaskType, boardStatusOptions, task: propTask, fromWork, getBoardName: propGetBoardName, dealName }) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(propTask || null);
  const [loading, setLoading] = useState(!propTask);
  const [activeTab, setActiveTab] = useState('1');
  const [taskType, setTaskType] = useState(propTaskType);
  const [accountData, setAccountData] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [contactInfoVisible, setContactInfoVisible] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const [users, setUsers] = useState([]);
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [productsDrawerVisible, setProductsDrawerVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false);
  const [taskToComplete, setTaskToComplete] = useState(null);
  const [lonConfig, setLonConfig] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(dayjs().format('YYYY-MM'));
  const [payrollDrawerVisible, setPayrollDrawerVisible] = useState(false);

  const { 
    isRunning, 
    isPaused, 
    currentTask,
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer
  } = useContext(TimeTrackerContext);

  const isCurrentTask = currentTask?.taskId === task?.id;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/user');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const getBoardName = useCallback((task) => {
    if (propGetBoardName) {
      return propGetBoardName(task);
    }
    // Default implementation if propGetBoardName is not provided
    if (task.task_type === 'recurring') {
      const recurringTypeMap = {
        'bogforing': 'bogforing',
        'lon': 'lon',
        'moms': 'moms',
        'arsafslutning': 'arsafslutning'
      };
      return recurringTypeMap[task.recurring_type] || task.recurring_type;
    }
    return task.task_type;
  }, [propGetBoardName]);

  const fetchTaskDetails = useCallback(async () => {
    try {
      let apiTaskType = taskType;
      if (taskType === 'andre-opgaver') {
        apiTaskType = 'other';
      } else if (taskType === 'activities') {
        apiTaskType = 'activity';
      }

      const response = await axios.get(`/taskboard/tasks/${apiTaskType}/${taskId}`);
      setTask(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setLoading(false);
    }
  }, [taskId, taskType]);

  useEffect(() => {
    if (!propTask && taskId) {
      fetchTaskDetails();
    } else if (propTask) {
      setTask(propTask);
      setLoading(false);
    }
  }, [taskId, propTask]);

  useEffect(() => {
    if (task && task.contact) {
      fetchContactInfo(task.contact.id);
    }
  }, [task]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  }, [onClose, navigate]);

  const handlePrevTask = () => {
    if (fromWork) {
      const currentIndex = tasks.findIndex(t => t.id === task.id);
      if (currentIndex > 0) {
        const prevTask = tasks[currentIndex - 1];
        navigate(`/work/${getBoardName(prevTask)}/${prevTask.id}`, { 
          replace: true, 
          state: { fromWork: true, filteredTasks: tasks } 
        });
      }
    } else {
      // Handle previous task in TaskBoard view
      const currentIndex = tasks.findIndex(t => t.id.toString() === taskId);
      if (currentIndex > 0) {
        const prevTask = tasks[currentIndex - 1];
        navigate(`/boards/${getBoardName(prevTask)}/${prevTask.id}`);
        console.log(`Navigated to previous task - Type: ${getBoardName(prevTask)}, Board: ${getBoardName(prevTask)}, Deal: ${prevTask.deal?.name || dealName}`);
      }
    }
  };

  const handleNextTask = () => {
    if (fromWork) {
      const currentIndex = tasks.findIndex(t => t.id === task.id);
      if (currentIndex < tasks.length - 1) {
        const nextTask = tasks[currentIndex + 1];
        navigate(`/work/${getBoardName(nextTask)}/${nextTask.id}`, { 
          replace: true, 
          state: { fromWork: true, filteredTasks: tasks } 
        });
      }
    } else {
      // Handle next task in TaskBoard view
      const currentIndex = tasks.findIndex(t => t.id.toString() === taskId);
      if (currentIndex < tasks.length - 1) {
        const nextTask = tasks[currentIndex + 1];
        navigate(`/boards/${getBoardName(nextTask)}/${nextTask.id}`);
        console.log(`Navigated to next task - Type: ${getBoardName(nextTask)}, Board: ${getBoardName(nextTask)}, Deal: ${nextTask.deal?.name || dealName}`);
      }
    }
  };

  const handleTaskStatusChange = async (updatedTask) => {
    setTask(prevTask => ({
      ...prevTask,
      board_status: updatedTask.board_status,
      progress_status: updatedTask.progress_status,
      status: updatedTask.status
    }));

    if (updatedTask.board_status === boardStatusOptions[boardStatusOptions.length - 1].value) {
      if (isCurrentTask && isRunning) {
        await stopTracking();
      }
      
      // Get the correct task type mapping
      const { taskType: mappedTaskType, recurringType } = getTaskTypeAndRecurringType(task);
      
      const taskToComplete = {
        ...updatedTask,
        task_type: mappedTaskType,  // Use the mapped taskType
        isRecurring: mappedTaskType === 'recurring',
        recurringType: recurringType,
        rollback: () => {
          setTask(prevTask => ({
            ...prevTask,
            board_status: prevTask.board_status
          }));
        }
      };

      console.log('Completing task with data:', taskToComplete); // Debug log
      setTaskToComplete(taskToComplete);
      setIsCompleteDrawerVisible(true);
    }
  };

  const handleCompleteDrawerClose = () => {
    if (taskToComplete?.rollback) {
      taskToComplete.rollback();
    }
    setIsCompleteDrawerVisible(false);
    setTaskToComplete(null);
  };

  const handleCompleteDrawerSubmit = async (values) => {
    try {
      const response = await axios.put(`/taskboard/tasks/${taskType}/${taskToComplete.id}/status`, {
        board_status: taskToComplete.board_status,
        board_status_options: boardStatusOptions,
        completion_data: values
      });

      if (response.status === 200) {
        setIsCompleteDrawerVisible(false);
        setTaskToComplete(null);
        message.success('Task status updated successfully');
      }
    } catch (error) {
      console.error('Error completing task:', error);
      message.error('Failed to complete task');
      if (taskToComplete?.rollback) {
        taskToComplete.rollback();
      }
    }
  };

  const renderTaskStatus = (status) => {
    return (
      <div className="task-status-wrapper">
        <TaskStatusSelector
          value={status}
          taskId={task.id}
          taskType={taskType}
          boardStatusOptions={boardStatusOptions}
          progressStatus={task.status || task.progress_status}
          onSuccess={handleTaskStatusChange}
          className="taskview-status-selector"
        />
      </div>
    );
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD');
  };

  const items = [
    {
      key: "1",
      label: <span><MailOutlined /> Communication</span>,
      children: <TabPane key="1">
        <TaskViewCommunication dealId={task?.deal_id} contact={task?.contact} />
      </TabPane>
    },
    {
      key: "2",
      label: <span><SyncOutlined /> Notes / Comments</span>,
      children: <TabPane key="2">
        <TaskViewNotes 
          task={task}
          taskType={taskType}
          users={users}
          onUpdate={() => {
            // Refresh task data
            fetchTaskDetails();
          }}
        />
      </TabPane>
    },
    {
      key: "3",
      label: <span><FileTextOutlined /> Activity Log</span>,
      children: <TabPane key="3">
        <TaskViewActivity 
          updates={task?.updates || []} 
          users={users}  // Pass users array
        />
      </TabPane>
    }
  ];

  const fetchContactInfo = async (contactId) => {
    try {
      const response = await axios.get(`/account/contacts/${contactId}/info`);
      setContactInfo(response.data.entries);
    } catch (error) {
      console.error('Error fetching contact info:', error);
    }
  };

  const handleContactInfoClick = () => {
    setContactInfoVisible(true);
  };

  const handleContactInfoClose = () => {
    setContactInfoVisible(false);
    if (task && task.contact) {
      fetchContactInfo(task.contact.id);
    }
  };

  const showAccountDetails = async (account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`);
      setSelectedAccount(response.data);
      setAccountModalVisible(true);
    } catch (error) {
      console.error('Error fetching account details:', error);
      message.error('Failed to fetch account details');
    }
  };

  const getTaskTypeAndRecurringType = (task) => {
    console.log('Getting task type for:', task);
    console.log('Prop taskType:', propTaskType);
    
    if (['bogforing', 'lon', 'moms', 'arsafslutning'].includes(propTaskType)) {
      return {
        taskType: 'recurring',
        recurringType: propTaskType
      };
    } else if (['andre-opgaver', 'activities'].includes(propTaskType)) {
      return {
        taskType: 'onboarding',
        recurringType: null
      };
    } else {
      return {
        taskType: propTaskType,
        recurringType: null
      };
    }
  };

  const handleStartTimer = () => {
    if (!task) return;

    const { taskType, recurringType } = getTaskTypeAndRecurringType(task);
    
    console.log('Starting timer with taskType:', taskType, 'recurringType:', recurringType);
    
    const taskData = {
      taskId: task.id,
      taskType: taskType,
      dealId: task.deal_id,
      recurringType: recurringType,
      dealName: task.deal_name || task.deal?.name,
      taskDescription: task.description || task.data?.Task
    };
    
    console.log('Sending task data:', taskData);
    startTracking(taskData);
  };

  const renderTimerButton = () => {
    if (!task) return null;

    if (isCurrentTask && isRunning) {
      return (
        <div className="timer-controls">
          <Button
            type="primary"
            icon={isPaused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
            onClick={() => isPaused ? resumeTimer() : pauseTracking()}
            className="timer-button pause-resume"
            data-paused={isPaused}
          >
            {isPaused ? "Resume Timer" : "Pause Timer"}
          </Button>
          <Button
            type="primary"
            danger
            icon={<StopOutlined />}
            onClick={stopTracking}
            className="timer-button stop"
          >
            Stop Timer
          </Button>
        </div>
      );
    }

    return (
      <Button
        icon={<PlayCircleOutlined />}
        type="primary"
        className="start-timer-button"
        onClick={handleStartTimer}
        disabled={isRunning && !isCurrentTask}
      >
        Start Timer
      </Button>
    );
  };

  // Move fetchLonConfig outside useEffect
  const fetchLonConfig = async () => {
    if (task?.deal_id && taskType === 'lon') {
      try {
        const response = await axios.get(`/lon/lon-config/${task.deal_id}`);
        setLonConfig(response.data);
      } catch (error) {
        if (error.response?.status !== 404) {  // Ignore 404s as some deals won't have config
          console.error('Error fetching lon configuration:', error);
          message.error('Failed to fetch løn configuration');
        }
      }
    }
  };

  // Update the useEffect to use the function
  useEffect(() => {
    fetchLonConfig();
  }, [task?.deal_id, taskType]);

  const handlePayrollConfigSave = () => {
    // Refresh lon config data
    if (task?.deal_id && taskType === 'lon') {
      fetchLonConfig();
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!task) return null;

  const dealData = task.deal || {};
  const contactData = task.contact || {};

  return (
    <Modal
      open={true}
      onCancel={handleClose}
      footer={null}
      width="90%"
      className="task-view-modal"
      style={{ top: '5%' }}
      maskClosable={true} // Add this line
      destroyOnClose={true} // Add this line
    >
      <div className="task-view">
        <div className="task-view-header">
          <div className="task-header-content">
            <div className="task-navigation">
              <Button
                icon={<ArrowUpOutlined />}
                onClick={handlePrevTask}
                disabled={tasks.findIndex(t => t.id.toString() === taskId) === 0}
                className="nav-button"
              />
              <Button
                icon={<ArrowDownOutlined />}
                onClick={handleNextTask}
                disabled={tasks.findIndex(t => t.id.toString() === taskId) === tasks.length - 1}
                className="nav-button"
              />
            </div>
            <h2 className="task-title">
              {dealData.name || dealName || 'N/A'}: {task.description || task.text}
            </h2>
            <DealNavBar dealId={task?.deal_id} />
          </div>
          <Tabs activeKey={activeTab} onChange={setActiveTab} className="task-tabs" items={items} />
        </div>
        <div className="task-view-content">
          <CustomScrollbar className="task-view-main">
            <div className="tab-content">
              {activeTab === '1' && (
                <TaskViewCommunication 
                  dealId={task?.deal_id} 
                  contact={task?.contact}
                />
              )}
              {activeTab === '2' && (
                <TaskViewNotes 
                  task={task}
                  taskType={taskType}
                  users={users}
                  onUpdate={() => {
                    // Refresh task data
                    fetchTaskDetails();
                  }}
                />
              )}
              {activeTab === '3' && (
                <TaskViewActivity 
                  updates={task?.updates || []} 
                  users={users}  // Pass users array
                />
              )}
            </div>
          </CustomScrollbar>
          <CustomScrollbar className="task-view-sidebar">
            <div className="sidebar-content">
              <div className="task-data">
                <h3>Task</h3>
                <div className="task-info-container">
                  <div className="task-dates">
                    <div className="taskview-date">
                      <ClockCircleOutlined className="task-icon" />
                      <TaskDateEditor
                        date={task.next_work_date}
                        dateType="work"
                        taskId={task.id}
                        taskType={taskType}
                        className="task-datepicker"
                        onSuccess={(updatedTask) => setTask(prevTask => ({
                          ...prevTask,
                          next_work_date: updatedTask.next_work_date,
                          next_due_date: updatedTask.next_due_date,
                        }))}
                      />
                      <span className="task-date-label">Work Date</span>
                    </div>
                    <div className="taskview-date">
                      <CalendarOutlined className="task-icon" />
                      <TaskDateEditor
                        date={task.next_due_date}
                        dateType="due"
                        taskId={task.id}
                        taskType={taskType}
                        className="task-datepicker"
                        onSuccess={(updatedTask) => setTask(updatedTask)}
                      />
                      <span className="task-date-label">Due Date</span>
                    </div>
                  </div>
                  <div className="task-status-workload">
                    <div className="task-status">
                      {renderTaskStatus(task.board_status)}
                    </div>
                    <div className="task-workload">
                      <FieldTimeOutlined className="task-icon" />
                      <span>{task.expected_workload} min</span>
                    </div>
                  </div>
                  {renderTimerButton()}
                </div>
              </div>

              {taskType === 'lon' && (
                <div className="lon-data">
                  <div className="section-header">
                    <h3>Payroll</h3>
                    <EditOutlined 
                      className="edit-icon"
                      onClick={() => setPayrollDrawerVisible(true)}
                    />
                  </div>
                  {!lonConfig?.salary_type ? (
                    <div className="no-config-message">
                      Payroll not configured yet
                    </div>
                  ) : (
                    <Descriptions column={1}>
                      {task?.deal_software?.lønprogram && (
                        <Descriptions.Item label="Lønprogram">
                          <Tag color={
                            dealSoftwareConfig
                              .find(config => config.key === 'lønprogram')
                              ?.options.find(opt => opt.value === task.deal_software.lønprogram)
                              ?.color || 'default'
                          }>
                            {task.deal_software.lønprogram}
                          </Tag>
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label="Type">
                        {lonConfig.salary_type}
                      </Descriptions.Item>
                      {(lonConfig.salary_period_hourly?.start || lonConfig.salary_period_hourly?.end) && (
                        <Descriptions.Item label="Period (hourly)">
                          {`${lonConfig.salary_period_hourly.display_start} - ${
                            lonConfig.salary_period_hourly.end === -1 
                              ? `${task.work_date_last_day}`
                              : lonConfig.salary_period_hourly.display_end
                          }`}
                        </Descriptions.Item>
                      )}
                      {(lonConfig.salary_period_monthly?.start || lonConfig.salary_period_monthly?.end) && (
                        <Descriptions.Item label="Period (monthly)">
                          {`${lonConfig.salary_period_monthly.display_start} - ${
                            lonConfig.salary_period_monthly.end === -1 
                              ? `${task.work_date_last_day}`
                              : lonConfig.salary_period_monthly.display_end
                          }`}
                        </Descriptions.Item>
                      )}
                      {lonConfig.mail_reminder_date && (
                        <Descriptions.Item label="Mail Reminder">
                          {`Day ${lonConfig.mail_reminder_date} each month`}
                        </Descriptions.Item>
                      )}
                      {Object.keys(lonConfig.payslip_history || {}).length > 0 && (
                        <Descriptions.Item label="Payslip History">
                          <div className="payslip-history">
                            <div className="payslip-graph">
                              {Object.entries(lonConfig.payslip_history)
                                .sort((a, b) => b[0].localeCompare(a[0]))
                                .slice(0, 5)
                                .reverse()
                                .map(([period, count]) => (
                                  <div 
                                    key={period} 
                                    className="graph-bar" 
                                    style={{ height: `${count * 20}px` }}
                                    title={`${period}: ${count} payslips`}
                                  >
                                    <span className="period-label">{period.slice(5)}</span>
                                  </div>
                                ))}
                            </div>
                            {Object.entries(lonConfig.payslip_history)
                              .sort((a, b) => b[0].localeCompare(a[0]))
                              .slice(0, 1)
                              .map(([period, count]) => (
                                <div key={period} className="latest-payslips">
                                  {`${count} payslips in ${period}`}
                                </div>
                              ))}
                          </div>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  )}
                </div>
              )}

              <div className="contact-data">
                <div className="section-header">
                  <h3>Contact</h3>
                  <Tooltip title={
                    contactInfo.length > 0 ? (
                      <div>
                        <p>Contact Details:</p>
                        <ul>
                          {contactInfo.map((entry, index) => (
                            <li key={index}>{entry.text}</li>
                          ))}
                        </ul>
                      </div>
                    ) : "No info added yet, click to add"
                  } placement="left">
                    <div className="contact-info-icon" onClick={handleContactInfoClick}>
                      <UserOutlined />
                    </div>
                  </Tooltip>
                </div>
                <Descriptions column={1}>
                  <Descriptions.Item label="Name">{contactData.name || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label="Email">{contactData.email || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{contactData.phone || 'N/A'}</Descriptions.Item>
                </Descriptions>
              </div>
              <div className="deal-data">
                <div className="section-header">
                  <h3>Deal</h3>
                  <Button 
                    type="link" 
                    className="tag-button" 
                    onClick={() => setDealDrawerVisible(true)}
                  >
                    <Tag color="blue" className="deal-tag">
                      {dealData.name}
                    </Tag>
                  </Button>
                </div>
                <Descriptions column={1}>
                  <Descriptions.Item label="CVR">{dealData.cvr || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label="Address">{`${dealData.address || ''} ${dealData.zipcode || ''} ${dealData.city || ''}`}</Descriptions.Item>
                  <Descriptions.Item label="Customer Number">{dealData.economic_customer_number || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label="Onboard Status">{dealData.onboard_status || 'N/A'}</Descriptions.Item>
                </Descriptions>
              </div>
              <div className="products-data">
                <div className="section-header">
                  <h3>Products</h3>
                  {task && (
                    <Button 
                      type="link" 
                      className="tag-button" 
                      onClick={() => setProductsDrawerVisible(true)}
                    >
                      <Tag color="magenta" className="product-tag">
                        <ShoppingOutlined /> {task?.products?.length || '0'} product(s)
                      </Tag>
                    </Button>
                  )}
                </div>
                <div className="product-preview-grid">
                  {(task?.products || []).map((product, index) => (
                    <Tooltip 
                      key={index} 
                      title={
                        <div>
                          <div>{product.name}</div>
                          <div>Quantity: {product.quantity || 1}</div>
                        </div>
                      }
                    >
                      <div className="product-preview-item">
                        <div className="product-icon">
                          {PRODUCT_ICONS[product.name] || <ShoppingOutlined />}
                        </div>
                        <span className="product-name">{product.name}</span>
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <div className="account-data">
                <div className="section-header">
                  <h3>Account</h3>
                  {task?.account && (
                    <Button type="link" className="tag-button" onClick={() => showAccountDetails(task.account)}>
                      {task.account.company_name && (
                        <Tag color="green" className="account-tag">
                          <TeamOutlined /> {task.account.company_name}
                        </Tag>
                      )}
                    </Button>
                  )}
                </div>
              </div>
              <Modal
                title="Account Details"
                open={accountModalVisible}
                onCancel={() => setAccountModalVisible(false)}
                footer={[
                  <Button key="close" onClick={() => setAccountModalVisible(false)}>
                    Close
                  </Button>,
                  <Button key="view" type="primary" onClick={() => navigate(`/accounts/${selectedAccount?.id}`)}>
                    View Full Details
                  </Button>,
                ]}
              >
                {selectedAccount && (
                  <div>
                    <p><strong>Company Name:</strong> {selectedAccount.company_name}</p>
                    <p><strong>CVR:</strong> {selectedAccount.cvr || 'N/A'}</p>
                    <p><strong>Address:</strong> {selectedAccount.address || 'N/A'}</p>
                    <p><strong>Zipcode:</strong> {selectedAccount.zipcode || 'N/A'}</p>
                    <p><strong>City:</strong> {selectedAccount.city || 'N/A'}</p>
                  </div>
                )}
              </Modal>
              <ProductsDrawer
                visible={productsDrawerVisible}
                onClose={() => setProductsDrawerVisible(false)}
                products={task?.products || []}
              />
            </div>
          </CustomScrollbar>
        </div>
      </div>
      <ContactInfoDrawer
        visible={contactInfoVisible}
        onClose={handleContactInfoClose}
        contactId={task?.contact?.id}
        contactInfo={contactInfo}
      />
      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => setDealDrawerVisible(false)}
        dealId={task?.deal_id}
      />
      <TaskCompleteDrawer
        visible={isCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={taskToComplete}
        onComplete={handleCompleteDrawerSubmit}
      />
      <PayrollDrawer
        visible={payrollDrawerVisible}
        onClose={() => setPayrollDrawerVisible(false)}
        dealId={task?.deal_id}
        onSave={handlePayrollConfigSave}
      />
    </Modal>
  );
};

export default TaskView;
