import { getDealFieldsArray } from '../../shared/dealData.config';
import { 
  isNodeConnectedToStart
} from '../../../utils/flowUtils';

export const config = {
    type: 'eventTrigger',
    category: 'triggers',
    label: 'Event Trigger',
    icon: 'ThunderboltOutlined',
  
    // Default data when creating new node
    defaultData: {
      label: 'Event Trigger',
      eventType: '',
      isConnected: false
    },
  
    // Available fields for different event types
    fields: {
      'deal_data_updated': getDealFieldsArray(),
      'task_data_updated': [
        { key: 'task.status', label: 'Status', type: 'string', options: ['pending', 'in_progress', 'completed'] },
        { key: 'task.due_date', label: 'Due Date', type: 'date' },
        { key: 'task.priority', label: 'Priority', type: 'string', options: ['low', 'medium', 'high'] }
      ],
      'onboarding_started': [],
      'onboarding_form_submitted': [
        { key: 'form_data', label: 'Form Data', type: 'object' }
      ]
    },
  
    // Module behavior configuration
    moduleConfig: {
      validateConnection: (connection, nodes) => {
        // If this is the target, only allow connection from flowStart
        if (connection.target) {
          const sourceNode = nodes.find(n => n.id === connection.source);
          return sourceNode?.type === 'flowStart';
        }
        // Allow outgoing connections to any node except flowStart
        const targetNode = nodes.find(n => n.id === connection.target);
        return targetNode?.type !== 'flowStart';
      },
      isConnectedToSource: (nodeId, nodes, edges) => {
        return isNodeConnectedToStart(nodeId, nodes, edges);
      },
      maxIncomingConnections: 1,
      maxOutgoingConnections: 1
    },
  
    // Form configuration for ModuleConfigurator
    formConfig: {
      fields: [
        {
          name: 'eventType',
          label: 'Event Type',
          type: 'groupedSelect',
          rules: [{ required: true }],
          options: [
            {
              label: 'Deals',
              options: [
                { value: 'deal_received', label: 'New Pending Deal' },
                { value: 'deal_data_updated', label: 'Deal Data Updated' },
              ]
            },
            {
              label: 'Tasks',
              options: [
                { value: 'task_data_updated', label: 'Task Data Updated' },
              ]
            },
            {
              label: 'Onboarding',
              options: [
                { value: 'onboarding_started', label: 'Onboarding Started', disabled: false },
                { value: 'onboarding_form_submitted', label: 'Onboarding Form Submitted', disabled: false }
              ]
            },
            {
              label: 'Users',
              options: [
                { value: 'user_created', label: 'User Created', disabled: true },
              ]
            },
            {
              label: 'Products',
              options: []  // Add product-related events here
            },
            {
              label: 'Accounts',
              options: []  // Add account-related events here
            }
          ]
        }
      ]
    },
  
    // Add method to expose available fields
    getAvailableFields: (node) => {
      if (!node.data.eventType) return [];

      switch (node.data.eventType) {
        case 'deal_data_updated':
          return getDealFieldsArray().map(field => ({
            ...field,
            sourceNode: node.id,
            sourceType: 'deal',
            label: `${node.data.label || 'Event Trigger'} (#${node.data.nodeNumber}) - ${field.label}`
          }));
        // Add other event types here
        default:
          return [];
      }
    }
  };