export const dealSoftwareConfig = [
  {
    key: 'lønprogram',
    label: 'Lønprogram',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'Apacta', label: 'Apacta', color: '#1890ff' },
      { value: 'Danløn', label: 'Danløn', color: '#52c41a' },
      { value: 'Dataløn', label: 'Dataløn', color: '#faad14' },
      { value: 'Proløn', label: 'Proløn', color: '#f5222d' },
      { value: 'Salary', label: 'Salary', color: '#722ed1' },
      { value: 'Zenegy', label: 'Zenegy', color: '#13c2c2' }
    ]
  },
  {
    key: 'lagerstyring',
    label: 'Lagerstyring',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'CleanManager', label: 'CleanManager', color: '#1890ff' },
      { value: 'RackBeat', label: 'RackBeat', color: '#52c41a' },
      { value: 'Shipmondo', label: 'Shipmondo', color: '#faad14' },
      { value: 'SmartWeb', label: 'SmartWeb', color: '#f5222d' },
      { value: 'TraceZilla', label: 'TraceZilla', color: '#722ed1' }
    ]
  },
  {
    key: 'kreditorstyring',
    label: 'Kreditorstyring',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'bcomnomy', label: 'bcomnomy', color: '#1890ff' },
      { value: 'CorPay One', label: 'CorPay One', color: '#52c41a' },
      { value: 'KontoLink', label: 'KontoLink', color: '#faad14' },
      { value: 'Minuba', label: 'Minuba', color: '#f5222d' },
      { value: 'Pleo', label: 'Pleo', color: '#722ed1' },
      { value: 'Smart-Inbox', label: 'Smart-Inbox', color: '#13c2c2' }
    ]
  },
  {
    key: 'betalingsgateway',
    label: 'Betalingsgateway',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'FlatPay', label: 'FlatPay', color: '#1890ff' },
      { value: 'PensoPay', label: 'PensoPay', color: '#52c41a' },
      { value: 'Stripe', label: 'Stripe', color: '#faad14' }
    ]
  },
  {
    key: 'abonnementsstyring',
    label: 'Abonnementsstyring',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'E-conomic\'s abonnementstyring', label: 'E-conomic\'s abonnementstyring', color: '#1890ff' },
      { value: 'Fenerum', label: 'Fenerum', color: '#52c41a' },
      { value: 'Upodi', label: 'Upodi', color: '#faad14' }
    ]
  },
  {
    key: 'debitorstyring_kredit',
    label: 'Debitorstyring (Kredit)',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'CleanManager', label: 'CleanManager', color: '#1890ff' },
      { value: 'CloudCollect', label: 'CloudCollect', color: '#52c41a' },
      { value: 'CollecTech', label: 'CollecTech', color: '#faad14' },
      { value: 'Danske Inkasso', label: 'Danske Inkasso', color: '#f5222d' },
      { value: 'Equity', label: 'Equity', color: '#722ed1' },
      { value: 'FarPay', label: 'FarPay', color: '#13c2c2' },
      { value: 'Inkasso mægleren', label: 'Inkasso mægleren', color: '#eb2f96' },
      { value: 'itv Leverandørservice', label: 'itv Leverandørservice', color: '#2f54eb' },
      { value: 'Likvido', label: 'Likvido', color: '#a0d911' },
      { value: 'MoneyFlow', label: 'MoneyFlow', color: '#fa541c' },
      { value: 'ReePay', label: 'ReePay', color: '#fa8c16' },
      { value: 'Rykkerportalen', label: 'Rykkerportalen', color: '#13c2c2' },
      { value: 'ZietWing', label: 'ZietWing', color: '#722ed1' }
    ]
  },
  {
    key: 'debitorstyring_kontant',
    label: 'Debitorstyring (Kontant)',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'FlexPOS', label: 'FlexPOS', color: '#1890ff' },
      { value: 'Storebuddy', label: 'Storebuddy', color: '#52c41a' },
      { value: 'PosOne', label: 'PosOne', color: '#faad14' },
      { value: 'Customers 1st', label: 'Customers 1st', color: '#f5222d' },
      { value: 'Shopify', label: 'Shopify', color: '#722ed1' },
      { value: 'OnlinePOS', label: 'OnlinePOS', color: '#13c2c2' },
      { value: 'ShopBox', label: 'ShopBox', color: '#eb2f96' },
      { value: 'OpenConnect', label: 'OpenConnect', color: '#2f54eb' },
      { value: 'Ajour POS', label: 'Ajour POS', color: '#a0d911' },
      { value: 'Zettie', label: 'Zettie', color: '#fa541c' },
      { value: 'Superb', label: 'Superb', color: '#fa8c16' },
      { value: 'ScanPOS', label: 'ScanPOS', color: '#13c2c2' },
      { value: 'Woolab', label: 'Woolab', color: '#722ed1' },
      { value: 'Loving Loyalty', label: 'Loving Loyalty', color: '#eb2f96' },
      { value: 'FlexyBox', label: 'FlexyBox', color: '#2f54eb' },
      { value: 'Zettle', label: 'Zettle', color: '#a0d911' },
      { value: 'Magasin', label: 'Magasin', color: '#fa541c' },
      { value: 'Loomis Pay', label: 'Loomis Pay', color: '#fa8c16' }
    ]
  },
  {
    key: 'projekt_og_tidsstyring',
    label: 'Projekt- og tidsstyring',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'Apacta', label: 'Apacta', color: '#1890ff' },
      { value: 'Au2Office', label: 'Au2Office', color: '#52c41a' },
      { value: 'CleanManager', label: 'CleanManager', color: '#faad14' },
      { value: 'Clockify', label: 'Clockify', color: '#f5222d' },
      { value: 'FTZ+', label: 'FTZ+', color: '#722ed1' },
      { value: 'Intempus', label: 'Intempus', color: '#13c2c2' },
      { value: 'Minuba', label: 'Minuba', color: '#eb2f96' },
      { value: 'Ordrestyring', label: 'Ordrestyring', color: '#2f54eb' },
      { value: 'Planday', label: 'Planday', color: '#a0d911' },
      { value: 'TimeLog', label: 'TimeLog', color: '#fa541c' },
      { value: 'WorkFeed', label: 'WorkFeed', color: '#fa8c16' }
    ]
  },
  {
    key: 'kreditorstyring_kvitteringer',
    label: 'Kreditorstyring (Kvitteringer)',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'Acubiz', label: 'Acubiz', color: '#1890ff' },
      { value: 'Pleo', label: 'Pleo', color: '#52c41a' },
      { value: 'Smart-Inbox', label: 'Smart-Inbox', color: '#faad14' },
      { value: 'Wolfpack', label: 'Wolfpack', color: '#f5222d' }
    ]
  },
  {
    key: 'andre_softwares',
    label: 'Andre softwares',
    type: 'text',
    editable: true
  }
];