import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, List, Typography, Tabs, Popconfirm, AutoComplete, Tooltip } from 'antd';
import { PushpinOutlined, EditOutlined, DeleteOutlined, SaveOutlined, CloseOutlined, TagOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import './StickyTasks.css';
import { format, parseISO } from 'date-fns';

const { Text } = Typography;

const STICKY_TASKS_DISABLED = true;

function formatTaskDate(dateString) {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, "dd/MM 'at' HH:mm");
}

function StickyTasks({ isVisible, isMaximized, onOpen, onClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [editingTask, setEditingTask] = useState(null);
  const [deals, setDeals] = useState([]);
  const [taggedDeal, setTaggedDeal] = useState(null);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [selectedDealIndex, setSelectedDealIndex] = useState(0);
  const inputRef = useRef(null);
  const taggedDealRef = useRef(null);

  useEffect(() => {
    fetchTasks();
    fetchDeals();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/ptask');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals');
      setDeals(response.data);
      console.log('Deals:', response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
    }
  };

  const toggleOpen = () => {
    if (STICKY_TASKS_DISABLED) return;
    if (isMaximized) {
      onClose();
    } else {
      onOpen();
    }
  };

  const addTask = async () => {
    if (newTask.trim() !== '') {
      try {
        console.log("Tagged Deal before sending:", taggedDealRef.current);
        const response = await axios.post('/ptask', { 
          text: newTask, 
          deal_id: taggedDealRef.current ? taggedDealRef.current.id : null
        });
        console.log("Response from server:", response.data);
        setTasks([...tasks, response.data]);
        setNewTask('');
        taggedDealRef.current = null; // Reset taggedDeal after adding the task
      } catch (error) {
        console.error('Error adding task:', error);
      }
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await axios.delete(`/ptask/${taskId}`);
      setTasks(tasks.filter(task => task.id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const startEditing = (task) => {
    setEditingTask({ ...task });
  };

  const saveEdit = async () => {
    try {
      const response = await axios.put(`/ptask/${editingTask.id}`, { text: editingTask.text });
      setTasks(tasks.map(task => task.id === editingTask.id ? response.data : task));
      setEditingTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleNewTaskChange = (e) => {
    const newValue = e.target.value;
    setNewTask(newValue);
    
    // Check if there's a '#' followed by text in the input
    const hashtagMatch = newValue.match(/#(\w+)(?:\s|$)/);
    
    if (hashtagMatch) {
      const taggedDealName = hashtagMatch[1];
      const matchedDeal = deals.find(deal => deal.name.toLowerCase() === taggedDealName.toLowerCase());
      
      if (matchedDeal) {
        taggedDealRef.current = matchedDeal;
      } else {
        // If we're in the process of typing a deal name, show filtered deals
        const searchText = taggedDealName;
        if (searchText) {
          const filtered = filterDeals(searchText);
          setFilteredDeals(filtered);
          setSelectedDealIndex(0);
        } else {
          setFilteredDeals([]);
        }
      }
    } else {
      // If there's no '#' in the input, reset filtered deals but keep the tagged deal
      setFilteredDeals([]);
    }
  };

  const handleDealSelect = (dealName) => {
    const selectedDeal = deals.find(deal => deal.name === dealName);
    console.log("Selected Deal:", selectedDeal);
    taggedDealRef.current = selectedDeal;
    setNewTask(newTask.replace(/#[^#]*$/, `#${dealName} `));
    setFilteredDeals([]);
    inputRef.current.focus();
  };

  const filterDeals = (searchText) => {
    if (!searchText) return [];
    return deals
      .filter(deal => deal && deal.name && deal.name.toLowerCase().startsWith(searchText.toLowerCase()))
      .map(deal => ({ value: deal.name, id: deal.id }));
  };

  const handleKeyDown = (e) => {
    if (filteredDeals.length > 0) {
      if (e.key === 'Tab') {
        e.preventDefault();
        setSelectedDealIndex((prevIndex) => (prevIndex + 1) % filteredDeals.length);
      } else if (e.key === 'Enter') {
        e.preventDefault();
        handleDealSelect(filteredDeals[selectedDealIndex].value);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      addTask();
    }
  };

  const renderTaskText = (text) => {
    const parts = text.split(/(#\w+)/);
    return parts.map((part, index) => {
      if (part.startsWith('#')) {
        return (
          <span key={index} className="tagged-account">
            <TagOutlined className="tag-icon" />
            <span className="account-badge">{part.slice(1)}</span>
          </span>
        );
      }
      return part;
    });
  };

  const tabItems = [
    {
      key: '1',
      label: 'Create',
      children: (
        <>
          <div className="new-task-input-container">
            {filteredDeals.length > 0 && (
              <ul className="deal-list">
                {filteredDeals.map((deal, index) => (
                  <li
                    key={deal.id}
                    className={index === selectedDealIndex ? 'selected' : ''}
                    onClick={() => handleDealSelect(deal.value)}
                  >
                    {deal.value}
                  </li>
                ))}
              </ul>
            )}
            <Input
              ref={inputRef}
              placeholder="Write a new task..."
              value={newTask}
              onChange={handleNewTaskChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !filteredDeals.length) {
                  e.preventDefault();
                  addTask();
                } else {
                  handleKeyDown(e);
                }
              }}
              className="new-task-input"
            />
          </div>
          <Button onClick={addTask} type="primary" className="add-task-button">
            Stick it!
          </Button>
        </>
      ),
    },
    {
      key: '2',
      label: 'View',
      children: (
        <List
          className="task-list"
          itemLayout="horizontal"
          dataSource={tasks}
          renderItem={task => (
            <List.Item
              actions={[
                <Button icon={<EditOutlined />} onClick={() => startEditing(task)} className="task-action-button" />,
                <Popconfirm
                  title="Are you sure you want to unstick this task?"
                  onConfirm={() => deleteTask(task.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} className="task-action-button" />
                </Popconfirm>
              ]}
            >
              {editingTask && editingTask.id === task.id ? (
                <>
                  <Input
                    value={editingTask.text}
                    onChange={(e) => setEditingTask({ ...editingTask, text: e.target.value })}
                    onPressEnter={saveEdit}
                    className="edit-task-input"
                  />
                  <Button icon={<SaveOutlined />} onClick={saveEdit} className="task-save-button" />
                </>
              ) : (
                <>
                  <Text className="task-text">{renderTaskText(task.text)}</Text>
                  {task.task_date && (
                    <Text className="personal-task-date">{formatTaskDate(task.task_date)}</Text>
                  )}
                </>
              )}
            </List.Item>
          )}
        />
      ),
    },
  ];

  return (
    <div className={`sticky-tasks-container ${isOpen ? 'open' : ''} ${isVisible ? 'visible' : ''} ${isMaximized ? 'maximized' : ''} ${STICKY_TASKS_DISABLED ? 'disabled' : ''}`}>
      <div className="sticky-tasks-content">
        {!isMaximized ? (
          <Tooltip title="Todo (Coming Soon)" placement="left">
            <div className={`sticky-tasks-minimized ${STICKY_TASKS_DISABLED ? 'disabled' : ''}`} onClick={toggleOpen}>
              <PushpinOutlined className="pushpin-icon" />
            </div>
          </Tooltip>
        ) : (
          <div className="sticky-tasks-maximized">
            <div className="sticky-header">
              <Text className="sticky-tasks-title">Sticky Tasks</Text>
              <Button type="text" onClick={toggleOpen} className="close-button">
                <CloseOutlined />
              </Button>
            </div>
            <Tabs defaultActiveKey="1" className="sticky-tabs" items={tabItems} />
          </div>
        )}
      </div>
    </div>
  );
}

export default StickyTasks;