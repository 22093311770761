export const dealDataConfig = [
    {
      key: 'data.value',
      label: 'Value',
      type: 'number',
      editable: true,
    },
    {
      key: 'main_mailbox',
      label: 'Bilags Mail',
      type: 'text',
      editable: false,
    },
    {
      key: 'corpay_mailbox',
      label: 'Corpay Mail',
      type: 'text',
      editable: false,
    },
    {
      key: 'data.aktiv_kunde',
      label: 'Aktiv kunde',
      type: 'boolean',
      editable: true,
    },
    {
      key: 'data.momsfrekvens',
      label: 'Momsfrekvens',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Momsfritaget', label: 'Momsfritaget', color: '#a6a6a6' },
        { value: 'Kvartalsvis', label: 'Kvartalsvis', color: '#1890ff' },
        { value: 'Månedligt', label: 'Månedligt', color: '#52c41a' },
        { value: 'Halvårligt', label: 'Halvårligt', color: '#faad14' },
        { value: 'Lønsumpligt', label: 'Lønsumpligt', color: '#f5222d' },
      ],
    },
    {
      key: 'data.lønfrekvens',
      label: 'Lønfrekvens',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Ingen løn', label: 'Ingen løn', color: '#a6a6a6' },
        { value: '14-dages løn', label: '14-dages løn', color: '#1890ff' },
        { value: 'Månedsløn', label: 'Månedlig', color: '#52c41a' },
        { value: 'Kvartalsvis', label: 'Kvartalsvis', color: '#faad14' },
      ],
    },
    {
      key: 'data.lønansvarlig',
      label: 'Lønansvarlig',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Ingen', label: 'Ingen', color: '#a6a6a6' },
        { value: 'Digi-Tal', label: 'Digi-Tal', color: '#1890ff' },
        { value: 'Kunden', label: 'Kunden', color: '#52c41a' },
      ],
    },
    {
      key: 'data.onboarding_dato',
      label: 'Onboarding dato',
      type: 'date',
      editable: true,
    },
    {
      key: 'data.virksomhedsform',
      label: 'Virksomhedsform',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Enkeltmandsvirksomhed', label: 'Enkeltmandsvirksomhed', color: '#1890ff' },
        { value: 'Holding', label: 'Holding', color: '#52c41a' },
        { value: 'Anpartsselskab', label: 'Anpartsselskab', color: '#faad14' },
        { value: 'Aktieselskab', label: 'Aktieselskab', color: '#f5222d' },
        { value: 'Interessentskab', label: 'Interessentskab', color: '#13c2c2' },
        { value: 'Forening', label: 'Forening', color: '#722ed1' },
        { value: 'Personligt ejet mindre virksomhed', label: 'Personligt ejet mindre virksomhed', color: '#eb2f96' },
        { value: 'Partnerskab', label: 'Partnerskab', color: '#2f54eb' },
        { value: 'Kommanditselskab', label: 'Kommanditselskab', color: '#a0d911' },
        { value: 'Fond', label: 'Fond', color: '#fa541c' },
      ],
    },
    {
      key: 'data.regnskabsprogram',
      label: 'Regnskabsprogram',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Ingen', label: 'Ingen', color: '#a6a6a6' },
        { value: 'Economic', label: 'Economic', color: '#52c41a' },
        { value: 'Dinero', label: 'Dinero', color: '#1890ff' },
        { value: 'Billy', label: 'Billy', color: '#faad14' },
        { value: 'Uniconta', label: 'Uniconta', color: '#13c2c2' },
      ],
    },
    {
      key: 'data.hvidvask_compliant',
      label: 'Hvidvask compliant',
      type: 'boolean',
      editable: true,
    },
    {
      key: 'data.sidste_fakturadato',
      label: 'Sidste fakturadato',
      type: 'date',
      editable: true,
    },
    {
      key: 'data.ansvarlig_bogholder',
      label: 'Ansvarlig bogholder',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Mads Brock-Madsen', label: 'Mads Brock-Madsen', color: '#1890ff' },
        { value: 'Dennis Suksuwat', label: 'Dennis Suksuwat', color: '#52c41a' },
      ],
    },
    {
      key: 'data.bogføringsfrekvens',
      label: 'Bogføringsfrekvens',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Månedlig', label: 'Månedlig', color: '#1890ff' },
        { value: 'Kvartalsvis', label: 'Kvartalsvis', color: '#52c41a' },
        { value: 'Halvårlig', label: 'Halvårlig', color: '#faad14' },
      ],
    },
    {
      key: 'data.seneste_momsopgørelse',
      label: 'Seneste momsopgørelse',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.ansvarlig_lønbogholder',
      label: 'Ansvarlig lønbogholder',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Ingen', label: 'Ingen', color: '#a6a6a6' },
        { value: 'Lina Drøschler', label: 'Lina Drøschler', color: '#1890ff' },
        { value: 'Daniella Krog Katic', label: 'Daniella Krog Katic', color: '#52c41a' },
      ],
    },
    {
      key: 'data.ansvarlig_revisor_for_årsregnskab',
      label: 'Ansvarlig revisor for årsregnskab',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Ingen', label: 'Ingen', color: '#a6a6a6' },
        { value: 'Mads Brock-Madsen', label: 'Mads Brock-Madsen', color: '#1890ff' },
        { value: 'Dennis Suksuwat', label: 'Dennis Suksuwat', color: '#52c41a' },
      ],
    },
    {
      key: 'data.hvem_er_ansvarlig_for_årsregnskab',
      label: 'Hvem er ansvarlig for årsregnskab',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Digi-Tal', label: 'Digi-Tal', color: '#1890ff' },
        { value: 'Kunden', label: 'Kunden', color: '#52c41a' },
        { value: 'Ekstern revisor', label: 'Ekstern revisor', color: '#faad14' },
      ],
    },
    {
      key: 'data.hvornår_slutter_næste_regnskabsår',
      label: 'Hvornår slutter næste regnskabsår',
      type: 'date',
      editable: true,
    },
    {
      key: 'data.antal_medarbejdere_til_lønhandtering',
      label: 'Antal medarbejdere til lønhåndtering',
      type: 'number',
      editable: true,
      colorScale: [
        { max: 5, color: '#52c41a' },    // Green for 1-5 employees
        { max: 20, color: '#1890ff' },   // Blue for 6-20 employees
        { max: 50, color: '#faad14' },   // Orange for 21-50 employees
        { max: Infinity, color: '#f5222d' }  // Red for 51+ employees
      ]
    },
    {
      key: 'data.kan_bankkonto_integreres_i_bogføringssystem',
      label: 'Kan bankkonto integreres i bogføringssystem',
      type: 'boolean',
      editable: true,
    },
    {
      key: 'description',
      label: 'Description',
      type: 'text',
      editable: false,
    },
    {
      key: 'data.kundenummer_i_economic',
      label: 'Kundenummer i Economic',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.onboarding_opgaver',
      label: 'Onboarding opgaver',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.deal_source',
      label: 'Deal source',
      type: 'text',
      editable: true,
    },

    // Software
    {
      key: 'software.lønprogram',
      label: 'Lønprogram',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.lagerstyring',
      label: 'Lagerstyring',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.kreditorstyring',
      label: 'Kreditorstyring',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.betalingsgateway',
      label: 'Betalingsgateway',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.abonnementsstyring',
      label: 'Abonnementsstyring',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.debitorstyring_kredit',
      label: 'Debitorstyring (Kredit)',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.debitorstyring_kontant',
      label: 'Debitorstyring (Kontant)',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.projekt_og_tidsstyring',
      label: 'Projekt- og tidsstyring',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.kreditorstyring_kvitteringer',
      label: 'Kreditorstyring (Kvitteringer)',
      type: 'dropdown',
      editable: true,
    },
    {
      key: 'software.andre_softwares',
      label: 'Andre softwares',
      type: 'text',
      editable: true,
    },
  ];