import React, { useState, useEffect } from 'react';
import './App.css';
import './AntDesignOverrides.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider, theme, App as AntApp } from 'antd';
import { MessageProvider } from './contexts/MessageContext';
import { TimeTrackerProvider } from './contexts/TimeTrackerContext';
import axios from './api/axiosConfig';
import { hasRequiredPermission } from './utils/permissionUtils';
import { PresenceProvider } from './contexts/ActivityContext';

import Login from './pages/Login';
import Pages from './pages/Pages';
import CompleteRegistration from './pages/CompleteRegistration';

import OverlayHub from './overlays/OverlayHub';

import MainMenu from './pages/MainMenu';
import DigiDashboard from './digi-tal-dashboard/Digi-Dashboard';
import Bot from './digi-tal-bot/Digi-Regnskabsbot';
import InvoiceOverview from './invoice/InvoiceOverview';

import Officebot from './tools/officebot/Officebot';
import LinkedinBot from './tools/gpts/LinkedinBot';

import Automations from './automation/Automations';

import AdminDashboard from './admin/AdminDashboard';

import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme') || 'dark');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const manualLogout = sessionStorage.getItem('manualLogout');
    const storedUser = localStorage.getItem('user');
    
    if (!manualLogout && storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing stored user:', error);
        localStorage.removeItem('user');
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      const token = localStorage.getItem('token');
      if (!token) {
        setUser(null);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
      }
    }
  }, [user]);

  useEffect(() => {
    document.body.setAttribute('data-theme', currentTheme);
    // Call the function defined in index.html
    window.setBackgroundColor();
  }, [currentTheme]);

  const toggleTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setCurrentTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    // Call the function defined in index.html
    window.setBackgroundColor();
  };
  
  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <ConfigProvider
      theme={{
        algorithm: currentTheme === 'dark' ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: currentTheme === 'light' ? '#0070FF' : '#4299E1',
          colorBgContainer: currentTheme === 'light' ? '#FFFFFF' : '#1A202C',
          colorText: currentTheme === 'light' ? '#181818' : '#F7FAFC',
          colorBgTextHover: currentTheme === 'light' ? '#F7FAFC' : '#2D3748',
        },
        components: {
          Table: {
            colorBgContainer: currentTheme === 'light' ? '#FFFFFF' : '#1A202C',
            colorText: currentTheme === 'light' ? '#181818' : '#F7FAFC',
            colorTextHeading: currentTheme === 'light' ? '#181818' : '#F7FAFC',
            colorBgTextHover: currentTheme === 'light' ? '#F7FAFC' : '#2D3748',
          },
        },
      }}
    >
      <MessageProvider>
        <TimeTrackerProvider>
          <PresenceProvider>
            <AntApp>
              <Router>
                <div className="App" data-theme={currentTheme}>
                  <Routes>
                    <Route path="/" element={<Login theme={currentTheme} setUser={setUser} />} />
                    <Route path="/login" element={<Login theme={currentTheme} setUser={setUser} />} />
                    <Route path="/complete-registration" element={<ProtectedRoute><CompleteRegistration /></ProtectedRoute>} />
                    <Route 
                      path="/main-menu" 
                      element={
                        <ProtectedRoute requiredPermission="user">
                          <MainMenu theme={currentTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/invoice-overview" 
                      element={
                        <ProtectedRoute requiredPermission="user">
                          <InvoiceOverview theme={currentTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/invoice-overview/:onboardingId" 
                      element={
                        <ProtectedRoute requiredPermission="user">
                          <InvoiceOverview theme={currentTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/*" 
                      element={
                        <ProtectedRoute requiredPermission="user">
                          <Pages theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />

                    
                    <Route 
                      path="/digi-dashboard" 
                      element={
                        <ProtectedRoute requiredPermission="admin">
                          <DigiDashboard theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/bot" 
                      element={
                        <ProtectedRoute requiredPermission="user">
                          <Bot theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/officebot" 
                      element={
                        <ProtectedRoute requiredPermission="user">
                          <Officebot theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/custom-gpts" 
                      element={
                        <ProtectedRoute requiredPermission="admin">
                          <LinkedinBot theme={currentTheme} toggleTheme={toggleTheme} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/admin" 
                      element={
                        <ProtectedRoute requiredPermission="owner">
                          <AdminDashboard theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                    <Route 
                      path="/flows/*" 
                      element={
                        <ProtectedRoute requiredPermission="admin">
                          <Automations theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                        </ProtectedRoute>
                      } 
                    />
                  </Routes>
                  <OverlayHub />
                </div>
              </Router>
            </AntApp>
          </PresenceProvider>
        </TimeTrackerProvider>
      </MessageProvider>
    </ConfigProvider>
  );
}

export default App;
