import React, { useState } from 'react';
import { Button, Card, Table, message, Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './AdminRevibot.css';

function AdminRevibot() {
  const [syncing, setSyncing] = useState(false);
  const [syncHistory, setSyncHistory] = useState([]);

  const handleSync = async () => {
    try {
      setSyncing(true);
      const response = await axios.post('/revibot/sync');
      message.success('Sync completed successfully');
      // Fetch updated sync status
      fetchSyncStatus();
    } catch (error) {
      message.error('Failed to sync: ' + (error.response?.data?.error || error.message));
    } finally {
      setSyncing(false);
    }
  };

  const fetchSyncStatus = async () => {
    try {
      const response = await axios.get('/revibot/sync/status');
      setSyncHistory(response.data);
    } catch (error) {
      message.error('Failed to fetch sync status');
    }
  };

  // Columns for the sync history table
  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'Status',
      dataIndex: 'sync_status',
      key: 'sync_status',
      render: (status) => (
        <span style={{ 
          color: status === 'success' ? '#52c41a' : 
                 status === 'error' ? '#f5222d' : 
                 status === 'deleted' ? '#faad14' : '#1890ff'
        }}>
          {status}
        </span>
      ),
    },
    {
      title: 'Last Sync',
      dataIndex: 'last_sync',
      key: 'last_sync',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      render: (date) => date ? new Date(date).toLocaleString() : '-',
    },
    {
      title: 'File Size',
      dataIndex: 'file_size',
      key: 'file_size',
      render: (size) => `${(size / 1024).toFixed(2)} KB`,
    },
  ];

  // Fetch sync status when component mounts
  React.useEffect(() => {
    fetchSyncStatus();
  }, []);

  return (
    <div className="revibot-container">
      <Card title="Revibot Sync" className="revibot-card">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button 
            type="primary" 
            icon={<SyncOutlined spin={syncing} />}
            onClick={handleSync}
            loading={syncing}
            style={{ marginBottom: 16 }}
          >
            Sync Revibot Files
          </Button>
          
          <Table 
            dataSource={syncHistory}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </Space>
      </Card>
    </div>
  );
}

export default AdminRevibot;