import React from 'react';
import { Select, Input, InputNumber, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { getAvailableFields } from '../modules/conditions/DataCondition';
import { moduleConfigs } from '../modules/nodeTypes';

const { Option } = Select;

export const getSourceNode = (currentNode, edges, nodes) => {
    const incomingEdge = edges.find(edge => edge.target === currentNode.id);
    if (!incomingEdge) return null;
    
    return nodes.find(node => node.id === incomingEdge.source);
  };
  
  export const renderValueInput = (fieldType, options) => {
    switch (fieldType) {
      case 'string':
        return options ? (
          <Select style={{ width: '100%' }}>
            {options.map(option => (
              <Option key={option} value={option}>{option}</Option>
            ))}
          </Select>
        ) : (
          <Input />
        );
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'date':
        return (
          <DatePicker 
            style={{ width: '100%' }} 
            format="YYYY-MM-DD"
            showTime={false}
          />
        );
      default:
        return <Input />;
    }
  };

// Helper function to find source trigger node
export const findFlowStartNode = (nodeId, nodes, edges) => {
  const visited = new Set();
  
  const traverse = (currentId) => {
    if (visited.has(currentId)) return null;
    visited.add(currentId);
    
    const currentNode = nodes.find(n => n.id === currentId);
    if (currentNode?.type === 'flowStart') {
      return currentNode;
    }
    
    const incomingEdges = edges.filter(e => e.target === currentId);
    
    for (const edge of incomingEdges) {
      const result = traverse(edge.source);
      if (result) return result;
    }
    
    return null;
  };
  
  return traverse(nodeId);
};

// Centralized function to check if a node is connected to a trigger
export const isNodeConnectedToStart = (nodeId, nodes, edges) => {
  const triggerNode = findFlowStartNode(nodeId, nodes, edges);
  return !!triggerNode;
};

// Helper function to find all trigger nodes connected to this node through flowStart
export const findConnectedTriggerNodes = (nodeId, nodes, edges) => {
  const visited = new Set();
  const triggerNodes = [];
  
  const traverse = (currentId) => {
    if (visited.has(currentId)) return;
    visited.add(currentId);
    
    const currentNode = nodes.find(n => n.id === currentId);
    if (!currentNode) return;

    // Collect trigger nodes when we find them
    if (currentNode.type === 'eventTrigger' || currentNode.type === 'scheduleTrigger') {
      triggerNodes.push(currentNode);
    }

    // Only traverse upstream
    const incomingEdges = edges.filter(e => e.target === currentId);
    incomingEdges.forEach(edge => traverse(edge.source));
  };
  
  traverse(nodeId);
  return triggerNodes;
};

// Update the existing function to use the new helper
export const getAvailableFieldsFromTrigger = (nodeId, nodes, edges) => {
  const triggerNodes = findConnectedTriggerNodes(nodeId, nodes, edges);
  
  // For UpdateDeal, filter to only deal-related triggers
  const currentNode = nodes.find(n => n.id === nodeId);
  if (currentNode?.type === 'updateDeal') {
    const dealTriggers = triggerNodes.filter(node => 
      node.data.eventType?.startsWith('deal_')
    );
    if (dealTriggers.length === 0) return [];
    // Use the first valid deal trigger
    return getAvailableFields(dealTriggers[0]);
  }

  // For other nodes like DataCondition, use all available triggers
  if (triggerNodes.length === 0) return [];
  
  // Combine fields from all triggers
  return triggerNodes.reduce((allFields, triggerNode) => {
    if (!triggerNode.data.eventType) return allFields;
    const fields = getAvailableFields(triggerNode);
    return [...allFields, ...fields];
  }, []);
};

// Helper to get field config for a specific field
export const getFieldConfig = (fieldKey, availableFields) => {
  return availableFields.find(field => field.key === fieldKey);
};

// Add this new utility function
export const getPreviousNodeFields = (nodeId, nodes, edges) => {
  const fields = [];
  const visited = new Set();

  const traverse = (currentId) => {
    if (!currentId || visited.has(currentId)) return;
    visited.add(currentId);

    const node = nodes.find(n => n.id === currentId);
    if (!node || node.id === nodeId) return;

    const moduleConfig = moduleConfigs[node.type];
    if (moduleConfig?.getAvailableFields) {
      const nodeFields = moduleConfig.getAvailableFields(node);
      // Add node number to field labels if not already included
      const fieldsWithNodeNumber = nodeFields.map(field => ({
        ...field,
        label: field.label.includes(`#${node.data.nodeNumber}`) 
          ? field.label 
          : `${node.data.label || moduleConfig.label} (#${node.data.nodeNumber}) - ${field.label}`,
        sourceNodeId: node.id,
        sourceNodeNumber: node.data.nodeNumber
      }));
      fields.push(...fieldsWithNodeNumber);
    }

    const incomingEdges = edges.filter(e => e.target === currentId);
    incomingEdges.forEach(e => traverse(e.source));
  };

  traverse(nodeId);
  return fields;
};