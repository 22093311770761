import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNodesState, useEdgesState, addEdge } from '@xyflow/react';
import { moduleConfigs } from '../modules/nodeTypes';

const FlowContext = createContext(null);

export const FlowProvider = ({ children }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [flowData, setFlowData] = useState({
    name: '',
    description: '',
    nodes: [],
    edges: []
  });

  const updateNode = useCallback((nodeId, newData) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, ...newData } } : node
      )
    );
  }, [setNodes]);

  const onConnect = useCallback((params) => {
    setEdges((eds) => {
      const newEdge = {
        ...params,
        type: 'smoothstep',
        animated: false,
        style: {
          stroke: 'var(--primary-color)',
          strokeWidth: 2,
        }
      };
      const newEdges = addEdge(newEdge, eds);

      // Update node connection status after adding edge
      setNodes((nds) => {
        return nds.map(node => {
          const moduleConfig = moduleConfigs[node.type];
          if (!moduleConfig?.moduleConfig?.isConnectedToSource) return node;

          const isConnected = moduleConfig.moduleConfig.isConnectedToSource(
            node.id,
            nds,
            newEdges
          );

          return {
            ...node,
            data: { ...node.data, isConnected }
          };
        });
      });

      return newEdges;
    });
  }, [setEdges, setNodes]);

  const updateNodeNumbers = useCallback(() => {
    setNodes(currentNodes => {
      // Filter out flow-start and flow-finish nodes
      const regularNodes = currentNodes.filter(
        node => !['flow-start', 'flow-finish'].includes(node.id)
      );

      // Sort nodes by existing numbers
      const sortedNodes = [...regularNodes].sort((a, b) => {
        const aNum = a.data.nodeNumber || Infinity;
        const bNum = b.data.nodeNumber || Infinity;
        return aNum - bNum;
      });

      // Find the highest existing number
      const maxNumber = Math.max(
        0,
        ...regularNodes.map(node => node.data.nodeNumber || 0)
      );

      // Assign next available number only to nodes without numbers
      let nextNumber = maxNumber + 1;
      const updatedNodes = currentNodes.map(node => {
        // Skip flow-start and flow-finish nodes
        if (['flow-start', 'flow-finish'].includes(node.id)) {
          return node;
        }
        
        // Keep existing numbers
        if (node.data.nodeNumber) {
          return node;
        }

        // Assign new number only to nodes without one
        return {
          ...node,
          data: { ...node.data, nodeNumber: nextNumber++ }
        };
      });

      return updatedNodes;
    });
  }, [setNodes]);

  useEffect(() => {
    updateNodeNumbers();
  }, [nodes.length]);

  const updateNodeNumber = useCallback((nodeId, newNumber) => {
    setNodes(currentNodes => {
      if (currentNodes.some(n => n.data.nodeNumber === newNumber && n.id !== nodeId)) {
        return currentNodes;
      }

      return currentNodes.map(node => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, nodeNumber: newNumber }};
        }
        return node;
      });
    });
  }, [setNodes]);

  const value = {
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    onConnect,
    selectedNode,
    setSelectedNode,
    isConfiguring,
    setIsConfiguring,
    flowData,
    setFlowData,
    updateNode,
    updateNodeNumber
  };

  return (
    <FlowContext.Provider value={value}>
      {children}
    </FlowContext.Provider>
  );
};

export const useFlow = () => {
  const context = useContext(FlowContext);
  if (!context) {
    throw new Error('useFlow must be used within a FlowProvider');
  }
  return context;
};