import React, { useState, useEffect } from 'react';
import { Form, Button, InputNumber, Calendar, Checkbox, Space, Modal, message } from 'antd';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import './UserCapacitySettings.css';

function UserCapacitySettings({ visible, onVisibilityChange }) {
  const [form] = Form.useForm();
  const [capacityForm] = Form.useForm();
  const [capacityData, setCapacityData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [capacityModalVisible, setCapacityModalVisible] = useState(false);
  const [workDays, setWorkDays] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false
  });
  const [calendarDate, setCalendarDate] = useState(dayjs());

  useEffect(() => {
    fetchCapacityData();
  }, []);

  useEffect(() => {
    if (capacityData) {
      form.setFieldsValue({
        default_capacity: {
          hours: Math.floor(capacityData.default_daily_capacity / 60),
          minutes: capacityData.default_daily_capacity % 60
        }
      });
    }
  }, [capacityData]);

  const fetchCapacityData = async () => {
    try {
      const response = await axios.get('/user/capacity');
      setCapacityData(response.data);
      setWorkDays(response.data.default_work_days);
    } catch (error) {
      message.error('Failed to load capacity data');
    }
  };

  const cellRender = (date, info) => {
    if (info.type !== 'date') return null;
    
    const dateStr = date.format('YYYY-MM-DD');
    const dayOfWeek = date.format('dddd').toLowerCase();
    const override = capacityData?.daily_overrides?.[dateStr];
    const isWorkDay = capacityData?.default_work_days?.[dayOfWeek];
    const defaultCapacity = capacityData?.default_daily_capacity;

    if (override === undefined && !isWorkDay) {
      return null;
    }

    const capacity = override !== undefined ? override : (isWorkDay ? defaultCapacity : 0);
    const hours = Math.floor(capacity / 60);
    const minutes = capacity % 60;

    let textColor = 'var(--primary-color)';
    if (capacity > defaultCapacity) {
      textColor = 'var(--error-color)';
    } else if (capacity < defaultCapacity && capacity > 0) {
      textColor = 'var(--success-color)';
    }

    return capacity > 0 ? (
      <div className="capacity-cell" style={{ color: textColor }}>
        {`${hours}h ${minutes}m`}
      </div>
    ) : null;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    
    const dayOfWeek = date.format('dddd').toLowerCase();
    const dateStr = date.format('YYYY-MM-DD');
    let initialCapacity = 0;
    
    if (dateStr in capacityData?.daily_overrides) {
      initialCapacity = capacityData.daily_overrides[dateStr];
    } else if (capacityData?.default_work_days[dayOfWeek] === true) {
      initialCapacity = capacityData.default_daily_capacity;
    }
    
    capacityForm.setFieldsValue({
      hours: Math.floor(initialCapacity / 60),
      minutes: initialCapacity % 60
    });
    
    setCapacityModalVisible(true);
  };

  const handleCapacityUpdate = async (values) => {
    try {
      const totalMinutes = (values.hours * 60) + (values.minutes || 0);
      await axios.put('/user/capacity/override', {
        date: selectedDate.format('YYYY-MM-DD'),
        capacity: totalMinutes
      });
      message.success('Capacity override updated');
      fetchCapacityData();
      setCapacityModalVisible(false);
    } catch (error) {
      message.error('Failed to update capacity override');
    }
  };

  const handleDefaultCapacityUpdate = async (values) => {
    try {
      const totalMinutes = (values.default_capacity.hours * 60) + 
        (values.default_capacity.minutes || 0);
      
      await axios.put('/user/capacity/default', {
        daily_capacity: totalMinutes,
        work_days: workDays
      });
      message.success('Default capacity updated');
      fetchCapacityData();
    } catch (error) {
      message.error('Failed to update default capacity');
    }
  };

  const headerRender = ({ value, onChange }) => {
    const currentMonth = value.format('MMMM YYYY');
    
    return (
      <div className="calendar-header">
        <Button 
          onClick={() => {
            const newDate = value.subtract(1, 'month');
            setCalendarDate(newDate);
            onChange(newDate);
          }}
        >
          Previous
        </Button>
        <div className="current-month">{currentMonth}</div>
        <Button 
          onClick={() => {
            const newDate = value.add(1, 'month');
            setCalendarDate(newDate);
            onChange(newDate);
          }}
        >
          Next
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (visible) {
      setCalendarDate(dayjs());
      fetchCapacityData();
    } else {
      setCalendarDate(dayjs());
    }
    onVisibilityChange?.(visible);
  }, [visible, onVisibilityChange]);


  return (
    <>
      <Form 
        form={form} 
        layout="vertical"
        onFinish={handleDefaultCapacityUpdate}
        initialValues={{
          default_capacity: {
            hours: capacityData ? Math.floor(capacityData.default_daily_capacity / 60) : 0,
            minutes: capacityData ? capacityData.default_daily_capacity % 60 : 0
          }
        }}
      >
        <Form.Item label="Default Daily Capacity" style={{ marginBottom: 24 }}>
          <div className="capacity-inputs">
            <Form.Item 
              name={['default_capacity', 'hours']} 
              label="Hours" 
              className="capacity-input-item"
              initialValue={capacityData ? Math.floor(capacityData.default_daily_capacity / 60) : 0}
            >
              <InputNumber min={0} max={24} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item 
              name={['default_capacity', 'minutes']} 
              label="Minutes"
              className="capacity-input-item"
              initialValue={capacityData ? capacityData.default_daily_capacity % 60 : 0}
            >
              <InputNumber min={0} max={59} style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Form.Item>

        <Form.Item label="Default Work Days">
          <div className="work-days-grid">
            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
              <Checkbox
                key={day}
                className="work-day-checkbox"
                checked={workDays[day]}
                onChange={(e) => setWorkDays(prev => ({
                  ...prev,
                  [day]: e.target.checked
                }))}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </Checkbox>
            ))}
          </div>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Default Settings
          </Button>
        </Form.Item>

        <Calendar
          fullscreen={false}
          cellRender={cellRender}
          onSelect={(date, { source }) => {
            if (source === 'date') {
              handleDateClick(date);
            }
          }}
          headerRender={headerRender}
          mode="month"
          value={calendarDate}
          defaultValue={dayjs()}
          onPanelChange={(date) => {
            setCalendarDate(date);
          }}
        />
      </Form>

      <Modal
        title={`Set Capacity for ${selectedDate?.format('MMMM D, YYYY')}`}
        open={capacityModalVisible}
        onCancel={() => {
          setCapacityModalVisible(false);
          capacityForm.resetFields();
        }}
        footer={null}
      >
        <Form 
          form={capacityForm}
          onFinish={handleCapacityUpdate} 
          layout="vertical"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space>
              <Form.Item 
                name="hours" 
                label="Hours"
                style={{ marginBottom: 0 }}
              >
                <InputNumber min={0} max={24} />
              </Form.Item>
              <Form.Item 
                name="minutes" 
                label="Minutes"
                style={{ marginBottom: 0 }}
              >
                <InputNumber min={0} max={59} />
              </Form.Item>
            </Space>

            <Space>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button 
                onClick={async () => {
                  try {
                    await axios.put('/user/capacity/override', {
                      date: selectedDate.format('YYYY-MM-DD'),
                      capacity: 0
                    });
                    await fetchCapacityData();
                    setCapacityModalVisible(false);
                    message.success('Set as day off');
                  } catch (error) {
                    message.error('Failed to set day off');
                  }
                }}
              >
                Set to Zero (Day Off)
              </Button>
              <Button 
                onClick={async () => {
                  try {
                    await axios.delete('/user/capacity/override', {
                      data: { date: selectedDate.format('YYYY-MM-DD') }
                    });
                    await fetchCapacityData();
                    setCapacityModalVisible(false);
                    message.success('Reset to default');
                  } catch (error) {
                    message.error('Failed to reset to default');
                  }
                }}
              >
                Reset to Default
              </Button>
            </Space>
          </Space>
        </Form>
      </Modal>
    </>
  );
}

export default UserCapacitySettings;