import React, { useState } from 'react';
import { Modal, Card, Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './AccountTree.css';

const AccountTree = ({ visible, onClose, account }) => {
  const [expandedProducts, setExpandedProducts] = useState({});

  const toggleProductExpansion = (dealId, productId) => {
    setExpandedProducts(prev => ({
      ...prev,
      [`${dealId}-${productId}`]: !prev[`${dealId}-${productId}`]
    }));
  };

  if (!account) return null;

  const hasSingleDeal = account.deals && account.deals.length === 1;

  return (
    <Modal
      title="Account Structure"
      open={visible}
      onCancel={onClose}
      width={800}
      footer={null}
      className="account-tree-modal"
    >
      <div className="account-tree">
        <div className="account-column">
          <Card title="Account" className="account-card">
            <p>{account.company_name}</p>
            <p>CVR: {account.cvr}</p>
          </Card>
          <div className="vertical-connector"></div>
          <Card title="Contacts" className="contacts-card">
            {account.contacts && account.contacts.map(contact => (
              <div key={contact.id}>
                <p>{contact.name}</p>
                <p>{contact.email}</p>
                <p>{contact.phone}</p>
              </div>
            ))}
          </Card>
        </div>
        <div className={`deals-container ${hasSingleDeal ? 'single-deal' : ''}`}>
          {account.deals && account.deals.length > 0 ? account.deals.map(deal => (
            <div key={deal.id} className="deal-branch">
              {!hasSingleDeal && <div className="horizontal-connector"></div>}
              <Card title={`Deal: ${deal.name || 'N/A'}`} className="deal-card">
                <p>CVR: {deal.cvr || 'N/A'}</p>
                <p>Value: {deal.value || 'N/A'} {deal.currency || ''}</p>
              </Card>
              <div className="vertical-connector"></div>
              <div className="products-container">
                <Card title="Products">
                  {deal.products && deal.products.map(product => (
                    <Card 
                      key={product.id} 
                      className="product-card"
                      title={product.name}
                      extra={
                        <Button 
                          type="text" 
                          icon={expandedProducts[`${deal.id}-${product.id}`] ? <UpOutlined /> : <DownOutlined />}
                          onClick={() => toggleProductExpansion(deal.id, product.id)}
                        />
                      }
                    >
                      <div className={`product-details ${expandedProducts[`${deal.id}-${product.id}`] ? 'expanded' : ''}`}>
                        <p>Code: {product.code}</p>
                        <p>Quantity: {product.quantity}</p>
                        <p>Price: {product.item_price}</p>
                        <p>Sum: {product.sum}</p>
                        <p>Billing Frequency: {product.billing_frequency}</p>
                      </div>
                    </Card>
                  ))}
                </Card>
              </div>
            </div>
          )) : <p>No deals available</p>}
        </div>
      </div>
    </Modal>
  );
};

export default AccountTree;
