import React, { useState } from 'react';
import { Tabs } from 'antd';
import TaskDash from '../digi-tal-dashboard/tasks/TaskDash';
import CustomerDash from '../digi-tal-dashboard/CustomerDash';
import SalesDash from '../digi-tal-dashboard/SalesDash';
import './Dashboard.css';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('tasks');

  const items = [
    { key: 'tasks', label: 'Tasks' },
    { key: 'customers', label: 'Customers' },
    { key: 'sales', label: 'Sales' },
  ];

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="dashboard-controls">
        <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} />
      </div>
      {activeTab === 'tasks' && <TaskDash />}
      {activeTab === 'customers' && <CustomerDash />}
      {activeTab === 'sales' && <SalesDash />}
    </div>
  );
}

export default Dashboard;