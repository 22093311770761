import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Checkbox, Select, message, Tooltip, Tabs, Badge } from 'antd';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './ImportDeals.css';

const { TabPane } = Tabs;

const ImportDeals = ({ visible, onClose, pendingDeals, onImportSuccess, onShowDealDetails }) => {
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [importing, setImporting] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState({});
  const [activeTab, setActiveTab] = useState('new');

  useEffect(() => {
    if (visible) {
      fetchAccounts();
    }
  }, [visible]);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  // Helper function to check if deal is Cross selling or Upsell
  const isCrossSellingOrUpsell = (deal) => {
    return ['Cross selling', 'Upsell'].includes(deal.data?.deal_source);
  };

  const handleDealSelection = (dealId) => {
    const deal = pendingDeals.find(d => d.id === dealId);
    if (isCrossSellingOrUpsell(deal)) {
      message.warning('Cross selling and Upsell deals must be imported individually');
      return;
    }
    setSelectedDeals(prevSelected => 
      prevSelected.includes(dealId)
        ? prevSelected.filter(id => id !== dealId)
        : [...prevSelected, dealId]
    );
  };

  const handleAccountSelection = (dealId, accountId) => {
    setSelectedAccountIds(prevState => ({
      ...prevState,
      [dealId]: accountId
    }));
  };

  const handleImportDeal = async (dealId) => {
    const deal = pendingDeals.find(d => d.id === dealId);
    
    if (isCrossSellingOrUpsell(deal) && !selectedAccountIds[dealId]) {
      message.error('Please select an existing account for Cross selling/Upsell deals');
      return;
    }

    setImporting(true);
    try {
      await axios.post(`/account/import-deal/${dealId}`, {
        accountId: selectedAccountIds[dealId]
      });
      message.success('Deal imported successfully');
      onImportSuccess();
    } catch (error) {
      console.error('Error importing deal:', error);
      message.error('Failed to import deal');
    } finally {
      setImporting(false);
    }
  };

  const handleImportAllDeals = async () => {
    const crossSellingDeals = pendingDeals.filter(deal => isCrossSellingOrUpsell(deal));
    if (crossSellingDeals.length > 0) {
      message.error('Cannot bulk import Cross selling or Upsell deals');
      return;
    }

    setImporting(true);
    try {
      await axios.post('/account/import-all-deals');
      message.success('All deals imported successfully');
      onClose();
      onImportSuccess();
    } catch (error) {
      console.error('Error importing all deals:', error);
      message.error('Failed to import all deals');
    } finally {
      setImporting(false);
    }
  };

  const handleImportSelected = async () => {
    setImporting(true);
    try {
      await Promise.all(selectedDeals.map(dealId => 
        axios.post(`/account/import-deal/${dealId}`, {
          accountId: selectedAccountIds[dealId]
        })
      ));
      message.success('Selected deals imported successfully');
      onImportSuccess();
      setSelectedDeals([]);
    } catch (error) {
      console.error('Error importing selected deals:', error);
      message.error('Failed to import selected deals');
    } finally {
      setImporting(false);
    }
  };

  const newDeals = pendingDeals.filter(deal => deal.is_new_deal);
  const migrationDeals = pendingDeals.filter(deal => !deal.is_new_deal);

  const renderAccountSelector = (deal) => {
    if (!isCrossSellingOrUpsell(deal)) {
      return null;
    }

    return (
      <div className="account-selector">
        <div className="account-selector-header">
          <WarningOutlined style={{ color: '#faad14' }} />
          <span style={{ marginLeft: 8 }}>
            This is a {deal.data.deal_source} deal. Please select an existing account:
          </span>
        </div>
        <Select
          showSearch
          style={{ width: '100%', marginTop: '8px' }}
          placeholder="Search and select an existing account"
          onChange={(value) => handleAccountSelection(deal.id, value)}
          value={selectedAccountIds[deal.id]}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {accounts.map(account => (
            <Select.Option key={account.id} value={account.id}>
              {account.company_name} ({account.cvr})
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderDeals = (deals) => (
    <>
      {deals.map(deal => (
        <Card 
          key={deal.id} 
          className={`pending-deal-card ${selectedDeals.includes(deal.id) ? 'selected' : ''} ${isCrossSellingOrUpsell(deal) ? 'cross-selling-deal' : ''}`}
          title={
            <div className="pending-deal-header">
              <h3>{deal.data.title}</h3>
              <div className="pending-deal-badges">
                {deal.recommendations?.recommendations && Object.keys(deal.recommendations.recommendations).length > 0 && (
                  <Tooltip title="This deal has recommendations">
                    <InfoCircleOutlined className="recommendation-icon" />
                  </Tooltip>
                )}
                {deal.recommendations?.alerts && deal.recommendations.alerts.length > 0 && (
                  <Tooltip title="This deal has alerts">
                    <WarningOutlined className="alert-icon" />
                  </Tooltip>
                )}
                <Badge 
                  count={deal.data.deal_source || 'N/A'} 
                  style={{ 
                    backgroundColor: isCrossSellingOrUpsell(deal) ? '#faad14' : '#52c41a'
                  }}
                />
              </div>
            </div>
          }
        >
          <p>Organization: {deal.name}</p>
          <p>CVR: {deal.cvr}</p>
          <p>Value: {Number(deal.data.value).toLocaleString()} {deal.data.currency}</p>
          <p>Source: {deal.source === 'make' ? 'Pipedrive -> Make' : deal.source}</p>
          {!isCrossSellingOrUpsell(deal) && (
            <Checkbox
              checked={selectedDeals.includes(deal.id)}
              onChange={() => handleDealSelection(deal.id)}
            >
              Select for import
            </Checkbox>
          )}
          {renderAccountSelector(deal)}
          <Button
            type="primary"
            onClick={() => handleImportDeal(deal.id)}
            loading={importing}
            disabled={isCrossSellingOrUpsell(deal) && !selectedAccountIds[deal.id]}
          >
            Import
          </Button>
          <Button type="link" onClick={() => onShowDealDetails(deal)}>
            View Details
          </Button>
        </Card>
      ))}
    </>
  );

  return (
    <Modal
      title={
        <Tabs 
          activeKey={activeTab} 
          onChange={setActiveTab}
          className="import-deals-tabs"
        >
          <TabPane 
            tab={
              <span>
                New Deals
                {newDeals.length > 0 && (
                  <Badge 
                    count={newDeals.length} 
                    className="tab-badge"
                    style={{ marginLeft: '8px' }}
                  />
                )}
              </span>
            } 
            key="new" 
          />
          <TabPane 
            tab={
              <span>
                Migrate Deals
                {migrationDeals.length > 0 && (
                  <Badge 
                    count={migrationDeals.length} 
                    className="tab-badge"
                    style={{ marginLeft: '8px' }}
                  />
                )}
              </span>
            } 
            key="migrate" 
          />
        </Tabs>
      }
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        activeTab === 'new' && (
          <Button
            key="importAll"
            type="primary"
            onClick={handleImportAllDeals}
            loading={importing}
          >
            Import All New Deals
          </Button>
        ),
        selectedDeals.length > 0 && activeTab === 'new' && (
          <Button
            key="importSelected"
            type="primary"
            onClick={handleImportSelected}
            loading={importing}
            className="import-selected-button"
          >
            Import Selected ({selectedDeals.length})
          </Button>
        ),
      ].filter(Boolean)}
      className="import-deals-modal"
    >
      {activeTab === 'new' ? renderDeals(newDeals) : renderDeals(migrationDeals)}
    </Modal>
  );
};

export default ImportDeals;
