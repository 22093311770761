import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import UserAvatar from '../../components/UserAvatar';
import './TaskViewNotes.css';

const TaskViewNotes = ({ task, taskType, users, onUpdate }) => {
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);

  const getBackendTaskType = (frontendTaskType) => {
    const taskTypeMap = {
      'andre-opgaver': 'other',
      'activities': 'activity',
      'bogforing': 'bogforing',
      'lon': 'lon',
      'moms': 'moms',
      'arsafslutning': 'arsafslutning'
    };
    return taskTypeMap[frontendTaskType] || frontendTaskType;
  };

  const handleSubmit = async () => {
    if (!newComment.trim()) return;

    setLoading(true);
    try {
      const backendTaskType = getBackendTaskType(taskType);
      const response = await axios.post(`/taskboard/tasks/${backendTaskType}/${task.id}/comments`, {
        content: newComment.trim()
      });
      
      setNewComment('');
      if (onUpdate) onUpdate();
      message.success('Comment added successfully');
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error(error.response?.data?.error || 'Failed to add comment');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const backendTaskType = getBackendTaskType(taskType);
      await axios.delete(`/taskboard/tasks/${backendTaskType}/${task.id}/comments/${commentId}`);
      message.success('Comment deleted successfully');
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error deleting comment:', error);
      message.error('Failed to delete comment');
    }
  };

  if (!task?.comments?.length) {
    return (
      <div className="notes-container">
        <div className="new-comment">
          <Input.TextArea
            placeholder="Write a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSubmit}
            loading={loading}
            disabled={!newComment.trim()}
          >
            Post
          </Button>
        </div>
        <div className="comments-list">
          <div className="notes-empty">
            <div className="empty-message">No comments yet</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="notes-container">
      <div className="new-comment">
        <Input.TextArea
          placeholder="Write a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSubmit}
          loading={loading}
          disabled={!newComment.trim()}
        >
          Post
        </Button>
      </div>
      <div className="comments-list">
        {[...task.comments].reverse().map((comment) => {
          const user = users.find(u => u.user_id === comment.user_id);
          return (
            <div key={comment.id} className="comment-item">
              <UserAvatar user={user} size={32} />
              <div className="comment-content">
                <div className="comment-message">{comment.content}</div>
                <div className="comment-timestamp">
                  {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(comment.id)}
                className="delete-button"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TaskViewNotes;