import React from 'react';
import { Button, Form, Space, Card, Select, Input, Checkbox, Collapse } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ConditionsBuilder from './ConditionsBuilder';
import DynamicList from './DynamicList';
import './FormBuilder.css';

const { Panel } = Collapse;

const FormBuilder = ({ value, onChange, config, nodes, edges }) => {
  const fields = Array.isArray(value) ? value : [];

  const { fieldTypes, fieldConfig } = config;

  const handleFieldChange = (index, field) => {
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], ...field };
    onChange?.(newFields);
  };

  const addField = () => {
    onChange?.([...fields, {
      name: '',
      label: '',
      type: 'text',
      required: false,
      placeholder: '',
      defaultValue: '',
      options: [],
      conditions: []
    }]);
  };

  const removeField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    onChange?.(newFields);
  };

  const renderFieldConfig = (field, index) => {
    const showOptions = ['select', 'multiSelect', 'radio'].includes(field.type);

    return (
      <Card 
        key={index} 
        className="form-builder-field"
        extra={
          <Button 
            type="text" 
            danger 
            icon={<DeleteOutlined />} 
            onClick={() => removeField(index)}
          />
        }
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item label="Field Name" required>
            <Input
              value={field.name}
              onChange={e => handleFieldChange(index, { name: e.target.value })}
              placeholder="field_name"
            />
          </Form.Item>

          <Form.Item label="Label" required>
            <Input
              value={field.label}
              onChange={e => handleFieldChange(index, { label: e.target.value })}
              placeholder="Field Label"
            />
          </Form.Item>

          <Form.Item label="Type" required>
            <Select
              value={field.type}
              onChange={value => handleFieldChange(index, { type: value })}
              options={fieldTypes}
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              checked={field.required}
              onChange={e => handleFieldChange(index, { required: e.target.checked })}
            >
              Required
            </Checkbox>
          </Form.Item>

          <Form.Item label="Placeholder">
            <Input
              value={field.placeholder}
              onChange={e => handleFieldChange(index, { placeholder: e.target.value })}
              placeholder="Placeholder text"
            />
          </Form.Item>

          <Form.Item label="Default Value">
            <Input
              value={field.defaultValue}
              onChange={e => handleFieldChange(index, { defaultValue: e.target.value })}
              placeholder="Default value"
            />
          </Form.Item>

          {showOptions && (
            <Form.Item label="Options">
              <DynamicList
                value={field.options}
                onChange={options => handleFieldChange(index, { options })}
              />
            </Form.Item>
          )}

          <Collapse>
            <Panel header="Display Conditions" key="conditions">
              <ConditionsBuilder
                value={field.conditions}
                onChange={conditions => handleFieldChange(index, { conditions })}
                config={{
                  ...fieldConfig.conditions.config,
                  nodes,
                  edges
                }}
              />
            </Panel>
          </Collapse>
        </Space>
      </Card>
    );
  };

  return (
    <div className="form-builder">
      <div className="form-builder-fields">
        {fields.map((field, index) => renderFieldConfig(field, index))}
      </div>
      <Button 
        type="dashed" 
        onClick={addField} 
        block 
        icon={<PlusOutlined />}
      >
        Add Field
      </Button>
    </div>
  );
};

export default FormBuilder;