import React from 'react';
import { Button, Input, Space } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import './DynamicList.css';

const DynamicList = ({ value = [], onChange }) => {
  const handleAdd = () => {
    onChange([...value, { label: '', value: '' }]);
  };

  const handleRemove = (index) => {
    const newOptions = [...value];
    newOptions.splice(index, 1);
    onChange(newOptions);
  };

  const handleChange = (index, field, newValue) => {
    const newOptions = [...value];
    newOptions[index] = {
      ...newOptions[index],
      [field]: newValue
    };
    onChange(newOptions);
  };

  return (
    <div className="dynamic-list">
      {value.map((option, index) => (
        <Space key={index} className="dynamic-list-item" align="baseline">
          <Input
            placeholder="Label"
            value={option.label}
            onChange={(e) => handleChange(index, 'label', e.target.value)}
            style={{ width: 120 }}
          />
          <Input
            placeholder="Value"
            value={option.value}
            onChange={(e) => handleChange(index, 'value', e.target.value)}
            style={{ width: 120 }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(index)}
          />
        </Space>
      ))}
      <Button
        type="dashed"
        onClick={handleAdd}
        block
        icon={<PlusOutlined />}
      >
        Add Option
      </Button>
    </div>
  );
};

export default DynamicList;