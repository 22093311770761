import React, { useState, useEffect } from 'react';
import { Table, Button, Tabs, Input, Space, Modal, Dropdown, Menu, Tooltip, message } from 'antd';
import { SearchOutlined, FilterOutlined, ScheduleOutlined, MoreOutlined, EyeOutlined, LinkOutlined, InboxOutlined, DeleteOutlined, StarFilled, HistoryOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import './TaskBoards.css';
import TaskSchedule from './modals/TaskSchedule';
import BoardsActivity from './modals/BoardsActivity';
import TaskKanban from './views/TaskKanban';
import TaskCalendar from './views/TaskCalendar';
import TaskView from './views/TaskView';
import { useNavigate, Routes, Route, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const { TabPane } = Tabs;

const TaskBoard = ({ title, fetchUrl, columns, boardStatusOptions, cardConfig, dealDataConfig, dealSoftwareConfig, taskType, defaultView, users, tasks: externalTasks, setTasks: setExternalTasks }) => {
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(defaultView || 'table');
  const [searchText, setSearchText] = useState('');
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [isTaskViewVisible, setIsTaskViewVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(fetchUrl);
      // Update both external and filtered tasks
      setExternalTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!externalTasks.length) {
      fetchTasks();
    }
  }, [fetchUrl]);

  useEffect(() => {
    filterTasks();
    // eslint-disable-next-line
  }, [externalTasks, searchText]);

  const filterTasks = () => {
    const filtered = externalTasks.filter(task => 
      Object.values(task).some(value => 
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredTasks(filtered);
  };

  const handleOpenTask = (task) => {
    navigate(`${task.id}`, { state: { tasks: filteredTasks, taskType } });
  };

  const handleCopyTaskLink = (task) => {
    const taskLink = `${window.location.origin}${window.location.pathname}/${task.id}`;
    navigator.clipboard.writeText(taskLink).then(() => {
      message.success('Task link copied to clipboard');
    }).catch((err) => {
      console.error('Failed to copy task link: ', err);
      message.error('Failed to copy task link');
    });
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key="open" icon={<EyeOutlined />} onClick={() => handleOpenTask(record)}>
        Open task
      </Menu.Item>
      <Menu.Item key="copy" icon={<LinkOutlined />} onClick={() => handleCopyTaskLink(record)}>
        Copy task link
      </Menu.Item>
      <Menu.Item key="archive" icon={<InboxOutlined />} disabled>
        Archive
      </Menu.Item>
      <Menu.Item key="delete" icon={<DeleteOutlined />} disabled>
        Delete task
      </Menu.Item>
    </Menu>
  );

  const mapFrequencyToLabel = (frequency) => {
    if (!frequency || !frequency.freq) return 'N/A';
    const mapping = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      BIWEEKLY: 'Every 2 weeks',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const enhancedColumns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <Button
            icon={<MoreOutlined />} // Use MoreOutlined icon
            className="minimalistic-action-button" // Add custom class
          />
        </Dropdown>
      ),
      fixed: 'left',
    },
    ...columns.map(col => ({
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        // Removed editable, handleChange, editing
        onClick: (col.dataIndex === 'deal_name' || col.dataIndex === 'description') 
          ? () => handleOpenTask(record) 
          : undefined,
        className: (col.dataIndex === 'deal_name' || col.dataIndex === 'description')
          ? 'ant-table-cell-clickable'
          : '',
      }),
      // Only override render if it's the 'frequency' column, else keep existing render
      render: col.dataIndex === 'frequency' ? (freq) => mapFrequencyToLabel(freq) : (col.render ? col.render : undefined),
    })),
  ];

  const handleTaskUpdate = async (taskId, updatedFields) => {
    try {
      const response = await axios.put(`${fetchUrl}/${taskId}`, updatedFields);
      
      // Update the external tasks state while preserving existing data
      setExternalTasks(prevTasks => 
        prevTasks.map(task => 
          task.id === taskId 
            ? { ...task, ...response.data } // Merge with existing task data
            : task
        )
      );
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD');
  };


  const renderTableView = () => {
    const dataSource = filteredTasks.map(task => ({
      ...task,
      key: task.id,
      next_work_date: formatDate(task.next_work_date),
      next_due_date: formatDate(task.next_due_date),
    }));

    return (
      <Table
        dataSource={dataSource}
        columns={enhancedColumns}
        rowKey="id"
        loading={loading}
        pagination={false}
        scroll={{ x: 'max-content' }}
        className="task-table"
        onRow={(record) => ({
          onClick: (event) => {
            const cellIndex = event.target.cellIndex;
            if (cellIndex === 1 || cellIndex === 2) { // Deal or Task column
              handleOpenTask(record);
            }
          },
        })}
      />
    );
  };

  // Add safety check before rendering TaskKanban
  const renderKanbanView = () => {
    if (!Array.isArray(externalTasks)) {
      console.error('Tasks is not an array:', externalTasks);
      return <div>Loading...</div>;
    }
    
    return (
      <TaskKanban
        tasks={externalTasks}
        onTaskUpdate={handleTaskUpdate}
        boardStatusOptions={boardStatusOptions}
        cardConfig={cardConfig}
        dealDataConfig={dealDataConfig}
        dealSoftwareConfig={dealSoftwareConfig}
        taskType={taskType}
        users={users}
        setTasks={setExternalTasks}
      />
    );
  };

  const renderCalendarView = () => (
    <TaskCalendar tasks={filteredTasks} />
  );

  const showScheduleModal = () => {
    setIsScheduleModalVisible(true);
  };

  const handleScheduleModalCancel = () => {
    setIsScheduleModalVisible(false);
  };

  const showScheduleButton = ['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType);

  const renderDealData = (dealData, config) => {
    return config.map(item => (
      <Tooltip key={item.key} title={item.key}>
        <div>{`${item.label}: ${dealData[item.key] || 'N/A'}`}</div>
      </Tooltip>
    ));
  };

  const handleCloseTask = () => {
    setIsTaskViewVisible(false);
    setSelectedTask(null);
    navigate(`/boards/${taskType}`, { replace: true });
  };

  const showActivityModal = () => {
    setIsActivityModalVisible(true);
  };

  const handleActivityModalClose = () => {
    setIsActivityModalVisible(false);
  };

  return (
    <div className="task-board">
      <h1>{title}</h1>
      <Tabs defaultActiveKey={defaultView || 'table'} onChange={setViewMode}>
        <TabPane tab={
          <span>
            Table
            {defaultView === 'table' && <StarFilled className="default-view-star" />}
          </span>
        } key="table" />
        <TabPane tab={
          <span>
            Kanban
            {defaultView === 'kanban' && <StarFilled className="default-view-star" />}
          </span>
        } key="kanban" />
        <TabPane tab={
          <span>
            Calendar
            {defaultView === 'calendar' && <StarFilled className="default-view-star" />}
          </span>
        } key="calendar" />
      </Tabs>
      <Space className="task-controls">
        <div className="task-controls-left">
          <Space>
            <Input
              placeholder="Search tasks"
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button icon={<FilterOutlined />}>Filter</Button>
          </Space>
        </div>
        <div className="task-controls-right">
          <Space>
            <Button icon={<HistoryOutlined />} onClick={showActivityModal}>Activity</Button>
            {showScheduleButton && (
              <Button icon={<ScheduleOutlined />} onClick={showScheduleModal}>Schedule</Button>
            )}
          </Space>
        </div>
      </Space>
      {!taskId && (
        viewMode === 'table' ? renderTableView() :
        viewMode === 'kanban' ? renderKanbanView() :
        renderCalendarView()
      )}
      <Modal
        title="Task Schedule"
        open={isScheduleModalVisible}
        onCancel={handleScheduleModalCancel}
        footer={null}
        width={1000}
        destroyOnClose={true} // This ensures the modal content is destroyed when closed
      >
        <TaskSchedule onClose={handleScheduleModalCancel} />
      </Modal>
      <BoardsActivity 
        visible={isActivityModalVisible} 
        onClose={handleActivityModalClose} 
        taskType={taskType}
        users={users}
      />
      <Routes>
        <Route 
          path=":taskId" 
          element={
            <TaskView 
              onClose={handleCloseTask}
              tasks={filteredTasks}
              taskType={taskType}
              boardStatusOptions={boardStatusOptions}
              fromWork={false}
              dealName={null}
              getBoardName={() => taskType}
            />
          } 
        />
      </Routes>
    </div>
  );
};


export default TaskBoard;
