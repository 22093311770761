import React, { useContext, memo } from 'react';
import { Button, Tooltip } from 'antd';
import { PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { TimeTrackerContext } from '../contexts/TimeTrackerContext';
import './LiveTimer.css';

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const LiveTimer = memo(() => {
  const { isRunning, isPaused, currentTask, time, pauseTracking, resumeTimer, stopTracking } = useContext(TimeTrackerContext);

  if (!isRunning || !currentTask) return null;

  return (
    <div className="live-timer-container">
      <div className="account-task-info">
        <span className="account-name">{currentTask.dealName}</span>
        <span className="task-name">{currentTask.taskName}</span>
      </div>
      <div className="timer-info">
        <span className="timer-display">{formatTime(time)}</span>
      </div>
      <div className="timer-controls">
        {isPaused ? (
          <Tooltip title="Resume Timer">
            <Button
              className="timer-control-button resume-button"
              icon={<PlayCircleOutlined />}
              onClick={resumeTimer}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Pause Timer">
            <Button
              className="timer-control-button pause-button"
              icon={<PauseCircleOutlined />}
              onClick={pauseTracking}
            />
          </Tooltip>
        )}
        <Tooltip title="Stop Timer">
          <Button
            className="timer-control-button stop-button"
            icon={<StopOutlined />}
            onClick={stopTracking}
          />
        </Tooltip>
      </div>
    </div>
  );
});

LiveTimer.displayName = 'LiveTimer';
export default LiveTimer;
