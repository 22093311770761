import React from 'react';
import { Modal, Tabs } from 'antd';
import { IoTrophyOutline } from 'react-icons/io5';
import { FaChartLine } from 'react-icons/fa';
import './Achievements.css';

const AchievementsModal = ({ visible, onClose }) => {
  const items = [
    {
      key: '1',
      label: 'Achievements',
      children: (
        <div className="achievements-content">
          <div className="achievements-icon">
            <IoTrophyOutline />
          </div>
          <div className="achievements-message">
            <h3>Achievements Coming Soon!</h3>
            <p>
              Soon you'll be able to unlock achievements for your contributions and milestones in Tuesday.
              Stay tuned for exciting challenges and rewards! 🏆
            </p>
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Leaderboard',
      children: (
        <div className="achievements-content">
          <div className="achievements-icon">
            <FaChartLine />
          </div>
          <div className="achievements-message">
            <h3>Leaderboard Under Construction</h3>
            <p>
              The race to the top begins soon! Compare your achievements with teammates
              and climb the ranks. Coming to Tuesday in a future update! 📈
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Modal
      title="Achievements & Leaderboard"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={500}
      className="achievements-modal"
    >
      <Tabs
        items={items}
        className="achievements-tabs"
        centered
      />
    </Modal>
  );
};

export default AchievementsModal;