import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import axios from '../api/axiosConfig';
import './TaskDateEditor.css';

const TaskDateEditor = ({ 
  date, 
  dateType, 
  taskId, 
  taskType, 
  onSuccess, 
  className = '',
  disabled = false 
}) => {
  const mapTaskTypeToBackend = (frontendTaskType) => {
    const mapping = {
      'andre-opgaver': 'other',
      'activities': 'activity',
      'bogforing': 'bogforing',
      'lon': 'lon',
      'moms': 'moms',
      'arsafslutning': 'arsafslutning'
    };
    return mapping[frontendTaskType] || frontendTaskType;
  };

  const getDateStatus = (date) => {
    if (!date) return '';
    
    const today = dayjs().startOf('day');
    const dateValue = dayjs(date);
    
    if (dateValue.isSame(today, 'day')) {
      return 'due-today';
    } else if (dateValue.isBefore(today)) {
      return 'overdue';
    }
    return '';
  };

  const handleDateChange = async (date) => {
    try {
      const formattedDate = date ? date.format('YYYY-MM-DD') : null;
      const dateKey = dateType === 'work' ? 'next_work_date' : 'next_due_date';
      
      const response = await axios.put(
        `/taskboard/tasks/${mapTaskTypeToBackend(taskType)}/${taskId}/dates`,
        { [dateKey]: formattedDate }
      );

      if (response.status === 200) {
        if (onSuccess) {
          onSuccess(response.data);
        }
      }
    } catch (error) {
      console.error('Error updating date:', error);
    }
  };

  const dateStatus = getDateStatus(date);
  const combinedClassName = `task-date-editor ${dateStatus} ${className}`.trim();
  const isKanbanView = className.includes('kanban-datepicker');

  return (
    <DatePicker
      value={date ? dayjs(date) : null}
      onChange={handleDateChange}
      format="YYYY-MM-DD"
      className={combinedClassName}
      disabled={disabled}
      {...(isKanbanView && {
        suffixIcon: null,
        allowClear: false
      })}
    />
  );
};

export default TaskDateEditor;