import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Spin, Select } from 'antd';
import { UserOutlined, ShopOutlined, RiseOutlined, BarChartOutlined } from '@ant-design/icons';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import axios from '../api/axiosConfig';
import 'leaflet/dist/leaflet.css';
import './CustomerDash.css';

const { Option } = Select;

// Chart.js registration
ChartJS.register(
  ArcElement,        
  CategoryScale,     
  LinearScale,      
  PointElement,      
  LineElement,       
  BarElement,        
  Title,
  Tooltip,
  Legend,
  Filler            
);

// Leaflet icon configuration
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function CustomerDash() {
  const [loading, setLoading] = useState(true);
  const [mapLoading, setMapLoading] = useState(true);
  const [customerData, setCustomerData] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [selectedChart, setSelectedChart] = useState('products');

  useEffect(() => {
    fetchCustomerData();
    fetchMapData();
  }, []);

  const fetchCustomerData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/dashboard/customers');
      setCustomerData(response.data);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    } finally {
      setLoading(false);
    }
  };

  /*const fetchMapData = async () => {
    try {
      setMapLoading(true);
      const response = await axios.get('/dashboard/customers/locations');
      setMapData(response.data);
    } catch (error) {
      console.error('Error fetching map data:', error);
    } finally {
      setMapLoading(false);
    }
  };*/

  /* dummy fetch data */
  const fetchMapData = async () => {
    setMapData({ locations: { '8000:Aarhus': { coordinates: { lat: 56.1616, lng: 10.2034 }, count: 10 } } });
    setMapLoading(false);
  };

  const renderGrowthChart = () => {
    if (!customerData?.customer_growth?.cumulative) return null;

    const data = {
      labels: Object.keys(customerData.customer_growth.cumulative),
      datasets: [
        {
          label: 'Total Customers',
          data: Object.values(customerData.customer_growth.cumulative),
          fill: true,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
        {
          label: 'New Customers',
          data: Object.values(customerData.customer_growth.monthly),
          type: 'bar',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ]
    };

    return (
      <Line
        data={data}
        options={{
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }}
      />
    );
  };

  const renderProductDistribution = () => {
    if (!customerData?.product_distribution) return null;

    const data = {
      labels: Object.keys(customerData.product_distribution),
      datasets: [{
        data: Object.values(customerData.product_distribution).map(p => p.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ]
      }]
    };

    return (
      <Pie
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'right'
            }
          }
        }}
      />
    );
  };

  const renderIndustryDistribution = () => {
    if (!customerData?.industry_distribution) return null;

    // Get only top 5 industries
    const topIndustries = Object.entries(customerData.industry_distribution)
      .slice(0, 5)
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    const data = {
      labels: Object.keys(topIndustries),
      datasets: [{
        label: 'Customers per Industry',
        data: Object.values(topIndustries),
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
      }]
    };

    return (
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y',  // This makes it a horizontal bar chart
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: 'Top 5 Industries by Customer Count'
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function(value) {
                  // Truncate long industry names
                  const label = this.getLabelForValue(value);
                  return label.length > 30 ? label.substr(0, 27) + '...' : label;
                }
              }
            }
          }
        }}
      />
    );
  };

  const renderCustomerMap = () => {
    if (mapLoading) {
      return (
        <div className="map-loading">
          <Spin size="large" />
          <p>Loading customer locations...</p>
        </div>
      );
    }

    if (!mapData?.locations) return null;

    return (
      <MapContainer
        center={[56.2639, 9.5018]}
        zoom={7}
        style={{ height: '600px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {Object.entries(mapData.locations).map(([location, data]) => {
          const [zipcode, ...cityParts] = location.split(' ');
          const city = cityParts.join(' ');
          const { coordinates, count } = data;

          if (!coordinates) return null;

          return (
            <Marker 
              key={location} 
              position={[coordinates.lat, coordinates.lng]}
            >
              <Popup>
                <strong>{city}</strong><br />
                Zipcode: {zipcode}<br />
                Customers: {count}
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    );
  };

  if (loading) {
    return (
      <div className="customer-dash-loading">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="customer-dash">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <Card className="stat-card">
            <Statistic
              title="Total Customers"
              value={customerData?.total_stats?.total_customers}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="stat-card">
            <Statistic
              title="Active Customers"
              value={customerData?.total_stats?.active_customers}
              prefix={<ShopOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="stat-card">
            <Statistic
              title="Total Products"
              value={customerData?.total_stats?.total_products}
              prefix={<BarChartOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="stat-card">
            <Statistic
              title="Avg Products/Customer"
              value={customerData?.total_stats?.average_products_per_customer?.toFixed(2)}
              prefix={<RiseOutlined />}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="chart-row">
        <Col xs={24} lg={12}>
          <Card className="chart-card">
            <Select
              defaultValue="products"
              onChange={setSelectedChart}
              className="chart-selector"
            >
              <Option value="products">Product Distribution</Option>
              <Option value="growth">Customer Growth</Option>
              <Option value="industries">Industry Distribution</Option>
            </Select>
            <div className="chart-container">
              {selectedChart === 'growth' && renderGrowthChart()}
              {selectedChart === 'products' && renderProductDistribution()}
              {selectedChart === 'industries' && renderIndustryDistribution()}
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card className="chart-card">
            <h3>Customer Distribution Map</h3>
            {renderCustomerMap()}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerDash;