import React, { memo, useMemo, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { hasRequiredPermission } from '../utils/permissionUtils';

const ProtectedRoute = memo(({ children, requiredPermission }) => {
  // Use ref to store user data to prevent re-parsing on every render
  const userRef = useRef(JSON.parse(localStorage.getItem('user')));
  
  const accessGranted = useMemo(() => {
    const user = userRef.current;
    
    if (!user) {
      return false;
    }
    
    if (!user.is_registered && window.location.pathname === '/complete-registration') {
      return true;
    }
    
    if (requiredPermission) {
      return hasRequiredPermission(user.permissions, requiredPermission);
    }
    
    return true;
  }, [requiredPermission]); // Remove user from dependencies since we're using ref

  if (!userRef.current) {
    return <Navigate to="/login" replace />;
  }

  if (!accessGranted) {
    return <Navigate to="/main-menu" replace />;
  }

  return children;
});

ProtectedRoute.displayName = 'ProtectedRoute'; // Add display name for debugging
export default ProtectedRoute;