import React, { useState, useEffect, useContext } from 'react';
import { Button, Select, Typography, Space, Tooltip } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, CloseOutlined, StopOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import { TimeTrackerContext } from '../contexts/TimeTrackerContext';
import './TimeTracker.css';

const { Title } = Typography;
const { Option } = Select;

function TimeTracker({ isVisible, isMaximized, onOpen, onClose, isLiveTimerVisible }) {
  const { 
    isRunning, 
    isPaused, 
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer 
  } = useContext(TimeTrackerContext);
  
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [deals, setDeals] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [dealTasks, setDealTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDeals();
    fetchTasks();
  }, []);

  useEffect(() => {
    if (selectedDeal) {
      filterDealTasks(selectedDeal);
    } else {
      setDealTasks([]);
    }
  }, [selectedDeal, tasks]);

  const fetchDeals = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/account/deals');
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/work/assigned_tasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterDealTasks = (dealId) => {
    try {
      setLoading(true);
      const filtered = tasks.filter(task => task.deal_id === dealId);
      setDealTasks(filtered);
    } catch (error) {
      console.error('Error filtering deal tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStartTimer = () => {
    if (!selectedDeal || !selectedTask) return;
    
    const task = dealTasks.find(t => t.id === selectedTask);
    const deal = deals.find(d => d.id === selectedDeal);
    
    startTracking({
      taskId: selectedTask,
      taskType: task.task_type,
      dealId: selectedDeal,
      recurringType: task.recurring_type,
      dealName: deal?.name,
      taskDescription: task.description || task.text
    });

    onClose();
  };

  const handleStopTimer = () => {
    stopTracking();
    setSelectedDeal(null);
    setSelectedTask(null);
  };

  if (!isMaximized) {
    return (
      <div className="time-tracker-minimized">
        <Tooltip title="Time Tracker" placement="topRight">
          <Button
            className={`time-tracker-overlay-button ${isRunning || isLiveTimerVisible ? 'running' : ''}`}
            type="primary"
            icon={<PlayCircleOutlined />}
            onClick={onOpen}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={`time-tracker-container ${isVisible ? 'visible' : ''} ${isMaximized ? 'maximized' : ''}`}>
      <div className="time-tracker-card">
        <div className="time-tracker-header">
          <Title level={4}>Time Tracker</Title>
          <Button 
            icon={<CloseOutlined />} 
            onClick={onClose}
            type="text"
          />
        </div>
        
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          {!isRunning && (
            <>
              <Select
                placeholder="Select Deal"
                showSearch
                loading={loading}
                value={selectedDeal}
                onChange={setSelectedDeal}
                className="tracker-select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {deals.map(deal => (
                  <Option key={deal.id} value={deal.id}>{deal.name}</Option>
                ))}
              </Select>

              <Select
                placeholder="Select Task"
                loading={loading}
                value={selectedTask}
                onChange={setSelectedTask}
                className="tracker-select"
                disabled={!selectedDeal}
              >
                {dealTasks.map(task => (
                  <Option key={task.id} value={task.id}>
                    {task.description || task.text}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <div className="timer-controls">
            {isRunning ? (
              <Space>
                <Button
                  type="primary"
                  className={isPaused ? 'resume-button' : ''}
                  icon={isPaused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
                  onClick={isPaused ? resumeTimer : pauseTracking}
                >
                  {isPaused ? 'Resume' : 'Pause'}
                </Button>
                <Button
                  danger
                  icon={<StopOutlined />}
                  onClick={handleStopTimer}
                >
                  Stop
                </Button>
              </Space>
            ) : (
              <Button
                type="primary"
                icon={<PlayCircleOutlined />}
                onClick={handleStartTimer}
                disabled={!selectedDeal || !selectedTask}
                className="start-timer-button"
              >
                Start Timer
              </Button>
            )}
          </div>
        </Space>
      </div>
    </div>
  );
}

export default TimeTracker;
