import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { useFlow } from '../contexts/FlowContext';
import './NodeNumber.css';

const NodeNumber = ({ nodeId, number }) => {
  const { updateNodeNumber } = useFlow();
  const [isEditing, setIsEditing] = useState(false);
  
  if (['flow-start', 'flow-finish'].includes(nodeId)) {
    return null;
  }

  const handleNumberClick = () => {
    setIsEditing(true);
  };

  const handleNumberChange = (value) => {
    updateNodeNumber(nodeId, parseInt(value));
    setIsEditing(false);
  };

  return isEditing ? (
    <InputNumber
      className="node-number-input"
      min={1}
      value={number}
      onChange={handleNumberChange}
      onBlur={() => setIsEditing(false)}
      autoFocus
    />
  ) : (
    <div className="node-number" onClick={handleNumberClick}>
      {number}
    </div>
  );
};

export default NodeNumber;