import React from 'react';
import { Handle } from '@xyflow/react';
import { FlagOutlined } from '@ant-design/icons';
import './FlowHandler.css';

const FlowFinish = ({ data, selected }) => {
  const statusColor = data.status === 'completed' ? 'var(--success-color)' : 'var(--warning-color)';
  
  return (
    <div className={`flow-handler-node flow-finish-node ${selected ? 'selected' : ''} ${!data.isConnected ? 'disconnected' : ''}`}>
      <Handle type="target" position="top" style={{ background: '#555' }} />
      <div className="handler-content" style={{ borderColor: statusColor }}>
        <FlagOutlined style={{ fontSize: '20px', color: statusColor }} />
        <div className="handler-label">{data.label}</div>
        <div className="handler-status" style={{ color: statusColor }}>
          {data.status}
        </div>
      </div>
    </div>
  );
};

export default FlowFinish;