import React from 'react';
import { Handle } from '@xyflow/react';
import { Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import NodeNumber from '../../../components/NodeNumber';
import '../../ModuleNode.css';

const UpdateDeal = ({ data, id }) => {
  return (
    <Card 
      size="small"
      title={<div className="node-title"><EditOutlined /> Update Deal</div>}
      className={`module-node action-node ${!data.isConnected ? 'disconnected' : ''}`}
      style={{ width: 200 }}
    >
      <NodeNumber nodeId={id} number={data.nodeNumber} />
      <Handle type="target" position="top" style={{ background: '#555' }} />
      <div className="node-content">
        <div className="node-label">{data.label}</div>
            <div className="node-condition">
            {data.updateField && (
            <div>
                <span className="condition-prefix">Field:</span>
                <span className="node-value">{data.updateField}</span>
            </div>
            )}
            {data.updateValue && (
            <div>
                <span className="condition-prefix">Value:</span>
                <span className="node-value">{data.updateValue}</span>
            </div>
            )}
        </div>
      </div>
      <Handle type="source" position="bottom" style={{ background: '#555' }} />
    </Card>
  );
};

export default UpdateDeal;