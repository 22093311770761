import React, { useState, useEffect } from 'react';
import { Modal, Table, Select, Button, message, Tabs, DatePicker } from 'antd';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import './OnboardingTasksConfig.css';

const { Option } = Select;
const { TabPane } = Tabs;

const OnboardingTaskConfig = ({ dealId, visible, onClose }) => {
  const [recurringTasks, setRecurringTasks] = useState([]);
  const [onboardingTasks, setOnboardingTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchTasks();
    }
  }, [visible, dealId]);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/onboarding/configure-tasks/${dealId}`);
      setRecurringTasks(response.data.recurring_tasks);
      setOnboardingTasks(response.data.onboarding_tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      message.error('Failed to fetch tasks');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      await axios.post(`/onboarding/configure-tasks/${dealId}`, {
        recurring_tasks: recurringTasks,
        onboarding_tasks: onboardingTasks
      });
      message.success('Tasks configured successfully');
      onClose();
    } catch (error) {
      console.error('Error saving tasks:', error);
      message.error('Failed to save tasks');
    }
  };

  const getAssigneeOptions = (taskType) => {
    if (taskType === 'Løn') {
      return [
        <Option key="4" value={23}>Daniella</Option>,
        <Option key="3" value={29}>Heidi</Option>,
        <Option key="5" value={2}>Rasmus</Option>
      ];
    } else {
      return [
        <Option key="1" value={28}>Mads Brock-Madsen</Option>,
        <Option key="2" value={25}>Dennis Suksuwat</Option>,
        <Option key="5" value={2}>Rasmus</Option>
      ];
    }
  };

  const recurringColumns = [
    {
      title: 'Task Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (frequency, record, index) => (
        <Select
          value={frequency}
          onChange={(value) => handleRecurringTaskChange(index, 'frequency', value)}
        >
          <Option value="monthly">Monthly</Option>
          <Option value="quarterly">Quarterly</Option>
          <Option value="semi-annually">Semi-annually</Option>
          <Option value="annually">Annually</Option>
        </Select>
      ),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      render: (assignee, record, index) => (
        <Select
          value={assignee}
          onChange={(value) => handleRecurringTaskChange(index, 'assignee', value)}
        >
          {getAssigneeOptions(record.type)}
        </Select>
      ),
    },
    {
      title: 'Next Work Date',
      dataIndex: 'next_work_date',
      key: 'next_work_date',
      render: (date, record, index) => (
        <DatePicker
          value={date ? dayjs(date) : null}
          onChange={(value) => handleRecurringTaskChange(index, 'next_work_date', value ? value.format('YYYY-MM-DD') : null)}
        />
      ),
    },
    {
      title: 'Next Due Date',
      dataIndex: 'next_due_date',
      key: 'next_due_date',
      render: (date, record, index) => (
        <DatePicker
          value={date ? dayjs(date) : null}
          onChange={(value) => handleRecurringTaskChange(index, 'next_due_date', value ? value.format('YYYY-MM-DD') : null)}
        />
      ),
    },
  ];

  const onboardingColumns = [
    {
      title: 'Task',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      render: (assignee, record, index) => (
        <Select
          value={assignee}
          onChange={(value) => handleOnboardingTaskChange(index, 'assignee', value)}
        >
          <Option value={1}>Mads Brock-Madsen</Option>
          <Option value={2}>Dennis Suksuwat</Option>
          <Option value={3}>Heidi</Option>
          <Option value={4}>Daniella</Option>
        </Select>
      ),
    },
    {
      title: 'Work Date',
      dataIndex: 'work_date',
      key: 'work_date',
      render: (date, record, index) => (
        <DatePicker
          value={date ? dayjs(date) : null}
          onChange={(value) => handleOnboardingTaskChange(index, 'work_date', value ? value.format('YYYY-MM-DD') : null)}
        />
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (date, record, index) => (
        <DatePicker
          value={date ? dayjs(date) : null}
          onChange={(value) => handleOnboardingTaskChange(index, 'due_date', value ? value.format('YYYY-MM-DD') : null)}
        />
      ),
    },
  ];

  const handleRecurringTaskChange = (index, field, value) => {
    const updatedTasks = [...recurringTasks];
    updatedTasks[index][field] = value;
    setRecurringTasks(updatedTasks);
  };

  const handleOnboardingTaskChange = (index, field, value) => {
    const updatedTasks = [...onboardingTasks];
    updatedTasks[index][field] = value;
    setOnboardingTasks(updatedTasks);
  };

  return (
    <Modal
      title="Configure Tasks"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      width={1000}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Recurring Tasks" key="1">
          <Table
            dataSource={recurringTasks}
            columns={recurringColumns}
            rowKey="type"
            loading={loading}
          />
        </TabPane>
        <TabPane tab="Onboarding Tasks" key="2">
          <Table
            dataSource={onboardingTasks}
            columns={onboardingColumns}
            rowKey="description"
            loading={loading}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default OnboardingTaskConfig;
