import React from 'react';
import { Typography, Collapse } from 'antd';
import * as Icons from '@ant-design/icons';
import CustomScrollbar from '../../components/CustomScrollbar';
import { moduleConfigs } from '../modules/nodeTypes';
import './ModuleSidebar.css';

const { Title } = Typography;
const { Panel } = Collapse;

const ModuleSidebar = () => {
  // Group modules by category and subcategory
  const groupedModules = Object.values(moduleConfigs).reduce((acc, config) => {
    const category = config.category;
    const subcategory = config.subcategory;
    
    if (!acc[category]) {
      acc[category] = {};
    }
    
    if (subcategory) {
      if (!acc[category][subcategory]) {
        acc[category][subcategory] = [];
      }
      acc[category][subcategory].push({
        type: config.type,
        label: config.label,
        icon: config.icon
      });
    } else {
      if (!acc[category].main) {
        acc[category].main = [];
      }
      acc[category].main.push({
        type: config.type,
        label: config.label,
        icon: config.icon
      });
    }
    
    return acc;
  }, {});

  const sections = [
    {
      key: 'triggers',
      icon: <Icons.ThunderboltOutlined />,
      title: 'Triggers',
      items: groupedModules.triggers?.main || []
    },
    {
      key: 'conditions',
      icon: <Icons.BranchesOutlined />,
      title: 'Conditions',
      items: groupedModules.conditions?.main || []
    },
    {
      key: 'actions',
      icon: <Icons.SendOutlined />,
      title: 'Actions',
      subcategories: [
        { key: 'deal', title: 'Deal', items: groupedModules.actions?.deal || [] },
        { key: 'task', title: 'Task', items: groupedModules.actions?.task || [] },
        { key: 'account', title: 'Account', items: groupedModules.actions?.account || [] },
        { key: 'contact', title: 'Contact', items: groupedModules.actions?.contact || [] },
        { key: 'products', title: 'Products', items: groupedModules.actions?.products || [] },
        { key: 'forms', title: 'Forms', items: groupedModules.actions?.forms || [] },
        { key: 'email', title: 'Email', items: groupedModules.actions?.email || [] },
        { key: 'slack', title: 'Slack', items: groupedModules.actions?.slack || [] },
      ]
    },
    {
      key: 'tools',
      icon: <Icons.ToolOutlined />,
      title: 'Tools',
      items: groupedModules.tools || []
    },
    {
      key: 'flowControl',
      icon: <Icons.ControlOutlined />,
      title: 'Flow Control',
      items: groupedModules.flowControl || []
    }
  ];

  const handleDragStart = (event, module) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify({
      type: module.type,
      data: moduleConfigs[module.type].defaultData
    }));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="module-sidebar">
      <Title level={4}>Modules</Title>
      <CustomScrollbar className="module-sidebar-scrollable">
        <Collapse defaultActiveKey={[]} ghost className="module-collapse">
          {sections.map(section => (
            <Panel
              key={section.key}
              header={
                <div className="module-section-header">
                  {section.icon} {section.title}
                </div>
              }
              className="module-section-panel"
            >
              {section.subcategories ? (
                <Collapse ghost className="subcategory-collapse">
                  {section.subcategories.map(subcat => (
                    <Panel
                      key={subcat.key}
                      header={<div className="subcategory-header">{subcat.title}</div>}
                      className="subcategory-panel"
                    >
                      {subcat.items.length === 0 ? (
                        <div className="no-modules">No modules available</div>
                      ) : (
                        subcat.items.map(module => (
                          <div
                            key={module.type}
                            className="module-item"
                            draggable
                            onDragStart={(e) => handleDragStart(e, module)}
                          >
                            {module.label}
                          </div>
                        ))
                      )}
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                section.items.length === 0 ? (
                  <div className="no-modules">No modules available</div>
                ) : (
                  section.items.map(module => (
                    <div
                      key={module.type}
                      className="module-item"
                      draggable
                      onDragStart={(e) => handleDragStart(e, module)}
                    >
                      {module.label}
                    </div>
                  ))
                )
              )}
            </Panel>
          ))}
        </Collapse>
      </CustomScrollbar>
    </div>
  );
};

export default ModuleSidebar;