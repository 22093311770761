import React, { useState, useEffect } from 'react';
import { Card, Button, Space, Table, Radio, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import axios from '../api/axiosConfig';

const ChurnDetails = () => {
  const { id } = useParams();
  const [churn, setChurn] = useState(null);
  const [tasks, setTasks] = useState({ onboarding_tasks: [], recurring_tasks: [] });
  const [loading, setLoading] = useState(true);

  const fetchChurnDetails = async () => {
    try {
      const response = await axios.get(`/churn/${id}`);
      setChurn(response.data);
    } catch (error) {
      message.error('Failed to fetch churn details');
      console.error('Error:', error);
    }
  };

  const fetchAssociatedTasks = async () => {
    try {
      const response = await axios.get(`/churn/${id}/tasks`);
      setTasks(response.data);
    } catch (error) {
      message.error('Failed to fetch tasks');
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchChurnDetails(),
          fetchAssociatedTasks()
        ]);
      } catch (error) {
        message.error('Failed to fetch data');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleWinbackStatusChange = async (value) => {
    try {
      await axios.put(`/churn/${id}/winback`, {
        isWinbackPossible: value
      });
      message.success('Winback status updated successfully');
      await fetchChurnDetails();
    } catch (error) {
      message.error('Failed to update winback status');
      console.error('Error:', error);
    }
  };

  const taskColumns = [
    {
      title: 'Task Type',
      dataIndex: 'recurring_type',
      key: 'recurring_type',
      render: (type) => type.charAt(0).toUpperCase() + type.slice(1),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'progress_status',
      key: 'progress_status',
    },
    {
      title: 'Assignees',
      dataIndex: 'assignees',
      key: 'assignees',
      render: (assignees) => (
        <Space>
          {assignees?.map(assignee => (
            <span key={assignee.id}>{assignee.full_name}</span>
          ))}
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="link" danger onClick={() => handleDeleteTask(record)}>
          Delete
        </Button>
      ),
    }
  ];

  const handleDeleteTask = async (task) => {
    try {
      await axios.delete(`/churn/delete-task/${task.recurring_type}/${task.id}`);
      message.success('Task deleted successfully');
      await fetchAssociatedTasks();
    } catch (error) {
      message.error('Failed to delete task');
      console.error('Error:', error);
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="churn-details">
      <Card title="Churn Details">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div>
            <h3>Customer: {churn?.deal_name}</h3>
            <p>Reason: {churn?.reason}</p>
            <p>Details: {churn?.reason_detail}</p>
            <p>Status: {churn?.status}</p>
            <p>Created: {new Date(churn?.created_at).toLocaleDateString()}</p>
          </div>

          <div>
            <h3>Winback Possibility</h3>
            <Radio.Group
              onChange={(e) => handleWinbackStatusChange(e.target.value)}
              value={churn?.is_winback_possible}
            >
              <Radio value={true}>Possible Winback</Radio>
              <Radio value={false}>Not Possible</Radio>
            </Radio.Group>
          </div>

          {!churn?.is_winback_possible && (
            <>
              <div>
                <h3>Onboarding Tasks</h3>
                <Table
                  dataSource={tasks.onboarding_tasks}
                  columns={taskColumns}
                  rowKey="id"
                />
              </div>

              <div>
                <h3>Recurring Tasks</h3>
                <Table
                  dataSource={tasks.recurring_tasks}
                  columns={taskColumns}
                  rowKey="id"
                />
              </div>
            </>
          )}
        </Space>
      </Card>
    </div>
  );
};

export default ChurnDetails;