import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Avatar, Tooltip, Tag, Button } from 'antd';
import { EyeOutlined, MessageOutlined, ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate } from 'react-router-dom';
import './TaskKanban.css';
import TaskCompleteDrawer from '../drawers/TaskCompleteDrawer';
import UserAvatar from '../../components/UserAvatar';
import AssigneeSelection from '../../modals/AssigneeSelection';
import axios from '../../api/axiosConfig';
import { message } from 'antd';
import TaskDateEditor from '../../components/TaskDateEditor';
import TaskNotes from '../modals/TaskNotes';
import DealDrawer from '../../drawers/DealDrawer';
import CustomScrollbar from '../../components/CustomScrollbar';

dayjs.extend(utc);
dayjs.extend(timezone);

const TaskKanban = ({ tasks = [], onTaskUpdate, boardStatusOptions, cardConfig, dealDataConfig, dealSoftwareConfig, taskType, users, setTasks, onTaskClick }) => {
  const [localTasks, setLocalTasks] = useState(tasks);
  const navigate = useNavigate();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false);
  const [completedTask, setCompletedTask] = useState(null);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState(null);
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(null);

  // Add handleAssigneeUpdate function
  const handleAssigneeUpdate = async (newAssignees) => {
    try {
      // Map frontend taskType to backend task_type
      let backendTaskType;
      if (taskType === 'bogforing' || taskType === 'lon' || taskType === 'moms' || taskType === 'arsafslutning') {
        backendTaskType = 'recurring';
      } else if (taskType === 'andre-opgaver') {
        backendTaskType = 'other';
      } else if (taskType === 'activities') {
        backendTaskType = 'activity';
      } else {
        backendTaskType = taskType;
      }

      const response = await axios.put(`/work/update_assignees`, {
        task_id: selectedTaskForAssignee.id,
        task_type: backendTaskType,
        assignees: newAssignees
      });
      
      if (response.status === 200) {
        message.success('Assignees updated successfully');
        // Update both local and parent state
        const updatedTasks = tasks.map(task => 
          task.id === selectedTaskForAssignee.id 
            ? { ...task, assigned_users: newAssignees }
            : task
        );
        setLocalTasks(updatedTasks);
        setTasks(updatedTasks); // Update parent state
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      message.error('Failed to update assignees');
    }
  };

  useEffect(() => {
    setLocalTasks(tasks);
  }, [tasks]);

  const getTasksForStatus = (status) => {
    return localTasks.filter(task => task.board_status === status);
  };

  const getColumnColor = (status) => {
    const option = boardStatusOptions.find(o => o.value === status);
    return option ? option.color : 'default';
  };

  const handleOpenTask = (taskId) => {
    navigate(`${taskId}`, { state: { fromKanban: true } });
  };

  const getTagColor = (key, value) => {
    const config = dealDataConfig.find(item => item.key === `data.${key}`);
    if (config) {
      if (config.type === 'dropdown') {
        const option = config.options.find(opt => opt.value === value);
        return option ? option.color : 'default';
      } else if (config.type === 'number' && config.colorScale) {
        const numValue = Number(value);
        for (let scale of config.colorScale) {
          if (numValue <= scale.max) {
            return scale.color;
          }
        }
      }
    }
    return 'default';
  };

  const mapFrequencyToLabel = (frequency) => {
    if (!frequency || !frequency.freq) return 'N/A';
    const mapping = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const handleMessageIconClick = (task, e) => {
    e.stopPropagation();
    setSelectedTask(task);
    setIsUpdateModalVisible(true);
  };

  const getUpdatesCount = (task) => {
    const commentsCount = task.comments?.length || 0;
    return commentsCount
  };

  const handleDealClick = (e, dealId) => {
    e.stopPropagation(); // Prevent task card click
    setSelectedDealId(dealId);
    setDealDrawerVisible(true);
  };

  const renderTaskContent = (task) => {
    return (
      <>
        <div className="kanban-card-header">
          <div className="kanban-card-deal">
            <Tag 
              color="blue" 
              onClick={(e) => handleDealClick(e, task.deal_id)}
              className="deal-tag" 
            >
              {task.deal_name}
            </Tag>
          </div>
        </div>
        <h4 className="kanban-card-title">{task.description}</h4>
        {cardConfig.showFrequency && (
          <div className="kanban-card-frequency">
            <Tag color={cardConfig.showFrequency ? "var(--primary-color)" : "default"}>
              {mapFrequencyToLabel(task.frequency)}
            </Tag>
          </div>
        )}
        <div className="kanban-card-dates">
          <div className="kanban-card-date">
            <ClockCircleOutlined className="kanban-card-icon" />
            <TaskDateEditor
              date={task.next_work_date || task.work_date}
              dateType="work"
              taskId={task.id}
              taskType={taskType}
              className="kanban-datepicker"
              onSuccess={(updatedTask) => {
                setLocalTasks(prevTasks =>
                  prevTasks.map(t =>
                    t.id === updatedTask.id 
                      ? { ...t, 
                          next_work_date: updatedTask.next_work_date,
                          work_date: updatedTask.work_date 
                        }
                      : t
                  )
                );
                if (setTasks) {
                  setTasks(prevTasks =>
                    prevTasks.map(t =>
                      t.id === updatedTask.id 
                        ? { ...t, 
                            next_work_date: updatedTask.next_work_date,
                            work_date: updatedTask.work_date 
                          }
                        : t
                    )
                  );
                }
              }}
            />
            <span className="kanban-card-date-label">Work Date</span>
          </div>
          <div className="kanban-card-date">
            <CalendarOutlined className="kanban-card-icon" />
            <TaskDateEditor
              date={task.next_due_date || task.due_date}
              dateType="due"
              taskId={task.id}
              taskType={taskType}
              className="kanban-datepicker"
              onSuccess={(updatedTask) => {
                setLocalTasks(prevTasks =>
                  prevTasks.map(t =>
                    t.id === updatedTask.id 
                      ? { ...t, 
                          next_due_date: updatedTask.next_due_date,
                          due_date: updatedTask.due_date 
                        }
                      : t
                  )
                );
                if (setTasks) {
                  setTasks(prevTasks =>
                    prevTasks.map(t =>
                      t.id === updatedTask.id 
                        ? { ...t, 
                            next_due_date: updatedTask.next_due_date,
                            due_date: updatedTask.due_date 
                          }
                        : t
                    )
                  );
                }
              }}
            />
            <span className="kanban-card-date-label">Due Date</span>
          </div>
        </div>
        <div className="kanban-card-deals">
          {dealDataConfig && dealDataConfig.map(item => {
            const key = item.key.replace('data.', '');
            const value = task.deal_data && task.deal_data[key];
            if (value) {
              return (
                <Tooltip key={item.key} title={item.label}>
                  <div className="kanban-card-deal">
                    <Tag color={getTagColor(key, value)}>
                      {`${value}`}
                    </Tag>
                  </div>
                </Tooltip>
              );
            }
            return null;
          })}

          {taskType === 'lon' && dealSoftwareConfig && dealSoftwareConfig.map(item => {
            const value = task.deal_software && task.deal_software[item.key];
            return (
              <Tooltip key={item.key} title={item.label}>
                <div className="kanban-card-deal">
                  <Tag color="var(--primary-color)">
                    {value || 'LØNPROGRAM'}
                  </Tag>
                </div>
              </Tooltip>
            );
          })}
        </div>
        <div className="kanban-card-footer">
          {cardConfig.showAssignee && (
            <div 
              className="kanban-card-assignee"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTaskForAssignee(task);
                setAssigneeModalVisible(true);
              }}
            >
              <Avatar.Group maxCount={3}>
                {Array.isArray(task.assigned_users) && task.assigned_users.map((userId) => {
                  const user = users?.find(u => u.user_id === userId);
                  if (!user) return null;
                  return (
                    <UserAvatar 
                      key={userId}
                      user={user}
                      size="medium"
                      style={{ 
                        border: '2px solid #fff'
                      }}
                    />
                  );
                })}
              </Avatar.Group>
            </div>
          )}
          <Button
            className="kanban-card-open-button"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenTask(task.id);
            }}
            >
            Open
          </Button>
          <Button
            className="kanban-card-notes-button"
            icon={<MessageOutlined />}
            onClick={(e) => handleMessageIconClick(task, e)}
          >
            {getUpdatesCount(task) > 0 && (
              <span className="notes-count">{getUpdatesCount(task)}</span>
            )}
          </Button>
        </div>
      </>
    );
  };

  const handleCompleteDrawerClose = () => {
    // Rollback the task status when drawer is closed without submission
    if (completedTask?.rollback) {
      completedTask.rollback();
    }
    setIsCompleteDrawerVisible(false);
    setCompletedTask(null);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) return;

    const task = localTasks.find(t => t.id.toString() === draggableId);
    const originalStatus = task.board_status;
    const originalProgressStatus = task.progress_status;
    const newStatus = destination.droppableId;

    // Check if moving to last status
    const isMovingToLastStatus = newStatus === boardStatusOptions[boardStatusOptions.length - 1].value;

    // Update tasks state optimistically
    const updatedTasks = localTasks.map(t =>
      t.id.toString() === draggableId
        ? { 
            ...t, 
            board_status: newStatus,
            progress_status: isMovingToLastStatus ? 'Completed' : 'In progress'
          }
        : t
    );
    setLocalTasks(updatedTasks);
    if (setTasks) setTasks(updatedTasks);

    // If moving to last status, open completion drawer
    if (isMovingToLastStatus) {
      setCompletedTask({
        ...task,
        board_status: newStatus,
        progress_status: 'Completed',
        task_type: taskType,
        isRecurring: ['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType),
        recurringType: taskType,
        rollback: () => {
          const revertedTasks = localTasks.map(t =>
            t.id.toString() === draggableId
              ? { 
                  ...t, 
                  board_status: originalStatus,
                  progress_status: originalProgressStatus 
                }
              : t
          );
          setLocalTasks(revertedTasks);
          if (setTasks) setTasks(revertedTasks);
        }
      });
      setIsCompleteDrawerVisible(true);
      return;
    }

    // For other status changes, proceed with API call
    try {
      let backendTaskType;
      if (['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType)) {
        backendTaskType = taskType;
      } else if (taskType === 'andre-opgaver') {
        backendTaskType = 'other';
      } else if (taskType === 'activities') {
        backendTaskType = 'activity';
      }

      const response = await axios.put(`/taskboard/tasks/${backendTaskType}/${draggableId}/status`, {
        board_status: newStatus,
        board_status_options: boardStatusOptions
      });

      if (!response.data) {
        throw new Error('No data received from server');
      }
    } catch (error) {
      console.error('Error updating task status:', error);
      // Revert to original state if the backend update failed
      const revertedTasks = localTasks.map(t =>
        t.id.toString() === draggableId
          ? { 
              ...t, 
              board_status: originalStatus,
              progress_status: originalProgressStatus
            }
          : t
      );
      setLocalTasks(revertedTasks);
      if (setTasks) setTasks(revertedTasks);
      message.error('Failed to update task status');
    }
  };

  useEffect(() => {
    if (completedTask) {
      setIsCompleteDrawerVisible(true);
    }
  }, [completedTask]);

  const handleTaskClick = (task) => {
    if (onTaskClick) {
      onTaskClick(task);
    }
  };

  const handleTaskUpdated = async (updatedTask) => {
    // Update both local and parent state
    setLocalTasks(prevTasks =>
      prevTasks.map(t =>
        t.id === updatedTask.id
          ? { ...t, ...updatedTask }
          : t
      )
    );
    
    if (setTasks) {
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === updatedTask.id
            ? { ...t, ...updatedTask }
            : t
        )
      );
    }
  };

  // Add this helper function
  const getBackendTaskType = (frontendTaskType) => {
    const taskTypeMap = {
      'andre-opgaver': 'other',
      'activities': 'activity',
      'bogforing': 'bogforing',
      'lon': 'lon',
      'moms': 'moms',
      'arsafslutning': 'arsafslutning'
    };
    return taskTypeMap[frontendTaskType] || frontendTaskType;
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="kanban-board">
          {boardStatusOptions.map((statusOption) => {
            const tasksInStatus = getTasksForStatus(statusOption.value);
            return (
              <div key={statusOption.value} className="kanban-column">
                <h3 className="kanban-column-title" style={{ backgroundColor: getColumnColor(statusOption.value) }}>
                  {statusOption.value} <span className="kanban-column-count">{tasksInStatus.length}</span>
                </h3>
                <Droppable droppableId={statusOption.value} type="TASK">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="kanban-column-content"
                      style={{
                        backgroundColor: snapshot.isDraggingOver 
                          ? 'rgba(var(--primary-color-rgb), 0.05)' 
                          : 'transparent',
                        transition: 'background-color 0.2s ease'
                      }}
                    >
                      <div className="kanban-column-droppable">
                        {tasksInStatus.map((task, index) => (
                          <Draggable 
                            key={task.id} 
                            draggableId={task.id.toString()} 
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`kanban-card ${snapshot.isDragging ? 'is-dragging' : ''}`}
                                style={{
                                  ...provided.draggableProps.style,
                                  opacity: snapshot.isDragging ? 0.6 : 1,
                                }}
                              >
                                <div 
                                  className="task-card" 
                                  onClick={() => handleTaskClick(task)}
                                >
                                  {renderTaskContent(task)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
        </div>
      </DragDropContext>
      <TaskNotes
        visible={isUpdateModalVisible}
        onClose={async (shouldRefresh) => {
          setIsUpdateModalVisible(false);
          if (shouldRefresh && selectedTask) {
            try {
              const backendTaskType = getBackendTaskType(taskType);
              // Use the existing endpoint with the correct task type
              const response = await axios.get(`/taskboard/tasks/${backendTaskType}/${selectedTask.id}`);
              handleTaskUpdated(response.data);
            } catch (error) {
              console.error('Error refreshing task:', error);
              message.error('Failed to refresh task data');
            }
          }
          setSelectedTask(null);
        }}
        task={selectedTask}
        taskType={taskType}
        users={users}
      />
      <TaskCompleteDrawer 
        visible={isCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={completedTask}
        onComplete={(values) => {
          // Handle completion logic here
          console.log('Task completed with values:', values);
          setIsCompleteDrawerVisible(false);
          setCompletedTask(null);
        }}
      />
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={handleAssigneeUpdate}
        initialAssignees={selectedTaskForAssignee?.assigned_users || []}
        users={users}
      />
      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />
    </>
  );
};

export default TaskKanban;
