import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Table, Avatar, DatePicker, Spin, message, Button, Tooltip, Card } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import './TaskWorkload.css';

const { MonthPicker } = DatePicker;

// Hardcoded user IDs
const HARDCODED_USER_IDS = [1, 2, 3, 4, 5, 6];

// Hardcoded task type conversions
const TASK_TYPE_DISPLAY_NAMES = {
  TaskBogforing: 'Bogføring',
  TaskLon: 'Løn',
  TaskMoms: 'Moms',
  TaskArsafslutning: 'Arsafslutning',
  activity: 'Aktiviteter',
  other: 'Andet'
};

// Define a constant for the workload cell width in pixels
const WORKLOAD_CELL_WIDTH = 40; // Adjust as needed

// Utility Functions
const convertMinutesToHours = (workload) => {
  return workload ? Number((workload / 60).toFixed(1)) : '';
};

const formatHours = (hours) => {
  return hours ? Number(hours.toFixed(1)) : '';
};

const getWorkloadColor = (workload, capacity) => {
  if (!workload || !capacity) return 'var(--background-color)'; // Empty cell color
  
  const hours = workload / 60; // Convert workload from minutes to hours
  const percentage = hours / (capacity / 60);  // Convert capacity to hours for comparison
  if (percentage > 1) return 'var(--error-color)'; // Over capacity

  // Define color ranges
  if (percentage > 0 && percentage <= 0.33) {
    return '#D4EDDA'; // Light green
  } else if (percentage > 0.33 && percentage <= 0.66) {
    return '#A3D4A1'; // Normal green
  } else if (percentage > 0.66 && percentage <= 1) {
    return '#6BBF59'; // Darker green
  }

  return 'var(--background-color)'; // Default color
};

const renderWorkloadCell = (cellData, date, tasks, capacity) => {
  // Handle both main row and expanded row data structures
  const workload = cellData?.workload || 0;
  const cellTasks = tasks || cellData?.tasks || [];
  const dailyCapacity = capacity || cellData?.capacity || 0;

  const hours = convertMinutesToHours(workload);
  const capacityHours = convertMinutesToHours(dailyCapacity);
  const isWeekend = date.day() === 0 || date.day() === 6;
  const backgroundColor = workload ? getWorkloadColor(workload, dailyCapacity) : (isWeekend ? 'var(--background-color)' : 'var(--info-color)');
  
  const tooltipContent = (
    <div>
      <strong>{hours} / {capacityHours} hours</strong>
      {cellTasks && cellTasks.length > 0 && (
        <div>
          {cellTasks.map((task, index) => (
            <div key={index}>
              <strong>{convertMinutesToHours(task.workload)} hours:</strong> {task.description}
              <br />
              <em>{task.deal_name}</em>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <Tooltip title={tooltipContent} mouseEnterDelay={0.5}>
      <div
        className="workload-cell"
        style={{ backgroundColor: backgroundColor }}
      >
        {hours}
      </div>
    </Tooltip>
  );
};

// Generate week columns based on the selected date
const generateWeekColumns = (selectedDate) => {
  const startOfMonth = selectedDate.startOf('month');
  const endOfMonth = selectedDate.endOf('month');
  let currentWeekStart = startOfMonth.startOf('week');
  const dynamicColumns = [];

  while (currentWeekStart.isBefore(endOfMonth)) {
    let weekEnd = currentWeekStart.endOf('week');
    const weekColumns = [];
    let weekStart = currentWeekStart;

    for (let i = 0; i < 7; i++) {
      const date = currentWeekStart.add(i, 'day');
      if (date.month() === selectedDate.month()) {
        weekColumns.push({
          title: date.format('dd')[0],
          dataIndex: ['daily_workload', date.format('YYYY-MM-DD')],
          key: date.format('YYYY-MM-DD'),
          width: WORKLOAD_CELL_WIDTH,
          render: (cellData, record) => renderWorkloadCell(
            cellData,
            date,
            cellData?.tasks,
            record.daily_capacity?.[date.format('YYYY-MM-DD')]
          ),
          onCell: () => ({
            style: { padding: 0, width: WORKLOAD_CELL_WIDTH, minWidth: WORKLOAD_CELL_WIDTH, maxWidth: WORKLOAD_CELL_WIDTH }
          }),
        });
      }
    }

    // Adjust the weekStart and weekEnd to only include days within the selected month
    if (weekStart.month() !== selectedDate.month()) {
      weekStart = startOfMonth;
    }
    if (weekEnd.month() !== selectedDate.month()) {
      weekEnd = endOfMonth;
    }

    dynamicColumns.push({
      title: `${weekStart.format('DD MMM')} - ${weekEnd.format('DD MMM YYYY')}`,
      children: weekColumns,
    });

    currentWeekStart = currentWeekStart.add(1, 'week');
  }

  return dynamicColumns;
};

// Main Table Columns
const mainColumnsBase = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'resource',
    fixed: 'left',
    width: 212,
    render: (user) => (
      <div className="user-info">
        <Avatar style={{ backgroundColor: user?.settings?.avatar_color || '#1890ff' }}>
          {user?.name?.[0] || ''}{user?.surname?.[0] || ''}
        </Avatar>
        <span>{user?.name} {user?.surname}</span>
      </div>
    ),
  },
  {
    title: 'Assigned',
    children: [
      {
        title: 'Tasks',
        dataIndex: 'assigned_tasks',
        key: 'assigned_tasks',
        fixed: 'left',
        width: 69,
      },
      {
        title: 'Work Hours',
        dataIndex: 'assigned_work_hours',
        key: 'assigned_work_hours',
        fixed: 'left',
        width: 110,
        render: (value) => formatHours(value),
      },
    ],
  },
];

// Expanded Row Columns (Separate from Main Columns)
const getExpandedColumns = (selectedDate) => {
  const weekColumns = generateWeekColumns(selectedDate);
  const expandedColumnsBase = [
    {
      title: 'Task Type',
      dataIndex: 'task_type',
      key: 'task_type',
      fixed: 'left',
      width: 212,
      render: (taskType) => TASK_TYPE_DISPLAY_NAMES[taskType] || taskType,
    },
    {
      title: 'Tasks',
      dataIndex: 'assigned_tasks',
      key: 'assigned_tasks',
      fixed: 'left',
      width: 69,
    },
    {
      title: 'Work Hours',
      dataIndex: 'total_workload',
      key: 'total_workload',
      fixed: 'left',
      width: 110,
      render: (value) => convertMinutesToHours(value),
    },
  ];
  
  return [...expandedColumnsBase, ...weekColumns];
};


// Shared Table Columns for Main and Expanded Tables
const getMainColumns = (selectedDate) => {
  const weekColumns = generateWeekColumns(selectedDate);
  return [...mainColumnsBase, ...weekColumns];
};

const TaskWorkload = () => {
  const [loading, setLoading] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  
  // Ref for the main table's scroll container
  const mainTableScrollRef = useRef(null);

  // Memoize column definitions to ensure consistency
  const mainColumns = useMemo(() => getMainColumns(selectedDate), [selectedDate]);
  const expandedColumns = useMemo(() => getExpandedColumns(selectedDate), [selectedDate]);
  
  useEffect(() => {
    fetchTaskData();
  }, [selectedDate]);

  const fetchTaskData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/dashboard/tasks/workload', {
        params: {
          year: selectedDate.year(),
          month: selectedDate.month() + 1,
          user_ids: HARDCODED_USER_IDS.join(',')
        }
      });
      setTaskData(response.data);
    } catch (error) {
      console.error('Error fetching task data:', error);
      message.error('Failed to fetch task data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePreviousMonth = () => {
    setSelectedDate(selectedDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.add(1, 'month'));
  };

  const expandedRowRender = (record) => {
    const taskTypes = Object.keys(record.task_details || {});
    
    const data = taskTypes.map(taskType => ({
      key: taskType,
      task_type: taskType,
      assigned_tasks: record.task_details[taskType]?.assigned_tasks || 0,
      total_workload: record.task_details[taskType]?.total_workload || 0,
      daily_workload: record.task_details[taskType]?.daily_workload || {},
      daily_capacity: record.daily_capacity
    }));
    
    return (
      <div className="expanded-row-container">
        <Table
          columns={expandedColumns}
          dataSource={data}
          pagination={false}
          showHeader={false}
          rowKey={(record) => record.task_type}
          tableLayout="fixed"
          style={{ width: '100%' }}
        />
      </div>
    );
  };

  useEffect(() => {
    const handleMainTableScroll = () => {
      const mainScrollContainer = mainTableScrollRef.current;
      if (mainScrollContainer) {
        const scrollLeft = mainScrollContainer.scrollLeft;

        // Select all expanded tables' scroll containers
        const expandedTables = document.querySelectorAll('.expanded-row-container .ant-table-body');

        expandedTables.forEach(expandedTable => {
          expandedTable.scrollLeft = scrollLeft;
        });
      }
    };

    const mainScrollContainer = mainTableScrollRef.current;
    if (mainScrollContainer) {
      mainScrollContainer.addEventListener('scroll', handleMainTableScroll);
    }

    return () => {
      if (mainScrollContainer) {
        mainScrollContainer.removeEventListener('scroll', handleMainTableScroll);
      }
    };
  }, []);

  return (
    <div className="task-data">
      <Card>
        <div className="date-picker-container">
          <Button icon={<LeftOutlined />} onClick={handlePreviousMonth} />
          <MonthPicker 
            onChange={handleDateChange} 
            value={selectedDate}
            allowClear={false}
          />
          <Button icon={<RightOutlined />} onClick={handleNextMonth} />
        </div>
        <div className="table-container" ref={mainTableScrollRef}>
          {loading ? (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          ) : (
            <Table
              className="task-workload-table"
              dataSource={taskData}
              columns={mainColumns}
              rowKey={(record) => record.user.user_id}
              pagination={false}
              tableLayout="fixed"
              expandable={{
                expandedRowRender,
                rowExpandable: record => Object.keys(record.task_details || {}).length > 0,
                expandRowByClick: true,
                showExpandColumn: false,
                expandIcon: () => null,
              }}
              scroll={{ x: 'max-content' }} /* Ensure the main table can scroll horizontally */
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default TaskWorkload;