import React from 'react';
import { Layout, Tabs } from 'antd';
import { BarChartOutlined, ScheduleOutlined, UnorderedListOutlined, SettingOutlined, FileTextOutlined, CloudUploadOutlined, RobotOutlined } from '@ant-design/icons';
import ErpHeader from '../components/ErpHeader';
import AdminStatus from './pages/AdminStatus';
import AdminSchedule from './pages/AdminSchedule';
import AdminTasks from './pages/AdminTasks';
import AdminInvoice from './pages/AdminInvoice';
import AdminBackup from './pages/AdminBackup';
import AdminPipedrive from './pages/AdminPipedrive';
import AdminRevibot from './pages/AdminRevibot';
import './AdminDashboard.css';

const { Content } = Layout;

function AdminDashboard({ theme, toggleTheme }) {
  const items = [
    {
      key: 'status',
      label: (
        <span>
          <BarChartOutlined />
          Status
        </span>
      ),
      children: <AdminStatus />,
    },
    {
      key: 'schedule',
      label: (
        <span>
          <ScheduleOutlined />
          Schedule
        </span>
      ),
      children: <AdminSchedule />,
    },
    {
      key: 'tasks',
      label: (
        <span>
          <UnorderedListOutlined />
          Tasks & Workload
        </span>
      ),
      children: <AdminTasks />,
    },
    {
      key: 'invoicing',
      label: (
        <span>
          <FileTextOutlined />
          Invoicing
        </span>
      ),
      children: <AdminInvoice />,
    },
    {
      key: 'backup',
      label: (
        <span>
          <CloudUploadOutlined />
          Backups
        </span>
      ),
      children: <AdminBackup />,
    },
    {
      key: 'pipedrive',
      label: (
        <span>
          <FileTextOutlined />
          Pipedrive
        </span>
      ),
      children: <AdminPipedrive />,
    },
    {
      key: 'revibot',
      label: (
        <span>
          <RobotOutlined />
          Revibot
        </span>
      ),
      children: <AdminRevibot />,
    },
  ];

  return (
    <Layout className="admin-dashboard-layout">
      <ErpHeader 
        theme={theme} 
        toggleTheme={toggleTheme} 
        icon={SettingOutlined}
        title="Admin"
      />
      <Content className="admin-dashboard-content">
        <Tabs defaultActiveKey="status" type="card" className="admin-tabs" items={items} />
      </Content>
    </Layout>
  );
}

export default AdminDashboard;
