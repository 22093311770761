import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, message, Select, DatePicker, Upload } from 'antd';
import { UserOutlined, LockOutlined, EnvironmentOutlined, CalendarOutlined, CameraOutlined } from '@ant-design/icons';
import axiosPublic from '../api/axiosPublic';
import logo from '../assets/logos/logo.png';
import './CompleteRegistration.css';
import UserAvatar from '../components/UserAvatar';

const { Title } = Typography;
const { Option } = Select;

function CompleteRegistration() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAvatarUpload, setShowAvatarUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')) || {});

  React.useEffect(() => {
    // Fetch countries list
    axiosPublic.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const sortedCountries = response.data
          .map(country => country.name.common)
          .sort((a, b) => a.localeCompare(b));
        setCountries(sortedCountries);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleAvatarUpload = async (file) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      setIsUploading(true);
      const response = await axiosPublic.post('/user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      });
      
      if (response.data.avatar_urls) {
        setCurrentUser(prev => ({
          ...prev,
          avatar_urls: response.data.avatar_urls
        }));
        localStorage.setItem('user', JSON.stringify({
          ...currentUser,
          avatar_urls: response.data.avatar_urls
        }));
        message.success('Avatar uploaded successfully');
      }
    } catch (error) {
      message.error('Failed to upload avatar');
    } finally {
      setIsUploading(false);
      setShowAvatarUpload(false);
    }
  };

  const handleAvatarDelete = async () => {
    try {
      setIsUploading(true);
      await axiosPublic.delete('/user/avatar', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCurrentUser(prev => ({
        ...prev,
        avatar_urls: {}
      }));
      localStorage.setItem('user', JSON.stringify({
        ...currentUser,
        avatar_urls: {}
      }));
      message.success('Avatar removed successfully');
    } catch (error) {
      message.error('Failed to remove avatar');
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axiosPublic.post('/auth/complete-registration', values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.user && response.data.user.is_registered) {
        message.success('Registration completed successfully. Please log in with your new credentials.');
        // Clear user data from local storage
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        // Navigate to login page after a short delay
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        throw new Error('Registration failed');
      }
    } catch (error) {
      message.error('Failed to complete registration. Please try again.');
      console.error('Registration error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="complete-registration-container">
      <div className="complete-registration-card">
        <img src={logo} alt="Digi-tal ERP Logo" className="registration-logo" />
        <Title level={2} className="registration-title">
          Complete Your Profile
        </Title>

        <div 
          className="registration-avatar-section"
          onMouseEnter={() => setShowAvatarUpload(true)}
          onMouseLeave={() => setShowAvatarUpload(false)}
        >
          <UserAvatar
            user={currentUser}
            size={120}
            style={{
              width: '120px',
              height: '120px',
            }}
          />
          {!currentUser.avatar_urls || Object.keys(currentUser.avatar_urls).length === 0 ? (
            <div className={`avatar-upload-overlay ${showAvatarUpload ? 'show' : ''}`}>
              <div className="avatar-label">
                <input
                  type="file"
                  className="file-input"
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      handleAvatarUpload(e.target.files[0]);
                    }
                  }}
                  accept="image/*"
                />
                <CameraOutlined className="overlay-icon" />
                <span className="overlay-text">Add Image</span>
              </div>
            </div>
          ) : (
            <div
              className="avatar-wrapper"
              onMouseEnter={() => setShowAvatarUpload(true)}
              onMouseLeave={() => setShowAvatarUpload(false)}
            >
              <UserAvatar
                user={currentUser}
                size={120}
                style={{ cursor: 'pointer' }}
              />
              {showAvatarUpload && (
                <div className="avatar-upload-overlay">
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleAvatarUpload(file);
                      return false;
                    }}
                  >
                    <Button 
                      icon={<CameraOutlined />}
                      loading={isUploading}
                    >
                      {currentUser.avatar_urls ? 'Change' : 'Upload'}
                    </Button>
                  </Upload>
                  {currentUser.avatar_urls && Object.keys(currentUser.avatar_urls).length > 0 && (
                    <Button 
                      danger 
                      onClick={handleAvatarDelete}
                      loading={isUploading}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <Form form={form} onFinish={handleSubmit} layout="vertical" className="registration-form">
          <div className="form-row">
            <Form.Item
              name="name"
              label="Name"
              className="form-col"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              name="surname"
              label="Surname"
              className="form-col"
              rules={[{ required: true, message: 'Please input your surname!' }]}
            >
              <Input prefix={<UserOutlined />} />
            </Form.Item>
          </div>

          <Form.Item
            name="password"
            label="New Password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>

          <div className="form-row">
            <Form.Item
              name="birthday"
              label="Birthday"
              className="form-col"
              rules={[{ required: true, message: 'Please select your birthday!' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                suffixIcon={<CalendarOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              className="form-col"
              rules={[{ required: true, message: 'Please input your city!' }]}
            >
              <Input prefix={<EnvironmentOutlined />} />
            </Form.Item>
          </div>

          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: 'Please select your country!' }]}
          >
            <Select
              showSearch
              placeholder="Select your country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map(country => (
                <Option key={country} value={country}>{country}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="form-actions">
            <Button type="primary" htmlType="submit" loading={loading}>
              Complete Registration
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CompleteRegistration;
