import React, { useState, useEffect } from 'react';
import { Layout, Card, Button, Upload, Select, message } from 'antd';
import { UploadOutlined, ToolOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axiosConfig';
import ErpHeader from '../../components/ErpHeader';
import './Officebot.css';

const { Content } = Layout;
const { Option } = Select;

const Officebot = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user')) || { name: 'User', id: null };
  const initials = user.name.split(' ').map(n => n[0]).join('').toUpperCase();
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [monthAdjustment, setMonthAdjustment] = useState(0);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const userMenu = {
    items: [
      { key: 'logout', label: 'Logout' },
    ],
    onClick: ({ key }) => {
      if (key === 'logout') {
        handleLogout();
      }
    },
  };

  const handleMainMenuRedirect = () => {
    navigate('/main-menu');
  };

  const handleFileUpload = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('month_adjustment', monthAdjustment);

    try {
      const response = await axios.post('/officebot/upload', formData, {
        responseType: 'blob', // Handle binary data
      });

      // Check if the response is an Excel file or an error message
      const contentType = response.headers['content-type'];
      if (
        contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        contentType === 'application/vnd.ms-excel'
      ) {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        setDownloadUrl(url);
        onSuccess(response.data, file);
        message.success(`${file.name} file uploaded and processed successfully`);
      } else if (contentType === 'application/json') {
        // Parse JSON error message
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            message.error(errorResponse.error || 'File upload failed');
            onError(new Error(errorResponse.error));
          } catch (parseError) {
            message.error('Failed to parse error message from server');
            onError(parseError);
          }
        };
        reader.readAsText(response.data);
      } else {
        message.error('Unexpected response from the server');
        onError(new Error('Unexpected response from the server'));
      }
    } catch (error) {
      message.error(`${file.name} file upload failed: ${error.message}`);
      onError(error);
    }
  };

  useEffect(() => {
    // Cleanup function to revoke the object URL to prevent memory leaks
    return () => {
        if (downloadUrl) {
            window.URL.revokeObjectURL(downloadUrl);
            console.log('Object URL revoked:', downloadUrl);
        }
    };
  }, [downloadUrl]);

  const handleDownloadClick = () => {
    // Revoke the object URL after initiating the download
    setTimeout(() => {
      if (downloadUrl) {
        window.URL.revokeObjectURL(downloadUrl);
        setDownloadUrl(null);
        console.log('Object URL revoked after download:', downloadUrl);
      }
    }, 0);
  };

  return (
    <Layout className="officebot-layout">
      <ErpHeader 
        theme={theme} 
        toggleTheme={toggleTheme} 
        icon={ToolOutlined}
        title="Officebot Tools"
      />
      <Content className="officebot-content">
        <Card title="Adjust Month">
          <Select
            value={monthAdjustment}
            onChange={(value) => setMonthAdjustment(value)}
            style={{ width: 200 }}
          >
            {Array.from({ length: 13 }, (_, i) => i - 6).map((value) => (
              <Option key={value} value={value}>
                {value > 0 ? `+${value}` : value}
              </Option>
            ))}
          </Select>
        </Card>
        <Card title="Upload File">
          <Upload
            name="file"
            customRequest={customRequest}
            onChange={handleFileUpload}
            accept=".csv,.xlsx"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Card>
        {downloadUrl && (
          <Card title="Download File">
            <a
              href={downloadUrl}
              download="FakturaLinje_processed.xlsx"
              onClick={handleDownloadClick}
            >
              <Button>Download File</Button>
            </a>
          </Card>
        )}
      </Content>
    </Layout>
  );
};

export default Officebot;