import React from 'react';
import { Handle } from '@xyflow/react';
import { Card } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import NodeNumber from '../../components/NodeNumber';
import '../ModuleNode.css';
import { config } from './configs/eventTrigger.config';

const EventTrigger = ({ data, id }) => {
  const formatEventType = (eventType) => {
    if (!eventType) return '';
    
    // Search through all groups to find the matching option
    const option = config.formConfig.fields[0].options
      .flatMap(group => group.options)
      .find(opt => opt.value === eventType);
      
    return option?.label || eventType;
  };

  return (
    <Card 
      size="small"
      title={<div className="node-title"><ThunderboltOutlined /> Event Trigger</div>}
      className={`module-node trigger-node ${!data.isConnected ? 'disconnected' : ''}`}
      style={{ width: 200 }}
    >
      <NodeNumber nodeId={id} number={data.nodeNumber} />
      <Handle type="target" position="top" style={{ background: '#555' }} />
      <Handle type="source" position="bottom" style={{ background: '#555' }} />
      {data.eventType && (
        <div>
          <span className="node-label">Event:</span>
          {formatEventType(data.eventType)}
        </div>
      )}
    </Card>
  );
};

export default EventTrigger;