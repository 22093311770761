import React from 'react';
import { Handle } from '@xyflow/react';
import { PlayCircleOutlined } from '@ant-design/icons';
import './FlowHandler.css';

const FlowStart = ({ data, selected }) => {
  return (
    <div className={`flow-handler-node flow-start-node ${selected ? 'selected' : ''}`}>
      <div className="handler-content">
        <PlayCircleOutlined style={{ fontSize: '20px', color: 'var(--success-color)' }} />
        <div className="handler-label">{data.label}</div>
        <div className="handler-status" style={{ color: 'var(--success-color)' }}>
          ready
        </div>
      </div>
      <Handle type="source" position="bottom" style={{ background: '#555' }} />
    </div>
  );
};

export default FlowStart;