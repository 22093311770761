import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import DigiAI from './DigiAI';
import TimeTracker from './TimeTracker';
import StickyTasks from './StickyTasks';
import './OverlayHub.css';

function OverlayHub() {
  const [isVisible, setIsVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);
  const [isTimeTrackerRunning, setIsTimeTrackerRunning] = useState(false);
  const [isLiveTimerVisible, setIsLiveTimerVisible] = useState(false);
  const [displayAccount, setDisplayAccount] = useState('');
  const [displayTask, setDisplayTask] = useState('');
  const hideTimeoutRef = useRef(null);
  const location = useLocation();

  const hiddenPages = ['/', '/login', '/complete-registration', '/main-menu', '/digi-dashboard', '/bot', '/officebot', '/custom-gpts', '/admin'];
  const hiddenPaths = ['/invoice-overview', '/flows'];

  const showOverlayHub = !hiddenPages.includes(location.pathname) && 
                         !hiddenPaths.some(path => location.pathname.startsWith(path));

  useEffect(() => {
    return () => {
      clearTimeout(hideTimeoutRef.current);
    };
  }, []);

  if (!showOverlayHub) return null;

  const showOverlays = () => {
    clearTimeout(hideTimeoutRef.current);
    setIsVisible(true);
  };

  const hideOverlays = () => {
    hideTimeoutRef.current = setTimeout(() => {
      if (!activeOverlay) {
        setIsVisible(false);
      }
    }, 300);
  };

  const handleOverlayOpen = (overlayName) => {
    setActiveOverlay(overlayName);
    setIsVisible(true);
  };

  const handleOverlayClose = () => {
    setActiveOverlay(null);
    if (!isTimeTrackerRunning) {
      setIsVisible(false);
    }
  };

  const handleTimeTrackerStatusChange = (isRunning, account, task) => {
    setIsTimeTrackerRunning(isRunning);
    setIsLiveTimerVisible(isRunning);
    setDisplayAccount(account);
    setDisplayTask(task);
    if (isRunning) {
      setActiveOverlay(null);
    }
  };

  return (
    <div 
      className="overlay-hub-container"
      onMouseEnter={showOverlays}
      onMouseLeave={hideOverlays}
    >
      <Button
        className="overlay-hub-button"
        type="primary"
        icon={<AppstoreOutlined />}
        shape="circle"
        size="large"
      />
      <div className={`overlay-components ${isVisible ? 'visible' : ''}`}>
        <div className="overlay-component sticky-tasks">
          <StickyTasks isVisible={isVisible} onOpen={() => handleOverlayOpen('stickyTasks')} />
        </div>
        <div className="overlay-component time-tracker">
          <TimeTracker 
            isVisible={isVisible} 
            onOpen={() => handleOverlayOpen('timeTracker')} 
            onStatusChange={handleTimeTrackerStatusChange}
            isRunning={isTimeTrackerRunning}
            isLiveTimerVisible={isLiveTimerVisible}
          />
        </div>
        <div className="overlay-component digi-ai">
          <DigiAI isVisible={isVisible} onOpen={() => handleOverlayOpen('digiAI')} />
        </div>
      </div>
      {activeOverlay === 'stickyTasks' && (
        <StickyTasks isVisible={true} isMaximized={true} onClose={handleOverlayClose} />
      )}
      {activeOverlay === 'digiAI' && (
        <DigiAI isVisible={true} isMaximized={true} onClose={handleOverlayClose} />
      )}
      {activeOverlay === 'timeTracker' && (
        <TimeTracker 
          isVisible={true} 
          isMaximized={true} 
          onClose={handleOverlayClose}
          onStatusChange={handleTimeTrackerStatusChange}
        />
      )}
      {isLiveTimerVisible && (
        <div className="live-timer-wrapper">
          <TimeTracker 
            isVisible={true} 
            isMaximized={false} 
            isRunning={isTimeTrackerRunning}
            isLiveTimer={true}
            onClose={() => setIsLiveTimerVisible(false)}
            onStatusChange={handleTimeTrackerStatusChange}
            displayAccountProp={displayAccount}
            displayTaskProp={displayTask}
          />
        </div>
      )}
    </div>
  );
}

export default OverlayHub;