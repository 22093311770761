import React, { useState, useEffect } from 'react';
import { Card, Table, Spin } from 'antd';
import { UserOutlined, CheckCircleOutlined, SafetyOutlined, ExclamationCircleOutlined, LoadingOutlined, SwapOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';
import './OnboardingDashboard.css';
import { format } from 'date-fns';

const OnboardingDashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    newDeals: null,
    currentOnboards: null,
    completedOnboards: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [migrationsCount, setMigrationsCount] = useState(0);

  useEffect(() => {
    fetchDashboardData();
    fetchActivities();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get('/onboarding/deals');
      setDashboardData(response.data.dashboardData);
      setMigrationsCount(response.data.dashboardData.migrationsCount);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setIsLoading(false);
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get('/onboarding/dashboard-activities');
      setActivities(response.data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handleRowClick = (record) => {
    if (record && record.id) {
      navigate(`/onboarding/cases/${record.id}`);
    } else {
      console.error('Invalid record or missing id:', record);
    }
  };

  const columns = [
    {
      title: 'Deal Name',
      dataIndex: 'deal_name',
      key: 'deal_name',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <span style={{ 
          backgroundColor: user.avatar_color, 
          color: 'white', 
          padding: '2px 8px', 
          borderRadius: '12px' 
        }}>
          {user.name}
        </span>
      ),
    },
    {
      title: 'Update',
      dataIndex: 'last_update',
      key: 'last_update',
      render: (date) => format(new Date(date), 'dd MMM yyyy HH:mm'),
    },
    {
      title: 'Onboarding Start',
      dataIndex: 'onboarding_start',
      key: 'onboarding_start',
      render: (date) => format(new Date(date), 'dd MMM yyyy HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`status status-${status.id}`}>
          {status.name}
        </span>
      ),
    },
    {
      title: 'Activity',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const renderCardContent = (value) => {
    return (
      <div className="card-content">
        {isLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <h2>{value}</h2>
        )}
      </div>
    );
  };

  return (
    <div className="onboarding-dashboard">
      <h1>Onboarding Dashboard</h1>
      <div className="dashboard-cards">
        {migrationsCount > 0 && (
          <Card className="dashboard-card" onClick={() => navigate('/onboarding/migrations')}>
            <SwapOutlined className="card-icon" />
            {renderCardContent(migrationsCount)}
            <p>Pending Migrations</p>
          </Card>
        )}
        <Card className="dashboard-card" onClick={() => navigate('/onboarding/cases?filter=1')}>
          <ExclamationCircleOutlined className="card-icon" />
          {renderCardContent(dashboardData.newDeals)}
          <p>New Deals</p>
        </Card>
        <Card className="dashboard-card" onClick={() => navigate('/onboarding/cases')}>
          <UserOutlined className="card-icon" />
          {renderCardContent(dashboardData.currentOnboards)}
          <p>Current Onboards</p>
        </Card>
        <Card className="dashboard-card">
          <CheckCircleOutlined className="card-icon" />
          {renderCardContent(dashboardData.completedOnboards)}
          <p>Completed Onboards</p>
        </Card>
      </div>
      <div className="latest-cases">
        <h2>Latest Updates</h2>
        <Table 
          columns={columns} 
          dataSource={activities} 
          rowKey="id" 
          className="dashboard-latest-cases-table"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </div>
  );
};

export default OnboardingDashboard;