import { 
  getAvailableFieldsFromTrigger, 
  findSourceTriggerNode,
  findConnectedTriggerNodes,
  getPreviousNodeFields
} from '../../../../utils/flowUtils';

export const config = {
  type: 'showForm',
  category: 'actions',
  subcategory: 'forms',
  label: 'Show Form',
  icon: 'FormOutlined',

  // Add configurator settings
  configurator: {
    width: 'wide' // Can be 'normal' or 'wide'
  },

  // Default data when creating new node
  defaultData: {
    label: 'Show Form',
    formType: 'continuing',
    formConfig: {
      title: '',
      description: '',
      fields: []
    },
    dataInput: '',
    dataOutput: '',
    redirectUrl: ''
  },

  // Module behavior configuration
  moduleConfig: {
    validateConnection: (sourceNode) => {
      return sourceNode?.type === 'eventTrigger';
    },
    isConnectedToSource: (nodeId, nodes, edges) => {
      const triggerNodes = findConnectedTriggerNodes(nodeId, nodes, edges);
      return triggerNodes.some(node => 
        ['onboarding_started', 'onboarding_form_submitted'].includes(node.data.eventType)
      );
    },
    maxIncomingConnections: 1,
    maxOutgoingConnections: 1,
    pausesExecution: true
  },

  // Add method to expose fields
  getAvailableFields: (node) => {
    const formFields = node.data?.formConfig?.fields || [];
    return formFields.map(field => ({
      key: `${node.id}.${field.name}`,
      label: `${node.data.formConfig.title || 'Untitled Form'} - ${field.label}`,
      type: field.type,
      sourceType: 'form'
    }));
  },

  // Form configuration for ModuleConfigurator
  formConfig: {
    fields: [
      {
        name: 'formType',
        label: 'Form Type',
        type: 'select',
        rules: [{ required: true }],
        options: [
          { value: 'starting', label: 'Starting Form' },
          { value: 'continuing', label: 'Continuing Form' },
          { value: 'finishing', label: 'Finishing Form' },
          { value: 'solo', label: 'Standalone Form' }
        ]
      },
      {
        name: ['formConfig', 'title'],
        label: 'Form Title',
        type: 'input',
        rules: [{ required: true }]
      },
      {
        name: ['formConfig', 'description'],
        label: 'Form Description',
        type: 'textarea'
      },
      {
        name: ['formConfig', 'fields'],
        label: 'Form Fields',
        type: 'formBuilder',
        rules: [{ required: true }],
        config: {
          fieldTypes: [
            { value: 'text', label: 'Text Input' },
            { value: 'number', label: 'Number' },
            { value: 'select', label: 'Select' },
            { value: 'multiSelect', label: 'Multi Select' },
            { value: 'date', label: 'Date' },
            { value: 'checkbox', label: 'Checkbox' },
            { value: 'radio', label: 'Radio Group' },
            { value: 'textarea', label: 'Text Area' }
          ],
          fieldConfig: {
            name: { type: 'input', label: 'Field Name', required: true },
            label: { type: 'input', label: 'Field Label', required: true },
            type: { type: 'select', label: 'Field Type', required: true },
            required: { type: 'checkbox', label: 'Required' },
            placeholder: { type: 'input', label: 'Placeholder' },
            defaultValue: { type: 'input', label: 'Default Value' },
            options: {
              type: 'dynamicList',
              label: 'Options',
              showWhen: (field) => ['select', 'multiSelect', 'radio'].includes(field.type)
            },
            conditions: {
              type: 'conditionsBuilder',
              label: 'Display Conditions',
              config: {
                getPreviousFormFields: getPreviousNodeFields
              }
            }
          }
        }
      },
      {
        name: 'dataInput',
        label: 'Data Input',
        type: 'dynamic',
        getDynamicInput: (value, nodeId, nodes, edges) => {
          const availableFields = getAvailableFieldsFromTrigger(nodeId, nodes, edges);
          return {
            type: 'select',
            options: availableFields.map(field => ({
              value: field.key,
              label: field.label
            }))
          };
        }
      },
      {
        name: 'redirectBehavior',
        label: 'Redirect Behavior',
        type: 'select',
        rules: [{ required: true }],
        options: [
          { value: 'next_form', label: 'Next Form in Flow' },
          { value: 'custom', label: 'Custom URL' },
          { value: 'none', label: 'No Redirect (Handle in Flow)' }
        ]
      },
      {
        name: 'redirectUrl',
        label: 'Custom Redirect URL',
        type: 'input',
        placeholder: '/custom-page',
        showWhen: (values) => values.redirectBehavior === 'custom'
      },
      {
        name: 'loadingMessage',
        label: 'Loading Message',
        type: 'input',
        placeholder: 'Processing your submission...',
        showWhen: (values) => values.redirectBehavior === 'next_form'
      }
    ]
  }
};