import { getDealFieldsArray } from '../../../shared/dealData.config';
import { 
  getAvailableFieldsFromTrigger,
  isNodeConnectedToStart 
} from '../../../../utils/flowUtils';

export const config = {
  type: 'getDeal',
  category: 'actions',
  subcategory: 'deal',
  label: 'Get Deal',
  icon: 'SearchOutlined',

  defaultData: {
    label: 'Get Deal',
    inputType: '',
    inputField: '',
    isConnected: false
  },

  moduleConfig: {
    validateConnection: () => true,
    isConnectedToSource: (nodeId, nodes, edges) => {
      return isNodeConnectedToStart(nodeId, nodes, edges);
    },
    getAvailableFields: (node) => {
      // This node provides deal fields to downstream nodes
      return getDealFieldsArray().map(field => ({
        ...field,
        sourceNode: node.id,
        sourceType: 'deal',
        label: `${node.data.label || 'Get Deal'} (#${node.data.nodeNumber}) - ${field.label}`
      }));
    }
  },

  formConfig: {
    fields: [
      {
        name: 'inputType',
        label: 'Input Type',
        type: 'select',
        rules: [{ required: true }],
        options: [
          { value: 'onboarding_id', label: 'Onboarding ID' },
          { value: 'contact_id', label: 'Contact ID' },
          { value: 'account_id', label: 'Account ID' },
          { value: 'custom_id', label: 'Custom Deal ID' }
        ]
      },
      {
        name: 'inputField',
        label: 'Input Field',
        type: 'dynamic',
        rules: [{ required: true }],
        showWhen: (data) => !!data.inputType,
        getDynamicInput: (value, nodeId, nodes, edges) => {
          const availableFields = getAvailableFieldsFromTrigger(nodeId, nodes, edges);
          
          return {
            type: 'select',
            options: availableFields
              .filter(field => {
                // Filter fields based on selected input type
                switch (value.inputType) {
                  case 'onboarding_id':
                    return field.key.includes('onboarding.id');
                  case 'contact_id':
                    return field.key.includes('contact.id');
                  case 'account_id':
                    return field.key.includes('account.id');
                  case 'custom_id':
                    return field.key.includes('deal.id');
                  default:
                    return false;
                }
              })
              .map(field => ({
                value: field.key,
                label: field.label
              }))
          };
        }
      }
    ]
  }
};