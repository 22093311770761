import React from 'react';
import { Handle } from '@xyflow/react';
import { Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NodeNumber from '../../../components/NodeNumber';
import '../../ModuleNode.css';

const GetDeal = ({ data, id }) => {
  return (
    <Card 
      size="small"
      title={<div className="node-title"><SearchOutlined /> Get Deal</div>}
      className={`module-node action-node ${!data.isConnected ? 'disconnected' : ''}`}
    >
      <NodeNumber nodeId={id} number={data.nodeNumber} />
      <Handle type="target" position="top" style={{ background: '#555' }} />
      
      <div className="node-content">
        {data.inputType && (
          <div>
            <span className="node-label">Input:</span> {data.inputType}
          </div>
        )}
      </div>

      <Handle type="source" position="bottom" style={{ background: '#555' }} />
    </Card>
  );
};

export default GetDeal;