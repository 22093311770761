export const config = {
  type: 'sendEmail',
  category: 'actions',
  subcategory: 'email',
  label: 'Send Email',
  icon: 'MailOutlined',

  defaultData: {
    label: 'Send Email',
    template: '',
    to: '',
    subject: '',
    body: ''
  },

  moduleConfig: {
    validateConnection: () => true,
    getTemplateOptions: () => [
      { value: 'welcome_email', label: 'Welcome Email' },
      { value: 'onboarding_email', label: 'Onboarding Email' },
      { value: 'follow_up_email', label: 'Follow-up Email' }
    ]
  },

  formConfig: {
    fields: [
      {
        name: 'template',
        label: 'Email Template',
        type: 'select',
        rules: [{ required: true }],
        getOptions: (nodeId, nodes, edges) => 
          config.moduleConfig.getTemplateOptions()
      },
      {
        name: 'to',
        label: 'To',
        type: 'select',
        rules: [{ required: true }],
        options: [
          { value: 'deal.email', label: 'Deal Email' },
          { value: 'deal.data.contact_email', label: 'Contact Email' }
        ]
      },
      {
        name: 'subject',
        label: 'Subject',
        type: 'input',
        rules: [{ required: true }]
      },
      {
        name: 'body',
        label: 'Body',
        type: 'textarea',
        rules: [{ required: true }]
      }
    ]
  }
};