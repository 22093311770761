import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import axiosPublic from '../api/axiosPublic';
import logo from '../assets/logos/logo.png';
import './Login.css';

function Login({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [autoLoginAttempted, setAutoLoginAttempted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const attemptAutoLogin = async () => {
      if (autoLoginAttempted) {
        console.log('Auto-login already attempted');
        return;
      }

      console.log('Session storage manualLogout:', sessionStorage.getItem('manualLogout'));
      console.log('Local storage user:', localStorage.getItem('user'));

      if (sessionStorage.getItem('manualLogout') === 'true') {
        console.log('Manual logout flag found, skipping auto-login');
        setAutoLoginAttempted(true);
        return;
      }

      const storedUser = localStorage.getItem('user');
      if (!storedUser) {
        console.log('No stored user found');
        setAutoLoginAttempted(true);
        return;
      }

      const user = JSON.parse(storedUser);
      console.log('Stored user settings:', user.settings);
      
      if (!user.settings?.auto_login) {
        console.log('Auto-login not enabled in settings');
        setAutoLoginAttempted(true);
        return;
      }

      try {
        console.log('Attempting auto-login for user:', user.user_id);
        setLoading(true);
        const response = await axiosPublic.post('/auth/auto-login', {
          user_id: user.user_id
        });
        
        if (response.data.access_token) {
          console.log('Auto-login successful');
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          setUser(response.data.user);
          navigate(response.data.user.is_registered ? '/main-menu' : '/complete-registration');
        }
      } catch (error) {
        console.error('Auto-login failed:', error);
        localStorage.removeItem('user');
      } finally {
        setLoading(false);
        setAutoLoginAttempted(true);
      }
    };

    attemptAutoLogin();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      const response = await axiosPublic.post('/auth/login', { email, password });
      
      if (response.data.access_token) {
        const { access_token, refresh_token, user } = response.data;
        
        const userWithAvatarColor = {
          ...user,
          avatar_color: user.settings?.avatar_color || '#f56a00'
        };

        localStorage.setItem('token', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('user', JSON.stringify(userWithAvatarColor));
        
        setUser(userWithAvatarColor);
        navigate(user.is_registered ? '/main-menu' : '/complete-registration');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Tuesday Logo" className="login-logo" />
      <h1>Digi-Tal ERP</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Sign in to your account</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? <Spin /> : 'Sign in'}
        </button>
      </form>
    </div>
  );
}

export default Login;