import React, { useState } from 'react';
import { Tabs } from 'antd';
import { ClockCircleOutlined, BarChartOutlined } from '@ant-design/icons';
import TaskWorkload from './TaskWorkload';
import TaskTimetracking from './TaskTimetracking';
import TaskData from './TaskData';
import './TaskDash.css';

const { TabPane } = Tabs;

const TaskDash = () => {
  const [activeTab, setActiveTab] = useState('data');

  return (
    <div className="task-dash">
      <h1 className="task-dash-title">Task Dashboard</h1>
      <div className="task-dash-content">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          tabPosition="left"
          className="task-dash-tabs"
        >
          <TabPane 
            tab={
              <span>
                <BarChartOutlined />
                <span className="tab-label">Data</span>
              </span>
            } 
            key="data"
          >
            <TaskData />
          </TabPane>

          <TabPane 
            tab={
              <span>
                <ClockCircleOutlined />
                <span className="tab-label">Timetracking</span>
              </span>
            } 
            key="timetracking"
          >
            <TaskTimetracking />
          </TabPane>

          <TabPane 
            tab={
              <span>
                <BarChartOutlined />
                <span className="tab-label">Workload</span>
              </span>
            } 
            key="workload"
          >
            <TaskWorkload />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default TaskDash;