import React, { useState, useEffect } from 'react';
import { Table, Button, Tag, Space, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined, HistoryOutlined, PoweroffOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axiosConfig';
import './FlowList.css';

const FlowList = ({ onEditFlow }) => {
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFlows();
  }, []);

  const fetchFlows = async () => {
    try {
      const response = await axios.get('/automation/flows');
      setFlows(response.data);
    } catch (error) {
      console.error('Error fetching flows:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (flowId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this flow?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete',
      okButtonProps: {
        type: 'primary',
        danger: true
      },
      cancelText: 'No, cancel',
      onOk: async () => {
        try {
          await axios.delete(`/automation/flows/${flowId}`);
          message.success('Flow deleted successfully');
          fetchFlows();
        } catch (error) {
          console.error('Error deleting flow:', error);
          message.error('Failed to delete flow');
        }
      },
    });
  };

  const handleToggle = async (flowId, currentStatus) => {
    try {
      const response = await axios.post(`/automation/flows/${flowId}/toggle`);
      message.success(response.data.message);
      fetchFlows(); // Refresh the list
    } catch (error) {
      console.error('Error toggling flow:', error);
      message.error('Failed to toggle flow status');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (isActive) => (
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'ACTIVE' : 'INACTIVE'}
        </Tag>
      ),
    },
    {
      title: 'Last Triggered',
      dataIndex: 'last_triggered',
      key: 'last_triggered',
      render: (date) => date ? new Date(date).toLocaleString() : 'Never',
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button 
            type={record.is_active ? "primary" : "default"}
            icon={<PoweroffOutlined />}
            onClick={() => handleToggle(record.id, record.is_active)}
          />
          <Button 
            type="text" 
            icon={<EditOutlined />} 
            onClick={() => onEditFlow(record)}
          />
          <Button 
            type="text" 
            icon={<HistoryOutlined />}
            onClick={() => navigate(`/flows/${record.id}/runs`)}
          />
          <Button 
            type="text" 
            danger 
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table 
      dataSource={flows} 
      columns={columns} 
      rowKey="id"
      loading={loading}
      className="flow-list-table"
    />
  );
};

export default FlowList;