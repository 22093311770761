import React from 'react';
import { Button, Select, Input, Space } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import './ConditionsBuilder.css';

const ConditionsBuilder = ({ value = [], onChange, config }) => {
  const { nodes, edges, nodeId } = config;
  const previousFields = config.getPreviousFormFields(nodeId, nodes, edges);

  const addCondition = () => {
    onChange([...value, {
      field: '',
      operator: 'equals',
      value: ''
    }]);
  };

  const removeCondition = (index) => {
    const newConditions = [...value];
    newConditions.splice(index, 1);
    onChange(newConditions);
  };

  const updateCondition = (index, updates) => {
    const newConditions = [...value];
    newConditions[index] = { ...newConditions[index], ...updates };
    onChange(newConditions);
  };

  const operators = [
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Not Equals' },
    { value: 'contains', label: 'Contains' },
    { value: 'greaterThan', label: 'Greater Than' },
    { value: 'lessThan', label: 'Less Than' }
  ];

  return (
    <div className="conditions-builder">
      {value.map((condition, index) => (
        <div key={index} className="condition-row">
          <div className="condition-row-inputs">
            <div className="condition-row-selects">
              <Select
                value={condition.field}
                onChange={value => updateCondition(index, { field: value })}
                placeholder="Select Field"
              >
                {previousFields.map(field => (
                  <Select.Option key={field.key} value={field.key}>
                    {field.label}
                  </Select.Option>
                ))}
              </Select>

              <Select
                value={condition.operator}
                onChange={value => updateCondition(index, { operator: value })}
              >
                {operators.map(op => (
                  <Select.Option key={op.value} value={op.value}>
                    {op.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            
            <div className="condition-row-value">
              <Input
                value={condition.value}
                onChange={e => updateCondition(index, { value: e.target.value })}
                placeholder="Value"
              />

              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeCondition(index)}
              />
            </div>
          </div>
        </div>
      ))}

      <Button
        type="dashed"
        onClick={addCondition}
        block
        icon={<PlusOutlined />}
      >
        Add Condition
      </Button>
    </div>
  );
};

export default ConditionsBuilder;