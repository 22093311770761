import React, { useState } from 'react';
import { Calendar, Badge } from 'antd';
import dayjs from 'dayjs';
import './TaskCalendar.css';

const TaskCalendar = ({ tasks }) => {
  const [currentDate, setCurrentDate] = useState(dayjs());

  const dateCellRender = (value) => {
    const listData = tasks.filter(task => dayjs(task.next_work_date).isSame(value, 'day'));
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.id}>
            <Badge status="default" text={item.description} />
          </li>
        ))}
      </ul>
    );
  };

  const onPanelChange = (value) => {
    setCurrentDate(value);
  };

  return (
    <div className="task-calendar">
      <Calendar
        dateCellRender={dateCellRender}
        value={currentDate}
        onPanelChange={onPanelChange}
      />
    </div>
  );
};

export default TaskCalendar;