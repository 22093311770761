import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Select, Button, message } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from '../api/axiosConfig';
import './UserProfile.css';
import UserAvatar from '../components/UserAvatar';

const { Option } = Select;

function UserProfile({ visible, onClose, profileData, countries, onProfileUpdate, onAvatarUpdate }) {
  const [form] = Form.useForm();
  const [showAvatarUpload, setShowAvatarUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingText, setLoadingText] = useState('Uploading...'); // Add this state

  const handleSubmit = async (values) => {
    try {
      const updatedData = {
        ...values,
        birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
        location_info: {
          city: values.city,
          country: values.country,
        },
      };
      await onProfileUpdate(updatedData);
      onClose(); // Close the modal only after updating the profile
    } catch (error) {
      message.error('Failed to update profile');
    }
  };

  const handleAvatarUpload = async (file) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      setIsUploading(true);
      setLoadingText('Uploading...');
      setShowAvatarUpload(false);
      const response = await axios.post('/user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.data.avatar_urls) {
        // Preserve the settings when updating the profile data
        onProfileUpdate({ 
          ...profileData, 
          avatar_urls: response.data.avatar_urls,
          settings: profileData.settings // Preserve settings
        });
        onAvatarUpdate();
        message.success('Avatar updated successfully');
      }
    } catch (error) {
      message.error('Failed to upload avatar');
    } finally {
      setIsUploading(false);
    }
  };

  const handleAvatarDelete = async () => {
    try {
      setIsUploading(true);
      setLoadingText('Removing...');
      setShowAvatarUpload(false);
      await axios.delete('/user/avatar');
      // Preserve the settings when updating the profile data
      onProfileUpdate({ 
        ...profileData, 
        avatar_urls: {},
        settings: profileData.settings // Preserve settings
      });
      onAvatarUpdate();
      message.success('Avatar removed successfully');
    } catch (error) {
      message.error('Failed to remove avatar');
    } finally {
      setIsUploading(false);
    }
  };

  // Reset form when profileData changes
  React.useEffect(() => {
    if (profileData) {
      form.setFieldsValue({
        ...profileData,
        birthday: profileData.birthday ? dayjs(profileData.birthday) : null,
        city: profileData.location_info?.city,
        country: profileData.location_info?.country,
      });
    }
  }, [profileData, form]);

  return (
    <Modal
      title="My Profile"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      className="profile-modal"
    >
      <div className="profile-content">
        <div className="profile-header">
          <div 
            className="avatar-section"
            onMouseEnter={() => !isUploading && setShowAvatarUpload(true)}
            onMouseLeave={() => !isUploading && setShowAvatarUpload(false)}
          >
            <UserAvatar 
              user={profileData}
              size={120}
              style={{
                width: '120px',
                height: '120px',
              }}
              wrapperStyle={{
                position: 'relative',
                display: 'inline-block'
              }}
              wrapperClassName="avatar-wrapper"
            />
            {isUploading && (
              <div className="avatar-loading-overlay show">
                <div className="loading-spinner"></div>
                <span className="loading-text">{loadingText}</span>
              </div>
            )}
            {profileData?.avatar_urls && Object.keys(profileData.avatar_urls).length > 0 ? (
              <div className={`avatar-upload-overlay ${showAvatarUpload ? 'show' : ''}`}>
                <div className="avatar-label-split">
                  <CameraOutlined className="overlay-icon" />
                </div>
                <div className="avatar-actions">
                  <div className="action-half left">
                    <input
                      type="file"
                      className="file-input"
                      onChange={(e) => {
                        if (e.target.files?.[0]) {
                          handleAvatarUpload(e.target.files[0]);
                        }
                      }}
                      accept="image/*"
                    />
                    <span className="action-text">Change</span>
                  </div>
                  <div className="action-half right" onClick={handleAvatarDelete}>
                    <span className="action-text">Remove</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`avatar-upload-overlay ${showAvatarUpload ? 'show' : ''}`}>
                <div className="avatar-label">
                  <input
                    type="file"
                    className="file-input"
                    onChange={(e) => {
                      if (e.target.files?.[0]) {
                        handleAvatarUpload(e.target.files[0]);
                      }
                    }}
                    accept="image/*"
                  />
                  <CameraOutlined className="overlay-icon" />
                  <span className="overlay-text">Add Image</span>
                </div>
              </div>
            )}
          </div>
          <div className="role-badge">
            {profileData?.permissions || 'User'}
          </div>
        </div>

        <Form form={form} onFinish={handleSubmit} layout="vertical" className="profile-form">
          <div className="form-row">
            <Form.Item name="name" label="Name" className="form-col">
              <Input />
            </Form.Item>
            <Form.Item name="surname" label="Surname" className="form-col">
              <Input />
            </Form.Item>
          </div>

          <Form.Item name="email" label="Email">
            <Input readOnly className="readonly-input" />
          </Form.Item>

          <div className="form-row">
            <Form.Item name="birthday" label="Birthday" className="form-col">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item name="city" label="City" className="form-col">
              <Input />
            </Form.Item>
          </div>

          <Form.Item name="country" label="Country">
            <Select
              showSearch
              placeholder="Select your country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map(country => (
                <Option key={country} value={country}>{country}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="form-actions">
            <Button type="primary" htmlType="submit">
              Update Profile
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default UserProfile;
