import React from 'react';
import { Handle } from '@xyflow/react';
import { Card } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import NodeNumber from '../../components/NodeNumber';

const ScheduleTrigger = ({ data, id }) => {
  const formatScheduleDisplay = () => {
    if (!data.schedule) return null;

    const time = data.timeUtc ? `at ${data.timeUtc} UTC` : '';
    
    switch (data.schedule) {
      case 'daily':
        return `Daily ${time}`;
      case 'weekly':
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return `Every ${days[data.dayOfWeek]} ${time}`;
      case 'monthly':
        let day = data.dayOfMonth;
        if (day === 'first') day = '1st';
        else if (day === 'last') day = 'last day';
        else day = `${day}${day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th'}`;
        return `Monthly on ${day} ${time}`;
      default:
        return data.schedule;
    }
  };

  return (
    <Card 
      size="small" 
      title={<><ClockCircleOutlined /> Schedule Trigger</>}
      style={{ width: 200 }}
      className="module-node trigger-node"
    >
      <NodeNumber nodeId={id} number={data.nodeNumber} />
      <Handle type="source" position="bottom" />
      <div>{data.label}</div>
      {data.schedule && (
        <div>
          <span className="node-label">Schedule:</span>
          {formatScheduleDisplay()}
        </div>
      )}
    </Card>
  );
};

export default ScheduleTrigger;