import React, { useState, useEffect } from 'react';
import { Drawer, Descriptions, Spin, Button, Input, Select, Switch, DatePicker, Tag, Modal } from 'antd';
import axios from '../api/axiosConfig';
import { dealDataConfig } from '../configs/DealDataConfig';
import { dealSoftwareConfig } from '../configs/DealSoftwareConfig';
import CustomScrollbar from '../components/CustomScrollbar';
import dayjs from 'dayjs';
import './DealDrawer.css';

const DealDrawer = ({ visible, onClose, dealId }) => {
  const [deal, setDeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingFields, setEditingFields] = useState({});
  const [changes, setChanges] = useState({});
  const [confirmVisible, setConfirmVisible] = useState(false);

  useEffect(() => {
    if (dealId && visible) {
      fetchDealData();
    }
  }, [dealId, visible]);

  const fetchDealData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/account/deals/${dealId}`);
      setDeal(response.data);
    } catch (error) {
      console.error('Error fetching deal data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, obj);
  };

  const handleEdit = (key, value) => {
    setEditingFields(prev => ({ ...prev, [key]: true }));
    
    // Handle nested data updates
    if (key.includes('.')) {
      const [mainKey, nestedKey] = key.split('.');
      if (mainKey === 'data') {
        setChanges(prev => {
          const newChanges = {
            ...prev,
            [key]: value  // Store the full key (e.g., 'data.value')
          };
          return newChanges;
        });
      }
    } else {
      setChanges(prev => {
        const newChanges = { ...prev, [key]: value };
        return newChanges;
      });
    }
  };

  const handleSave = async () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    try {
      const updatedChanges = {};
      
      // Process each change
      Object.entries(changes).forEach(([key, value]) => {
        if (key.startsWith('data.')) {
          // Handle nested data updates
          if (!updatedChanges.data) {
            updatedChanges.data = { ...(deal.data || {}) };
          }
          const nestedKey = key.split('.')[1];
          updatedChanges.data[nestedKey] = value;
        } else {
          // Handle direct field updates
          updatedChanges[key] = value;
        }
      });

      await axios.patch(`/account/deals/${dealId}`, updatedChanges);
      
      // Update local state
      setDeal(prev => ({
        ...prev,
        ...(updatedChanges.data ? { data: updatedChanges.data } : {}),
        ...updatedChanges
      }));
      
      setEditingFields({});
      setChanges({});
      setConfirmVisible(false);
    } catch (error) {
      console.error('Error updating deal data:', error);
    }
  };

  const handleCloseDrawer = () => {
    setEditingFields({});
    setChanges({});
    onClose();
  };

  const renderEditableField = (fieldConfig, value) => {
    const currentValue = changes[fieldConfig.key] ?? value;

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    // Find matching software config if it's a software field
    let config = fieldConfig;
    if (fieldConfig.key.startsWith('software.')) {
      const softwareKey = fieldConfig.key.split('.')[1];
      const softwareConfig = dealSoftwareConfig.find(c => c.key === softwareKey);
      if (softwareConfig) {
        config = { ...fieldConfig, options: softwareConfig.options };
      }
    }

    switch (config.type) {
      case 'text':
      case 'number':
        return (
          <Input
            type={config.type}
            value={currentValue}
            onChange={(e) => handleEdit(config.key, e.target.value)}
            className="editable-field-wrapper"
          />
        );

      case 'dropdown':
        return (
          <Select
            value={currentValue}
            onChange={(value) => handleEdit(config.key, value)}
            className="editable-field-wrapper"
            popupClassName="deal-drawer-select-dropdown"
          >
            {(config.options || []).map((option) => (
              <Select.Option 
                key={option.value} 
                value={option.value}
                className="custom-select-option"
                data-color={option.color}
              >
                <span style={{ 
                  backgroundColor: option.color,
                  color: option.color === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  width: '100%'
                }}>
                  {option.label}
                </span>
              </Select.Option>
            ))}
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue}
            onChange={(checked) => handleEdit(config.key, checked)}
            className="editable-field-wrapper"
          />
        );

      case 'date':
        return (
          <DatePicker
            value={currentValue ? dayjs(currentValue) : null}
            onChange={(date) => handleEdit(config.key, date ? date.format('YYYY-MM-DD') : null)}
            className="editable-field-wrapper"
          />
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderDealData = () => {
    return dealDataConfig.map(fieldConfig => {
      const value = getNestedValue(deal, fieldConfig.key);
      
      if (value === undefined) return null;

      return (
        <Descriptions.Item
          key={fieldConfig.key}
          label={fieldConfig.label}
          className="deal-data-item"
          labelStyle={{ width: '50%' }}
          contentStyle={{ width: '50%' }}
        >
          {renderEditableField(fieldConfig, value)}
        </Descriptions.Item>
      );
    }).filter(Boolean);
  };

  const renderChanges = () => {
    
    return Object.entries(changes).map(([key, newValue]) => {
      const fieldConfig = dealDataConfig.find(config => config.key === key);
      
      if (!fieldConfig) {
        console.warn(`No configuration found for key: ${key}`);
        return null;
      }

      const originalValue = getNestedValue(deal, key);
      
      if (fieldConfig.type === 'dropdown') {
        const getOptionColor = (value) => {
          const option = fieldConfig.options.find(opt => opt.value === value);
          return option ? option.color : '#d9d9d9';
        };
        
        return (
          <p key={key} className="change-item">
            <span className="change-label">{fieldConfig.label}:</span>
            <span 
              className="change-value"
              style={{ 
                backgroundColor: getOptionColor(originalValue),
                color: getOptionColor(originalValue) === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                padding: '2px 8px',
                borderRadius: '4px',
                display: 'inline-block'
              }}
            >
              {originalValue || 'N/A'}
            </span>
            <span className="change-arrow"> → </span>
            <span 
              className="change-value"
              style={{ 
                backgroundColor: getOptionColor(newValue),
                color: getOptionColor(newValue) === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                padding: '2px 8px',
                borderRadius: '4px',
                display: 'inline-block'
              }}
            >
              {newValue}
            </span>
          </p>
        );
      }
      
      return (
        <p key={key} className="change-item">
          <span className="change-label">{fieldConfig.label}:</span>
          <span className="change-value">{originalValue || 'N/A'}</span>
          <span className="change-arrow"> → </span>
          <span className="change-value">{newValue}</span>
        </p>
      );
    }).filter(Boolean); // Remove any null entries
  };

  const handleSoftwareEdit = (key, value) => {
    setChanges(prev => ({
      ...prev,
      software: {
        ...(prev.software || {}),
        [key]: value
      }
    }));
  };

  const renderSoftwareField = (fieldConfig) => {
    const currentValue = changes.software?.[fieldConfig.key] ?? deal?.software?.[fieldConfig.key];

    if (fieldConfig.type === 'dropdown') {
      return (
        <Select
          value={currentValue}
          onChange={(value) => handleSoftwareEdit(fieldConfig.key, value)}
          className="editable-field-wrapper"
          popupClassName="deal-drawer-select-dropdown"
        >
          {fieldConfig.options.map((option) => (
            <Select.Option 
              key={option.value} 
              value={option.value}
              className="custom-select-option"
              data-color={option.color}
            >
              <span style={{ 
                backgroundColor: option.color,
                color: option.color === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                padding: '2px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                width: '100%'
              }}>
                {option.label}
              </span>
            </Select.Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Input
          value={currentValue}
          onChange={(e) => handleSoftwareEdit(fieldConfig.key, e.target.value)}
          className="editable-field-wrapper"
        />
      );
    }
  };

  const drawerTitle = deal ? `${deal.name} - Data` : 'Deal Details';

  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        onClose={handleCloseDrawer}
        open={visible}
        width={550}
        className="deal-drawer"
        styles={{ padding: 0 }}
      >
        {loading ? (
          <div className="deal-drawer-loading">
            <Spin size="large" />
          </div>
        ) : (
          <CustomScrollbar className="deal-drawer-content">
            <div className="deal-drawer-inner">
              <Descriptions 
                column={1} 
                bordered={true}
                layout="horizontal" 
                className="deal-descriptions"
              >
                {renderDealData()}
              </Descriptions>
              <Button 
                onClick={handleSave} 
                className="save-changes-button" 
                disabled={Object.keys(changes).length === 0}
              >
                Save Changes
              </Button>
              <Button onClick={handleCloseDrawer} className="close-drawer-button">
                Close
              </Button>
            </div>
          </CustomScrollbar>
        )}
      </Drawer>
      <Modal
        title="Confirm Changes"
        open={confirmVisible}
        onOk={confirmSave}
        onCancel={() => setConfirmVisible(false)}
      >
        <p>Are you sure you want to save the following changes?</p>
        {renderChanges()}
      </Modal>
    </>
  );
};

export default DealDrawer;
