import React, { useState } from 'react';
import { Tag, Avatar, message } from 'antd';
import TaskBoard from './TaskBoards';
import { dealDataConfig } from '../configs/DealDataConfig';
import { boardStatusConfigs } from '../configs/BoardStatusConfig';
import UserAvatar from '../components/UserAvatar';
import AssigneeSelection from '../modals/AssigneeSelection';
import axios from '../api/axiosConfig';
import TaskDateEditor from '../components/TaskDateEditor';
import TaskStatusSelector from './components/TaskStatusSelector';
import TaskCompleteDrawer from './drawers/TaskCompleteDrawer';
import TimeTrackerCell from '../components/TimeTrackerCell';

const AndreOpgaverTasks = ({ users }) => {
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState(null);
  const [tasks, setTasks] = useState([]);
  const boardStatusOptions = boardStatusConfigs.andreOpgaver;
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false);
  const [taskToComplete, setTaskToComplete] = useState(null);
  const taskType = 'other'; // Backend expects 'other' for andre-opgaver

  const handleAssigneeUpdate = async (newAssignees) => {
    try {
      const response = await axios.put(`/work/update_assignees`, {
        task_id: selectedTaskForAssignee.id,
        task_type: selectedTaskForAssignee.task_type,
        assignees: newAssignees
      });
      
      if (response.status === 200) {
        message.success('Assignees updated successfully');
        // Update local tasks state
        setTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === selectedTaskForAssignee.id 
              ? { ...task, assigned_users: newAssignees }
              : task
          )
        );
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      message.error('Failed to update assignees');
    }
  };

  const handleTaskStatusChange = (updatedTask) => {
    // Update tasks state optimistically
    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === updatedTask.id
          ? { 
              ...task, 
              board_status: updatedTask.board_status,
              progress_status: updatedTask.progress_status,
              status: updatedTask.progress_status
            }
          : task
      )
    );

    // If this is a move to the last status, open the completion drawer
    if (updatedTask.board_status === boardStatusOptions[boardStatusOptions.length - 1].value) {
      const taskToComplete = {
        ...updatedTask,
        task_type: 'onboarding',  // This is an onboarding task
        isRecurring: false,      // Not a recurring task
        rollback: updatedTask.rollback
      };
      setTaskToComplete(taskToComplete);
      setIsCompleteDrawerVisible(true);
    }
  };

  const handleCompleteDrawerClose = () => {
    // If drawer is closed without submission, rollback the changes
    if (taskToComplete?.rollback) {
      taskToComplete.rollback();
    }
    setIsCompleteDrawerVisible(false);
    setTaskToComplete(null);
  };

  const handleCompleteDrawerSubmit = async (values) => {
    try {
      // Make the actual API call with completion data
      const response = await axios.put(`/taskboard/tasks/${taskType}/${taskToComplete.id}/status`, {
        board_status: taskToComplete.board_status,
        board_status_options: boardStatusOptions,
        completion_data: values
      });

      if (response.status === 200) {
        setIsCompleteDrawerVisible(false);
        setTaskToComplete(null);
      }
    } catch (error) {
      console.error('Error completing task:', error);
      message.error('Failed to complete task');
      // Rollback on error
      if (taskToComplete?.rollback) {
        taskToComplete.rollback();
      }
    }
  };

  const columns = [
    {
      title: 'Deal',
      dataIndex: 'deal_name',
      key: 'deal_name',
      sorter: (a, b) => a.deal_name.localeCompare(b.deal_name),
      fixed: 'left',
    },
    {
      title: 'Task',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text,
      fixed: 'left',
    },
    {
      title: 'Assignee',
      dataIndex: 'assigned_users',
      key: 'assignee',
      render: (assignedUsers, record) => {
        const assigneeIds = Array.isArray(assignedUsers) ? assignedUsers : [];
        
        return (
          <div 
            onClick={() => {
              setSelectedTaskForAssignee(record);
              setAssigneeModalVisible(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Avatar.Group 
              max={{
                count: 3,
                popover: { trigger: 'click' },
                style: { 
                  color: '#f56a00', 
                  backgroundColor: '#fde3cf',
                  cursor: 'pointer',
                }
              }}
            >
              {assigneeIds.map((userId) => {
                const user = users.find(u => u.user_id === userId);
                
                if (!user) return null;
                
                return (
                  <UserAvatar 
                    key={userId}
                    user={user}
                    size="medium"
                    style={{ 
                      border: '2px solid #fff'
                    }}
                  />
                );
              })}
            </Avatar.Group>
          </div>
        );
      },
    },
    {
      title: 'Work Date',
      dataIndex: 'work_date',
      key: 'work_date',
      render: (date, record) => (
        <TaskDateEditor
          date={date}
          dateType="work"
          taskId={record.id}
          taskType="andre-opgaver"
          onSuccess={(updatedTask) => {
            setTasks(prevTasks =>
              prevTasks.map(task =>
                task.id === updatedTask.id
                  ? { ...task, work_date: updatedTask.work_date }
                  : task
              )
            );
          }}
        />
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (date, record) => (
        <TaskDateEditor
          date={date}
          dateType="due"
          taskId={record.id}
          taskType="andre-opgaver"
          onSuccess={(updatedTask) => {
            setTasks(prevTasks =>
              prevTasks.map(task =>
                task.id === updatedTask.id
                  ? { ...task, due_date: updatedTask.due_date }
                  : task
              )
            );
          }}
        />
      ),
    },
    {
      title: 'Time Tracker',
      dataIndex: 'time_tracker',
      key: 'time_tracker',
      render: (_, record) => (
        <TimeTrackerCell 
          task={{
            id: record.id,
            task_type: 'onboarding',  // This is different from recurring tasks
            deal_id: record.deal_id,
            deal_name: record.deal_name,
            description: record.description
          }} 
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'board_status',
      key: 'board_status',
      render: (status, record) => (
        <TaskStatusSelector
          value={status}
          taskId={record.id}
          taskType={taskType}
          boardStatusOptions={boardStatusOptions}
          progressStatus={record.status} // Andre opgaver uses 'status' instead of 'progress_status'
          onSuccess={handleTaskStatusChange}
        />
      ),
    },
    {
      title: 'Progress',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const color = status === 'Completed' ? 'green' : status === 'In progress' ? 'orange' : 'red';
        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  const cardConfig = {
    showAssignee: true,
  };

  const relevantDealDataConfig = dealDataConfig.filter(item => 
    ['data.contact_name', 'data.company_name'].includes(item.key)
  );

  return (
    <>
      <TaskBoard
        title="Andre Opgaver Tasks"
        fetchUrl="/taskboard/tasks/onboarding/other"
        defaultView="kanban"
        columns={columns}
        boardStatusOptions={boardStatusOptions}
        cardConfig={cardConfig}
        dealDataConfig={relevantDealDataConfig}
        taskType="andre-opgaver"
        users={users}
        tasks={tasks}
        setTasks={setTasks}
      />
      
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={handleAssigneeUpdate}
        initialAssignees={selectedTaskForAssignee?.assigned_users || []}
        users={users}
      />

      <TaskCompleteDrawer
        visible={isCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={taskToComplete}
        onComplete={handleCompleteDrawerSubmit}
      />
    </>
  );
};

export default AndreOpgaverTasks;
