import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Button, Modal, Space, message } from 'antd';
import { FileSearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import './BillingSettings.css';

const { RangePicker } = DatePicker;

function BillingSettings() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [economicInvoice, setEconomicInvoice] = useState(null);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/invoice/all');
      setInvoices(response.data);
    } catch (error) {
      message.error('Failed to fetch invoices');
    }
    setLoading(false);
  };

  const handleReviewInvoice = async (invoice) => {
    setSelectedInvoice(invoice);
    try {
      const response = await axios.get(`/invoice/economic/${invoice.economic_invoice_id}`);
      setEconomicInvoice(response.data);
      setIsModalVisible(true);
    } catch (error) {
      message.error('Failed to fetch invoice details');
    }
  };

  const handleBookInvoice = async () => {
    try {
      await axios.post(`/invoice/book/${selectedInvoice.id}`);
      message.success('Invoice booked successfully');
      setIsModalVisible(false);
      fetchInvoices();
    } catch (error) {
      message.error('Failed to book invoice');
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      await axios.delete(`/invoice/${selectedInvoice.id}`);
      message.success('Invoice deleted successfully');
      setIsModalVisible(false);
      fetchInvoices();
    } catch (error) {
      message.error('Failed to delete invoice');
    }
  };

  const handleViewPDF = async () => {
    try {
      const response = await axios.get(`/invoice/economic/${selectedInvoice.economic_invoice_id}/pdf`, {
        responseType: 'blob'
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error('Error fetching PDF:', error);
      message.error('Failed to fetch invoice PDF');
    }
  };

  const formatNumber = (number) => {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = [
    { title: 'Invoice Number', dataIndex: 'invoice_number', key: 'invoice_number' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { 
      title: 'Total Amount, kr.', 
      dataIndex: 'total_amount', 
      key: 'total_amount',
      render: (text) => `${formatNumber(text)} u/moms`
    },
    { 
      title: 'Created At', 
      dataIndex: 'created_at', 
      key: 'created_at',
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => handleReviewInvoice(record)}>Review Invoice</Button>
      ),
    },
  ];

  return (
    <div className="billing-settings">
      <h2>Billing Overview</h2>
      <Table
        columns={columns}
        dataSource={invoices}
        rowKey="id"
        loading={loading}
        defaultSortOrder="ascend"
        sortDirections={['ascend', 'descend']}
        defaultSort={{ field: 'created_at', order: 'ascend' }}
      />

      <Modal
        title="Review Invoice"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="pdf" onClick={handleViewPDF} icon={<FilePdfOutlined />} className="billing-settings-pdf-button">
            View PDF
          </Button>,
          <Button key="delete" onClick={handleDeleteInvoice} className="billing-settings-delete-button">
            Delete Invoice
          </Button>,
          <Button key="book" onClick={handleBookInvoice} className="billing-settings-book-button">
            Book Invoice
          </Button>,
          <Button key="cancel" onClick={() => setIsModalVisible(false)} className="billing-settings-cancel-button">
            Cancel
          </Button>,
        ]}
        width={800}
      >
        {economicInvoice && (
          <div className="billing-settings-invoice-details">
            <h2>Invoice Details</h2>
            <p>Invoice Number: <strong>{economicInvoice.draftInvoiceNumber}</strong></p>
            <p>Customer: <strong>{economicInvoice.customer.name}</strong></p>
            <p>Invoice Date: <strong>{dayjs(economicInvoice.invoiceDate).format('YYYY-MM-DD')}</strong></p>
            <p>Due Date: <strong>{dayjs(economicInvoice.dueDate).format('YYYY-MM-DD')}</strong></p>
            <p>Total Amount (excl. VAT): <strong>{formatNumber(economicInvoice.grossAmount / 1.25)} kr.</strong></p>
            <p>Total Amount (incl. VAT): <strong>{formatNumber(economicInvoice.grossAmount)} kr.</strong></p>
            <h3>Line Items</h3>
            <Table
              dataSource={economicInvoice.lines}
              columns={[
                { title: 'Description', dataIndex: 'description', key: 'description' },
                { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
                { 
                  title: 'Unit Price (u/moms)', 
                  dataIndex: 'unitNetPrice', 
                  key: 'unitNetPrice',
                  render: (text) => `${formatNumber(text)} kr.`
                },
                { 
                  title: 'Total (u/moms)', 
                  dataIndex: 'totalNetAmount', 
                  key: 'totalNetAmount',
                  render: (text) => `${formatNumber(text)} kr.`
                },
              ]}
              pagination={false}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}

export default BillingSettings;