import { getDealFieldsArray } from '../../../shared/dealData.config';
import { getAvailableFieldsFromTrigger, isNodeConnectedToStart } from '../../../../utils/flowUtils';

export const config = {
  type: 'updateDeal',
  category: 'actions',
  subcategory: 'deal',
  label: 'Update Deal',
  icon: 'EditOutlined',

  // Default data when creating new node
  defaultData: {
    label: 'Update Deal',
    dealId: '',
    updateField: '',
    updateValue: ''
  },

  // Module behavior configuration
  moduleConfig: {
    getAvailableFields: () => getDealFieldsArray(),
    validateConnection: () => true,
    isConnectedToSource: (nodeId, nodes, edges) => {
      return isNodeConnectedToStart(nodeId, nodes, edges);
    },
    getDealIdOptions: () => [
      { value: 'trigger.deal_id', label: 'Trigger Deal ID' }
    ]
  },

  // Form configuration for ModuleConfigurator
  formConfig: {
    fields: [
      {
        name: 'dealId',
        label: 'Deal ID',
        type: 'dynamic',
        rules: [{ required: true }],
        getDynamicInput: (value, nodeId, nodes, edges) => {
          const availableFields = getAvailableFieldsFromTrigger(nodeId, nodes, edges);
          if (availableFields.length === 0) {
            return {
              type: 'select',
              options: [],
              disabled: true,
              placeholder: 'Connect to a deal trigger first'
            };
          }
          return {
            type: 'select',
            options: [
              { value: 'trigger_deal', label: 'Deal from trigger' },
              { value: 'custom', label: 'Custom Deal ID' }
            ]
          };
        }
      },
      {
        name: 'updateField',
        label: 'Field to Update',
        type: 'select',
        rules: [{ required: true }],
        options: getDealFieldsArray().map(field => ({
          value: field.key,
          label: field.label
        }))
      },
      {
        name: 'updateValue',
        label: 'New Value',
        type: 'dynamic',
        rules: [{ required: true }],
        getDynamicInput: (selectedField) => {
          const fieldConfig = getDealFieldsArray().find(f => f.key === selectedField);
          if (fieldConfig?.options) {
            return {
              type: 'select',
              options: fieldConfig.options.map(opt => ({ value: opt, label: opt }))
            };
          }
          if (fieldConfig?.type === 'boolean') {
            return {
              type: 'select',
              options: [
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]
            };
          }
          return {
            type: 'input',
            inputType: fieldConfig?.type === 'number' ? 'number' : 'text'
          };
        }
      }
    ]
  }
};