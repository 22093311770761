import React, { useState, useEffect } from 'react';
import { Table, Spin, Button, Modal, Tag, message } from 'antd';
import axios from '../api/axiosConfig';
import './Contacts.css';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import UserAvatar from '../components/UserAvatar';
import AssigneeSelection from '../modals/AssigneeSelection';

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchContacts();
    fetchUsers();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('/account/contacts');
      setContacts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const showAccountDetails = async (account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`);
      setSelectedAccount(response.data);
      setAccountModalVisible(true);
    } catch (error) {
      console.error('Error fetching account details:', error);
      message.error('Failed to fetch account details');
    }
  };

  const handleUpdateAircallUser = async (contact, selectedUsers) => {
    try {
      const userId = selectedUsers[0]; // Only take the first user since we only allow single selection
      await axios.patch(`/account/contacts/${contact.id}/aircall-user`, {
        user_id: userId
      });
      message.success('Aircall contact updated successfully');
      fetchContacts(); // Refresh the contacts list
    } catch (error) {
      console.error('Error updating aircall contact:', error);
      message.error('Failed to update aircall contact');
    }
  };

  const columns = [
    {
      title: 'Contacts',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => (
        <Tag color="orange" className="contact-tag">
          <UserOutlined /> {text}
        </Tag>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Accounts',
      dataIndex: 'accounts',
      key: 'accounts',
      render: (accounts) => (
        <span>
          {accounts.map((account, index) => (
            <Button key={account.id} type="link" className="tag-button" onClick={() => showAccountDetails(account)}>
              <Tag color="green" className="account-tag">
                <TeamOutlined /> {account.company_name}
              </Tag>
            </Button>
          ))}
        </span>
      ),
    },
    {
      title: 'Aircall Contact',
      dataIndex: 'aircall_user',
      key: 'aircall_user',
      render: (aircall_user, record) => (
        <div 
          style={{ cursor: 'pointer' }} 
          onClick={() => {
            setSelectedContact(record);
            setAssigneeModalVisible(true);
          }}
        >
          {aircall_user ? (
            <UserAvatar user={aircall_user} size={32} />
          ) : (
            <span style={{ color: '#999' }}>No Aircall Contact</span>
          )}
        </div>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="contacts-container">
      <h1>Contacts</h1>
      <Table
        columns={columns}
        dataSource={contacts}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        className="contacts-table"
      />
      <Modal
        title="Account Details"
        open={accountModalVisible}
        onCancel={() => setAccountModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setAccountModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedAccount && (
          <div>
            <p><strong>Company Name:</strong> {selectedAccount.company_name}</p>
            <p><strong>CVR:</strong> {selectedAccount.cvr || 'N/A'}</p>
            <p><strong>Address:</strong> {selectedAccount.address || 'N/A'}</p>
            <p><strong>Zipcode:</strong> {selectedAccount.zipcode || 'N/A'}</p>
            <p><strong>City:</strong> {selectedAccount.city || 'N/A'}</p>
          </div>
        )}
      </Modal>
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setSelectedContact(null);
        }}
        onSave={(selectedUsers) => {
          handleUpdateAircallUser(selectedContact, selectedUsers);
          setAssigneeModalVisible(false);
          setSelectedContact(null);
        }}
        initialAssignees={selectedContact?.aircall_user_id ? [selectedContact.aircall_user_id] : []}
        users={users}
        maxAssignees={1}
      />
    </div>
  );
};

export default Contacts;
