import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Input, message } from 'antd';
import { ExpandOutlined, FormOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './OnboardingDealData.css';

const OnboardingDealData = ({ dealId, initialDealData, onDataUpdate }) => {
  const [dealData, setDealData] = useState(filterDealData(initialDealData || {}));
  const [modalVisible, setModalVisible] = useState(false);
  const [editingDealData, setEditingDealData] = useState(null);
  const contentRef = useRef(null);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(true);

  function filterDealData(data) {
    const { 
      title, 
      products, 
      contact_email, 
      contact_name, 
      contact_phone, 
      currency, 
      status, 
      ...filteredData 
    } = data;
    return filteredData;
  }

  const getReadableKeyName = (key) => {
    return key.split('_').map((word, index) => 
      index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    ).join(' ');
  };

  const renderDealDataItem = (key, value) => (
    <div key={key} className="deal-data-item">
      <span className="deal-data-key">{getReadableKeyName(key)}</span>
      <span className="deal-data-value">{typeof value === 'boolean' ? value.toString() : value}</span>
    </div>
  );

  const handleDealDataEdit = async () => {
    try {
      const response = await axios.put(`/onboarding/deal-data/${dealId}`, editingDealData);
      if (response.data.success) {
        message.success('Deal data updated successfully');
        setDealData(filterDealData(editingDealData));
        onDataUpdate(editingDealData);
        setModalVisible(false);
      }
    } catch (error) {
      console.error('Error updating deal data:', error);
      message.error('Failed to update deal data');
    }
  };

  const openModal = () => {
    setEditingDealData({ ...dealData });
    setModalVisible(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setShowTopShadow(scrollTop > 0);
        setShowBottomShadow(scrollTop + clientHeight < scrollHeight);
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="deal-data-section">
      <h3><FormOutlined /> Deal Data</h3>
      <div 
        ref={contentRef}
        className={`deal-data-content ${showTopShadow ? 'top-shadow' : ''} ${showBottomShadow ? 'bottom-shadow' : ''}`}
      >
        {Object.entries(dealData).map(([key, value]) => renderDealDataItem(key, value))}
      </div>
      <Button 
        icon={<ExpandOutlined />} 
        onClick={openModal}
        className="expand-button"
      >
        Expand Deal Data
      </Button>
      <Modal
        title="Edit Deal Data"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleDealDataEdit}>
            Save Changes
          </Button>
        ]}
        width={1000}
      >
        <div className="deal-data-modal-content">
          {Object.entries(editingDealData || {}).map(([key, value]) => (
            <div key={key} className="deal-data-modal-item">
              <span className="deal-data-modal-key">{getReadableKeyName(key)}</span>
              <Input
                value={typeof value === 'boolean' ? value.toString() : value}
                onChange={(e) => setEditingDealData(prev => ({ ...prev, [key]: e.target.value }))}
                className="deal-data-modal-input"
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default OnboardingDealData;