import React, { useState, useEffect, useCallback } from 'react';
import { Form, InputNumber, Button, Checkbox, Alert, Drawer, Timeline, Typography, Spin, Select, Input, Tooltip, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import axios from '../../api/axiosConfig';
import './TaskCompleteDrawer.css';

dayjs.extend(duration);
const { Text } = Typography;
const { TextArea } = Input;

const TIME_REASONS = [
  { value: 'complex_case', label: 'Complex Case' },
  { value: 'technical_issues', label: 'Technical Issues' },
  { value: 'client_communication', label: 'Extra Client Communication' },
  { value: 'data_quality', label: 'Poor Data Quality' },
  { value: 'training', label: 'Training/Learning' },
  { value: 'documentation', label: 'Extra Documentation Required' },
];

const TaskCompleteDrawer = ({ visible, onClose, task, onComplete }) => {
  const [form] = Form.useForm();
  const [timeHistory, setTimeHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paySlipsDifference, setPaySlipsDifference] = useState(false);
  const [paySlipsValue, setPaySlipsValue] = useState(null);
  const [showTimeComment, setShowTimeComment] = useState(false);

  const contractPaySlips = 8; // Move this to a constant for easier reference

  const fetchTimeHistory = useCallback(async () => {
    if (!task) return;
    
    setLoading(true);
    try {
      const response = await axios.get(`/timetracker/task-time-history/${task.id}/${task.task_type}`);
      setTimeHistory(response.data);
      
      // Convert total duration from seconds to minutes and round to nearest minute
      const totalMinutes = Math.round(response.data.total_duration / 60);
      
      // Set the form value
      form.setFieldsValue({
        timeSpent: totalMinutes
      });
      
      console.log('Setting time spent:', totalMinutes); // Debug log
    } catch (error) {
      console.error('Failed to fetch time history:', error);
    } finally {
      setLoading(false);
    }
  }, [task, form]);

  // Add reset function
  const resetDrawerState = useCallback(() => {
    form.resetFields();
    setTimeHistory(null);
    setLoading(false);
    setPaySlipsDifference(false);
    setPaySlipsValue(null);
    setShowTimeComment(false);
  }, [form]);

  // Handle drawer close
  const handleClose = useCallback(() => {
    resetDrawerState();
    onClose();
  }, [onClose, resetDrawerState]);

  useEffect(() => {
    if (visible && task) {
      form.resetFields();
      setPaySlipsValue(null);
      setPaySlipsDifference(false);
      fetchTimeHistory();
    }
    // Reset state when drawer becomes invisible
    if (!visible) {
      resetDrawerState();
    }
  }, [visible, task, form, fetchTimeHistory, resetDrawerState]);

  const formatDuration = (seconds) => {
    return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
  };

  const renderTimeHistory = () => {
    if (!timeHistory || !timeHistory.tracking_history.length) {
      return null;
    }

    const timelineItems = timeHistory.tracking_history.map((session, index) => ({
      key: session.id,
      children: (
        <>
          <Text>Session {index + 1}: {dayjs(session.start_time).format('YYYY-MM-DD HH:mm:ss')}</Text>
          <br />
          <Text type="secondary">Duration: {formatDuration(session.duration)}</Text>
          
          {session.pauses.length > 0 && (
            <div style={{ marginLeft: 24, marginTop: 8 }}>
              <Text type="secondary">Pauses:</Text>
              {session.pauses.map((pause, pIndex) => (
                <div key={pIndex}>
                  <Text type="secondary">
                    {dayjs(pause.start).format('HH:mm:ss')} - 
                    {pause.end ? dayjs(pause.end).format('HH:mm:ss') : 'ongoing'}
                    {pause.duration && ` (${formatDuration(pause.duration)})`}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </>
      )
    }));

    return (
      <div style={{ marginBottom: 24 }}>
        <Text strong>Time Tracking History:</Text>
        <Timeline 
          style={{ marginTop: 16 }}
          items={timelineItems}
        />
      </div>
    );
  };

  const onFinish = (values) => {
    onComplete(values);
  };

  const onPaySlipsChange = (value) => {
    setPaySlipsValue(value);
    setPaySlipsDifference(value !== null && value !== contractPaySlips);
  };

  const renderLonTaskFields = () => (
    <>
      <Form.Item name="paySlips" label="Number of Pay Slips Completed">
        <InputNumber onChange={onPaySlipsChange} min={0} />
      </Form.Item>
      <Alert
        message="Contract Information"
        description={`Number of pay slips in contract: ${contractPaySlips}`}
        type={paySlipsValue === contractPaySlips ? "success" : "info"}
        showIcon
        className="contract-alert"
      />
      {paySlipsValue === contractPaySlips && (
        <Alert
          message="Pay Slips Match Contract"
          description="The number of pay slips completed matches the contract."
          type="success"
          showIcon
          className="contract-alert"
        />
      )}
      {paySlipsDifference && (
        <Alert
          message="Pay Slip Difference Detected"
          description="The number of pay slips completed differs from the contract."
          type="warning"
          showIcon
          className="contract-alert"
        />
      )}
      {paySlipsDifference && (
        <Form.Item name="updateInvoice" valuePropName="checked">
          <Checkbox>Use this number for next invoice</Checkbox>
        </Form.Item>
      )}
      {paySlipsDifference && (
        <Form.Item name="updateContract" valuePropName="checked">
          <Checkbox>Update contract with the new number of pay slips as default going forward</Checkbox>
        </Form.Item>
      )}
    </>
  );

  const renderTimeCommentSection = () => {
    if (!showTimeComment) {
      return (
        <Tooltip title="Add Time Comment">
          <Button 
            type="text" 
            icon={<PlusOutlined />} 
            onClick={() => setShowTimeComment(true)}
            className="add-time-comment-btn"
          >
            Add Comment
          </Button>
        </Tooltip>
      );
    }

    return (
      <Space direction="vertical" className="time-comment-section">
        <Form.Item name="timeReason" label="Reason">
          <Select
            placeholder="Select a reason"
            options={TIME_REASONS}
            allowClear
          />
        </Form.Item>
        <Form.Item name="timeComment" label="Additional Comments">
          <TextArea 
            rows={2} 
            placeholder="Add any additional context..."
            maxLength={500}
            showCount
          />
        </Form.Item>
      </Space>
    );
  };

  return (
    <Drawer
      title={`Complete ${task?.task_type.charAt(0).toUpperCase() + task?.task_type.slice(1)} Task`}
      placement="right"
      onClose={handleClose}
      open={visible}
      width={400}
    >
      <div className="task-complete-drawer">
        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : (
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item 
              name="timeSpent"
              label="Time Spent (minutes)"
              rules={[{ required: true, message: 'Please input time spent' }]}
            >
              <InputNumber min={0} />
            </Form.Item>
            {renderTimeCommentSection()}
            {task?.isRecurring && task?.recurringType === 'lon' && renderLonTaskFields()}
            {renderTimeHistory()}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </Drawer>
  );
};

export default TaskCompleteDrawer;
