import React from 'react';
import { Select } from 'antd';
import axios from '../../api/axiosConfig';
import CustomScrollbar from '../../components/CustomScrollbar';
import './TaskStatusSelector.css';

const TaskStatusSelector = ({ 
  value, 
  taskId, 
  taskType, 
  boardStatusOptions, 
  onSuccess,
  progressStatus
}) => {
  const handleStatusChange = async (newStatus) => {
    // Store original values for potential rollback
    const originalBoardStatus = value;
    const originalProgressStatus = progressStatus;

    // Check if moving to last status
    const isMovingToLastStatus = newStatus === boardStatusOptions[boardStatusOptions.length - 1].value;

    // Determine new progress status
    let newProgressStatus;
    if (newStatus === boardStatusOptions[0].value) {
      newProgressStatus = 'Not started';
    } else if (newStatus === boardStatusOptions[boardStatusOptions.length - 1].value) {
      newProgressStatus = 'Completed';
    } else {
      const isFromMiddleStatus = value !== boardStatusOptions[0].value && 
                                value !== boardStatusOptions[boardStatusOptions.length - 1].value;
      const isToMiddleStatus = newStatus !== boardStatusOptions[0].value && 
                              newStatus !== boardStatusOptions[boardStatusOptions.length - 1].value;
      newProgressStatus = 'In progress';
    }

    // Optimistically update frontend
    onSuccess({
      id: taskId,
      board_status: newStatus,
      progress_status: newProgressStatus,
      isOptimistic: true, // Flag to indicate this is an optimistic update
      rollback: () => {
        // Rollback function to revert changes
        onSuccess({
          id: taskId,
          board_status: originalBoardStatus,
          progress_status: originalProgressStatus
        });
      }
    });

    // If moving to last status, we'll handle the API call after TaskCompleteDrawer submission
    if (isMovingToLastStatus) {
      return;
    }

    // For other status changes, proceed with API call immediately
    try {
      const response = await axios.put(`/taskboard/tasks/${taskType}/${taskId}/status`, {
        board_status: newStatus,
        board_status_options: boardStatusOptions
      });

      if (response.status !== 200) {
        throw new Error('Failed to update task status');
      }
    } catch (error) {
      console.error('Error updating task status:', error);
      // Revert the optimistic update
      onSuccess({
        id: taskId,
        board_status: originalBoardStatus,
        progress_status: originalProgressStatus
      });
    }
  };

  const getOptionStyle = (optionValue) => {
    const option = boardStatusOptions.find(opt => opt.value === optionValue);
    return {
      backgroundColor: option?.color || '#d9d9d9',
      color: '#fff',
      width: '100%',
      padding: '2px 8px',
      borderRadius: '4px',
      marginRight: '24px',
      transition: 'all 0.3s ease'
    };
  };

  // Custom dropdown render function to use CustomScrollbar
  const dropdownRender = (menu) => (
    <CustomScrollbar className="task-status-scrollbar">
      {menu}
    </CustomScrollbar>
  );

  return (
    <Select
      value={value}
      onChange={handleStatusChange}
      className="task-status-selector"
      popupClassName="task-status-dropdown"
      dropdownStyle={{ 
        backgroundColor: 'var(--background-color)',
        minWidth: '200px'
      }}
      dropdownRender={dropdownRender}
      popupMatchSelectWidth={false}
      style={{ width: 'fit-content', minWidth: '100%' }}
    >
      {boardStatusOptions.map(option => (
        <Select.Option 
          key={option.value} 
          value={option.value}
          className="custom-select-option"
        >
          <div style={getOptionStyle(option.value)}>
            {option.value}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default TaskStatusSelector;