import React, { memo } from 'react';
import { Handle } from '@xyflow/react';
import { Card, Tooltip } from 'antd';
import { BranchesOutlined } from '@ant-design/icons';
import { getDealFieldsArray } from '../shared/dealData.config';
import '../ModuleNode.css';
import NodeNumber from '../../components/NodeNumber';

// Define operators that require value input(s)
export const operatorValueRequirements = {
  'is': { valueInputs: 1 },
  'is_not': { valueInputs: 1 },
  'has_changed_to': { valueInputs: 1 },
  'is_more_than': { valueInputs: 1 },
  'is_more_than_equal': { valueInputs: 1 },
  'is_less_than': { valueInputs: 1 },
  'is_less_than_equal': { valueInputs: 1 },
  'was': { valueInputs: 1 },
  'was_more_than': { valueInputs: 1 },
  'is_between': { valueInputs: 2 },
  'is_not_between': { valueInputs: 2 },
  'has_changed': { valueInputs: 0 },
  'is_empty': { valueInputs: 0 },
  'is_not_empty': { valueInputs: 0 }
};

// Define available fields based on source node type
export const getAvailableFields = (sourceNode) => {
  if (!sourceNode) return [];

  const fieldMappings = {
    eventTrigger: {
      'deal_data_updated': getDealFieldsArray(),
      'task_data_updated': [
        { key: 'task.status', label: 'Status', type: 'string', options: ['pending', 'in_progress', 'completed'] },
        { key: 'task.due_date', label: 'Due Date', type: 'date' },
        { key: 'task.priority', label: 'Priority', type: 'string', options: ['low', 'medium', 'high'] }
      ]
    }
  };

  return fieldMappings[sourceNode.type]?.[sourceNode.data?.eventType] || [];
};

const DataCondition = memo(({ data, selected, id }) => {
  const operatorDisplayNames = {
    'is': 'is',
    'is_not': 'is not',
    'has_changed': 'has changed',
    'has_changed_to': 'has changed to',
    'is_more_than': 'is more than',
    'is_more_than_equal': 'is more than or equal',
    'is_less_than': 'is less than',
    'is_less_than_equal': 'is less than or equal',
    'is_empty': 'is empty',
    'is_not_empty': 'is not empty',
    'was': 'was',
    'was_more_than': 'was more than',
    'is_between': 'is between',
    'is_not_between': 'is not between'
  };

  const formatSingleCondition = (c, index, array) => {
    const parts = [];
    
    // Format the field name to be more readable
    const fieldName = c.dataField?.split('.')?.pop() || c.dataField;
    
    // Build the condition string with spans for styling
    parts.push(<span className="node-value">{fieldName}</span>);
    parts.push(operatorDisplayNames[c.operator] || c.operator);
    
    if (c.value !== undefined && c.value !== null && 
        !['has_changed', 'is_empty', 'is_not_empty'].includes(c.operator)) {
      // Handle operators that use two values
      if (['is_between', 'is_not_between'].includes(c.operator)) {
        const [value1, value2] = Array.isArray(c.value) ? c.value : [c.value, c.value2];
        parts.push(
          <>
            <span className="node-value">{value1}</span>
            <span> and </span>
            <span className="node-value">{value2}</span>
          </>
        );
      } else {
        parts.push(<span className="node-value">{c.value}</span>);
      }
    }

    // Add logic operator if not the last condition
    if (index < array.length - 1) {
      parts.push(<span className="node-logic"> {array[index + 1].logic}</span>);
    }

    return parts.filter(Boolean).map((part, i) => 
      typeof part === 'string' ? <span key={i}>{` ${part} `}</span> : <span key={i}>{part}</span>
    );
  };

  const formatCondition = (condition) => {
    if (!condition?.conditions?.length) return '';

    return condition.conditions
      .filter(c => c.dataField && c.operator)
      .map((c, index, array) => (
        <div key={index} className="condition-line">
          {formatSingleCondition(c, index, array)}
        </div>
      ));
  };

  const conditionText = formatCondition(data.condition);

  return (
    <Card 
      size="small" 
      title={
        <div className="node-title">
          <BranchesOutlined /> Data Condition
        </div>
      }
      className={`module-node condition-node ${selected ? 'selected' : ''} ${!data.isConnected ? 'disconnected' : ''}`}
      style={{ width: 200 }}
    >
      <NodeNumber nodeId={id} number={data.nodeNumber} />
      
      <Handle 
        type="target" 
        position="top" 
        style={{ background: '#555' }}
      />
      
      <div className="node-content">
        <div className="node-label">{data.label}</div>
        {data.condition && (
          <div className="node-condition">
            <span className="condition-prefix">If: </span>
            <div className="condition-text">
              {data.condition.conditions
                .filter(c => c.dataField && c.operator)
                .map((c, index, array) => (
                  <div key={index} className="condition-line">
                    {formatSingleCondition(c, index, array)}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <Handle 
        type="source" 
        position="bottom" 
        id="true"
        style={{ background: '#4CAF50', left: '25%' }}
      />
      <Handle 
        type="source" 
        position="bottom" 
        id="false"
        style={{ background: '#f44336', left: '75%' }}
      />
    </Card>
  );
});

DataCondition.displayName = 'DataCondition';

export default DataCondition;