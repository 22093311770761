import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Typography, Input, Tag } from 'antd';
import axios from '../api/axiosConfig';
import './Revibot.css';
import dayjs from 'dayjs';

const { Title } = Typography;

const compareDates = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return dayjs(a, 'DD-MM-YYYY').unix() - dayjs(b, 'DD-MM-YYYY').unix();
};

function Revibot() {
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [syncStatus, setSyncStatus] = useState({});
  const [tableKey, setTableKey] = useState(0);

  const fetchSyncStatus = async () => {
    try {
      const response = await axios.get('/revibot/sync/status');
      setSyncStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch sync status:', error);
    }
  };

  useEffect(() => {
    fetchSyncStatus();
  }, []);

  useEffect(() => {
    setTableKey(prev => prev + 1);
    setCustomerData([]);
  }, [selectedCard]);

  const statsData = [
    {
      key: 'kundeadgange',
      label: 'Kundeadgange',
      value: syncStatus.customer_access ? (
        <Tag color="green">
          Sidst ændret: {dayjs(syncStatus.customer_access).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">Ikke synkroniseret</Tag>
      )
    },
    {
      key: 'momsindberetning',
      label: 'Momsindberetning',
      value: syncStatus.moms ? (
        <Tag color="green">
          Sidst ændret: {dayjs(syncStatus.moms).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">Ikke synkroniseret</Tag>
      )
    },
    {
      key: 'private_aarsopgoerelser',
      label: 'Personlige Selvangivelser',
      value: syncStatus.personlige ? (
        <Tag color="green">
          Sidst ændret: {dayjs(syncStatus.personlige).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">Ikke synkroniseret</Tag>
      )
    },
    {
      key: 'erhvervs_selvangivelser',
      label: 'Erhvervs Selvangivelser',
      value: syncStatus.selskaber ? (
        <Tag color="green">
          Sidst ændret: {dayjs(syncStatus.selskaber).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">Ikke synkroniseret</Tag>
      )
    },
    {
      key: 'udloebsdato_cpr',
      label: 'Udløbsdato (CPR)',
      value: syncStatus.udloebsdato_cpr ? (
        <Tag color="green">
          Sidst ændret: {dayjs(syncStatus.udloebsdato_cpr).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">Ikke synkroniseret</Tag>
      )
    },
    {
      key: 'transfer_pricing',
      label: 'Transfer Pricing',
      value: syncStatus.transfer_pricing ? (
        <Tag color="green">
          Sidst ændret: {dayjs(syncStatus.transfer_pricing).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">Ikke synkroniseret</Tag>
      )
    }
  ];

  const columns = [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '15%',
    },
    {
      title: 'Virksomhed',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Adgange',
      dataIndex: 'access_types',
      key: 'access_types',
      width: '40%',
      render: (access_types) => Array.isArray(access_types) ? access_types.join(', ') : '',
    },
    {
      title: 'Dato',
      dataIndex: 'latest_date',
      key: 'latest_date',
      width: '15%',
      sorter: (a, b) => compareDates(a.latest_date, b.latest_date),
    },
  ];

  const getMomsColumns = () => [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '15%',
    },
    {
      title: 'Virksomhed',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Fra',
      dataIndex: 'periodFrom',
      key: 'periodFrom',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sorter: (a, b) => compareDates(a.periodFrom, b.periodFrom),
    },
    {
      title: 'Til',
      dataIndex: 'periodTo',
      key: 'periodTo',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sorter: (a, b) => compareDates(a.periodTo, b.periodTo),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sorter: (a, b) => compareDates(a.deadline, b.deadline),
    }
  ];

  const getPrivateColumns = () => [
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Er indberettet',
      dataIndex: 'isReported',
      key: 'isReported',
      width: '25%',
      render: (isReported) => (
        <Tag color={isReported ? 'green' : 'red'}>
          {isReported ? 'TRUE' : 'FALSE'}
        </Tag>
      ),
      filters: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false }
      ],
      onFilter: (value, record) => record.isReported === value,
    },
    {
      title: 'År',
      dataIndex: 'year',
      key: 'year',
      width: '25%',
    },
  ];

  const getErhvervsColumns = () => [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '15%',
    },
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      width: '45%',
    },
    {
      title: 'Er indberettet',
      dataIndex: 'isReported',
      key: 'isReported',
      width: '20%',
      render: (isReported) => (
        <Tag color={isReported ? 'green' : 'red'}>
          {isReported ? 'TRUE' : 'FALSE'}
        </Tag>
      ),
      filters: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false }
      ],
      onFilter: (value, record) => record.isReported === value,
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sorter: (a, b) => compareDates(a.deadline, b.deadline),
    },
  ];

  const getCPRColumns = () => [
    {
      title: 'CPR',
      dataIndex: 'cpr',
      key: 'cpr',
      width: '20%',
    },
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      width: '55%',
    },
    {
      title: 'Udløbsdato',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      width: '25%',
      render: (date) => date || 'Ikke angivet',
      sorter: (a, b) => compareDates(a.expirationDate, b.expirationDate),
    },
  ];

  const getTransferPricingColumns = () => [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '10%',
    },
    {
      title: 'Virksomhed',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: '15%',
      render: (date) => date || 'Ikke angivet',
      sorter: (a, b) => compareDates(a.deadline, b.deadline),
    },
    {
      title: 'Dokumenter',
      dataIndex: 'documents',
      key: 'documents',
      width: '10%',
      sorter: (a, b) => a.documents - b.documents,
    },
    {
      title: 'TP Sidste År',
      dataIndex: 'tp_last_year',
      key: 'tp_last_year',
      width: '10%',
      filters: [
        { text: 'Ja', value: 'Ja' },
        { text: 'Nej', value: 'Nej' }
      ],
      onFilter: (value, record) => record.tp_last_year === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status) => (
        <Tag color={status === 'Ikke indberettet' ? 'red' : 'green'}>
          {status}
        </Tag>
      ),
      filters: [
        { text: 'Ikke indberettet', value: 'Ikke indberettet' },
        { text: 'Indberettet', value: 'Indberettet' }
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Selv Indb',
      dataIndex: 'self_reported',
      key: 'self_reported',
      width: '15%',
      render: (status) => (
        <Tag color={status === 'Ikke indberettet' ? 'red' : 'green'}>
          {status}
        </Tag>
      ),
      filters: [
        { text: 'Ikke indberettet', value: 'Ikke indberettet' },
        { text: 'Indberettet', value: 'Indberettet' }
      ],
      onFilter: (value, record) => record.self_reported === value,
    },
  ];

  const getColumns = () => {
    switch(selectedCard) {
      case 'momsindberetning':
        return getMomsColumns();
      case 'private_aarsopgoerelser':
        return getPrivateColumns();
      case 'erhvervs_selvangivelser':
        return getErhvervsColumns();
      case 'udloebsdato_cpr':
        return getCPRColumns();
      case 'transfer_pricing':
        return getTransferPricingColumns();
      default:
        return columns;
    }
  };

  const fetchCustomerData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/customer-access');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch customer data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMomsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/moms-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch moms data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPrivateData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/private-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch private data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchErhvervsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/erhvervs-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch erhvervs data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCPRData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/cpr-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch CPR data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransferPricingData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/transfer-pricing-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch transfer pricing data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (key) => {
    setSelectedCard(key);
    switch(key) {
      case 'kundeadgange':
        fetchCustomerData();
        break;
      case 'momsindberetning':
        fetchMomsData();
        break;
      case 'private_aarsopgoerelser':
        fetchPrivateData();
        break;
      case 'erhvervs_selvangivelser':
        fetchErhvervsData();
        break;
      case 'udloebsdato_cpr':
        fetchCPRData();
        break;
      case 'transfer_pricing':
        fetchTransferPricingData();
        break;
    }
  };

  const filteredData = customerData.filter(item => {
    const searchLower = searchText.toLowerCase();
    
    switch(selectedCard) {
      case 'private_aarsopgoerelser':
        return item.name?.toLowerCase().includes(searchLower);
      default:
        return (
          item.cvr?.toLowerCase().includes(searchLower) ||
          item.name?.toLowerCase().includes(searchLower)
        );
    }
  });

  // Add this new function to handle row keys
  const getRowKey = (record) => {
    switch(selectedCard) {
      case 'private_aarsopgoerelser':
        return record.name;
      case 'udloebsdato_cpr':
        return `${record.cvr}-${record.cpr}`; // Combine CVR and CPR for unique key
      default:
        return record.cvr;
    }
  };

  return (
    <div className="revibot-container">
      <Row gutter={[16, 16]}>
        {statsData.map((stat) => (
          <Col xs={24} sm={12} md={8} key={stat.key || stat.label}>
            <Card 
              className={`stat-card ${selectedCard === stat.key ? 'selected' : ''}`}
              onClick={() => handleCardClick(stat.key)}
            >
              <h3 className="stat-label">{stat.label}</h3>
              <div className="stat-value">{stat.value}</div>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedCard && (
        <div className="customer-table-container">
          <Title level={4} style={{ marginTop: 24, marginBottom: 16 }}>
            {statsData.find(s => s.key === selectedCard)?.label} Oversigt
          </Title>
          <Input.Search
            placeholder="Søg efter CVR, Virksomhed eller Navn"
            style={{ marginBottom: 16 }}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear
          />
          <Table 
            key={tableKey}
            columns={getColumns()}
            dataSource={filteredData}
            loading={loading}
            rowKey={getRowKey}
            pagination={{ pageSize: 15 }}
            scroll={{ x: true }}
          />
        </div>
      )}
    </div>
  );
}

export default Revibot;