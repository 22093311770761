import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoNotificationsOutline, IoPersonAddOutline, IoTrophyOutline } from 'react-icons/io5';
import { BsSun, BsMoon, BsGrid } from 'react-icons/bs';
import { Dropdown, Form, Avatar, Button, message, Tooltip } from 'antd';
import { FaUser, FaUsers, FaCog, FaSignOutAlt, FaTools, FaRobot, FaBuilding } from 'react-icons/fa';
import { hasRequiredPermission } from '../utils/permissionUtils';
import UserManagementModal from '../modals/UserManagement';
import UserSettings from '../modals/UserSettings';
import ActivityScreen from '../modals/ActivityScreen';
import axios from '../api/axiosConfig';
import axiosPublic from '../api/axiosPublic';
import './Header.css';
import dayjs from 'dayjs';
import { Layout } from 'antd';
import logoLight from '../assets/logos/tuesday_x500_b.png';
import logoDark from '../assets/logos/tuesday_x500_w.png';
import LiveTimer from './LiveTimer';
import { TimeTrackerContext } from '../contexts/TimeTrackerContext';
import UserProfile from '../modals/UserProfile';
import UserAvatar from '../components/UserAvatar';
import Settings from '../settings/Settings';
import NotificationsModal from '../modals/Notifications';
import AchievementsModal from '../modals/Achievements';

const { Header: AntHeader } = Layout;

function Header({ theme, toggleTheme, onUpdateUsers }) {
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const userMenuRef = useRef(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { isRunning } = useContext(TimeTrackerContext);
  const [showDigiTalModal, setShowDigiTalModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showAchievements, setShowAchievements] = useState(false);

  // Combined fetch for all users
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('/user');
      const allUsers = response.data; // Don't filter out current user
      const onlineUsers = response.data.filter(user => 
        user.user_id !== currentUser?.user_id && 
        (user.status === 'online' || user.status === 'inactive')
      );
      
      setAllUsers(allUsers);
      setOnlineUsers(onlineUsers);
      onUpdateUsers(allUsers, onlineUsers); // Pass all users to parent
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // First useEffect for one-time initialization
  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([
        fetchCurrentUser(),
        fetchCountries()
      ]);
    };
    initializeData();
  }, []);

  // Second useEffect for recurring user fetching
  useEffect(() => {
    // Initial fetch
    fetchAllUsers();

    // Set up interval for subsequent fetches
    const intervalId = setInterval(() => {
      console.log('Fetching users (5-minute interval)');
      fetchAllUsers();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear interval when component unmounts
    return () => {
      console.log('Clearing user fetch interval');
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means this runs once on mount

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get('/user/current');
      const updatedUser = {
        ...response.data,
        avatar_color: response.data.settings?.avatar_color || '#f56a00',
      };
      setCurrentUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      const userSettings = currentUser?.settings;
      
      console.log('Current user settings before logout:', userSettings);
      
      // Call logout endpoint
      await axiosPublic.post('/auth/logout');
      
      // Clear auth tokens
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      
      // Set manualLogout flag (matching ActivityContext.js)
      sessionStorage.setItem('manualLogout', 'true');
      
      // Store only auto_login preference if enabled
      if (userSettings?.auto_login) {
        const minimalUser = {
          user_id: currentUser.user_id,
          settings: {
            auto_login: true,
            avatar_color: userSettings.avatar_color
          }
        };
        console.log('Storing minimal user data:', minimalUser);
        localStorage.setItem('user', JSON.stringify(minimalUser));
      } else {
        console.log('Removing user data - auto_login not enabled');
        localStorage.removeItem('user');
      }
      
      window.location.href = '/login';
      message.success('Logged out successfully');
    } catch (error) {
      console.error('Logout failed:', error);
      sessionStorage.setItem('manualLogout', 'true');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.href = '/login';
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get('/user/profile');
      // Merge the current user's settings with the profile data
      const profileWithSettings = {
        ...response.data,
        settings: currentUser.settings, // Add the settings from currentUser
      };
      setProfileData(profileWithSettings);
      form.setFieldsValue({
        ...response.data,
        birthday: response.data.birthday ? dayjs(response.data.birthday) : null,
        city: response.data.location_info?.city,
        country: response.data.location_info?.country,
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      const sortedCountries = response.data
        .map(country => country.name.common)
        .sort((a, b) => a.localeCompare(b));
      setCountries(sortedCountries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userMenuRef]);

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case '1':
        setShowProfileModal(true);
        fetchProfile();
        break;
      case '2':
        setShowSettingsModal(true);
        break;
      case '3':
        setShowDigiTalModal(true);
        break;
      case '4':
        if (hasRequiredPermission(currentUser?.permissions, 'admin')) {
          window.open('/flows', '_blank');
        }
        break;
      case '5':
        if (hasRequiredPermission(currentUser?.permissions, 'accounting')) {
          navigate('/revibot');
        }
        break;
      case '6':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      key: '1',
      icon: <FaUser />,
      label: 'My Profile',
      className: 'menu-item-left-aligned'
    },
    {
      key: '2',
      icon: <FaCog />,
      label: 'Settings',
      className: 'menu-item-left-aligned'
    },
    {
      key: '3',
      icon: <FaBuilding />,
      label: 'Digi-Tal',
      className: 'menu-item-left-aligned',
    },
    {
      key: '4',
      icon: <FaTools />,
      label: 'Flows',
      className: 'menu-item-left-aligned',
      disabled: !hasRequiredPermission(currentUser?.permissions, 'admin')
    },
    {
      key: '5',
      icon: <FaRobot />,
      label: 'Revibot',
      className: 'menu-item-left-aligned',
    },
    {
      type: 'divider',
    },
    {
      key: '6',
      icon: <FaSignOutAlt />,
      label: 'Logout',
      className: 'menu-item-centered'
    },
  ];

  const [showUserManagementModal, setShowUserManagementModal] = useState(false);

  const renderOnlineAvatars = () => {
    const maxVisibleAvatars = 5;
    const avatarsToShow = onlineUsers.slice(0, maxVisibleAvatars);
    const remainingCount = onlineUsers.length - maxVisibleAvatars;

    return (
      <div className="online-avatars" onClick={() => setIsActivityModalVisible(true)}>
        {onlineUsers.length > 0 ? (
          <>
            {avatarsToShow.map((user, index) => (
              <Tooltip key={user.user_id} title={`${user.name} ${user.surname} (${user.status})`}>
                <div className={`stacked-avatar ${user.status === 'inactive' ? 'inactive-avatar' : ''}`}
                     style={{ zIndex: avatarsToShow.length - index }}>
                  <UserAvatar
                    user={user}
                    size={32}
                    disableTooltip={true}
                  />
                </div>
              </Tooltip>
            ))}
            {remainingCount > 0 && (
              <Avatar className="stacked-avatar remaining-count" style={{ zIndex: 0 }}>
                +{remainingCount}
              </Avatar>
            )}
          </>
        ) : (
          <Tooltip title="No users online">
            <Avatar className="stacked-avatar no-users-avatar">
              <FaUsers />
            </Avatar>
          </Tooltip>
        )}
      </div>
    );
  };

  const logo = theme === 'dark' ? logoDark : logoLight;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const menuProps = {
    items: menuItems.map(item => {
      if (item.type === 'divider') {
        return { type: 'divider' };
      }
      return {
        key: item.key,
        label: (
          <span>
            {item.icon} {item.label}
          </span>
        ),
        disabled: item.disabled,
        className: item.disabled ? 'menu-item-disabled' : item.className
      };
    }),
    onClick: handleMenuClick,
  };

  return (
    <AntHeader className="site-layout-background header">
      <div className="header-left">
        <Button
          type="text"
          icon={<BsGrid />}
          onClick={() => navigate('/main-menu')}
          className="main-menu-button"
        />
        <img src={logo} alt="Logo" className="header-logo" />
        <button className="header-icon-button" onClick={toggleTheme}>
          {theme === 'light' ? <BsMoon /> : <BsSun />}
        </button>
      </div>
      <div className="header-right">
        <div className="header-actions">
          {isRunning && <LiveTimer />}
          {renderOnlineAvatars()}
          <button 
            className="header-icon-button"
            onClick={() => setShowAchievements(true)}
          >
            <IoTrophyOutline />
          </button>
          <button 
            className="header-icon-button"
            onClick={() => setShowNotifications(true)}
          >
            <IoNotificationsOutline />
          </button>
          <button className="header-icon-button" onClick={() => setShowUserManagementModal(true)}>
            <IoPersonAddOutline />
          </button>
          <div className="avatar-dropdown">
            <Dropdown
              menu={menuProps}
              trigger={['click']}
              open={dropdownOpen}
              onOpenChange={setDropdownOpen}
              placement="bottomRight"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <div className="avatar-trigger">
                <UserAvatar
                  user={currentUser}
                  size={35}
                  className="user-avatar"
                  style={{ cursor: 'pointer' }}
                  disableTooltip={true}
                />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <UserManagementModal
        open={showUserManagementModal}
        onClose={() => setShowUserManagementModal(false)}
      />
      <UserSettings
        visible={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
      <UserProfile
        visible={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        profileData={profileData}
        countries={countries}
        onProfileUpdate={async (values) => {
          try {
            await axios.put('/user/profile', values);
            message.success('Profile updated successfully');
            fetchProfile(); // Refresh the data without closing the modal
            fetchCurrentUser(); // Add this to refresh the current user data
          } catch (error) {
            console.error('Error updating profile:', error);
            message.error('Failed to update profile');
          }
        }}
        onAvatarUpdate={fetchCurrentUser} // Add this prop
      />
      <ActivityScreen
        visible={isActivityModalVisible}
        onClose={() => setIsActivityModalVisible(false)}
        users={allUsers}
        currentUser={currentUser}
      />
      <Settings
        visible={showDigiTalModal}
        onClose={() => setShowDigiTalModal(false)}
      />
      <NotificationsModal
        visible={showNotifications}
        onClose={() => setShowNotifications(false)}
      />
      <AchievementsModal
        visible={showAchievements}
        onClose={() => setShowAchievements(false)}
      />
    </AntHeader>
  );
}

export default Header;
