import React, { useState, useEffect, useRef } from 'react';
import { Layout, Typography, Card, Button, Input, Avatar, message, Tooltip, Row, Col, Statistic } from 'antd';
import { RobotOutlined, SendOutlined, InfoCircleOutlined, FileTextOutlined, ApiOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import ErpHeader from '../components/ErpHeader';
import './Digi-Regnskabsbot.css';

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const DigiRegnskabsbot = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const [sourceUrls, setSourceUrls] = useState({});
  const [totalThreads, setTotalThreads] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [isNewThread, setIsNewThread] = useState(true);

  const user = JSON.parse(localStorage.getItem('user')) || { name: 'User', id: null };
  const initials = user.name.split(' ').map(n => n[0]).join('').toUpperCase();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const userMenu = {
    items: [
      { key: 'logout', label: 'Logout' },
    ],
    onClick: ({ key }) => {
      if (key === 'logout') {
        handleLogout();
      }
    },
  };

  const handleMainMenuRedirect = () => {
    navigate('/main-menu');
  };

  useEffect(() => {
    createThread();
    fetchSourceUrls();
    fetchChatbotStats();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  const createThread = async () => {
    try {
      const response = await axiosInstance.post('/ai/create_thread');
      setThreadId(response.data.thread_id);
    } catch (error) {
      console.error('Error creating thread:', error);
      message.error('Kunne ikke oprette chatsamtale');
    }
  };

  const fetchSourceUrls = async () => {
    try {
      const response = await axiosInstance.get('/ai/get_source_urls');
      setSourceUrls(response.data);
    } catch (error) {
      console.error('Error fetching source URLs:', error);
    }
  };

  const fetchChatbotStats = async () => {
    try {
      const response = await axiosInstance.get('/ai/get_chatbot_stats');
      setTotalThreads(response.data.total_threads);
      setTotalQuestions(response.data.total_questions);
    } catch (error) {
      console.error('Error fetching chatbot stats:', error);
    }
  };

  const handleSourceClick = (source) => {
    const url = sourceUrls[source];
    if (url) {
      window.open(url, '_blank');
    } else {
      console.warn(`No URL found for source: ${source}`);
    }
  };

  const formatMessage = (text) => {
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      if (line === 'NEWLINE') {
        return <br key={lineIndex} />;
      }
      const parts = line.split(/(\*\*.*?\*\*|\[[\d,]+\]|^-\s|__.*?__)/);
      return (
        <div key={lineIndex} className={line.startsWith('- ') ? 'bullet-point' : ''}>
          {parts.map((part, partIndex) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
            } else if (part.startsWith('__') && part.endsWith('__')) {
              return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
            } else if (part.match(/\[[\d,]+\]/)) {
              return <sup key={partIndex}>{part}</sup>;
            } else if (part === '- ') {
              return null; // Remove the bullet point marker
            } else if (line.startsWith('# ')) {
              return <h1 key={partIndex}>{part.slice(2)}</h1>;
            } else if (line.startsWith('## ')) {
              return <h2 key={partIndex}>{part.slice(3)}</h2>;
            } else if (line.startsWith('### ')) {
              return <h3 key={partIndex}>{part.slice(4)}</h3>;
            }
            return part;
          })}
        </div>
      );
    });
  };

  const renderCitations = (citations) => {
    return citations.map((citation, index) => {
      const [numbers, source] = citation.split('] Kilde: ');
      return (
        <div key={index} className="citation">
          {numbers}] Kilde:{' '}
          <span
            className="source-link"
            onClick={() => handleSourceClick(source.trim())}
          >
            {source}
          </span>
        </div>
      );
    });
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newMessage = { text: inputMessage, sender: 'user' };
    setChatMessages(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    // Add a temporary message for the AI's response
    setChatMessages(prevMessages => [...prevMessages, { text: '', sender: 'assistant', isTyping: true }]);

    try {
      const token = localStorage.getItem('token');
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
      const response = await fetch(`${baseUrl}/ai/send_message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          thread_id: threadId,
          message: inputMessage,
          user_id: user.user_id,
          is_new_thread: isNewThread  // Include the is_new_thread flag
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Set isNewThread to false after the first message is sent
      setIsNewThread(false);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let botResponse = '';
      let citations = [];
      let isCitationMode = false;

      const processChunk = (chunk) => {
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') return true;
            if (data === 'CITATIONS_START') {
              isCitationMode = true;
              continue;
            }
            if (data === 'CITATIONS_END') {
              isCitationMode = false;
              continue;
            }
            if (data.startsWith('Error:')) {
              botResponse = data;
              updateChatMessages(botResponse.trim(), citations);
              return true; // Signal completion on error
            }
            if (isCitationMode) {
              citations.push(data);
            } else {
              botResponse += data + '\n';
            }
            updateChatMessages(botResponse.trim(), citations);
          }
        }
        return false; // Not completed
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const isCompleted = processChunk(chunk);
        if (isCompleted) break;
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage.sender === 'assistant') {
          lastMessage.text = `Error: ${error.message}`;
          lastMessage.isTyping = false;
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
      fetchChatbotStats();  // Refresh stats after each message
    }
  };

  const updateChatMessages = (text, cits) => {
    setChatMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      if (lastMessage.sender === 'assistant') {
        lastMessage.text = text;
        lastMessage.formatted = formatMessage(text);
        lastMessage.citations = cits;
        lastMessage.isTyping = false;
      }
      return updatedMessages;
    });
  };

  const handlePressEnter = (event) => {
    if (!event.shiftKey && inputMessage.trim()) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const chatbotDescription = `Denne chatbot er designet til at hjælpe med spørgsmål om bogføring og regnskab.
    Den kan assistere med vejledning i fakturering, momsindberetning, skat, årsrapporter, 
    og lovgivning vedrørende bogføring og regnskab.
  `;

  const sources = [
    'Bogføringsloven',
    'Revisorloven',
    'Årsregnskabsloven',
    'Virksomhedsskatteloven',
    'Personskatteloven',
    'Aktieavancebeskatningsloven',
    'Kildeskattebekendtgørelsen',
    'Virksomhedsordningen og kapitalafkastordningen',
    'Bekendtgørelse om krav til det skattemæssige årsregnskab m.v. for mindre virksomheder',
    'Bekendtgørelse om krav til det skattemæssige årsregnskab m.v. for større virksomheder',
    'Lovkrav til tidsregistrering',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-B - Beierholm',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-C-mellem - Beierholm',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-C-stor - Beierholm',
    'Ændringer til årsregnskabsloven for årsrapport 2020 - Beierholm',
    'Momsvejledning-2024 - pwc',
    'Regnskabshåndbogen-2024 - pwc',
  ];

  return (
    <Layout className="digi-regnskabsbot-layout">
      <ErpHeader 
        theme={theme} 
        toggleTheme={toggleTheme} 
        icon={RobotOutlined}
        title="Digi-Regnskabsbot"
      />
      <Content className="digi-regnskabsbot">
        <Row gutter={16} className="bot-stats">
          <Col span={12}>
            <Card>
              <Statistic
                title="Total Threads Created"
                value={totalThreads}
                prefix={<ApiOutlined className="threads-icon" />}
                valueStyle={{ color: 'inherit' }}
                className="threads-value"
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Total Questions Answered"
                value={totalQuestions}
                prefix={<QuestionCircleOutlined className="questions-icon" />}
                valueStyle={{ color: 'inherit' }}
                className="questions-value"
              />
            </Card>
          </Col>
        </Row>
        <Card 
          title={
            <div className="chatbot-title">
              <Avatar icon={<RobotOutlined />} className="chatbot-avatar" />
              <span>Digi-Tal Regnskabs-Chatbot</span>
              <Tooltip title={chatbotDescription} className="chatbot-tooltip">
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
              <Tooltip 
                title={
                  <div>
                    <p>Kilder:</p>
                    <ul>
                      {sources.map((source, index) => (
                        <li key={index}>{source}</li>
                      ))}
                    </ul>
                  </div>
                } 
                className="sources-tooltip"
                placement="right"
                overlayInnerStyle={{
                  width: '500px'
                }}
              >
                <span className="sources-icon">
                  <FileTextOutlined /> Kilder
                </span>
              </Tooltip>
            </div>
          } 
          className="chatbot-card"
        >
          <div className="chat-container">
            <div className="chat-messages" ref={chatContainerRef}>
              {chatMessages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  {msg.isTyping ? (
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    <div>{msg.formatted || msg.text}</div>
                  )}
                  {msg.citations && (
                    <div className="citation-section">
                      {renderCitations(msg.citations)}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="chat-input">
              <TextArea
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Stil et spørgsmål om dine fakturaer..."
                autoSize={{ minRows: 1, maxRows: 3 }}
                disabled={isLoading}
                onPressEnter={handlePressEnter}
              />
              <Button 
                type="primary" 
                icon={<SendOutlined />} 
                onClick={handleSendMessage}
                loading={isLoading}
                disabled={!inputMessage.trim() || !threadId}
              >
                Send
              </Button>
            </div>
          </div>
        </Card>
      </Content>
    </Layout>
  );
};

export default DigiRegnskabsbot;