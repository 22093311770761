import React, { useEffect } from 'react';
import { Form, Input, Select, Button, Space, Typography, TimePicker } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useFlow } from '../contexts/FlowContext';
import { moduleConfigs } from '../modules/nodeTypes';
import CustomScrollbar from '../../components/CustomScrollbar';
import './ModuleConfigurator.css';
import FormBuilder from './form-builder/FormBuilder';

const { Option } = Select;
const { Title } = Typography;

const ModuleConfigurator = ({ onClose }) => {
  const { 
    selectedNode, 
    updateNode, 
    setSelectedNode, 
    setIsConfiguring,
    nodes,
    edges 
  } = useFlow();
  const [form] = Form.useForm();

  const moduleConfig = moduleConfigs[selectedNode?.type];

  useEffect(() => {
    if (selectedNode) {
      form.setFieldsValue(selectedNode.data);
    }
  }, [selectedNode, form]);

  const renderField = (fieldConfig) => {
    const isDisabled = !selectedNode.data.isConnected;

    if (fieldConfig.type === 'select') {
      return (
        <Form.Item
          name={fieldConfig.name}
          label={fieldConfig.label}
          rules={fieldConfig.rules}
        >
          <Select 
            disabled={isDisabled}
            placeholder={isDisabled ? "Connect to trigger first" : fieldConfig.placeholder}
          >
            {(fieldConfig.options || []).map(option => (
              <Option 
                key={option.value} 
                value={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    if (fieldConfig.type === 'dynamic') {
      const dynamicInput = fieldConfig.getDynamicInput(
        form.getFieldValue(fieldConfig.name),
        selectedNode.id,
        nodes,
        edges
      );

      // If no options are available, show disabled state
      if (dynamicInput.type === 'select' && (!dynamicInput.options || dynamicInput.options.length === 0)) {
        return (
          <Form.Item
            name={fieldConfig.name}
            label={fieldConfig.label}
            rules={fieldConfig.rules}
          >
            <Select disabled placeholder="No data available">
              <Option value="">No data available</Option>
            </Select>
          </Form.Item>
        );
      }

      return (
        <Form.Item
          name={fieldConfig.name}
          label={fieldConfig.label}
          rules={fieldConfig.rules}
        >
          {React.cloneElement(
            renderDynamicInput(dynamicInput),
            { 
              disabled: isDisabled,
              placeholder: isDisabled ? "Connect to trigger first" : fieldConfig.placeholder
            }
          )}
        </Form.Item>
      );
    }

    switch (fieldConfig.type) {
      case 'groupedSelect':
        return (
          <Form.Item
            name={fieldConfig.name}
            label={fieldConfig.label}
            rules={fieldConfig.rules}
          >
            <Select 
              disabled={isDisabled}
              placeholder={isDisabled ? "Connect to trigger first" : fieldConfig.placeholder}
            >
              {(fieldConfig.options || []).map(group => (
                <Select.OptGroup key={group.label} label={group.label}>
                  {group.options.map(option => (
                    <Option 
                      key={option.value} 
                      value={option.value} 
                      disabled={option.disabled}
                    >
                      {option.label}
                    </Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        );

      case 'timePicker':
        return (
          <Form.Item
            name={fieldConfig.name}
            label={fieldConfig.label}
            rules={fieldConfig.rules}
          >
            <TimePicker 
              format={fieldConfig.timeFormat}
              use12Hours={fieldConfig.use12Hours}
            />
          </Form.Item>
        );

      case 'conditionArray':
        return (
          <Form.List name={fieldConfig.name}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    {Object.entries(fieldConfig.subFields).map(([key, subField]) => {
                      if (subField.showWhen && !subField.showWhen(form.getFieldValue(['condition', 'conditions'])[index], index, fields)) {
                        return null;
                      }
                      return renderField({
                        ...subField,
                        name: [field.name, key],
                      });
                    })}
                    {fields.length > 1 && (
                      <Button
                        type="link"
                        onClick={() => remove(field.name)}
                        icon={<DeleteOutlined />}
                      />
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add(moduleConfig.defaultData.condition.conditions[0])}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Condition
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        );

      case 'formBuilder':
        return (
          <Form.Item
            label={fieldConfig.label}
            name={fieldConfig.name}
            rules={fieldConfig.rules}
          >
            <FormBuilder 
              config={{
                ...fieldConfig.config,
                nodeId: selectedNode.id
              }}
              nodes={nodes}
              edges={edges}
            />
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            name={fieldConfig.name}
            label={fieldConfig.label}
            rules={fieldConfig.rules}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        );
    }
  };

  const renderDynamicInput = (dynamicConfig) => {
    switch (dynamicConfig.type) {
      case 'select':
        return (
          <Select>
            {dynamicConfig.options.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        );
      default:
        return <Input type={dynamicConfig.inputType || 'text'} />;
    }
  };

  const handleFinish = (values) => {
    updateNode(selectedNode.id, values);
    setSelectedNode(null);
    setIsConfiguring(false);
    onClose();
  };

  if (!selectedNode || !moduleConfig) return null;

  return (
    <div className="module-configurator">
      <div className="module-configurator-header">
        <Title level={4}>Configure {moduleConfig.label}</Title>
        <Button type="link" onClick={onClose}>Close</Button>
      </div>
      <CustomScrollbar className="module-configurator-scrollable">
        <div className="module-configurator-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            className="configurator-form"
          >
            {moduleConfig.formConfig.fields.map(fieldConfig => 
              renderField(fieldConfig)
            )}
            <Form.Item className="module-configurator-actions">
              <Space>
                <Button type="primary" htmlType="submit">
                  Apply
                </Button>
                <Button onClick={onClose}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default ModuleConfigurator;