import React, { useState, useEffect } from 'react';
import { Modal, Input, Tag, List, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import UserAvatar from '../components/UserAvatar';
import CustomScrollbar from '../components/CustomScrollbar';
import './AssigneeSelection.css';

const AssigneeSelection = ({ visible, onClose, onSave, initialAssignees = [], users = [], maxAssignees = Infinity }) => {
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSelectedAssignees(initialAssignees);
  }, [initialAssignees]);

  const handleSave = () => {
    if (selectedAssignees.length === 0) {
      message.error('Please select at least one assignee');
      return;
    }
    onSave(selectedAssignees);
    onClose();
  };

  const handleRemoveAssignee = (userId) => {
    setSelectedAssignees(prev => prev.filter(id => id !== userId));
  };

  const handleSelectAssignee = (userId) => {
    if (!selectedAssignees.includes(userId)) {
      if (maxAssignees === 1) {
        setSelectedAssignees([userId]);
      } else if (selectedAssignees.length < maxAssignees) {
        setSelectedAssignees(prev => [...prev, userId]);
      } else {
        message.warning(`Maximum of ${maxAssignees} assignees allowed`);
      }
    }
  };

  const filteredUsers = users
    .filter(user => !user.is_test_user)
    .filter(user => {
      const fullName = `${user.name} ${user.surname}`.toLowerCase();
      return fullName.includes(searchText.toLowerCase());
    });

  return (
    <Modal
      title="Select Assignees"
      open={visible}
      onCancel={onClose}
      onOk={handleSave}
      width={500}
    >
      <div className="assignee-selection-container">
        <div className="selected-assignees">
          {selectedAssignees.map(userId => {
            const user = users.find(u => u.user_id === userId);
            if (!user) return null;
            return (
              <Tag 
                key={userId}
                closable
                onClose={() => handleRemoveAssignee(userId)}
                className="assignee-tag"
              >
                <UserAvatar user={user} size="small" />
                <span className="assignee-name">{user.name} {user.surname}</span>
              </Tag>
            );
          })}
        </div>

        <Input
          prefix={<SearchOutlined />}
          placeholder="Search users..."
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="assignee-search"
        />

        <CustomScrollbar className="assignee-list-container">
          <List
            className="assignee-list"
            dataSource={filteredUsers}
            renderItem={user => (
              <List.Item
                onClick={() => handleSelectAssignee(user.user_id)}
                className={`assignee-list-item ${selectedAssignees.includes(user.user_id) ? 'selected' : ''}`}
              >
                <UserAvatar user={user} />
                <span className="assignee-list-name">{user.name} {user.surname}</span>
              </List.Item>
            )}
          />
        </CustomScrollbar>
      </div>
    </Modal>
  );
};

export default AssigneeSelection;
