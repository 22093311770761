import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { List, Card, Button, Tag, Timeline, Collapse, Spin, Select, message, Modal, Space } from 'antd';
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './FlowRuns.css';

const { Panel } = Collapse;

const FlowRuns = () => {
  const { flowId } = useParams();
  const navigate = useNavigate();
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRun, setSelectedRun] = useState(null);
  const [steps, setSteps] = useState([]);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [expandedSteps, setExpandedSteps] = useState(new Set());
  const [contentHeights, setContentHeights] = useState({});
  const contentRefs = useRef({});
  const resizeObserver = useRef(null);

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'completed', label: 'Completed' },
    { value: 'failed', label: 'Failed' },
    { value: 'incomplete', label: 'Incomplete' },
    { value: 'paused', label: 'Paused' }
  ];

  const filteredRuns = runs.filter(run => 
    statusFilter === 'all' ? true : run.status === statusFilter
  );

  useEffect(() => {
    fetchRuns();
  }, [flowId]);

  const fetchRuns = async () => {
    try {
      const response = await axios.get(`/automation/flows/${flowId}/runs`);
      setRuns(response.data);
    } catch (error) {
      console.error('Error fetching flow runs:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRunSteps = async (runId) => {
    setLoadingSteps(true);
    try {
      const response = await axios.get(`/automation/flows/${flowId}/runs/${runId}/steps`);
      setSteps(response.data);
      setSelectedRun(runId);
    } catch (error) {
      console.error('Error fetching run steps:', error);
    } finally {
      setLoadingSteps(false);
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      completed: 'success',
      failed: 'error',
      running: 'processing',
      pending: 'default'
    };
    return colors[status] || 'default';
  };

  const toggleStepExpansion = (stepId) => {
    const newExpanded = new Set(expandedSteps);
    if (newExpanded.has(stepId)) {
      newExpanded.delete(stepId);
    } else {
      newExpanded.add(stepId);
    }
    setExpandedSteps(newExpanded);
  };

  // Initialize ResizeObserver
  useEffect(() => {
    resizeObserver.current = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const stepId = entry.target.dataset.stepId;
        if (stepId) {
          const height = entry.target.scrollHeight;
          setContentHeights(prev => ({
            ...prev,
            [stepId]: height
          }));
        }
      });
    });

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, []);

  // Update ref callback to use ResizeObserver
  const setContentRef = useCallback((element, stepId) => {
    if (element) {
      element.dataset.stepId = stepId;
      resizeObserver.current.observe(element);
      contentRefs.current[stepId] = element;
    } else {
      if (contentRefs.current[stepId]) {
        resizeObserver.current.unobserve(contentRefs.current[stepId]);
        delete contentRefs.current[stepId];
      }
    }
  }, []);

  // Update the status filter handler
  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
    // Clear selected run and steps when filter changes
    setSelectedRun(null);
    setSteps([]);
  };

  const copyRunDetails = async (runId) => {
    try {
      const response = await axios.get(`/automation/flows/runs/${runId}/details`);
      const details = response.data;
      
      // Format the data for better readability
      const formattedData = JSON.stringify(details, null, 2);
      
      // Copy to clipboard
      await navigator.clipboard.writeText(formattedData);
      message.success('Run details copied to clipboard');
    } catch (error) {
      console.error('Error copying run details:', error);
      message.error('Failed to copy run details');
    }
  };

  const deleteRun = async (runId) => {
    Modal.confirm({
      title: 'Delete Run',
      content: 'Are you sure you want to delete this run? This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await axios.delete(`/automation/flows/runs/${runId}`);
          // Refresh the runs list
          fetchRuns();
          message.success('Run deleted successfully');
        } catch (error) {
          console.error('Error deleting run:', error);
          message.error('Failed to delete run');
        }
      }
    });
  };

  return (
    <div className="flow-runs-container">
      <div className="flow-runs-header">
        <Button 
          icon={<ArrowLeftOutlined />} 
          onClick={() => navigate('/flows')}
        >
          Back to Flows
        </Button>
        <Select
          className="status-filter"
          value={statusFilter}
          onChange={handleStatusFilterChange}
          options={statusOptions}
          style={{ width: 150 }}
        />
      </div>

      <Card title="Flow Execution History">
        <List
          loading={loading}
          dataSource={filteredRuns}
          renderItem={run => (
            <List.Item
              key={run.id}
              actions={[
                <Space>
                  <Button 
                    icon={<CopyOutlined />}
                    onClick={() => copyRunDetails(run.id)}
                    title="Copy run details"
                  >
                    Copy Details
                  </Button>
                  <Button 
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => deleteRun(run.id)}
                    title="Delete run"
                  >
                    Delete Run
                  </Button>
                  <Button 
                    type="link" 
                    onClick={() => fetchRunSteps(run.id)}
                  >
                    View Details
                  </Button>
                </Space>
              ]}
            >
              <List.Item.Meta
                title={`${run.flow_name} - Execution #${run.id}`}
                description={
                  <>
                    <Tag color={getStatusColor(run.status)}>{run.status.toUpperCase()}</Tag>
                    <span>Started: {new Date(run.started_at).toLocaleString()}</span>
                    {run.completed_at && (
                      <span> | Completed: {new Date(run.completed_at).toLocaleString()}</span>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />

        {selectedRun && (
          <Collapse className="steps-collapse">
            <Panel 
              header={`Execution Steps - ${selectedRun ? 
                runs.find(r => r.id === selectedRun)?.flow_name : ''}`} 
              key="1"
            >
              {loadingSteps ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <Timeline>
                  {steps.map(step => (
                    <Timeline.Item
                      key={step.id}
                      color={getStatusColor(step.status)}
                      dot={step.status === 'completed' ? <CheckCircleOutlined /> : 
                           step.status === 'failed' ? <CloseCircleOutlined /> : null}
                    >
                      <div 
                        className={`step-content ${expandedSteps.has(step.id) ? 'expanded' : ''}`}
                        ref={el => setContentRef(el, step.id)}
                      >
                        <div className="step-content-inner">
                          <h4>{step.node_id}</h4>
                          <Tag color={getStatusColor(step.status)}>{step.status}</Tag>
                          {step.error && <p className="error-message">{step.error}</p>}
                          {step.output && (
                            <pre className="step-output">
                              {JSON.stringify(step.output, null, 2)}
                            </pre>
                          )}
                        </div>
                        {contentHeights[step.id] > 250 && (
                          <div 
                            className="step-expand-button"
                            onClick={() => toggleStepExpansion(step.id)}
                          >
                            {expandedSteps.has(step.id) ? 'Show Less' : 'Show More'}
                          </div>
                        )}
                      </div>
                    </Timeline.Item>
                  ))}
                </Timeline>
              )}
            </Panel>
          </Collapse>
        )}
      </Card>
    </div>
  );
};

export default FlowRuns;