import React, { useState, useEffect } from 'react';
import { Button, Card, Input, Radio, Typography, Space, Tooltip } from 'antd';
import { RobotOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './DigiAI.css';

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const DIGI_AI_DISABLED = true;

function DigiAI({ isVisible, isMaximized, onOpen, onClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const [context, setContext] = useState('this page');
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const location = useLocation();

  const toggleAI = () => {
    if (isMaximized) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleContextChange = (e) => {
    setContext(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (message.trim()) {
      const newUserMessage = { type: 'user', content: message };
      const newAIMessage = { type: 'ai', content: 'This is a mock AI response.' }; // Mock AI response
      setChatHistory([...chatHistory, newUserMessage, newAIMessage]);
      setMessage('');
    }
  };

  return (
    <div className={`digi-ai-container ${isVisible ? 'visible' : ''} ${isMaximized ? 'maximized' : ''} ${DIGI_AI_DISABLED ? 'disabled' : ''}`}>
      <div className="digi-ai-content">
        {!isMaximized ? (
          <Tooltip title="Digi-AI (Coming Soon)" placement="left">
            <div className={`digi-ai-minimized ${DIGI_AI_DISABLED ? 'disabled' : ''}`} onClick={toggleAI}>
              <RobotOutlined className="robot-icon" />
            </div>
          </Tooltip>
        ) : (
          <Card className="digi-ai-maximized">
            <div className="digi-ai-header">
              <Title level={4}>Digi-AI Assistant</Title>
              <Button 
                icon={<CloseOutlined />} 
                onClick={toggleAI}
                type="text"
              />
            </div>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <div className="context-selector">
                <Text strong>AI Context:</Text>
                <Radio.Group onChange={handleContextChange} value={context}>
                  <Radio value="this page">This page</Radio>
                  <Radio value="everything">Everything</Radio>
                </Radio.Group>
              </div>
              <div className="chat-history">
                {chatHistory.map((msg, index) => (
                  <div key={index} className={`chat-message ${msg.type}`}>
                    <div className="chat-bubble">{msg.content}</div>
                  </div>
                ))}
              </div>
              <TextArea
                value={message}
                onChange={handleMessageChange}
                placeholder="Type your message here..."
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={handleSubmit}
                disabled={!message.trim()}
              >
                Send
              </Button>
            </Space>
          </Card>
        )}
      </div>
    </div>
  );
}

export default DigiAI;