import React, { useCallback } from 'react';
import { 
  ReactFlow, 
  Background, 
  Controls, 
  MiniMap,
  useReactFlow 
} from '@xyflow/react';
import './FlowCanvas.css';
import { useFlow } from '../contexts/FlowContext';
import { moduleConfigs } from '../modules/nodeTypes';

const FlowCanvas = ({ nodeTypes }) => {
  const { 
    nodes, edges, 
    onNodesChange, onEdgesChange, 
    setNodes,
    setSelectedNode, setIsConfiguring,
    onConnect
  } = useFlow();
  
  const reactFlowInstance = useReactFlow();

  const onNodeClick = useCallback((_, node) => {
    setSelectedNode(node);
    setIsConfiguring(true);
  }, [setSelectedNode, setIsConfiguring]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowWrapper = document.querySelector('.react-flow-wrapper');
      const reactFlowBounds = reactFlowWrapper.getBoundingClientRect();
      const moduleData = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      // Get the next available node number
      const maxNumber = Math.max(
        0,
        ...nodes
          .filter(n => !['flow-start', 'flow-finish'].includes(n.id))
          .map(n => n.data.nodeNumber || 0)
      );

      const newNode = {
        id: `${moduleData.type}-${Date.now()}`,
        type: moduleData.type,
        position,
        data: { 
          ...moduleData.data,
          isConnected: false,
          nodeNumber: maxNumber + 1  // Assign next available number
        }
      };

      onNodesChange([{ type: 'add', item: newNode }]);
    },
    [onNodesChange, reactFlowInstance, nodes]
  );

  const onNodeDragStart = useCallback((event, node) => {
    const nodeElement = document.querySelector(`[data-id="${node.id}"]`);
    if (nodeElement) {
      nodeElement.classList.add('dragging');
    }
  }, []);

  const onNodeDragStop = useCallback((event, node) => {
    const nodeElement = document.querySelector(`[data-id="${node.id}"]`);
    if (nodeElement) {
      nodeElement.classList.remove('dragging');
    }
  }, []);

  // Add default edge options
  const defaultEdgeOptions = {
    type: 'smoothstep',  // or 'bezier' or 'step'
    animated: false,
    style: {
      stroke: 'var(--primary-color)',
      strokeWidth: 2,
    }
  };

  // Add this function to handle node deletion
  const handleNodesChange = useCallback((changes) => {
    // Filter out any attempts to delete flow-start or flow-finish nodes
    const allowedChanges = changes.filter(change => {
      if (change.type === 'remove') {
        const nodeId = change.id;
        return !['flow-start', 'flow-finish'].includes(nodeId);
      }
      return true;
    });
    
    onNodesChange(allowedChanges);

    // Update connection status for all nodes
    setNodes((nds) => {
      return nds.map(node => {
        const moduleConfig = moduleConfigs[node.type];
        if (!moduleConfig?.moduleConfig?.isConnectedToSource) return node;

        const isConnected = moduleConfig.moduleConfig.isConnectedToSource(
          node.id,
          nds,
          edges
        );

        return {
          ...node,
          data: { ...node.data, isConnected }
        };
      });
    });
  }, [onNodesChange, edges, setNodes]);

  return (
    <div className="react-flow-wrapper">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}  // Use the new handler here
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onNodeClick={onNodeClick}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        fitView
      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
};

export default FlowCanvas;