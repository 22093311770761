import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Table, Spin, Empty } from 'antd';
import { Bar, Pie } from 'react-chartjs-2';
import { 
  CheckCircleOutlined, 
  UserOutlined, 
  CalendarOutlined,
  FileOutlined 
} from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './TaskData.css';

const TaskData = () => {
  const [loading, setLoading] = useState(true);
  const [taskStats, setTaskStats] = useState(null);

  useEffect(() => {
    fetchTaskData();
  }, []);

  const fetchTaskData = async () => {
    try {
      const response = await axios.get('/dashboard/task-data');
      setTaskStats(response.data);
    } catch (error) {
      console.error('Error fetching task stats:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderTaskTypeDistribution = () => {
    if (!taskStats?.task_types) return null;

    const data = {
      labels: Object.keys(taskStats.task_types),
      datasets: [{
        data: Object.values(taskStats.task_types),
        backgroundColor: [
          'rgba(24, 144, 255, 0.6)',
          'rgba(82, 196, 26, 0.6)',
          'rgba(250, 173, 20, 0.6)',
          'rgba(255, 77, 79, 0.6)',
          'rgba(47, 84, 235, 0.6)'
        ],
        borderColor: [
          'rgba(24, 144, 255, 1)',
          'rgba(82, 196, 26, 1)',
          'rgba(250, 173, 20, 1)',
          'rgba(255, 77, 79, 1)',
          'rgba(47, 84, 235, 1)'
        ],
        borderWidth: 1
      }]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right'
        }
      }
    };

    return (
      <div className="chart-container">
        <Pie data={data} options={options} />
      </div>
    );
  };

  const renderUserAssignments = () => {
    if (!taskStats?.user_assignments) return null;

    const data = {
      labels: Object.keys(taskStats.user_assignments).map(user => user.split(' ')[0]),
      datasets: [{
        label: 'Assigned Tasks',
        data: Object.values(taskStats.user_assignments).map(user => user.total),
        backgroundColor: 'rgba(24, 144, 255, 0.6)',
        borderColor: 'rgba(24, 144, 255, 1)',
        borderWidth: 1
      }]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Tasks'
          }
        }
      }
    };

    return (
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    );
  };

  const renderUserTaskTable = () => {
    if (!taskStats?.user_assignments) return null;

    const columns = [
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
      },
      {
        title: 'Total Tasks',
        dataIndex: 'total',
        key: 'total',
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: 'Recurring Tasks',
        dataIndex: 'recurring',
        key: 'recurring',
      },
      {
        title: 'Onboarding Tasks',
        dataIndex: 'onboarding',
        key: 'onboarding',
      }
    ];

    const data = Object.entries(taskStats.user_assignments).map(([user, stats]) => ({
      key: user,
      user: user,
      total: stats.total,
      recurring: stats.recurring,
      onboarding: stats.onboarding
    }));

    return (
      <Table 
        columns={columns} 
        dataSource={data}
        pagination={false}
        className="user-task-table"
      />
    );
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="task-data">
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Tasks"
              value={taskStats?.total_tasks}
              prefix={<FileOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Active Users"
              value={taskStats?.active_users}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tasks This Month"
              value={taskStats?.tasks_this_month}
              prefix={<CalendarOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Completed Tasks"
              value={taskStats?.completed_tasks}
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
        
        <Col span={12}>
          <Card title="Task Type Distribution">
            {renderTaskTypeDistribution()}
          </Card>
        </Col>
        
        <Col span={12}>
          <Card title="User Task Assignments">
            {renderUserAssignments()}
          </Card>
        </Col>
        
        <Col span={24}>
          <Card title="Detailed User Task Distribution">
            {renderUserTaskTable()}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TaskData;