import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Form, Input, Button, Select, message, Space } from 'antd';
import { UsergroupAddOutlined, UserDeleteOutlined, SwapOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import './UserManagement.css';

const { Option } = Select;

function UserManagementModal({ open, onClose }) {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [emailPrefix, setEmailPrefix] = useState('');
  const [useCustomEmail, setUseCustomEmail] = useState(false);

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to fetch users');
    }
  };

  const handleInvite = async (values) => {
    const email = useCustomEmail ? values.email : `${emailPrefix}@digi-tal.dk`;
    try {
      console.log('Sending invitation data:', { email, permissions: values.permissions });
      
      await axios.post('/user/invite', { 
        email: email,
        permissions: values.permissions 
      });
      message.success('User invited successfully');
      form.resetFields();
      setEmailPrefix('');
    } catch (error) {
      console.error('Error inviting user:', error.response?.data || error);
      message.error(error.response?.data?.msg || 'Failed to invite user');
    }
  };

  const handleRemoveUser = async () => {
    if (!selectedUser) return;
    try {
      await axios.delete(`/user/${selectedUser}`);
      message.success('User removed successfully');
      setSelectedUser(null);
      fetchUsers();
    } catch (error) {
      console.error('Error removing user:', error);
      message.error('Failed to remove user');
    }
  };

  const handleEmailPrefixChange = (e) => {
    setEmailPrefix(e.target.value);
  };

  const handleModalClose = () => {
    form.resetFields();
    setEmailPrefix('');
    setUseCustomEmail(false);
    onClose();
  };

  const items = [
    {
      key: '1',
      label: (
        <span>
          <UsergroupAddOutlined />
          Invite Member
        </span>
      ),
      children: (
        <>
          <Form form={form} onFinish={handleInvite} layout="vertical">
            <Form.Item>
              <Button 
                type="link" 
                onClick={() => setUseCustomEmail(!useCustomEmail)}
                className="email-toggle-button"
              >
                {useCustomEmail ? (
                  <>Use @digi-tal.dk email <SwapOutlined /></>
                ) : (
                  <>Use custom email <SwapOutlined /></>
                )}
              </Button>
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please input the email!' },
                useCustomEmail 
                  ? { type: 'email', message: 'Invalid email format!' }
                  : { pattern: /^[a-zA-Z0-9._-]+$/, message: 'Invalid email prefix!' },
              ]}
            >
              {useCustomEmail ? (
                <Input />
              ) : (
                <Space.Compact style={{ width: '100%' }}>
                  <Input
                    value={emailPrefix}
                    onChange={handleEmailPrefixChange}
                    style={{ width: 'calc(100% - 120px)' }}
                  />
                  <Input
                    style={{ width: '120px' }}
                    disabled
                    defaultValue="@digi-tal.dk"
                  />
                </Space.Compact>
              )}
            </Form.Item>

            <Form.Item
              name="permissions"
              label="Permissions"
              rules={[{ required: true, message: 'Please select permissions!' }]}
            >
              <Select>
                <Option value="admin">Admin</Option>
                <Option value="accounting">Accounting</Option>
                <Option value="payroll">Payroll</Option>
                <Option value="sales">Sales</Option>
                <Option value="user">User</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="action-button">
                Invite
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <UserDeleteOutlined />
          Remove User
        </span>
      ),
      children: (
        <>
          <Select
            placeholder="Select a user to remove"
            style={{ width: '100%' }}
            onChange={setSelectedUser}
            value={selectedUser}
          >
            {users.map(user => (
              <Option key={user.user_id} value={user.user_id}>{user.email}</Option>
            ))}
          </Select>
          <Button type="primary" danger onClick={handleRemoveUser} className="action-button">
            Remove
          </Button>
        </>
      ),
    },
  ];

  return (
    <Modal
      title="User Management"
      open={open}
      onCancel={handleModalClose}
      footer={null}
      width={600}
      className="user-management-modal"
    >
      <Tabs items={items} />
    </Modal>
  );
}

export default UserManagementModal;
