import { isNodeConnectedToStart } from '../../../utils/flowUtils';

export const config = {
  type: 'flowFinish',
  category: 'flowFinish',
  label: 'Flow Finish',
  icon: 'FlagOutlined',

  // Default data when creating new node
  defaultData: {
    label: 'Flow Finish',
  },

  // Module behavior configuration
  moduleConfig: {
    validateConnection: () => true,
    isConnectedToSource: isNodeConnectedToStart,
    maxIncomingConnections: -1, // Allow multiple incoming connections
    maxOutgoingConnections: 0,  // No outgoing connections allowed
    isRequired: true, // Cannot be removed
    isDefault: true // Added by default to new flows
  },

  // Form configuration for ModuleConfigurator
  formConfig: {
    fields: [] // No configuration needed
  }
};
