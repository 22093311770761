import EventTrigger from './triggers/EventTrigger';
import ScheduleTrigger from './triggers/ScheduleTrigger';
import ShowForm from './actions/forms/ShowForm';
import SendEmail from './actions/email/SendEmail';
import DataCondition from './conditions/DataCondition';
import GetDeal from './actions/deal/GetDeal';
import UpdateDeal from './actions/deal/UpdateDeal';
import FlowFinish from './flow-finish/FlowFinish';
import FlowStart from './flow-finish/FlowStart';

// Import configs
import { config as eventTriggerConfig } from './triggers/configs/eventTrigger.config';
import { config as scheduleTriggerConfig } from './triggers/configs/scheduleTrigger.config';
import { config as showFormConfig } from './actions/forms/configs/showForm.config';
import { config as sendEmailConfig } from './actions/email/configs/sendEmail.config';
import { config as dataConditionConfig } from './conditions/configs/dataCondition.config';
import { config as getDealConfig } from './actions/deal/configs/getDeal.config';
import { config as updateDealConfig } from './actions/deal/configs/updateDeal.config';
import { config as flowFinishConfig } from './flow-finish/configs/flowFinish.config';
import { config as flowStartConfig } from './flow-finish/configs/flowStart.config';

// Component registry
export const nodeTypes = {
  eventTrigger: EventTrigger,
  scheduleTrigger: ScheduleTrigger,
  showForm: ShowForm,
  sendEmail: SendEmail,
  dataCondition: DataCondition,
  getDeal: GetDeal,
  updateDeal: UpdateDeal,
  flowFinish: FlowFinish,
  flowStart: FlowStart
};

// Config registry
export const moduleConfigs = {
  eventTrigger: eventTriggerConfig,
  scheduleTrigger: scheduleTriggerConfig,
  showForm: showFormConfig,
  sendEmail: sendEmailConfig,
  dataCondition: dataConditionConfig,
  getDeal: getDealConfig,
  updateDeal: updateDealConfig,
  flowFinish: flowFinishConfig,
  flowStart: flowStartConfig
};