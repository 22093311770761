import React, { useState, useEffect } from 'react';
import { Steps, Button, message, Spin, Descriptions, Table, Modal, Input, Select, Switch, DatePicker } from 'antd';
import axios from '../api/axiosConfig';
import { dealDataConfig } from '../configs/DealDataConfig';
import dayjs from 'dayjs';
import './MigrateDeal.css';
import CustomScrollbar from '../components/CustomScrollbar';
import UserAvatar from '../components/UserAvatar';
import AssigneeSelection from '../modals/AssigneeSelection';
import { dealSoftwareConfig } from '../configs/DealSoftwareConfig';

const { Step } = Steps;

const MigrateDeal = () => {
  const [deals, setDeals] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [economicStatus, setEconomicStatus] = useState(null);
  const [tasksStatus, setTasksStatus] = useState(null);
  const [changes, setChanges] = useState({});
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [taskConfig, setTaskConfig] = useState(null);
  const [editedTasks, setEditedTasks] = useState(null);
  const [users, setUsers] = useState([]);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [currentEditingTaskIndex, setCurrentEditingTaskIndex] = useState(null);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/migration/deals');
      setDeals(response.data);
    } catch (error) {
      message.error('Error fetching migration deals');
    }
  };

  const fetchProducts = async (dealId) => {
    try {
      const response = await axios.get(`/migration/deal/${dealId}/products`);
      setProducts(response.data);
    } catch (error) {
      message.error('Error fetching products');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
  ];

  const handleCreateEconomicCustomer = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`/migration/deal/${selectedDeal.id}/economic`);
      setEconomicStatus('success');
      message.success('Customer created in e-conomic');
      setCurrentStep(2);
    } catch (error) {
      setEconomicStatus('error');
      message.error(error.response?.data?.message || 'Error creating customer');
    } finally {
      setLoading(false);
    }
  };

  const handleConfigureTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/migration/deal/${selectedDeal.id}/tasks`);
      setTaskConfig(response.data.recurring_tasks);
      setEditedTasks(response.data.recurring_tasks);
      message.success('Task recommendations generated');
    } catch (error) {
      message.error('Error getting task recommendations');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTaskConfig = async () => {
    try {
      setLoading(true);
      await axios.post(`/migration/deal/${selectedDeal.id}/tasks`, {
        recurring_tasks: editedTasks
      });
      setTasksStatus('success');
      message.success('Tasks saved successfully');
    } catch (error) {
      setTasksStatus('error');
      message.error('Error saving task configuration');
    } finally {
      setLoading(false);
    }
  };

  const handleTaskChange = (taskIndex, field, value) => {
    setEditedTasks(prev => {
      const updated = [...prev];
      updated[taskIndex] = {
        ...updated[taskIndex],
        [field]: value
      };
      return updated;
    });
  };

  const handleEdit = (key, value) => {
    setChanges(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSave = () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    try {
      const updatedChanges = {};
      const updatedData = { ...(selectedDeal.data || {}) };
      const updatedSoftware = { ...(selectedDeal.software || {}) };
      
      // First, apply any explicit changes
      Object.entries(changes).forEach(([key, value]) => {
        if (key.startsWith('software.')) {
          const softwareKey = key.split('.')[1];
          updatedSoftware[softwareKey] = value;
        } else if (key.startsWith('data.')) {
          const nestedKey = key.split('.')[1];
          updatedData[nestedKey] = value;
        } else {
          updatedChanges[key] = value;
        }
      });

      // Then, ensure all configured fields exist in the data/software objects
      dealDataConfig.forEach(config => {
        if (config.key.startsWith('software.')) {
          const softwareKey = config.key.split('.')[1];
          if (!(softwareKey in updatedSoftware)) {
            updatedSoftware[softwareKey] = null;
          }
        } else if (config.key.startsWith('data.')) {
          const dataKey = config.key.split('.')[1];
          if (!(dataKey in updatedData)) {
            updatedData[dataKey] = null;
          }
        }
      });

      // Always include both data and software in the update
      updatedChanges.data = updatedData;
      updatedChanges.software = updatedSoftware;

      await axios.patch(`/migration/pending-deal/${selectedDeal.id}`, updatedChanges);
      
      setSelectedDeal(prev => ({
        ...prev,
        data: updatedData,
        software: updatedSoftware,
        ...updatedChanges
      }));
      
      setChanges({});
      setConfirmVisible(false);
      message.success('Changes saved successfully');
    } catch (error) {
      message.error('Error saving changes');
    }
  };

  const renderEditableField = (fieldConfig, value) => {
    const currentValue = changes[fieldConfig.key] ?? value;

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    switch (fieldConfig.type) {
      case 'text':
      case 'number':
        return (
          <Input
            type={fieldConfig.type}
            value={currentValue}
            onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
            className="editable-field-wrapper"
          />
        );

      case 'dropdown':
        if (fieldConfig.key.startsWith('software.')) {
          const softwareKey = fieldConfig.key.split('.')[1];
          const softwareConfig = dealSoftwareConfig.find(config => config.key === softwareKey);
          
          if (softwareConfig?.options) {
            return (
              <Select
                value={currentValue}
                onChange={(value) => handleEdit(fieldConfig.key, value)}
                className="editable-field-wrapper"
              >
                {softwareConfig.options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    <span style={{
                      backgroundColor: option.color,
                      color: '#fff',
                      padding: '2px 8px',
                      borderRadius: '4px',
                      display: 'inline-block'
                    }}>
                      {option.label}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            );
          }
        }
        
        return (
          <Select
            value={currentValue}
            onChange={(value) => handleEdit(fieldConfig.key, value)}
            className="editable-field-wrapper"
          >
            {fieldConfig.options?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                <span style={{
                  backgroundColor: option.color,
                  color: option.color === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  display: 'inline-block'
                }}>
                  {option.label}
                </span>
              </Select.Option>
            ))}
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue}
            onChange={(checked) => handleEdit(fieldConfig.key, checked)}
            className="editable-field-wrapper"
          />
        );

      case 'date':
        return (
          <DatePicker
            value={currentValue ? dayjs(currentValue) : null}
            onChange={(date) => handleEdit(fieldConfig.key, date ? date.format('YYYY-MM-DD') : null)}
            className="editable-field-wrapper"
          />
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderDealData = () => {
    if (!selectedDeal) return null;

    // Filter out the source field from the main config rendering
    const configWithoutSource = dealDataConfig.filter(
      fieldConfig => fieldConfig.key !== 'data.deal_source'
    );

    return (
      <>
        {/* Render all fields except source */}
        {configWithoutSource.map(fieldConfig => {
          const value = fieldConfig.key.split('.').reduce((obj, key) => 
            obj ? obj[key] : undefined, selectedDeal
          );
          
          return (
            <Descriptions.Item
              key={fieldConfig.key}
              label={fieldConfig.label}
              className={`deal-data-item ${value === undefined ? 'missing-value' : ''}`}
            >
              {renderEditableField(fieldConfig, value)}
            </Descriptions.Item>
          );
        })}

        {/* Render source field */}
        <Descriptions.Item
          label="Source"
          className="deal-data-item"
        >
          {renderEditableField(
            { key: 'data.deal_source', type: 'text', editable: true },
            selectedDeal.data?.deal_source
          )}
        </Descriptions.Item>

        {/* Type selection */}
        <Descriptions.Item label="Type">
          <Select
            value={changes.type || selectedDeal.type}
            onChange={(value) => handleEdit('type', value)}
            className="editable-field-wrapper"
          >
            <Select.Option value="drift">Drift</Select.Option>
            <Select.Option value="holding">Holding</Select.Option>
          </Select>
        </Descriptions.Item>
      </>
    );
  };

  const getChangesSummary = () => {
    const changedFields = Object.keys(changes).length;
    const newFields = dealDataConfig.reduce((count, config) => {
      const value = config.key.split('.').reduce((obj, key) => obj?.[key], selectedDeal);
      if (value === undefined) count++;
      return count;
    }, 0);
    
    return {
      changedFields,
      newFields,
      total: changedFields + newFields
    };
  };

  const renderChanges = () => {
    const summary = getChangesSummary();
    return (
      <>
        {summary.total === 0 ? (
          <p>No changes detected. Saving will store all current field values to the database.</p>
        ) : (
          <>
            {summary.changedFields > 0 && (
              <div>
                <h4>Changed Fields:</h4>
                {Object.entries(changes).map(([key, newValue]) => {
                  const fieldConfig = dealDataConfig.find(config => config.key === key);
                  if (!fieldConfig) return null;

                  const originalValue = key.split('.').reduce((obj, k) => 
                    obj ? obj[k] : undefined, selectedDeal
                  );

                  return (
                    <p key={key} className="change-item">
                      <span className="change-label">{fieldConfig.label}:</span>
                      <span className="change-value">{originalValue || 'N/A'}</span>
                      <span className="change-arrow"> → </span>
                      <span className="change-value">{newValue || 'N/A'}</span>
                    </p>
                  );
                })}
              </div>
            )}
            {summary.newFields > 0 && (
              <div>
                <h4>New Fields to be Added:</h4>
                {dealDataConfig
                  .filter(config => {
                    const value = config.key.split('.').reduce((obj, key) => obj?.[key], selectedDeal);
                    return value === undefined;
                  })
                  .map(config => (
                    <p key={config.key} className="change-item">
                      <span className="change-label">{config.label}</span>
                      <span className="change-value">Will be initialized</span>
                    </p>
                  ))
                }
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const handleNext = () => {
    setCurrentStep(current => current + 1);
  };

  const handlePrev = () => {
    setCurrentStep(current => current - 1);
  };

  const handleCompleteMigration = async () => {
    try {
      setLoading(true);
      await axios.post(`/migration/deal/${selectedDeal.id}/complete`);
      message.success('Migration completed successfully');
      setTaskConfig(null);
      setEditedTasks(null);
      setTasksStatus(null);
      fetchDeals(); // Refresh the list
      setSelectedDeal(null);
    } catch (error) {
      message.error('Error completing migration');
    } finally {
      setLoading(false);
    }
  };

  const handleAssigneeSelect = (taskIndex) => {
    setCurrentEditingTaskIndex(taskIndex);
    setAssigneeModalVisible(true);
  };

  const taskColumns = [
    { 
      title: 'Type', 
      dataIndex: 'type',
      key: 'type'
    },
    { 
      title: 'Frequency', 
      dataIndex: 'frequency',
      key: 'frequency',
      render: (freq, record, index) => (
        <Select
          value={freq}
          onChange={(value) => handleTaskChange(index, 'frequency', value)}
          options={[
            { value: 'monthly', label: 'Monthly' },
            { value: 'quarterly', label: 'Quarterly' },
            { value: 'yearly', label: 'Yearly' }
          ]}
        />
      )
    },
    {
      title: 'Work Date',
      dataIndex: 'next_work_date',
      key: 'next_work_date',
      render: (date, record, index) => (
        <DatePicker
          value={date ? dayjs(date) : null}
          onChange={(value) => handleTaskChange(
            index,
            'next_work_date',
            value ? value.format('YYYY-MM-DD') : null
          )}
        />
      )
    },
    {
      title: 'Due Date',
      dataIndex: 'next_due_date',
      key: 'next_due_date',
      render: (date, record, index) => (
        <DatePicker
          value={date ? dayjs(date) : null}
          onChange={(value) => handleTaskChange(
            index,
            'next_due_date',
            value ? value.format('YYYY-MM-DD') : null
          )}
        />
      )
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      render: (assigneeId, record, index) => {
        const assignedUser = users.find(user => user.user_id === assigneeId);
        
        return (
          <div 
            style={{ cursor: 'pointer' }} 
            onClick={() => handleAssigneeSelect(index)}
          >
            <UserAvatar 
              user={assignedUser} 
              size={32}
            />
          </div>
        );
      }
    }
  ];

  const steps = [
    {
      title: 'Review Deal Data',
      content: (
        <div className="step-content">
          <CustomScrollbar className="deal-data-content">
            <Descriptions 
              column={1} 
              bordered={true}
              layout="horizontal" 
              className="deal-descriptions"
            >
              {renderDealData()}
            </Descriptions>
          </CustomScrollbar>
          <div className="steps-action">
            <Button onClick={handleSave}>
              {(() => {
                const summary = getChangesSummary();
                if (summary.total === 0) {
                  return 'Save All Fields';
                }
                return `Save (${summary.changedFields} changes${
                  summary.newFields > 0 ? `, ${summary.newFields} new fields` : ''
                })`;
              })()}
            </Button>
            <Button type="primary" onClick={() => setCurrentStep(1)}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Create E-conomic Customer',
      content: (
        <div className="step-content">
          <Button 
            type="primary" 
            onClick={handleCreateEconomicCustomer}
            loading={loading}
            disabled={economicStatus === 'success'}
          >
            Create Customer in E-conomic
          </Button>
          <div className="steps-action">
            <Button onClick={() => setCurrentStep(0)}>Previous</Button>
            <Button 
              type="primary" 
              onClick={() => setCurrentStep(2)}
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Configure Tasks',
      content: (
        <div className="step-content">
          <div className="products-section">
            <h3>Deal Products</h3>
            <Table
              dataSource={products}
              columns={[
                { title: 'Name', dataIndex: 'name' },
                { title: 'Code', dataIndex: 'code' },
                { title: 'Billing Start', dataIndex: 'billing_start_date', 
                  render: date => date ? dayjs(date).format('DD/MM/YYYY') : 'N/A' },
                { title: 'Frequency', dataIndex: 'billing_frequency' }
              ]}
              pagination={false}
            />
          </div>

          <div className="tasks-section">
            <h3>Recurring Tasks Configuration</h3>
            {!taskConfig ? (
              <Button 
                type="primary" 
                onClick={handleConfigureTasks}
                loading={loading}
              >
                Configure Recurring Tasks
              </Button>
            ) : (
              <>
                <Table
                  dataSource={editedTasks}
                  columns={taskColumns}
                  pagination={false}
                  className="tasks-table"
                  rowKey="type"
                />
                <Button 
                  type="primary"
                  onClick={handleSaveTaskConfig}
                  loading={loading}
                  disabled={tasksStatus === 'success'}
                  className="save-tasks-button"
                >
                  Save Task Configuration
                </Button>
              </>
            )}
          </div>

          <div className="steps-action">
            <Button onClick={() => setCurrentStep(1)}>Previous</Button>
            <Button type="primary" onClick={() => setCurrentStep(3)}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Complete Migration',
      content: (
        <div className="step-content">
          <Button 
            type="primary" 
            onClick={handleCompleteMigration}
            loading={loading}
          >
            Complete Migration
          </Button>
          <div className="steps-action">
            <Button onClick={() => setCurrentStep(2)}>Previous</Button>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (selectedDeal) {
      fetchProducts(selectedDeal.id);
    }
  }, [selectedDeal]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/user');
        setUsers(response.data);
      } catch (error) {
        message.error('Error fetching users');
      }
    };
    fetchUsers();
  }, []);

  return (
    <div className="migrate-deal">
      <h1>Deals to Migrate</h1>
      
      <Table 
        dataSource={deals}
        columns={columns}
        rowKey="id"
        className="migration-deals-table"
        onRow={(record) => ({
          onClick: () => {
            setSelectedDeal(record);
            setCurrentStep(0);
            setEconomicStatus(null);
            setTasksStatus(null);
            setChanges({});
          }
        })}
        rowClassName={(record) => record.id === selectedDeal?.id ? 'selected-row' : ''}
      />

      {selectedDeal && (
        <div className="migration-form">
          <h2>Migrating: {selectedDeal.name}</h2>
          <Steps current={currentStep}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {loading ? <Spin /> : steps[currentStep].content}
          </div>
        </div>
      )}

      <Modal
        title="Confirm Save"
        open={confirmVisible}
        onOk={confirmSave}
        onCancel={() => setConfirmVisible(false)}
      >
        {renderChanges()}
      </Modal>

      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => setAssigneeModalVisible(false)}
        onSave={(selectedUsers) => {
          if (currentEditingTaskIndex !== null && selectedUsers.length > 0) {
            const newTasks = [...editedTasks];
            const selectedUser = users.find(u => u.user_id === selectedUsers[0]);
            newTasks[currentEditingTaskIndex].assignee = selectedUser.user_id;
            setEditedTasks(newTasks);
          }
          setAssigneeModalVisible(false);
          setCurrentEditingTaskIndex(null);
        }}
        initialAssignees={currentEditingTaskIndex !== null ? 
          [editedTasks[currentEditingTaskIndex]?.assignee] : 
          []
        }
        users={users}
        maxAssignees={1}
      />
    </div>
  );
};

export default MigrateDeal;