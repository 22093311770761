import React from 'react';
import { Layout, Typography, Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams, useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import FlowList from './flows/FlowList';
import FlowBuilder from './flows/FlowBuilder';
import FlowRuns from './flows/FlowRuns';
import { FlowProvider } from './contexts/FlowContext';
import { useFlow } from './hooks/useFlow';
import './Automations.css';

const { Content } = Layout;
const { Title } = Typography;

function FlowBuilderWrapper({ isNew }) {
  const { flowId } = useParams();
  const navigate = useNavigate();
  const { flow, loading, error } = useFlow(isNew ? null : flowId);

  const handleCloseBuilder = () => {
    navigate('/flows');
  };

  if (!isNew && loading) {
    return <Spin size="large" />;
  }

  if (!isNew && error) {
    return <div>Error loading flow</div>;
  }

  return (
    <FlowProvider>
      <FlowBuilder 
        flow={flow} 
        onClose={handleCloseBuilder}
        isNew={isNew}
      />
    </FlowProvider>
  );
}

function Automations() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateFlow = () => {
    navigate('/flows/new');
  };

  const handleEditFlow = (flow) => {
    navigate(`/flows/${flow.id}`);
  };

  return (
    <Layout className="layout">
      <Content className="automations-content">
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <div className="automations-header">
                  <Title level={2}>Automation Flows</Title>
                  <Button 
                    type="primary" 
                    icon={<PlusOutlined />}
                    onClick={handleCreateFlow}
                  >
                    Create Flow
                  </Button>
                </div>
                <FlowList onEditFlow={handleEditFlow} />
              </>
            } 
          />
          <Route 
            path="/new" 
            element={<FlowBuilderWrapper isNew={true} />} 
          />
          <Route 
            path="/:flowId" 
            element={<FlowBuilderWrapper isNew={false} />} 
          />
          <Route 
            path="/:flowId/runs" 
            element={<FlowRuns />} 
          />
        </Routes>
      </Content>
    </Layout>
  );
}

export default Automations;