import React, { useState, useEffect } from 'react';
import { Modal, Form, Switch, Select, Button, message, Tooltip, Input, Tabs, InputNumber, Space } from 'antd';
import axios from '../api/axiosConfig';
import './UserSettings.css';
import dayjs from 'dayjs';
import UserCapacitySettings from './UserCapacitySettings';

const { Option } = Select;

const preSpecifiedColors = [
  '#1890ff', '#52c41a', '#fa8c16', '#722ed1', '#eb2f96', '#f5222d', '#13c2c2', '#faad14',
  '#2f54eb', '#fa541c', '#237804', '#0050b3', '#391085', '#cf1322', '#006d75', '#ad6800',
  '#1d39c4', '#a8071a', '#5c0011', '#003a8c'
];

function UserSettings({ visible, onClose }) {
  const [form] = Form.useForm();
  const [avatarColor, setAvatarColor] = useState('');
  const [availableColors, setAvailableColors] = useState([]);
  const [users, setUsers] = useState([]);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [currentPasswordVerified, setCurrentPasswordVerified] = useState(false);
  const [passwordForm] = Form.useForm();
  const [capacityData, setCapacityData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [capacityModalVisible, setCapacityModalVisible] = useState(false);
  const [workDays, setWorkDays] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false
  });
  const [activeTab, setActiveTab] = useState('appearance');
  const [capacityForm] = Form.useForm();

  useEffect(() => {
    if (visible) {
      fetchUserSettings();
      fetchAvailableColors();
      fetchUsers();
      fetchCapacityData();
    }
  }, [visible]);

  // Reset states when modal closes
  useEffect(() => {
    if (!visible) {
      // Reset password-related states
      setShowPasswordReset(false);
      setCurrentPasswordVerified(false);
      passwordForm.resetFields();
      
      // Reset form if changes weren't saved
      form.resetFields();
      
      // Reset avatar color to original
      fetchUserSettings();
      
      // Reset active tab to 'appearance'
      setActiveTab('appearance');
    }
  }, [visible]);

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get('/user/settings');
      form.setFieldsValue(response.data);
      setAvatarColor(response.data.avatar_color);
    } catch (error) {
      console.error('Error fetching user settings:', error);
      message.error('Failed to load user settings');
      // Set default values if settings couldn't be fetched
      form.setFieldsValue({
        theme: 'dark',
        language: 'en',
        desktop_notifications: false,
        email_notifications: false,
      });
      setAvatarColor(preSpecifiedColors[0]);
    }
  };

  const fetchAvailableColors = async () => {
    try {
      const response = await axios.get('/user/available-colors');
      setAvailableColors(response.data);
    } catch (error) {
      console.error('Error fetching available colors:', error);
      message.error('Failed to load available colors');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const fetchCapacityData = async () => {
    try {
      const response = await axios.get('/user/capacity');
      setCapacityData(response.data);
      setWorkDays(response.data.default_work_days);
    } catch (error) {
      message.error('Failed to fetch capacity data');
    }
  };

  const handleSubmit = async (values) => {
    try {
      await axios.put('/user/settings', { ...values, avatar_color: avatarColor });
      message.success('Settings updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating user settings:', error);
      message.error('Failed to update settings');
    }
  };

  const getUserInitials = (color) => {
    const user = users.find(u => u.settings?.avatar_color === color);
    return user ? `${user.name?.[0] || ''}${user.surname?.[0] || ''}`.toUpperCase() : '';
  };

  const verifyCurrentPassword = async (values) => {
    try {
      await axios.post('/auth/verify-password', { password: values.currentPassword });
      setCurrentPasswordVerified(true);
      message.success('Current password verified');
    } catch (error) {
      message.error('Current password is incorrect');
    }
  };

  const handlePasswordReset = async (values) => {
    try {
      await axios.post('/auth/reset-password', {
        new_password: values.newPassword
      });
      message.success('Password updated successfully');
      setShowPasswordReset(false);
      setCurrentPasswordVerified(false);
      passwordForm.resetFields();
    } catch (error) {
      message.error('Failed to update password');
    }
  };

  // Modify the onClose handler to ensure clean state reset
  const handleModalClose = () => {
    // Reset all states to their initial values
    setShowPasswordReset(false);
    setCurrentPasswordVerified(false);
    setActiveTab('appearance');
    setCapacityModalVisible(false);
    setSelectedDate(null);
    
    // Reset all forms
    passwordForm.resetFields();
    form.resetFields();
    
    // Call the parent's onClose handler
    onClose();
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setCapacityModalVisible(true);
  };

  const handleCapacityUpdate = async (values) => {
    try {
      const totalMinutes = (values.hours * 60) + (values.minutes || 0);
      await axios.put('/user/capacity/override', {
        date: selectedDate.format('YYYY-MM-DD'),
        capacity: totalMinutes
      });
      message.success('Capacity override updated');
      fetchCapacityData();
      setCapacityModalVisible(false);
    } catch (error) {
      message.error('Failed to update capacity override');
    }
  };

  const handleDefaultCapacityUpdate = async (values) => {
    try {
      const totalMinutes = (values.default_capacity.hours * 60) + 
        (values.default_capacity.minutes || 0);
      
      await axios.put('/user/capacity/default', {
        daily_capacity: totalMinutes,
        work_days: workDays
      });
      message.success('Default capacity updated');
      fetchCapacityData();
    } catch (error) {
      message.error('Failed to update default capacity');
    }
  };

  const dateCellRender = (date) => {
    const dateStr = date.format('YYYY-MM-DD');
    const dayOfWeek = date.format('dddd').toLowerCase();
    const override = capacityData?.daily_overrides?.[dateStr];
    const isWorkDay = capacityData?.default_work_days?.[dayOfWeek];
    const defaultCapacity = capacityData?.default_daily_capacity;

    // If there's no override and it's not a work day, return empty cell
    if (override === undefined && !isWorkDay) {
      return null;
    }

    const capacity = override !== undefined ? override : (isWorkDay ? defaultCapacity : 0);
    const hours = Math.floor(capacity / 60);
    const minutes = capacity % 60;

    let textColor = 'var(--primary-color)';  // default capacity
    if (capacity > defaultCapacity) {
      textColor = 'var(--error-color)';      // over capacity
    } else if (capacity < defaultCapacity && capacity > 0) {
      textColor = 'var(--success-color)';    // under capacity
    }

    return capacity > 0 ? (
      <div className="capacity-cell" style={{ color: textColor }}>
        {`${hours}h ${minutes}m`}
      </div>
    ) : null;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    
    // Calculate initial capacity
    const dayOfWeek = date.format('dddd').toLowerCase();
    const dateStr = date.format('YYYY-MM-DD');
    let initialCapacity = 0;
    
    if (dateStr in capacityData?.daily_overrides) {
      initialCapacity = capacityData.daily_overrides[dateStr];
    } else if (capacityData?.default_work_days[dayOfWeek] === true) {
      initialCapacity = capacityData.default_daily_capacity;
    }
    
    // Reset form with correct values
    capacityForm.setFieldsValue({
      hours: Math.floor(initialCapacity / 60),
      minutes: initialCapacity % 60
    });
    
    setCapacityModalVisible(true);
  };

  const getInitialCapacity = () => {
    if (!selectedDate || !capacityData) return 0;
    
    const dateStr = selectedDate.format('YYYY-MM-DD');
    const dayOfWeek = selectedDate.format('dddd').toLowerCase();
    
    // First check if there's an override
    if (dateStr in capacityData.daily_overrides) {
      return capacityData.daily_overrides[dateStr];
    }
    
    // Then check if it's a work day
    const isWorkDay = capacityData.default_work_days[dayOfWeek] === true;
    
    // Return default capacity only if it's a work day
    return isWorkDay ? capacityData.default_daily_capacity : 0;
  };

  return (
    <>
      <Modal
        title="User Settings"
        open={visible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        <Tabs 
          activeKey={activeTab}
          onChange={setActiveTab}
        >
          <Tabs.TabPane tab="Appearance" key="appearance">
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item name="theme" label="Default Theme">
                <Select>
                  <Option value="light">Light</Option>
                  <Option value="dark">Dark</Option>
                </Select>
              </Form.Item>
              <Form.Item name="language" label="Language">
                <Select disabled>
                  <Option value="en">English</Option>
                  <Option value="da">Danish</Option>
                </Select>
              </Form.Item>
              <Form.Item name="avatar_color" label="Avatar Color">
                <div className="color-picker">
                  {preSpecifiedColors.map(color => (
                    <Tooltip key={color} title={availableColors.includes(color) ? '' : getUserInitials(color)}>
                      <div
                        className={`color-option ${color === avatarColor ? 'selected' : ''} ${availableColors.includes(color) ? '' : 'unavailable'}`}
                        style={{ backgroundColor: color }}
                        onClick={() => availableColors.includes(color) && setAvatarColor(color)}
                      >
                        {!availableColors.includes(color) && getUserInitials(color)}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save Appearance Settings
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Notifications" key="notifications">
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item name="desktop_notifications" valuePropName="checked" label="Desktop Notifications">
                <Switch disabled />
              </Form.Item>
              <Form.Item name="email_notifications" valuePropName="checked" label="Email Notifications">
                <Switch disabled />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save Notification Settings
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Account" key="account">
            <div className="account-settings">
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item 
                  name="auto_login" 
                  valuePropName="checked" 
                  label="Stay logged in on this device"
                >
                  <Switch defaultChecked />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save Account Settings
                  </Button>
                </Form.Item>
              </Form>
              
              <Button 
                type="default" 
                onClick={() => setShowPasswordReset(!showPasswordReset)}
                className="reset-password-button"
              >
                Reset Password
              </Button>
              
              {showPasswordReset && (
                <Form
                  form={passwordForm}
                  layout="vertical"
                  onFinish={currentPasswordVerified ? handlePasswordReset : verifyCurrentPassword}
                  className="password-reset-form"
                >
                  {!currentPasswordVerified ? (
                    <Form.Item
                      name="currentPassword"
                      label="Current Password"
                      rules={[{ required: true, message: 'Please input your current password' }]}
                    >
                      <Input.Password />
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        name="newPassword"
                        label="New Password"
                        rules={[{ required: true, message: 'Please input your new password' }]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item
                        name="confirmPassword"
                        label="Confirm New Password"
                        dependencies={['newPassword']}
                        rules={[
                          { required: true, message: 'Please confirm your new password' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Passwords do not match'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {currentPasswordVerified ? 'Update Password' : 'Verify Current Password'}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Capacity" key="capacity">
            <UserCapacitySettings 
              visible={visible} 
              onVisibilityChange={(isVisible) => {
                if (!isVisible) {
                  // This will trigger when the parent modal closes
                  setActiveTab('appearance');
                }
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>

      <Modal
        title={`Set Capacity for ${selectedDate?.format('MMMM D, YYYY')}`}
        open={capacityModalVisible}
        onCancel={() => {
          setCapacityModalVisible(false);
          capacityForm.resetFields();
        }}
        footer={null}
      >
        <Form 
          form={capacityForm}
          onFinish={handleCapacityUpdate} 
          layout="vertical"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space>
              <Form.Item 
                name="hours" 
                label="Hours"
                style={{ marginBottom: 0 }}
              >
                <InputNumber min={0} max={24} />
              </Form.Item>
              <Form.Item 
                name="minutes" 
                label="Minutes"
                style={{ marginBottom: 0 }}
              >
                <InputNumber min={0} max={59} />
              </Form.Item>
            </Space>

            <Space>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button 
                onClick={async () => {
                  try {
                    await axios.put('/user/capacity/override', {
                      date: selectedDate.format('YYYY-MM-DD'),
                      capacity: 0
                    });
                    await fetchCapacityData();
                    setCapacityModalVisible(false);
                    message.success('Set as day off');
                  } catch (error) {
                    message.error('Failed to set day off');
                  }
                }}
              >
                Set to Zero (Day Off)
              </Button>
              <Button 
                onClick={async () => {
                  try {
                    await axios.delete('/user/capacity/override', {
                      data: { date: selectedDate.format('YYYY-MM-DD') }
                    });
                    await fetchCapacityData();
                    setCapacityModalVisible(false);
                    message.success('Reset to default');
                  } catch (error) {
                    message.error('Failed to reset to default');
                  }
                }}
              >
                Reset to Default
              </Button>
            </Space>
          </Space>
        </Form>
      </Modal>
    </>
  );
}

export default UserSettings;
