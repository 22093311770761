import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Table, Avatar, Tag, Button, Dropdown, Input, DatePicker, Select, Tooltip, Spin, Badge, message } from 'antd';
import { DownOutlined, SearchOutlined, ClearOutlined, WarningOutlined, ClockCircleOutlined } from '@ant-design/icons';
import './Work.css';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import axios from '../api/axiosConfig';
import DealDrawer from '../drawers/DealDrawer';
import { boardStatusConfigs } from '../configs/BoardStatusConfig';
import TaskCompleteDrawer from '../taskboards/drawers/TaskCompleteDrawer';
import TaskView from '../taskboards/views/TaskView';
import { useNavigate, useParams } from 'react-router-dom';
import AssigneeSelection from '../modals/AssigneeSelection';
import UserAvatar from '../components/UserAvatar';
import TaskDateEditor from '../components/TaskDateEditor';
import TaskStatusSelector from '../taskboards/components/TaskStatusSelector';
import TimeTrackerCell from '../components/TimeTrackerCell';
const { RangePicker } = DatePicker;

// Register the plugin
dayjs.extend(isBetween);

const getBoardDisplayName = (boardName) => {
  const boardNameMap = {
    'Recurring lon': 'Løn',
    'Recurring bogforing': 'Bogføring',
    'Recurring moms': 'Moms',
    'Recurring arsafslutning': 'Årsafslutning',
    'Personal Task': 'Personal Task',
    'activity': 'Activity',
    'other': 'Andre Opgaver'
  };

  return boardNameMap[boardName] || boardName;
};

function Work() {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedTodo, setSelectedTodo] = useState('todo');
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [customDateRange, setCustomDateRange] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const [userColors, setUserColors] = useState({});
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [filters, setFilters] = useState({
    progress_status: ['Not started', 'In progress']
  });
  const [taskCompleteDrawerVisible, setTaskCompleteDrawerVisible] = useState(false);
  const [selectedTaskForCompletion, setSelectedTaskForCompletion] = useState(null);
  const navigate = useNavigate();
  const { boardType, taskId } = useParams();
  const [isTaskViewVisible, setIsTaskViewVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState(null);

  // Move this useEffect to the top
  useEffect(() => {
    fetchCurrentUser();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (boardType && taskId) {
      const task = allTasks.find(t => t.id.toString() === taskId && getBoardName(t) === boardType);
      if (task) {
        setSelectedTask(task);
        setIsTaskViewVisible(true);
      }
    }
  }, [boardType, taskId, allTasks]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
      setUsersLoaded(true);
      const colors = {};
      response.data.forEach(user => {
        colors[user.user_id] = user.settings?.avatar_color || '#f56a00';
      });
      setUserColors(colors);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsersLoaded(true); // Set to true even on error to allow rendering
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get('/user/current');
      setCurrentUser(response.data);
      setSelectedAssignee([response.data.user_id]);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchTasks = useCallback(async () => {
    try {
      const assigneeIds = selectedAssignee.length > 0 ? selectedAssignee : (currentUser ? [currentUser.user_id] : []);
      const params = new URLSearchParams({
        assignees: assigneeIds.join(','),
      });
      const response = await axios.get(`/work/assigned_tasks?${params}`);
      setAllTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  }, [currentUser, selectedAssignee]);

  // Update the allTasksData useMemo
  const allTasksData = useMemo(() => {
    return allTasks.map(task => ({
      ...task,
      key: task.task_type === 'recurring' 
          ? `${task.task_type}-${task.recurring_type}-${task.id}`
          : `${task.task_type}-${task.id}`,
      board_name: task.task_type === 'recurring' ? `Recurring ${task.recurring_type}` : 
                 task.task_type === 'personal' ? 'Personal Task' :
                 task.task_type === 'activity' ? 'activity' :
                 task.task_type === 'other' ? 'other' : 'Onboarding',
      data: {
        Task: task.description || task.text,
        'Due Date': task.next_due_date || task.due_date || task.task_date,
        'Work Date': task.next_work_date || task.work_date || task.task_date,
        Status: task.progress_status || task.status,
        Assignee: task.assigned_users || [task.user_id],
      },
      deal_id: task.deal_id,
      deal_name: task.deal_name || (task.deal ? task.deal.name : null),
    }));
  }, [allTasks]);

  const getDateRange = (key) => {
    const today = dayjs();
    
    if (!key) return [null, null];

    switch (key) {
      case 'today':
        return [today.startOf('day'), today.endOf('day')];
      case 'thisMonth':
        return [today.startOf('month'), today.endOf('month')];
      case 'lastMonth':
        return [
          today.subtract(1, 'month').startOf('month'),
          today.subtract(1, 'month').endOf('month')
        ];
      case 'thisAndNextMonth':
        return [
          today.startOf('month'),
          today.add(1, 'month').endOf('month')
        ];
      case 'next3Months':
        return [today.startOf('day'), today.add(3, 'months').endOf('day')];
      case 'custom':
        if (!customDateRange || !customDateRange[0] || !customDateRange[1]) {
          return [null, null];
        }
        return [
          dayjs(customDateRange[0]),
          dayjs(customDateRange[1])
        ];
      default:
        return [null, null];
    }
  };

  const isTaskInPeriod = (task, period) => {
    try {
      // Ensure we have a valid date to work with
      const rawDueDate = task.data['Due Date'];
      if (!rawDueDate) return false;

      const dueDate = dayjs(rawDueDate);
      if (!dueDate.isValid()) return false;

      const [startDate, endDate] = getDateRange(period);
      if (!startDate || !endDate) return false;

      // Use inclusive comparison
      return dueDate >= startDate && dueDate <= endDate;
    } catch (error) {
      console.error('Error in isTaskInPeriod:', error);
      return false;
    }
  };

  const isTaskOverdue = (task) => {
    const dueDate = dayjs(task.data['Due Date']);
    return dueDate.isBefore(dayjs(), 'day');
  };

  const filteredTasks = useMemo(() => {
    return allTasksData.filter((task) => {
      const taskDescription = task.data.Task || task.description || '';
      const boardName = task.board_name || '';
      
      const matchesSearch = taskDescription.toLowerCase().includes(searchText.toLowerCase()) ||
        boardName.toLowerCase().includes(searchText.toLowerCase());
      
      const matchesPeriod = selectedPeriod ? isTaskInPeriod(task, selectedPeriod) : true;
      
      const matchesTodo = selectedTodo === 'todo' ? task.data.Status !== 'Completed' :
                         selectedTodo === 'done' ? task.data.Status === 'Completed' :
                         selectedTodo === 'overdue' ? isTaskOverdue(task) : true;
      
      return matchesSearch && matchesPeriod && matchesTodo;
    });
  }, [allTasksData, searchText, selectedPeriod, selectedTodo]);

  const sortedAndFilteredTasks = useMemo(() => {
    return filteredTasks.sort((a, b) => {
      const dateA = dayjs(a.data['Work Date'] || a.work_date);
      const dateB = dayjs(b.data['Work Date'] || b.work_date);
      return dateA.diff(dateB);
    });
  }, [filteredTasks]);

  useEffect(() => {
    if (currentUser && !isTaskViewVisible) {
      fetchTasks();
    }
  }, [currentUser, fetchTasks, isTaskViewVisible]);

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const response = await axios.get('/user');
        setAssignees(response.data);
      } catch (error) {
        console.error('Error fetching assignees:', error);
      }
    };
    fetchAssignees();
  }, []);

  const getRowClassName = (record) => {
    const dueDate = dayjs(record.data['Due Date']);
    const workDate = dayjs(record.data['Work Date'] || record.work_date);
    const today = dayjs().startOf('day');
    const status = record.data.Status;

    if (workDate.isSame(today, 'day') || 
        (dueDate.isBefore(today) && status !== 'Completed') || 
        (workDate.isBefore(today) && status !== 'Completed')) {
      return 'needs-attention';
    }

    if (dueDate.isSame(today, 'day')) {
      return 'due-today';
    } else if (dueDate.isBefore(today)) {
      return 'overdue';
    }

    return '';
  };

  const handleDealClick = (dealId) => {
    setSelectedDeal(dealId);
    setDealDrawerVisible(true);
  };

  const getUniqueValues = (data, key) => {
    if (key === 'board_name') {
      const uniqueBoards = new Set(data.map(item => item[key]));
      return Array.from(uniqueBoards).map(boardName => ({
        text: getBoardDisplayName(boardName),
        value: boardName
      }));
    }
    const values = new Set(data.map(item => item.data[key]));
    return Array.from(values).map(value => ({ text: value, value: value }));
  };

  const handleProgressStatusChange = async (taskId, taskType, newStatus) => {
    if (newStatus === 'Completed') {
      const taskToComplete = allTasks.find(task => task.id === taskId && task.task_type === taskType);
      const enhancedTaskToComplete = {
        ...taskToComplete,
        isRecurring: taskToComplete.task_type === 'recurring',
        recurringType: taskToComplete.recurring_type
      };
      setSelectedTaskForCompletion(enhancedTaskToComplete);
      setTaskCompleteDrawerVisible(true);
    } else {
      try {
        const response = await axios.put('/work/update_progress_status', {
          task_id: taskId,
          task_type: taskType,
          new_status: newStatus
        });
        
        if (response.status === 200) {
          // Update the local state
          setAllTasks(prevTasks => 
            prevTasks.map(task => 
              task.id === taskId && (task.task_type === taskType || 
                (taskType === 'onboarding' && ['activity', 'andre opgaver'].includes(task.task_type)))
                ? { ...task, progress_status: newStatus, status: newStatus }
                : task
            )
          );
          message.success('Progress status updated successfully');
        }
      } catch (error) {
        console.error('Error updating progress status:', error);
        if (error.response && error.response.data && error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error('Failed to update progress status');
        }
      }
    }
  };

  const handleTaskCompletion = async (formData) => {
    try {
      const { id, task_type } = selectedTaskForCompletion;
      const response = await axios.put('/work/update_progress_status', {
        task_id: id,
        task_type: task_type,
        new_status: 'Completed',
        completion_data: formData
      });
      
      if (response.status === 200) {
        setAllTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === id && task.task_type === task_type
              ? { ...task, progress_status: 'Completed', status: 'Completed' }
              : task
          )
        );
        message.success('Task completed successfully');
        setTaskCompleteDrawerVisible(false);
        setSelectedTaskForCompletion(null);
      }
    } catch (error) {
      console.error('Error completing task:', error);
      message.error('Failed to complete task');
    }
  };

  const getBoardName = (task) => {
    if (task.task_type === 'recurring') {
      const recurringTypeMap = {
        'bogforing': 'bogforing',
        'lon': 'lon',
        'moms': 'moms',
        'arsafslutning': 'arsafslutning'
      };
      return recurringTypeMap[task.recurring_type] || task.recurring_type;
    }
    return task.task_type;
  };

  const handleOpenTask = (task) => {
    setSelectedTask(task);
    setIsTaskViewVisible(true);
    navigate(`/work/${getBoardName(task)}/${task.id}`, { 
      replace: true,
      state: { fromWork: true, filteredTasks: sortedAndFilteredTasks }
    });
  };

  const handleCloseTask = () => {
    setIsTaskViewVisible(false);
    setSelectedTask(null);
    navigate('/work', { replace: true });
  };

  const handleAssigneeUpdate = async (newAssignees) => {
    try {
      const response = await axios.put(`/work/update_assignees`, {
        task_id: selectedTaskForAssignee.id,
        task_type: selectedTaskForAssignee.task_type,
        assignees: newAssignees
      });
      
      if (response.status === 200) {
        setAllTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === selectedTaskForAssignee.id && task.task_type === selectedTaskForAssignee.task_type
              ? { ...task, assigned_users: newAssignees }
              : task
          )
        );
        message.success('Assignees updated successfully');
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      message.error('Failed to update assignees');
    }
  };

  const columns = [
    {
      title: 'Task',
      dataIndex: ['data', 'Task'],
      key: 'task',
      render: (text, record) => {
        const dueDate = dayjs(record.data['Due Date']);
        const today = dayjs().startOf('day');
        let badge = null;

        if (dueDate.isBefore(today)) {
          badge = (
            <Tooltip title="Overdue">
              <WarningOutlined className="task-badge overdue-badge" />
            </Tooltip>
          );
        } else if (dueDate.isSame(today, 'day')) {
          badge = (
            <Tooltip title="Due Today">
              <ClockCircleOutlined className="task-badge due-today-badge" />
            </Tooltip>
          );
        }

        return (
          <div className="task-column">
            <span>{text}</span>
            {badge}
          </div>
        );
      },
      onCell: (record) => ({
        onClick: () => handleOpenTask(record),
        className: 'task-column-cell clickable-cell',
      }),
    },
    {
      title: 'Assignee',
      dataIndex: 'assigned_users',
      key: 'assignee',
      render: (assignedUsers, record) => {
        // Handle personal tasks differently
        const assigneeIds = record.task_type === 'personal' 
          ? [record.user_id]  // Use user_id for personal tasks
          : (Array.isArray(assignedUsers) ? assignedUsers : []); // Use assigned_users for other tasks
        
        return (
          <div 
            onClick={() => {
              // Only show assignee selection modal for non-personal tasks
              if (record.task_type !== 'personal') {
                setSelectedTaskForAssignee(record);
                setAssigneeModalVisible(true);
              }
            }}
            style={{ cursor: record.task_type === 'personal' ? 'default' : 'pointer' }}
          >
            <Avatar.Group 
              max={{
                count: 3,
                popover: { trigger: 'click' },
                style: { 
                  color: '#f56a00', 
                  backgroundColor: '#fde3cf',
                  cursor: record.task_type === 'personal' ? 'default' : 'pointer',
                }
              }}
            >
              {assigneeIds.map((userId) => {
                const user = users.find(u => u.user_id === userId);
                
                if (!user) return null;
                
                return (
                  <UserAvatar 
                    key={userId}
                    user={user}
                    size="medium"
                    style={{ 
                      border: '2px solid #fff'
                    }}
                  />
                );
              })}
            </Avatar.Group>
          </div>
        );
      },
    },
    {
      title: 'Deal',
      dataIndex: 'deal_name',
      key: 'deal',
      render: (deal_name, record) => (
        deal_name ? (
          <Tag 
            color="blue" 
            className="deal-tag" 
            onClick={() => handleDealClick(record.deal_id)}
          >
            {deal_name}
          </Tag>
        ) : null
      ),
    },
    {
      title: 'Time Tracker',
      key: 'timeTracker',
      render: (_, record) => <TimeTrackerCell task={record} />
    },
    {
      title: 'Progress',
      dataIndex: ['data', 'Status'],
      key: 'status',
      render: (status, record) => {
        const progressOptions = ['Not started', 'In progress', 'Completed'];
        
        return (
          <Dropdown
            menu={{
              items: progressOptions.map(option => ({
                key: option,
                label: option,
              })),
              onClick: ({ key }) => handleProgressStatusChange(record.id, record.task_type, key),
            }}
          >
            <Tag color={getStatusColor(status)} style={{ cursor: 'pointer' }}>
              {status} ▼
            </Tag>
          </Dropdown>
        );
      },
    },
    {
      title: 'Due Date',
      dataIndex: ['data', 'Due Date'],
      key: 'dueDate',
      render: (date, record) => (
        <TaskDateEditor
          date={date || record.due_date}
          dateType="due"
          taskId={record.id}
          taskType={record.task_type === 'recurring' ? record.recurring_type : record.task_type}
          onSuccess={(updatedTask) => {
            setAllTasks(prevTasks =>
              prevTasks.map(task =>
                task.id === updatedTask.id
                  ? {
                      ...task,
                      ...updatedTask,
                      data: {
                        ...task.data,
                        'Due Date': updatedTask.next_due_date || updatedTask.due_date
                      }
                    }
                  : task
              )
            );
          }}
        />
      ),
      sorter: (a, b) => dayjs(a.data['Due Date']).unix() - dayjs(b.data['Due Date']).unix(),
    },
    {
      title: 'Work Date',
      dataIndex: ['data', 'Work Date'],
      key: 'workDate',
      render: (date, record) => (
        <TaskDateEditor
          date={date || record.work_date}
          dateType="work"
          taskId={record.id}
          taskType={record.task_type === 'recurring' ? record.recurring_type : record.task_type}
          onSuccess={(updatedTask) => {
            setAllTasks(prevTasks =>
              prevTasks.map(task =>
                task.id === updatedTask.id
                  ? {
                      ...task,
                      ...updatedTask,
                      data: {
                        ...task.data,
                        'Work Date': updatedTask.next_work_date || updatedTask.work_date
                      }
                    }
                  : task
              )
            );
          }}
        />
      ),
      sorter: (a, b) => dayjs(a.data['Work Date'] || a.work_date).unix() - dayjs(b.data['Work Date'] || b.work_date).unix(),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Board',
      dataIndex: 'board_name',
      key: 'board_name',
      render: (boardName, record) => {
        if (record.task_type === 'personal') {
          return (
            <Badge
              count="Todo"
              style={{
                backgroundColor: '#feff9c',
                color: '#1A202C',
                fontFamily: "'Indie Flower', cursive",
                fontWeight: 'bold',
                fontSize: '14px',
                padding: '0 8px',
              }}
            />
          );
        }
        
        // Handle the exception for Onboarding tasks
        if (boardName === 'Onboarding') {
          return getBoardDisplayName(record.task_type);
        }
        
        return getBoardDisplayName(boardName);
      },
      filters: getUniqueValues(allTasksData, 'board_name'),
      filteredValue: filters.board_name || null,
      onFilter: (value, record) => record.board_name === value,
    },
    {
      title: 'Status',
      dataIndex: 'board_status',
      key: 'boardStatus',
      render: (status, record) => {
        // Don't render anything for personal tasks
        if (record.task_type === 'personal') {
          return null;
        }

        // For other tasks, keep the existing logic
        let apiTaskType = record.task_type;
        if (record.task_type === 'recurring') {
          apiTaskType = record.recurring_type;
        } else if (record.task_type === 'onboarding') {
          apiTaskType = record.task_type === 'activity' ? 'activity' : 'other';
        }

        // Determine the correct board status options
        let configKey = apiTaskType;
        if (record.task_type === 'recurring') {
          configKey = record.recurring_type;
        } else if (record.task_type === 'onboarding' || record.task_type === 'other') {
          configKey = 'andreOpgaver';
        } else if (record.task_type === 'activity') {
          configKey = 'activities';
        }

        return (
          <TaskStatusSelector
            value={status}
            taskId={record.id}
            taskType={apiTaskType}
            boardStatusOptions={boardStatusConfigs[configKey] || boardStatusConfigs.bogforing}
            progressStatus={record.progress_status}
            onSuccess={(updatedTask) => {
              // Include the task type information in the updatedTask
              const enhancedUpdatedTask = {
                ...updatedTask,
                task_type: record.task_type,
                recurring_type: record.recurring_type
              };
              handleTaskStatusChange(enhancedUpdatedTask);
            }}
          />
        );
      },
    },
  ];

  const getPeriodLabel = (key) => {
    if (!key) return 'Period';

    switch (key) {
      case 'today':
        return 'To do today';
      case 'thisMonth':
        return 'This month';
      case 'lastMonth':
        return 'Last month';
      case 'thisAndNextMonth':
        return 'This and next month';
      case 'next3Months':
        return 'Next 3 months';
      case 'custom':
        if (customDateRange && customDateRange[0] && customDateRange[1]) {
          return `${customDateRange[0].format('YYYY-MM-DD')} - ${customDateRange[1].format('YYYY-MM-DD')}`;
        }
        return 'Custom Period';
      default:
        return 'Period';
    }
  };

  const periodMenu = {
    items: [
      { key: 'today', label: 'To do today' },
      { key: 'thisMonth', label: 'This month' },
      { key: 'lastMonth', label: 'Last month' },
      { key: 'thisAndNextMonth', label: 'This and next month' },
      { key: 'next3Months', label: 'Next 3 months' },
      {
        key: 'custom',
        label: (
          <div 
            onClick={e => e.stopPropagation()}
            style={{ padding: '8px' }}
          >
            <RangePicker
              value={customDateRange}
              onChange={(dates) => {
                if (dates) {
                  setCustomDateRange(dates);
                  setSelectedPeriod('custom');
                } else {
                  setCustomDateRange(null);
                  setSelectedPeriod(null);
                }
              }}
              allowClear={true}
              format="DD/MM/YYYY"
              getPopupContainer={(trigger) => trigger.parentElement}
              popupClassName="custom-date-picker-dropdown"
            />
          </div>
        ),
      },
    ],
    onClick: (e) => {
      if (e.key !== 'custom') {
        setSelectedPeriod(e.key);
        const [start, end] = getDateRange(e.key);
        setCustomDateRange([start, end]);
      }
    },
  };

  const todoOptions = [
    { key: 'todo', label: 'To do' },
    { key: 'overdue', label: 'Overdue' },
    { key: 'done', label: 'Done' },
  ];

  const todoMenu = {
    items: todoOptions.map(option => ({
      key: option.key,
      label: option.label,
    })),
    onClick: (e) => {
      setSelectedTodo(e.key);
      // Update the progress_status filter when 'done' is selected
      if (e.key === 'done') {
        setFilters({
          ...filters,
          progress_status: ['Completed']
        });
      } else if (e.key === 'todo') {
        setFilters({
          ...filters,
          progress_status: ['Not started', 'In progress']
        });
      } else {
        // For 'overdue', don't set any progress status filter
        setFilters({
          ...filters,
          progress_status: null
        });
      }
    },
  };

  const renderMultiSelect = (options, placeholder, value, setValue) => ({
    items: [
      {
        key: 'content',
        label: (
          <div className="multi-select-dropdown" onClick={(e) => e.stopPropagation()}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={placeholder}
              value={value}
              onChange={setValue}
              options={options.map((option) => ({ 
                label: option.name && option.surname ? `${option.name} ${option.surname}` : option.full_name || `User ${option.user_id}`, 
                value: option.user_id 
              }))}
            />
            <div className="multi-select-actions">
              <Button onClick={() => setValue(options.map(o => o.user_id))}>All</Button>
              <Button onClick={() => setValue([])}>None</Button>
            </div>
          </div>
        ),
      },
    ],
  });

  const renderDropdown = (menu, buttonText, className = '') => (
    <Dropdown 
      menu={menu} 
      trigger={['click']}
    >
      <Button className={className}>
        {buttonText} <DownOutlined />
      </Button>
    </Dropdown>
  );

  const clearFilters = () => {
    setSelectedPeriod(null);
    setSelectedTodo('todo');
    setSelectedAssignee([currentUser?.user_id].filter(Boolean));
    setSearchText('');
    setCustomDateRange(null);
    // Reset the filters state to include default progress_status values
    setFilters({
      progress_status: ['Not started', 'In progress'],
      board_name: undefined  // Reset board name filter
    });
  };

  // Helper function to get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In progress':
        return 'orange';
      case 'Not started':
        return 'red';
      default:
        return 'default';
    }
  };

  const handleTaskStatusChange = (updatedTask) => {
    // Update tasks state optimistically
    setAllTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === updatedTask.id
          ? { 
              ...task, 
              board_status: updatedTask.board_status,
              progress_status: updatedTask.progress_status,
              data: {
                ...task.data,
                Status: updatedTask.progress_status
              }
            }
          : task
      )
    );

    // If this is a move to the last status, open the completion drawer
    const taskType = updatedTask.task_type === 'recurring' ? updatedTask.recurring_type : updatedTask.task_type;
    const configKey = taskType === 'onboarding' ? 'andreOpgaver' : 
                     taskType === 'activity' ? 'activities' : taskType;
    const boardStatusOptions = boardStatusConfigs[configKey] || boardStatusConfigs.bogforing;

    if (updatedTask.board_status === boardStatusOptions[boardStatusOptions.length - 1].value) {
      const taskToComplete = {
        ...updatedTask,
        task_type: updatedTask.task_type,
        isRecurring: updatedTask.task_type === 'recurring',
        recurringType: updatedTask.recurring_type,
        rollback: updatedTask.rollback
      };
      setSelectedTaskForCompletion(taskToComplete);
      setTaskCompleteDrawerVisible(true);
    }
  };

  const handleCompleteDrawerClose = () => {
    // Rollback the optimistic update when drawer is closed without submission
    if (selectedTaskForCompletion?.rollback) {
      selectedTaskForCompletion.rollback();
    }
    setTaskCompleteDrawerVisible(false);
    setSelectedTaskForCompletion(null);
  };

  if (!usersLoaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="work-content">
      <h1>My Work</h1>
      <div className="filter-container">
        {renderDropdown(periodMenu, getPeriodLabel(selectedPeriod), selectedPeriod ? 'filter-active' : '')}
        {renderDropdown(todoMenu, todoOptions.find(option => option.key === selectedTodo)?.label || 'To do', 'filter-active')}
        {renderDropdown(
          renderMultiSelect(users, 'Select assignee', selectedAssignee, setSelectedAssignee),
          selectedAssignee.length > 0 ? `Assignee (${selectedAssignee.length})` : 'Assignee',
          selectedAssignee.length > 0 ? 'filter-active' : ''
        )}
        <Button 
          icon={<ClearOutlined />} 
          onClick={clearFilters}
          className="clear-filters-button"
        >
          Clear Filters
        </Button>
      </div>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search by account or task name"
        className="work-search-input"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      {/* Always render the table */}
      <div className="table-wrapper">
        <Table
          dataSource={sortedAndFilteredTasks}
          columns={columns}
          loading={loading}
          rowKey={(record) => record.key}
          rowClassName={getRowClassName}
          filteredValue={filters}
          onChange={(pagination, filters, sorter) => {
            setFilters(prevFilters => ({
              ...prevFilters,
              progress_status: filters.progress_status || ['Not started', 'In progress'],
              board_name: filters.board_name
            }));
          }}
        />
      </div>

      {/* Render the TaskView modal */}
      {isTaskViewVisible && selectedTask && (
        <TaskView
          onClose={handleCloseTask}
          taskType={getBoardName(selectedTask)}
          boardStatusOptions={boardStatusConfigs[getBoardName(selectedTask)] || boardStatusConfigs.bogforing}
          task={selectedTask}
          fromWork={true}
          tasks={sortedAndFilteredTasks}
          getBoardName={getBoardName}
          dealName={selectedTask.deal_name || selectedTask.deal?.name}
        />
      )}

      <DealDrawer 
        visible={dealDrawerVisible} 
        onClose={() => setDealDrawerVisible(false)} 
        dealId={selectedDeal}
      />
      <TaskCompleteDrawer
        visible={taskCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={selectedTaskForCompletion}
        onComplete={handleTaskCompletion}
      />
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={handleAssigneeUpdate}
        initialAssignees={selectedTaskForAssignee?.assigned_users || []}
        users={users}
      />
    </div>
  );
}

export default Work;
