import React, { useState, useEffect } from 'react';
import { Table, InputNumber, message, Card, Space, Button, Modal, Form, Select, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import './TaskConfiguration.css';

const { TabPane } = Tabs;

function TaskConfiguration() {
  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const taskTypes = ['Bogføring', 'Løn', 'Moms'];
  const frequencies = ['Monthly', 'Quarterly', 'Semi-annually', 'Yearly'];

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const response = await axios.get('/task-config/');
      setConfigs(response.data);
      setChanges({});
    } catch (error) {
      message.error('Failed to fetch task configurations');
    }
  };

  const handleChange = (configId, field, value) => {
    setChanges(prev => ({
      ...prev,
      [configId]: {
        ...prev[configId],
        [field]: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await axios.put('/task-config/batch', { changes });
      message.success('Configurations updated successfully');
      await fetchConfigs();
    } catch (error) {
      message.error('Failed to update configurations');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = async (values) => {
    try {
      const { task_type, frequency, window_days, workload_hours, workload_minutes } = values;
      const totalMinutes = (workload_hours * 60) + workload_minutes;
      
      const response = await axios.post('/task-config/', {
        task_type,
        frequency,
        window_days,
        workload_minutes: totalMinutes
      });
      
      message.success('Task configuration added successfully');
      setIsModalVisible(false);
      fetchConfigs();
    } catch (error) {
      message.error('Failed to add task configuration');
    }
  };

  const AddTaskModal = () => (
    <Modal
      title="Add New Task Configuration"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Form
        onFinish={handleAdd}
        layout="vertical"
        initialValues={{
          window_days: 14,
          workload_hours: 2,
          workload_minutes: 0
        }}
      >
        <Form.Item
          name="task_type"
          label="Task Type"
          rules={[{ required: true, message: 'Please select task type' }]}
        >
          <Select>
            {taskTypes.map(type => (
              <Select.Option key={type} value={type}>{type}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="frequency"
          label="Frequency"
          rules={[{ required: true, message: 'Please select frequency' }]}
        >
          <Select>
            {frequencies.map(freq => (
              <Select.Option key={freq} value={freq}>{freq}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="window_days"
          label="Window (Days)"
          rules={[{ required: true, message: 'Please enter window days' }]}
        >
          <InputNumber min={1} max={364} />
        </Form.Item>

        <Form.Item label="Workload">
          <Space>
            <Form.Item
              name="workload_hours"
              noStyle
              rules={[{ required: true, message: 'Please enter hours' }]}
            >
              <InputNumber min={0} max={40} addonAfter="hours" />
            </Form.Item>
            <Form.Item
              name="workload_minutes"
              noStyle
              rules={[{ required: true, message: 'Please enter minutes' }]}
            >
              <InputNumber min={0} max={59} addonAfter="min" />
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Add Configuration
            </Button>
            <Button onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );

  const columns = [
    {
      title: 'Task Type',
      dataIndex: 'task_type',
      key: 'task_type',
      sorter: (a, b) => a.task_type.localeCompare(b.task_type),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      sorter: (a, b) => a.frequency.localeCompare(b.frequency),
    },
    {
      title: 'Window (Days)',
      dataIndex: 'window_days',
      key: 'window_days',
      render: (value, record) => (
        <InputNumber
          min={1}
          max={364}
          value={changes[record.id]?.window_days ?? value}
          onChange={(newValue) => handleChange(record.id, 'window_days', newValue)}
        />
      ),
    },
    {
      title: 'Workload',
      dataIndex: 'workload_minutes',
      key: 'workload_minutes',
      render: (value, record) => {
        const currentValue = changes[record.id]?.workload_minutes ?? value;
        const hours = Math.floor(currentValue / 60);
        const minutes = currentValue % 60;
        
        return (
          <Space>
            <InputNumber
              min={0}
              max={40}
              value={hours}
              onChange={(newHours) => {
                const totalMinutes = (newHours * 60) + minutes;
                handleChange(record.id, 'workload_minutes', totalMinutes);
              }}
              addonAfter="hours"
            />
            <InputNumber
              min={0}
              max={59}
              value={minutes}
              onChange={(newMinutes) => {
                const totalMinutes = (hours * 60) + newMinutes;
                handleChange(record.id, 'workload_minutes', totalMinutes);
              }}
              addonAfter="min"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div className="task-configuration">
      <h2 className="task-configuration-title">Task Configuration</h2>
      <Card className="task-configuration-card">
        <div style={{ marginBottom: 16, textAlign: 'right' }}>
          <Button 
            type="primary" 
            onClick={() => setIsModalVisible(true)}
            icon={<PlusOutlined />}
          >
            Add Configuration
          </Button>
        </div>
        <Table
          dataSource={configs}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        <div style={{ marginTop: 16, textAlign: 'right' }}>
          <Button 
            type="primary" 
            onClick={handleSave}
            loading={loading}
            disabled={Object.keys(changes).length === 0}
          >
            Save Changes
          </Button>
        </div>
      </Card>
      <AddTaskModal />
    </div>
  );
}

export default TaskConfiguration;