import React, { useState, useEffect } from 'react';
import { Layout, Tabs, Button, Input, List, DatePicker, message, Card, Typography, Popconfirm, Spin } from 'antd';
import { SendOutlined, DeleteOutlined, RobotOutlined, SaveOutlined, HistoryOutlined, ExperimentOutlined, CopyOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from '../../api/axiosConfig';
import ErpHeader from '../../components/ErpHeader';
import './LinkedinBot.css';

// First, import the avatar image
import mortenAvatar from '../../assets/avatars/morten.jpg';

const { Content } = Layout;
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

function LinkedinBot({ theme, toggleTheme }) {
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [posts, setPosts] = useState([]);
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostDate, setNewPostDate] = useState(null);
  const [generatedPost, setGeneratedPost] = useState('');
  const [expandedPosts, setExpandedPosts] = useState(new Set());
  const [isGenerating, setIsGenerating] = useState(false);
  const [streamingContent, setStreamingContent] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('/tools/linkedin-posts');
      setPosts(response.data);
    } catch (error) {
      message.error('Failed to fetch posts');
    }
  };

  const generatePost = async () => {
    if (!topic || !description) {
        message.warning('Udfyld venligst både emne og beskrivelse');
        return;
    }
    
    try {
        setIsGenerating(true);
        setGeneratedContent('');
        
        // Use axios instead of fetch for consistent error handling
        const response = await axios.post('/tools/generate-post', { 
            topic, 
            description 
        });
        
        if (response.data.success) {
            setGeneratedContent(response.data.response);
        } else {
            message.error('Failed to generate post: ' + (response.data.error || 'Unknown error'));
        }
    } catch (error) {
        // Improved error handling
        const errorMessage = error.response?.data?.error || error.message || 'Unknown error';
        message.error('Error generating post: ' + errorMessage);
        console.error('Generate post error:', error);
    } finally {
        setIsGenerating(false);
    }
  };

  const handleSavePost = async () => {
    if (!newPostContent) {
      message.warning('Please enter post content');
      return;
    }

    try {
      await axios.post('/tools/save-post', {
        content: newPostContent,
        post_date: newPostDate ? newPostDate.format('YYYY-MM-DD') : null  // Only send the date portion
      });
      message.success('Post saved successfully');
      setNewPostContent('');
      setNewPostDate(null);
      fetchPosts();
    } catch (error) {
      message.error('Failed to save post');
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await axios.delete(`/tools/linkedin-posts/${postId}`);
      message.success('Post deleted successfully');
      // Refresh the posts list
      fetchPosts();
    } catch (error) {
      console.error('Delete error:', error);
      message.error('Failed to delete post');
    }
  };

  const togglePostExpansion = (postId) => {
    setExpandedPosts(prev => {
      const newSet = new Set(prev);
      if (newSet.has(postId)) {
        newSet.delete(postId);
      } else {
        newSet.add(postId);
      }
      return newSet;
    });
  };

  const handleUpdateBot = async () => {
    try {
      const response = await axios.post('/tools/update-bot');
      if (response.data.success) {
        message.success('Bot updated successfully with latest posts');
      } else {
        message.error('Failed to update bot');
      }
    } catch (error) {
      message.error('Failed to update bot: ' + error.message);
    }
  };

  const items = [
    {
      key: 'generator',
      label: (
        <span>
          <RobotOutlined />
          Post Generator
        </span>
      ),
      children: (
        <Card className="generation-card">
          <Title level={3}>LinkedIn Post Generator</Title>
          <div className="input-section">
            <Input
              placeholder="Skriv overordnet emne (f.eks. 'Digital transformation i revisionsbranchen')"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              className="topic-input"
            />
            <TextArea
              placeholder="Beskriv hvad indlægget skal handle om. Inkluder gerne:
• Hovedbudskabet du vil formidle
• Din personlige holdning eller erfaring med emnet
• Hvilke pointer du gerne vil have med
• Evt. konkrete eksempler du vil inkludere
• Målgruppen for indlægget"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={6}
              className="description-input"
            />
            <Button 
              type="primary" 
              icon={<SendOutlined />}
              onClick={generatePost}
              className="generate-button"
            >
              Generate Post
            </Button>
          </div>

          {generatedContent || isGenerating ? (
            <div className="generated-content">
              <Title level={4}>Genereret Indlæg:</Title>
              <Card className="generated-post-card">
                <div className="post-header">
                  <div className="avatar">
                    <img src={mortenAvatar} alt="Morten Krog Kristensen" />
                  </div>
                  <div className="user-info">
                    <h3>Morten Krog Kristensen</h3>
                    <p>Slipper revisorer fri hos Digi-Tal Regnskab</p>
                  </div>
                </div>
                <div className="post-body">
                  {isGenerating ? (
                    <div className="loading-container">
                        <Spin size="large" />
                        <p>Genererer dit LinkedIn opslag...</p>
                    </div>
                  ) : (
                    <Paragraph copyable>{generatedContent}</Paragraph>
                  )}
                </div>
              </Card>
            </div>
          ) : null}
        </Card>
      ),
    },
    {
      key: 'save',
      label: (
        <span>
          <SaveOutlined />
          Save New Post
        </span>
      ),
      children: (
        <Card>
          <Title level={3}>Save LinkedIn Post</Title>
          <div className="save-post-section">
            <DatePicker
              placeholder="Select post date"
              onChange={(date) => setNewPostDate(date)}
              value={newPostDate}
              className="date-picker"
            />
            <TextArea
              placeholder="Paste your LinkedIn post content here"
              value={newPostContent}
              onChange={(e) => setNewPostContent(e.target.value)}
              rows={10}
              className="post-content-input"
            />
            <Button 
              type="primary"
              onClick={handleSavePost}
              className="save-button"
            >
              Save Post
            </Button>
          </div>
        </Card>
      ),
    },
    {
      key: 'posts',
      label: (
        <span>
          <HistoryOutlined />
          View Posts
        </span>
      ),
      children: (
        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Title level={3}>Saved LinkedIn Posts</Title>
            <Button
              type="primary"
              icon={<SyncOutlined />}
              onClick={handleUpdateBot}
            >
              Update Bot
            </Button>
          </div>
          <List
            dataSource={posts}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    ghost
                    icon={<CopyOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(item.content);
                      message.success('Content copied to clipboard');
                    }}
                  >
                    Copy
                  </Button>,
                  <Popconfirm
                    title="Delete this post?"
                    onConfirm={() => handleDeletePost(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button 
                      type="primary"
                      danger
                      ghost
                      icon={<DeleteOutlined />}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                ]}
              >
                <div className="post-preview" onClick={() => togglePostExpansion(item.id)}>
                  <Paragraph className="post-date">
                    {item.post_date ? dayjs(item.post_date).format('YYYY-MM-DD') : 'Draft'}
                  </Paragraph>
                  <div className={`post-content ${expandedPosts.has(item.id) ? 'expanded' : ''}`}>
                    {item.content}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Card>
      ),
    },
  ];

  return (
    <Layout className="linkedin-bot-layout">
      <ErpHeader 
        theme={theme} 
        toggleTheme={toggleTheme} 
        icon={ExperimentOutlined}
        title="Custom GPTs"
      />
      <Content className="linkedin-bot-content">
        <Tabs 
          defaultActiveKey="generator" 
          type="card" 
          className="linkedin-tabs" 
          items={items} 
        />
      </Content>
    </Layout>
  );
}

export default LinkedinBot;
