import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Modal, message, Tabs } from 'antd';
import { ScheduleOutlined, BugOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import './AdminStatus.css';

const { TabPane } = Tabs;

function AdminStatus() {
  const [jobStatuses, setJobStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedJobLogs, setSelectedJobLogs] = useState('');

  useEffect(() => {
    fetchJobStatuses();
  }, []);

  const fetchJobStatuses = async () => {
    try {
      const response = await axios.get('/admin/job-status');
      setJobStatuses(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching job statuses:', error);
      message.error('Failed to fetch job statuses');
      setLoading(false);
    }
  };

  const showLogs = async (jobId) => {
    try {
      const response = await axios.get(`/admin/job-status/${jobId}`);
      setSelectedJobLogs(response.data.logs || 'No logs available');
      setModalVisible(true);
    } catch (error) {
      console.error('Error fetching job logs:', error);
      message.error('Failed to fetch job logs');
    }
  };

  const columns = [
    {
      title: 'Job Name',
      dataIndex: 'job_name',
      key: 'job_name',
      filters: [...new Set(jobStatuses.map(job => job.job_name))].map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.job_name.indexOf(value) === 0,
      sorter: (a, b) => a.job_name.localeCompare(b.job_name),
    },
    {
      title: 'Run Time',
      dataIndex: 'run_time',
      key: 'run_time',
      sorter: (a, b) => dayjs(a.run_time).unix() - dayjs(b.run_time).unix(),
      render: (run_time) => dayjs(run_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color;
        switch (status) {
          case 'success':
            color = 'green';
            break;
          case 'partial_success':
            color = 'orange';
            break;
          case 'error':
            color = 'red';
            break;
          case 'failed':
            color = 'red';
            break;
          default:
            color = 'blue';
        }
        return (
          <Tag color={color}>
            {status.toUpperCase().replace('_', ' ')}
          </Tag>
        );
      },
      filters: [
        { text: 'Success', value: 'success' },
        { text: 'Partial Success', value: 'partial_success' },
        { text: 'Failed', value: 'failed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showLogs(record.id)}>View Logs</Button>
      ),
    },
  ];

  const ScheduleJobsContent = () => (
    <>
      <h3>Schedule/Jobs Status</h3>
      <Table
        columns={columns}
        dataSource={jobStatuses}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title="Job Logs"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <pre>{selectedJobLogs}</pre>
      </Modal>
    </>
  );

  const ErrorLogsContent = () => {
    const [errorLogs, setErrorLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedError, setSelectedError] = useState(null);

    useEffect(() => {
      fetchErrorLogs();
    }, []);

    const fetchErrorLogs = async () => {
      try {
        const response = await axios.get('/admin/error-logs');
        setErrorLogs(response.data.logs);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching error logs:', error);
        message.error('Failed to fetch error logs');
        setLoading(false);
      }
    };

    const columns = [
      {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (timestamp) => dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss'),
        sorter: (a, b) => dayjs(a.timestamp).unix() - dayjs(b.timestamp).unix(),
      },
      {
        title: 'Endpoint',
        dataIndex: 'endpoint',
        key: 'endpoint',
      },
      {
        title: 'Error Type',
        dataIndex: 'error_type',
        key: 'error_type',
        filters: [...new Set(errorLogs.map(log => log.error_type))].map(type => ({
          text: type,
          value: type,
        })),
        onFilter: (value, record) => record.error_type === value,
      },
      {
        title: 'Error Message',
        dataIndex: 'error_message',
        key: 'error_message',
        ellipsis: true,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <Button onClick={() => {
            setSelectedError(record);
            setModalVisible(true);
          }}>
            View Details
          </Button>
        ),
      },
    ];

    return (
      <>
        <Table
          columns={columns}
          dataSource={errorLogs}
          rowKey="id"
          loading={loading}
        />
        <Modal
          title="Error Details"
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          width={800}
        >
          {selectedError && (
            <div>
              <h4>Stack Trace</h4>
              <pre>{selectedError.stack_trace}</pre>
              <h4>Request Data</h4>
              <pre>{JSON.stringify(selectedError.request_data, null, 2)}</pre>
            </div>
          )}
        </Modal>
      </>
    );
  };

  return (
    <div className="admin-status">
      <h2>Admin Status</h2>
      <Tabs tabPosition="left">
        <TabPane
          tab={
            <span>
              <ScheduleOutlined />
              Schedule/Jobs
            </span>
          }
          key="1"
        >
          <ScheduleJobsContent />
        </TabPane>
        <TabPane
          tab={
            <span>
              <BugOutlined />
              Error Logs
            </span>
          }
          key="2"
        >
          <ErrorLogsContent />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminStatus;
