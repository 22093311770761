import React, { useState, useEffect } from 'react';
import { Modal, Timeline, Typography, Spin, Empty } from 'antd';
import { 
  ClockCircleOutlined,
  SwapRightOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import UserAvatar from '../../components/UserAvatar';
import './BoardsActivity.css';
import { boardStatusConfigs } from '../../configs/BoardStatusConfig';
import CustomScrollbar from '../../components/CustomScrollbar';

dayjs.extend(relativeTime);

const BoardsActivity = ({ visible, onClose, taskType, users }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (visible) {
      fetchActivities();
    }
  }, [visible, taskType]);

  const fetchActivities = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/taskboard/tasks/${taskType}/activity`);
      setActivities(response.data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    const config = boardStatusConfigs[taskType] || [];
    const statusConfig = config.find(s => s.value === status);
    return statusConfig?.color || '#8c8c8c';
  };

  const renderActivityContent = (activity) => {
    const changes = activity.changes || {};
    switch (activity.type) {
      case 'board_status':
        return (
          <div className="activity-content">
            <div className="activity-header">
              <Typography.Text strong>Status Changed</Typography.Text>
              <Typography.Text type="secondary">
                {dayjs(activity.timestamp).fromNow()}
              </Typography.Text>
            </div>
            <div className="activity-details">
              <span 
                className="status-label"
                style={{ backgroundColor: getStatusColor(changes.from) }}
              >
                {changes.from || 'None'}
              </span>
              <SwapRightOutlined className="activity-arrow" />
              <span 
                className="status-label"
                style={{ backgroundColor: getStatusColor(changes.to) }}
              >
                {changes.to || 'None'}
              </span>
            </div>
          </div>
        );
      case 'next_work_date':
      case 'next_due_date':
        const isWorkDate = activity.type === 'next_work_date';
        return (
          <div className="activity-content">
            <div className="activity-header">
              <Typography.Text strong>
                {isWorkDate ? 'Work Date Updated' : 'Due Date Updated'}
              </Typography.Text>
              <Typography.Text type="secondary">
                {dayjs(activity.timestamp).fromNow()}
              </Typography.Text>
            </div>
            <div className="activity-details">
              <Typography.Text delete type="secondary">
                {changes.from ? dayjs(changes.from).format('DD/MM/YYYY') : 'None'}
              </Typography.Text>
              <SwapRightOutlined className="activity-arrow" />
              <Typography.Text>
                {changes.to ? dayjs(changes.to).format('DD/MM/YYYY') : 'None'}
              </Typography.Text>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      title={
        <div className="activity-modal-header">
          <Typography.Title level={4}>Recent Activities</Typography.Title>
          <Typography.Text type="secondary">
            {activities.length} updates in the last 30 days
          </Typography.Text>
        </div>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      className="boards-activity-modal"
      width={600}
      styles={{ body: { overflow: 'hidden' } }}
    >
      <Spin spinning={loading}>
        <CustomScrollbar className="activity-modal-content">
          {activities && activities.length > 0 ? (
            <Timeline
              className="activity-timeline"
              items={activities.map(activity => ({
                dot: <UserAvatar 
                  user={users.find(u => u.user_id === activity.user_id)} 
                  size={24}
                />,
                children: (
                  <div className="timeline-item">
                    <div className="timeline-header">
                      <Typography.Text strong className="deal-name">
                        {activity.deal_name}
                      </Typography.Text>
                      {users.find(u => u.user_id === activity.user_id) && (
                        <Typography.Text type="secondary" className="user-name">
                          {users.find(u => u.user_id === activity.user_id).name} {users.find(u => u.user_id === activity.user_id).surname}
                        </Typography.Text>
                      )}
                    </div>
                    <Typography.Text type="secondary" className="task-description">
                      {activity.task_description}
                    </Typography.Text>
                    {renderActivityContent(activity)}
                  </div>
                )
              }))}
            />
          ) : (
            <Empty 
              description="No recent activities found" 
              className="activity-empty"
            />
          )}
        </CustomScrollbar>
      </Spin>
    </Modal>
  );
};

export default BoardsActivity;