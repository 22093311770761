import React, { useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import UserAvatar from '../../components/UserAvatar';
import CustomScrollbar from '../../components/CustomScrollbar';
import './TaskNotes.css';

const TaskUpdates = ({ visible, onClose, task, taskType, users }) => {
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);

  const getBackendTaskType = (frontendTaskType) => {
    const taskTypeMap = {
      'andre-opgaver': 'other',
      'activities': 'activity',
      'bogforing': 'bogforing',
      'lon': 'lon',
      'moms': 'moms',
      'arsafslutning': 'arsafslutning'
    };
    return taskTypeMap[frontendTaskType] || frontendTaskType;
  };

  const handleSubmit = async () => {
    if (!newComment.trim()) {
      return;
    }

    setLoading(true);
    try {
      const backendTaskType = getBackendTaskType(taskType);
      const response = await axios.post(`/taskboard/tasks/${backendTaskType}/${task.id}/comments`, {
        content: newComment.trim()
      });
      
      if (onClose) onClose(true);
      setNewComment('');
      message.success('Comment added successfully');
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error(error.response?.data?.error || 'Failed to add comment');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const backendTaskType = getBackendTaskType(taskType);
      await axios.delete(`/taskboard/tasks/${backendTaskType}/${task.id}/comments/${commentId}`);
      message.success('Comment deleted successfully');
      if (onClose) onClose(true);
    } catch (error) {
      console.error('Error deleting comment:', error);
      message.error('Failed to delete comment');
    }
  };

  return (
    <Modal
      title="Task Notes & Comments"
      open={visible}
      onCancel={() => onClose(false)}
      footer={null}
      width={600}
    >
      <div className="notes-container">
        <div className="new-comment">
          <Input.TextArea
            placeholder="Write a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSubmit}
            loading={loading}
            disabled={!newComment.trim()}
          >
            Post
          </Button>
        </div>

        <CustomScrollbar className="comments-list-wrapper">
          <div className="comments-list">
            {task?.comments?.length === 0 ? (
              <div className="notes-empty">
                <div className="empty-message">No comments yet</div>
              </div>
            ) : (
              [...(task?.comments || [])].reverse().map((comment) => {
                const user = users.find(u => u.user_id === comment.user_id);
                return (
                  <div key={comment.id} className="comment-item">
                    <UserAvatar user={user} size={32} />
                    <div className="comment-content">
                      <div className="comment-message">{comment.content}</div>
                      <div className="comment-timestamp">
                        {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
                      </div>
                    </div>
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(comment.id)}
                      className="delete-button"
                    />
                  </div>
                );
              })
            )}
          </div>
        </CustomScrollbar>
      </div>
    </Modal>
  );
};

export default TaskUpdates;