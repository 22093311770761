import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';
import { Button, Tabs, message, Tooltip, Checkbox, Table, Pagination, Skeleton, Modal } from 'antd';
import { ArrowLeftOutlined, CodeOutlined, CalendarOutlined, DollarOutlined, FieldTimeOutlined, MailOutlined, FormOutlined, FileAddOutlined, UserOutlined, CheckCircleOutlined, SafetyOutlined, LockOutlined, HomeOutlined, EnvironmentOutlined, PhoneOutlined, InboxOutlined } from '@ant-design/icons';
import './OnboardingDetails.css';

import OnboardingTasksConfig from './modals/OnboardingTaskConfig';
import OnboardingDealData from './modals/OnboardingDealData';

const DealDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dealData, setDealData] = useState(null);
  const [tasks, setTasks] = useState({});
  const [currentStage, setCurrentStage] = useState(null);
  const [stages, setStages] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activityPagination, setActivityPagination] = useState({ current: 1, total: 0 });
  const [recurringTasksModalVisible, setRecurringTasksModalVisible] = useState(false);

  useEffect(() => {
    fetchDealData();
    fetchActivities();
    fetchStages();
  }, [id]);

  const fetchDealData = async () => {
    try {
      const response = await axios.get(`/onboarding/deal/${id}`);
      setDealData(response.data);
      setCurrentStage(response.data.stage);
      setTasks(response.data.all_tasks);
    } catch (error) {
      console.error('Error fetching deal data:', error);
      message.error('Failed to fetch deal data');
    }
  };

  const fetchActivities = async (page = 1) => {
    try {
      const response = await axios.get(`/onboarding/activities/${id}?page=${page}&per_page=15`);
      setActivities(response.data.activities);
      setActivityPagination({
        current: response.data.current_page,
        total: response.data.total,
        pageSize: 15,
      });
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const fetchStages = async () => {
    try {
      const response = await axios.get('/onboarding/stages');
      setStages(response.data);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const renderProductCard = (product) => (
    <div key={product.id} className="product-card">
      <h4>{product.name}</h4>
      <div className="product-details">
        <p><CodeOutlined className="icon" /> {product.code}</p>
        <p><CalendarOutlined className="icon" /> {product.billing_start_date}</p>
        <p><FieldTimeOutlined className="icon" /> {product.billing_frequency}</p>
      </div>
      <div className="product-pricing">
        <p><DollarOutlined className="icon" /> <span className="price">{product.item_price.toFixed(2)} DKK</span>{' '}per item</p>
        <p><strong>Quantity: </strong>{product.quantity}</p>
        <p className="total-price"><strong>Total: </strong><span className="price">{product.sum.toFixed(2)} DKK</span></p>
      </div>
    </div>
  );

  const getIconComponent = (iconName) => {
    const iconMap = {
      FileAddOutlined,
      CalendarOutlined,
      CheckCircleOutlined,
      UserOutlined,
      MailOutlined,
      SafetyOutlined,
      LockOutlined,
      // Add any other icons you need here
    };
    return iconMap[iconName] || null;
  };

  const renderProgressBar = () => {
    const currentStageIndex = stages.findIndex(stage => stage.id === currentStage);
  
    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          {stages.map((stage, index) => {
            const IconComponent = getIconComponent(stage.icon);
            const isClickable = index < currentStageIndex;
            return (
              <React.Fragment key={stage.id}>
                <Tooltip title={stage.name}>
                  <div 
                    className={`progress-stage ${index === currentStageIndex ? 'current' : index < currentStageIndex ? 'completed' : 'future'}`}
                    onClick={() => isClickable ? handleStageChange(stage.id, stage.name) : null}
                    style={{ cursor: isClickable ? 'pointer' : 'default' }}
                  >
                    <div className="progress-stage-circle">
                      {IconComponent && <IconComponent className="progress-stage-icon" />}
                    </div>
                    {index === currentStageIndex && (
                      <div className="progress-stage-label">{stage.name}</div>
                    )}
                  </div>
                </Tooltip>
                {index < stages.length - 1 && (
                  <div className={`progress-line ${index < currentStageIndex ? 'completed' : ''}`}></div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const handleTaskAction = async (task) => {
    try {
      let result;
      switch (task.action) {
        case 'start_onboarding':
          result = await axios.post(`/onboarding/start/${id}`);
          break;
        case 'create_customer_in_economic':
          window.open(`/invoice-overview/${id}`, '_blank');
          return; // Exit the function early as we're opening a new tab
        case 'configure_tasks':
          setRecurringTasksModalVisible(true);
          return; // Exit the function early as we're opening the modal
        default:
          throw new Error('Unknown task action');
      }
      
      if (result && result.data.success) {
        updateTaskCompletion(currentStage, task.id, true);
        message.success('Task completed successfully');
      }
    } catch (error) {
      console.error('Error performing task action:', error);
      message.error('Failed to complete task');
    }
  };

  const updateTaskCompletion = async (stageId, taskId, completed) => {
    try {
      const updatedTasks = {...dealData.all_tasks};
      updatedTasks[stageId] = updatedTasks[stageId].map(t => 
        t.id === taskId ? { ...t, completed } : t
      );
      setTasks(prevTasks => ({
        ...prevTasks,
        [stageId]: updatedTasks[stageId]
      }));
      await axios.put(`/onboarding/deal/${id}`, { tasks: updatedTasks });
      message.success('Task status updated');
    } catch (error) {
      console.error('Error updating task completion:', error);
      message.error('Failed to update task completion');
    }
  };

  const handleDealDataUpdate = (updatedData) => {
    setDealData(prevData => ({
      ...prevData,
      deal: {
        ...prevData.deal,
        data: {
          ...prevData.deal.data,
          ...updatedData
        }
      }
    }));
  };

  const openForm = (formId) => {
    // Logic to open the form with conditional content based on deal data
    console.log(`Opening form ${formId} with deal data:`, dealData);
  };

  const renderTasks = () => (
    <div className="tasks-section">
      {tasks[currentStage] && tasks[currentStage].length > 0 ? (
        tasks[currentStage].map((task, index) => (
          <React.Fragment key={`${currentStage}-${task.id}`}>
            <div className="task-item">
              {task.type === 'button' ? (
                <>
                  <Button 
                    onClick={() => handleTaskAction(task)}
                    disabled={task.completed}
                    className={`task-button ${task.required ? 'required' : ''} ${task.completed ? 'completed' : ''}`}
                  >
                    {task.task}
                  </Button>
                  {task.description && (
                    <p className="task-description">{task.description}</p>
                  )}
                </>
              ) : task.type === 'form' ? (
                <>
                  <div className="task-content">
                    <Checkbox
                      checked={task.completed}
                      onChange={() => updateTaskCompletion(currentStage, task.id, !task.completed)}
                    >
                      {task.task}
                    </Checkbox>
                    <Button 
                      onClick={() => openForm(task.form_id)}
                      className={`form-button ${task.required ? 'required' : ''} ${task.completed ? 'completed' : ''}`}
                    >
                      Open Form
                    </Button>
                  </div>
                  {task.description && (
                    <p className="task-description">{task.description}</p>
                  )}
                </>
              ) : (
                <>
                  <Checkbox
                    checked={task.completed}
                    onChange={() => updateTaskCompletion(currentStage, task.id, !task.completed)}
                  >
                    {task.task}
                  </Checkbox>
                  {task.description && (
                    <p className="task-description">{task.description}</p>
                  )}
                </>
              )}
            </div>
            {index < tasks[currentStage].length - 1 && <div className="task-connector"></div>}
          </React.Fragment>
        ))
      ) : (
        <p>No tasks available for this stage.</p>
      )}
      <div className="stage-actions">
        {canProgressToNextStage() && (
          <Button onClick={progressToNextStage} type="primary">
            Progress to Next Stage
          </Button>
        )}
        <Button onClick={skipToNextStage} type="default" className="skip-button">
          Skip to Next Stage
        </Button>
      </div>
    </div>
  );

  const canProgressToNextStage = () => {
    const currentStageConfig = stages.find(stage => stage.id === currentStage);
    if (!currentStageConfig || !currentStageConfig.can_progress) return false;

    if (currentStageConfig.progress_condition === 'all_required_tasks_completed') {
      return tasks[currentStage].every(task => !task.required || task.completed);
    }

    // Add other conditions as needed
    return false;
  };

  const updateStageAndTasks = (newStage, newStageName, newTasks) => {
    setCurrentStage(newStage);
    setTasks(prevTasks => ({
      ...prevTasks,
      [newStage]: newTasks
    }));
    setDealData(prevData => ({
      ...prevData,
      stage: newStage,
      stage_name: newStageName,
      all_tasks: {
        ...prevData.all_tasks,
        [newStage]: newTasks
      }
    }));
  };

  const progressToNextStage = async () => {
    if (!canProgressToNextStage()) return;
  
    try {
      const result = await axios.post(`/onboarding/progress-stage/${id}`);
      if (result.data.success) {
        updateStageAndTasks(result.data.new_stage, result.data.new_stage_name, result.data.new_tasks);
        message.success('Progressed to next stage successfully');
      }
    } catch (error) {
      console.error('Error progressing to next stage:', error);
      message.error('Failed to progress to next stage');
    }
  };

  const skipToNextStage = async () => {
    try {
      const result = await axios.post(`/onboarding/skip-stage/${id}`);
      if (result.data.success) {
        updateStageAndTasks(result.data.new_stage, result.data.new_stage_name, result.data.new_tasks);
        message.success('Skipped to next stage successfully');
      }
    } catch (error) {
      console.error('Error skipping to next stage:', error);
      message.error('Failed to skip to next stage');
    }
  };

  const handleStageChange = async (newStage, newStageName) => {
    try {
      const response = await axios.post(`/onboarding/set-stage/${id}`, { stage: newStage });
      if (response.data.success) {
        setCurrentStage(newStage);
        setDealData(prevData => ({
          ...prevData,
          stage: newStage,
          stage_name: newStageName
        }));
        message.success(`Moved to ${newStageName} stage`);
        // Optionally, you might want to refetch activities or other data that might have changed
        fetchActivities();
      }
    } catch (error) {
      console.error('Error changing stage:', error);
      message.error('Failed to change stage');
    }
  };

  const handleBack = () => {
    navigate(-1);  // This will navigate back to the previous page
  };

  const renderAssignee = (assignee) => {
    if (!assignee) return <p>Not assigned</p>;
    
    const avatarColor = assignee.avatar_color || '#f56a00';
    
    return (
      <div>
        <span 
          className="assignee-name"
          style={{ backgroundColor: avatarColor }}
        >
          {`${assignee.name} ${assignee.surname}`}
        </span>
      </div>
    );
  };

  const OnboardingTasksSection = ({ tasks }) => (
    <div className="onboarding-tasks-section">
      <h3><FormOutlined /> Onboarding Tasks</h3>
      <div className="onboarding-tasks-content">
        {tasks ? tasks : "Ingen"}
      </div>
    </div>
  );

  const ContactSection = ({ contact, account }) => (
    <div className="contact-section">
      <h3><UserOutlined /> Contact Information</h3>
      {contact && account ? (
        <div className="contact-info">
          <p><UserOutlined /> {contact.name}</p>
          <p><HomeOutlined /> {account.address}</p>
          <p><EnvironmentOutlined /> {account.city}, {account.zipcode}</p>
          <p><MailOutlined /> {contact.email}</p>
          <p><PhoneOutlined /> {contact.phone}</p>
        </div>
      ) : (
        <p>Loading contact information...</p>
      )}
    </div>
  );

  const CustomerOnboardingConfig = ({ mainMailbox, corpayMailbox, economicCustomerNumber, aircallContact }) => (
    <div className="customer-onboarding-config">
      <h3>Customer Onboarding Configuration</h3>
      <div className="config-grid">
        <div className="config-item">
          <strong>E-conomic Customer Number:</strong>
          <span className={economicCustomerNumber ? 'configured' : 'not-configured'}>
            {economicCustomerNumber || 'Not created'}
          </span>
        </div>
        <div className="config-item">
          <strong>Aircall Contact:</strong>
          <span className={aircallContact ? 'configured' : 'not-configured'}>
            {aircallContact || 'Not created'}
          </span>
        </div>
        <div className="config-item">
          <strong>Bilags Mailbox:</strong>
          <span className={mainMailbox ? 'configured' : 'not-configured'}>
            {mainMailbox || 'Not configured'}
          </span>
        </div>
        <div className="config-item">
          <strong>Corpay Mailbox:</strong>
          <span className={corpayMailbox ? 'configured' : 'not-configured'}>
            {corpayMailbox || 'Not configured'}
          </span>
        </div>
      </div>
    </div>
  );

  const renderSkeletonProgressBar = () => (
    <div className="progress-bar-container">
      <div className="progress-bar skeleton">
        {[...Array(9)].map((_, index) => (
          <React.Fragment key={index}>
            <div className="progress-stage skeleton">
              <Skeleton.Button active={true} size="default" shape="circle" />
            </div>
            {index < 8 && <div className="progress-line skeleton"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const renderSkeletonTasks = () => (
    <div className="tasks-section">
      {[1, 2, 3].map((_, index) => (
        <React.Fragment key={index}>
          <div className="task-item">
            <Skeleton.Input active={true} size="default" block={true} />
          </div>
          {index < 2 && <div className="task-connector"></div>}
        </React.Fragment>
      ))}
    </div>
  );

  const renderSkeletonInfoHeader = () => (
    <div className="info-header">
      {[1, 2, 3, 4].map((_, index) => (
        <div key={index} className="info-item">
          <Skeleton.Input active={true} size="small" style={{ width: '100%', marginBottom: '8px' }} />
          <Skeleton.Input active={true} size="small" style={{ width: '100%' }} />
        </div>
      ))}
    </div>
  );

  const renderSkeletonSection = (title) => (
    <div className="skeleton-section">
      <h3>{title}</h3>
      <div>
        <Skeleton.Input active={true} size="small" block={true} style={{ width: '100%', marginBottom: 8 }} />
        <Skeleton.Input active={true} size="small" block={true} style={{ width: '80%', marginBottom: 8 }} />
        <Skeleton.Input active={true} size="small" block={true} style={{ width: '90%', marginBottom: 8 }} />
        <Skeleton.Input active={true} size="small" block={true} style={{ width: '70%' }} />
      </div>
    </div>
  );

  const renderSkeletonProducts = () => (
    <div className="product-section">
      <div className="product-grid">
        {[1, 2, 3].map((_, index) => (
          <div key={index} className="product-card">
            <Skeleton active={true} paragraph={{ rows: 4 }} />
          </div>
        ))}
      </div>
    </div>
  );

  const activityColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      key: 'user',
      render: (name, record) => `${name} ${record.user.surname}`,
    },
    {
      title: 'Activity',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const renderInfoSection = () => (
    <div className="info-section">
      {dealData ? (
        <>
          <div className="info-header">
            <div className="info-item">
              <p><strong>Creation Date:</strong></p>
              <p>{new Date(dealData?.created_at).toLocaleDateString()}</p>
            </div>
            <div className="info-item">
              <p><strong>Last Update:</strong></p>
              <p>{new Date(dealData?.updated_at).toLocaleDateString()}</p>
            </div>
            <div className="info-item">
              <p><strong>Status:</strong></p>
              <p className={`status status-${dealData?.stage}`}>{dealData?.stage_name}</p>
            </div>
            <div className="info-item">
              <p><strong>Assignee:</strong></p>
              {renderAssignee(dealData?.assignee)}
            </div>
          </div>
          <div className="info-content">
            <div className="info-column">
              <ContactSection 
                contact={dealData?.deal?.contact} 
                account={dealData?.deal?.account} 
              />
              <OnboardingTasksSection tasks={dealData?.onboarding_tasks} />
            </div>
            <div className="info-column">
              <OnboardingDealData 
                dealId={id}
                initialDealData={dealData?.deal?.data}
                onDataUpdate={handleDealDataUpdate}
              />
            </div>
          </div>
          <div className="info-full-width">
            <CustomerOnboardingConfig 
              mainMailbox={dealData?.deal?.main_mailbox}
              corpayMailbox={dealData?.deal?.corpay_mailbox}
              economicCustomerNumber={dealData?.deal?.economic_customer_number}
            />
            <div className="product-section">
              <h3>Products</h3>
              <div className="product-grid">
                {dealData?.deal?.products.map(renderProductCard)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {renderSkeletonInfoHeader()}
          <div className="info-content">
            <div className="info-column">
              {renderSkeletonSection("Contact Information")}
              {renderSkeletonSection("Onboarding Tasks")}
            </div>
            <div className="info-column">
              {renderSkeletonSection("Deal Data")}
            </div>
          </div>
          <div className="info-full-width">
            {renderSkeletonSection("Customer Onboarding Configuration")}
            {renderSkeletonProducts()}
          </div>
        </>
      )}
    </div>
  );

  const tabItems = [
    {
      key: '1',
      label: 'Case',
      children: (
        <div className="case-content">
          <div className="case-actions">
            <h2>Next Steps</h2>
            {dealData ? renderTasks() : renderSkeletonTasks()}
          </div>
          <div className="case-info">
            {renderInfoSection()}
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Activity Log',
      children: (
        <div>
          <Table
            columns={activityColumns}
            dataSource={activities}
            pagination={false}
            className="activity-table"
          />
          <Pagination
            current={activityPagination.current}
            total={activityPagination.total}
            pageSize={activityPagination.pageSize}
            onChange={(page) => fetchActivities(page)}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: 'Notes',
      children: <div>{/* Add notes content here */}</div>,
    },
  ];

  return (
    <div className="onboarding-details-wrapper" style={{ overflow: 'visible', height: 'auto' }}>
      <div className="deal-details-container">
        <div className="deal-header">
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>Back</Button>
          <h1>Onboarding - {dealData?.deal?.name || <Skeleton.Input active={true} size="small" style={{ width: 200 }} />}</h1>
        </div>
        <div className="deal-details">
          {dealData ? renderProgressBar() : renderSkeletonProgressBar()}
          <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
      </div>
      <OnboardingTasksConfig
        dealId={id}
        visible={recurringTasksModalVisible}
        onClose={() => setRecurringTasksModalVisible(false)}
      />
    </div>
  );
};

export default DealDetails;