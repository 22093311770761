import React, { useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, StopOutlined } from '@ant-design/icons';
import { TimeTrackerContext } from '../contexts/TimeTrackerContext';
import './TimeTrackerCell.css';

const TimeTrackerCell = ({ task }) => {
    const { 
        isRunning, 
        isPaused, 
        currentTask,
        startTracking,
        stopTracking,
        pauseTracking,
        resumeTimer
    } = useContext(TimeTrackerContext);

    const isCurrentTask = currentTask?.taskId === task.id;

    const handleStartTimer = () => {
        startTracking({
            taskId: task.id,
            taskType: task.task_type === 'recurring' ? 'recurring' : 'onboarding',
            dealId: task.deal_id,
            recurringType: task.task_type === 'recurring' ? task.recurring_type : null,
            dealName: task.deal_name || task.deal?.name,
            taskDescription: task.description || task.data?.Task
        });
    };

    return (
        <div className="time-tracker-wrapper">
            {isCurrentTask && isRunning ? (
                <div className="time-tracker-controls active">
                    <Tooltip title={isPaused ? "Resume Timer" : "Pause Timer"}>
                        <Button
                            className={`timer-control-table-button ${isPaused ? 'resume-button' : 'pause-button'}`}
                            icon={isPaused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
                            onClick={() => isPaused ? resumeTimer() : pauseTracking()}
                        />
                    </Tooltip>
                    <Tooltip title="Stop Timer">
                        <Button
                            className="timer-control-table-button stop-button"
                            icon={<StopOutlined />}
                            onClick={stopTracking}
                        />
                    </Tooltip>
                </div>
            ) : (
                <Tooltip title="Start Timer">
                    <Button
                        className={`time-tracker-button ${isRunning ? 'faded' : ''}`}
                        icon={<PlayCircleOutlined />}
                        onClick={handleStartTimer}
                        disabled={isRunning && !isCurrentTask}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default TimeTrackerCell;