import React, { useState, useEffect } from 'react';
import { Form, Select, InputNumber, Button, DatePicker, Switch, Table, message, Tag, Input } from 'antd';
import dayjs from 'dayjs';
import axios from '../../api/axiosConfig';
import './TaskSchedule.css';  // Make sure this import is present

const { Option } = Select;

const TaskSchedule = ({ onClose }) => {
  const [form] = Form.useForm();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingTask, setEditingTask] = useState(null);
  const [interval, setInterval] = useState(1);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/taskboard/recurring-tasks');
      setTasks(response.data);  // Show all tasks, both active and inactive
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      message.error('Failed to fetch tasks');
      setLoading(false);
    }
  };

  const handleFrequencyChange = (value) => {
    let newInterval;
    switch (value) {
      case 'QUARTERLY':
        newInterval = 3;
        break;
      case 'BIWEEKLY':
        newInterval = 2;
        break;
      default:
        newInterval = 1;
    }
    setInterval(newInterval);
    form.setFieldsValue({ frequency: { freq: value, interval: newInterval } });
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        ...values,
        next_work_date: values.next_work_date.format('YYYY-MM-DD'),
        next_due_date: values.next_due_date.format('YYYY-MM-DD'),
      };
      await axios.put(`/taskboard/recurring-tasks/${values.id}`, payload);
      message.success('Task updated successfully');
      fetchTasks();
      setEditingTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
      message.error('Failed to update task');
    }
  };

  const resetEditing = () => {
    setEditingTask(null);
    form.resetFields();
  };

  const toggleTaskStatus = async (taskId, currentStatus) => {
    // Optimistically update the UI
    const updatedTasks = tasks.map(task => 
      task.id === taskId ? { ...task, status_active: !currentStatus } : task
    );
    setTasks(updatedTasks);

    try {
      await axios.put(`/taskboard/recurring-tasks/${taskId}/toggle-status`, {
        status_active: !currentStatus
      });
      message.success('Task status updated successfully');
    } catch (error) {
      console.error('Error toggling task status:', error);
      message.error('Failed to update task status');
      
      // Revert the optimistic update
      const revertedTasks = tasks.map(task => 
        task.id === taskId ? { ...task, status_active: currentStatus } : task
      );
      setTasks(revertedTasks);
    }
  };

  const columns = [
    {
      title: 'Deal',
      dataIndex: 'deal_name',
      key: 'deal_name',
      render: (deal_name) => <Tag color="blue">{deal_name}</Tag>,
    },
    { title: 'Task', dataIndex: 'description', key: 'description' },
    {
      title: 'Frequency',
      dataIndex: ['frequency', 'freq'],
      key: 'frequency',
      render: (freq) => {
        const mapping = {
          DAILY: 'Daily',
          WEEKLY: 'Weekly',
          MONTHLY: 'Monthly',
          QUARTERLY: 'Quarterly',
          YEARLY: 'Yearly'
        };
        return mapping[freq] || freq;
      }
    },
    {
      title: 'Next Work Date',
      dataIndex: 'next_work_date',
      key: 'next_work_date',
      render: (date) => dayjs(date).format('YYYY-MM-DD')
    },
    {
      title: 'Next Due Date',
      dataIndex: 'next_due_date',
      key: 'next_due_date',
      render: (date) => dayjs(date).format('YYYY-MM-DD')
    },
    {
      title: 'Active',
      dataIndex: 'status_active',
      key: 'status_active',
      render: (status_active, record) => (
        <Switch
          checked={status_active}
          onChange={() => toggleTaskStatus(record.id, status_active)}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => {
          setEditingTask(record);
          form.setFieldsValue({
            ...record,
            next_work_date: dayjs(record.next_work_date),
            next_due_date: dayjs(record.next_due_date),
          });
        }}>Edit</Button>
      ),
    },
  ];

  return (
    <div className="task-schedule">
      <Table
        dataSource={tasks}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        rowClassName={(record) => {
          return record.status_active ? '' : 'inactive-row';
        }}
      />
      {editingTask && (
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="id" hidden>
            <InputNumber />
          </Form.Item>
          
          {/* 1. Deal as a non-editable tag */}
          <Form.Item label="Deal">
            <Tag color="blue">{editingTask.deal_name}</Tag>
          </Form.Item>
          
          {/* 3. Task description */}
          <Form.Item label="Task">
            <div>{editingTask.description}</div>
          </Form.Item>
          
          <Form.Item name={['frequency', 'freq']} label="Frequency" rules={[{ required: true, message: 'Please select frequency' }]}>
            <Select onChange={handleFrequencyChange}>
              <Option value="DAILY">Daily</Option>
              <Option value="WEEKLY">Weekly</Option>
              <Option value="BIWEEKLY">Every 2 weeks</Option>
              <Option value="MONTHLY">Monthly</Option>
              <Option value="QUARTERLY">Quarterly</Option>
              <Option value="YEARLY">Yearly</Option>
            </Select>
          </Form.Item>
          
          {/* 2. Interval as non-editable, greyed out field */}
          <Form.Item name={['frequency', 'interval']} label="Interval">
            <InputNumber disabled className="greyed-out-input" value={interval} />
          </Form.Item>
          
          <Form.Item name="next_work_date" label="Next Work Date" rules={[{ required: true, message: 'Please select next work date' }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          
          <Form.Item name="next_due_date" label="Next Due Date" rules={[{ required: true, message: 'Please select next due date' }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
            <Button onClick={resetEditing} style={{ marginLeft: '8px' }}>Cancel</Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default TaskSchedule;