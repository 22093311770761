import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Result, Button } from 'antd';
import axios from '../api/axiosConfig';
import DynamicForm from './forms/DynamicForm';

const OnboardingFlow = () => {
  const { formExecutionId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flowFormExecution, setFlowFormExecution] = useState(null);
  const [redirectStatus, setRedirectStatus] = useState(null);

  useEffect(() => {
    const fetchFormExecution = async () => {
      try {
        const { data } = await axios.get(`/automation/flows/forms/${formExecutionId}`);
        console.log('Form execution data:', data);
        setFlowFormExecution(data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch form execution:', err);
        setError('Failed to load form');
        setLoading(false);
      }
    };

    fetchFormExecution();
  }, [formExecutionId]);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      
      const response = await axios.post(
        `/automation/flows/forms/${formExecutionId}/submit`,
        values
      );

      if (response.data.redirect) {
        const { type, url, status, message } = response.data.redirect;
        
        if (status === 'waiting') {
          setRedirectStatus({ url, message });
          startPolling(formExecutionId);
        } else {
          navigate(url);
        }
      } else {
        navigate('/onboarding/cases');
      }
    } catch (err) {
      console.error('Failed to submit form:', err);
      setError('Failed to submit form');
      setLoading(false);
    }
  };

  const startPolling = async (formExecutionId) => {
    const pollInterval = setInterval(async () => {
      const status = await axios.get(`/automation/flows/status/${formExecutionId}`);
      if (status.data.ready_for_next_form) {
        clearInterval(pollInterval);
        navigate(redirectStatus.url);
      }
    }, 2000);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle={error}
        extra={[
          <Button type="primary" onClick={() => navigate('/onboarding/cases')}>
            Back to Cases
          </Button>
        ]}
      />
    );
  }

  if (redirectStatus) {
    return (
      <div className="form-waiting">
        <Spin size="large" />
        <h2>{redirectStatus.message}</h2>
      </div>
    );
  }

  if (flowFormExecution?.status === 'pending') {
    return (
      <div className="onboarding-flow">
        <DynamicForm
          config={flowFormExecution.form_config}
          onSubmit={handleFormSubmit}
          initialValues={{}}
        />
      </div>
    );
  }

  return <Spin size="large" />;
};

export default OnboardingFlow;